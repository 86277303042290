.view {
  .item-list {
    ul {
      @extend %reset-list;
      li {
        h2, h3, h4, h5 {
          @include rem(margin-bottom, $space-half);
        }
      }
    }
  }
}

.view-events {
  .slide {
    border: 1px solid $gray-lighter;
    @include rem(padding, $space-half $pad-half);
  }
}

.pane-views-panes {
  .panel-title {
    @extend %set_layout;
  }
}

.views-exposed-form {
  .views-exposed-widgets {
    @include rem(margin-bottom, $space);
  }
  .views-exposed-widget {
    float: none;
    .form-submit {
      margin: 0;
    }
    input[type=text], input[type=search], input[type=url], input[type=number], textarea{
      width: 100%
    }
    &:last-child {
      padding-right: 0;
    }
  }
}
.ctools-auto-submit-processed {
  .views-exposed-widget .views-submit-button {
    height: 0;
    padding: 0;
  }
}
@include breakpoint($tab) {
  .views-exposed-form {
    .form-checkboxes .form-item, .form-radios .form-item {
      @include rem(margin-bottom, 8px);
    }
    .views-exposed-widgets {
      display: flex;
    }
    .views-exposed-widget {
      .form-submit {
        @include rem(margin-top, 30px);
      }
    }
    fieldset.collapsible {
      @include rem(margin-top, $space-and-half);
    }
    .views-widget-filter-field_topics,
    .views-widget-filter-search_api_views_fulltext {
      width: 45%;
    }
  }
  .view-finding-guide {
    .bef-select-as-links > .form-item {
      display: flex;
      > .form-item {
        @include rem(margin-right, 5px);
        &:after {
          content: '  • ';
          //@include rem(font-size, 14px);
         // color: $gray-light;
        }
        &:first-child,
        &:last-child {
          &:after {
            content: '';
          }
        }
        &:first-child {
          @include rem(margin-right, 10px);
        }
      }
    }
  }
  //.view-slideshow {
  //  @include rem(padding-bottom, $space-half);
  //}
}

//@include breakpoint($desk) {
//  .view-slideshow {
//    @include rem(padding-bottom, $space);
//  }
//}


//.views-view-grid {
//  >.views-row {
//    display: flex;
//    @include rem(margin-bottom, $space);
//    @include breakpoint($small) {
//      margin-bottom: 0;
//    }
//  }
//}


