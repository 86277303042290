/*------------------------------------*\
    $MEDIA ELEMENTS
\*------------------------------------*/

/* Flexible Media */
img, video, object, iframe {
	max-width: 100% !important;
	//height: auto !important;
	border: none;
}

img {
	//height: auto !important;
	height: auto;
}

//iframe {
//	margin-bottom: $space;
//	//max-width: 100%;
//	//height: auto;
//}

figure {
	@include rem(margin, 0 0 $space);
	img {
		border: 1px solid $gray-lighter;
		@include rem(padding, 3px);
	  .slick-slide {
		border-color: #444;
	  }
	}
	display: flex !important;
	flex-direction: column;
	align-items: center;
}

figure,
img {
	&[style="float:right"],
	&[style="float:left"] {
		float: none !important;
	}
}

div[style="text-align:center"] {
	margin-right: auto;
	margin-left: auto;
}

figcaption {
	font-style: italic;
	max-width: 90%;
	@include rem(font-size, $font-size-small);
	line-height: 1.5;
	display: flex;
	justify-content: center;
  flex-flow: column;
	@include rem(margin-top, $space-half);
	p {
		@include rem(margin-bottom, $space-half);
		&:last-child {
			margin-bottom: 0;
		}
	}
	//.panel-region-hero & {
	//  max-width: $max-width;
	//  @include rem(margin, $space auto 0);
	//}
}

//canvas {
//	height: 500px !important;
//}

.wrapper--video, .mejs__video {
	padding-bottom: 56.25%; /* 16:9 */
	position: relative;
	height: 0;
}
.mejs__video {
	&,
	.mejs__overlay {
		width: 100% !important;
		height: 100% !important;
	}
}
.wrapper--video iframe,
.mejs__video video{
		position: absolute;
		top: 0;
		left: 0;
		width: 100% !important;
		height: 100% !important;
		margin: 0;
}

.wrapper--image-text {
	@include rem(margin-bottom, $space);
	.image img {
		border: 1px solid $gray-lighter;
	}
}
.media--loading {
	display: block;
}
@include breakpoint($tab) {
	img[style="float:right"],
	figure[style="float:right"] {
		float: right !important;
		width: 40% !important;
		@include rem(margin, 0 0 $space-half $pad);
		display: block;
	}
	img[style="float:left"],
	figure[style="float:left"] {
		float: left !important;
		width: 40% !important;
		@include rem(margin, 0 $pad $space-half 0);
		display: block;
	}
	.wrapper--image-text {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		align-content: stretch;
		@include rem(grid-gap, $space);
		.image {
			&.right,
			&.left {
				margin: 0;
				float: none;
				width: 100%;
				max-width: 100% !important;
			}
			&.right {
				order: 2;
			}
		}
		.text {
			grid-column-end: span 3;
		}
	}
  //.panel-region-hero figure {
	//@include rem(margin, $space-and-half auto 0);
  //}
}
