.field-label-inline {
  .field-label {
    display: inline-block;
    float: none;
    @include rem(margin-right, $pad-half);
  }
}

.field-type-image {
  line-height: 0;
}
.field-name-content-type,
.field-name-book-parent-content-type{
  @include rem(margin-bottom, $space-half);
  font-style: italic;

}

.field-name-field-tagline{
  font-family: $font-secondary;
  font-style: italic;
  @include rem(font-size,$font-size-h3 );
  line-height: $line-height-h3;
  @include rem(margin-top, $space-half);
  color: $header-color;
}

.inline-block {
  > div {
    display: inline-block;
  }
}
.field-name-field-media-provider {
  @include rem(margin, $space-half 0 $space);
  font-style: italic;
  @include rem(font-size, $font-size-small);
  line-height: $line-height-small;
}

.field-name-field-soundcloud {
  @include rem(margin-bottom, $space-half);
  iframe {width: 100%}
}

.field-name-field-block {
  .sf-menu.menu {
    float: none;
    a {
      padding: 0;
    }
    li{
      background: none;
      @include rem(margin, $space-half 0 $space-half $pad);
      float: none;
      &.sf-depth-2 {
        margin-left: 0;
      }
    }
    > li {
      float: none;
      > ul {
        @include rem(margin-left, $pad);
      }
    }

    ul {
      position: static;
      display: block;
    }
    li,
    ul li{
      background: none;
    }
    li li{
      list-style-type: disc;
      li {
        list-style-type: circle;
        li {
          list-style-type: square;
        }
      }
    }
  }
}

.field-type-entityreference {
  > a {
    display: block;
    @include rem(margin-bottom, $space-half);
  }
}

.field-name-field-contact {
  @include rem(margin-bottom, $space);
  .node {
    > div {
      display: inline-block;
      &:after {
        content: ' • ';
        @include rem(font-size, 14px);
        color: $gray;
      }
    }
    .field-name-field-title {
      @include rem(margin-right, 5px);
      font-weight:600;
    }
    > div:last-child,
    .contextual-links-wrapper,
    .field-name-field-title {
      &:after {
        content: '';
      }
    }
  }


  //.name, .field-name-field-email {
  //  display: inline;
	//
  //}
}

.file-image {
  .view-mode-grid & {
    border: 1px solid $gray-lighter;
    .group-meta {
      @include rem(padding, $space-half 5px);
      display: flex;
      justify-content: space-between;
    }
    .download {
      @include rem(margin-left, $pad-half);
    }
  }
}
.expand-icon {
  @include svg-background('expand');
  display: block;
  @include rem(height, 20px);
  @include rem(width, 20px);
}
span.file > a,
.download a,
a.download{
    @include font-icon("\f019", 25px, 2px, 0, $font-size-base, inherit, inherit);
}
.file-download-link,
.file{
  .file-size {
    display: block;
    @include rem(font-size, 12px);
    line-height: $line-height-base/12px;
    color: $gray-alt;
    @include rem(padding-left, 25px);
  }
}

.mediaelement-download-link {
  @include rem(margin-top, $space-half);
  a {
    @include font-icon("\f019", 25px, 2px, 0, $font-size-base, inherit, inherit);
  }
}

.field-name-field-transcripts {
  @include rem(margin, $space 0);
  .si-tabs{
    &.accordion  {
      .card{
        .tab-pane {
          border: none;
        }
      }
      .card-header .btn {
        background: none;
        color: $text-color;
        @include rem(font-size, $font-size-h4);
        padding-top: 0;
        padding-bottom: 0;
        border: none;
        &:before {
          @include rem(top, 6px);
        }
        &:hover,
        &:focus{
          background: none;
          color: $link-hover;
        }
        &.collapsed {
          background: none;
          border: none;
          &:before {
            @include rem(top, 4px);
          }
        }
      }

    }

  }
}

.field-type-taxonomy-term-reference {
  a {
    &:after {
      content: ' • ';
      @include rem(font-size, 14px);
      color: inherit;
    }
    &:last-child {
      &:after {
        content: '';
      }
    }
  }
}
