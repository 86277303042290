.b-media-wrapper {
  line-height: 1;
}

.teaser{
  position: relative;
  #{headings()} {
    font-family: $font;
    &.secondary {
      font-family: $font-secondary;
    }
  }
  p {
    @include rem(margin, 0 0 $space-half);
    &:last-child {
      margin: 0;
    }
  }
  img {
    display: block;
    width: 100%;

  }
  h3,
  h2{
    margin-bottom: 0;
  }
  a:hover {
    text-decoration: none;
  }
  .preface {
    font-family: $font-secondary;
  }
  a {
    @include link-colors($text-color, $text-color, $text-color, $text-color, $text-color);
  }
  img {
    width: 100% !important;
  }
  .b-media-wrapper, .b-text-wrapper {display: block}
  .b-text-wrapper {
    @include rem(padding,$space-half $pad-half $space);
    span {
      display: block;
      &.btn-text {
        display: inline-block;
      }
      &.date-display-single {
        &,
        span {
          display: inline-block;
        }
      }
      &.date-display-wrapper {
        span {
          display: inline-block;
        }
      }
    }
  }
  .h2, .h3, .h4, h2, h3, h4 {
    @include rem(margin-bottom, $space-half);
  }
  .bg-image {
    height: 66.66vw;
    max-height: 300px;
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .btn-text {
    @include rem(margin-top, $space-half);
  }
  p + p .btn-text {
    margin-top: 0;
  }
  ul {
    @include rem(margin-top, $space-half);
    @include rem(margin-bottom, $space-half);
  }

}

.teaser-long {
  display: flex;
  flex-flow: column;
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  .preface {
    @include rem(font-size, $font-size-small);
  }
  .inner {
    background-color: $gray-lightest;
    border: 1px solid darken($gray-lightest, 2%);
    display: block;
    position: relative;
    z-index: 1;
    border-radius: inherit;
    flex-grow: 1;
    @include link-colors($text-color, $text-color, $text-color, $text-color, $text-color);

    &:after, &:before {
      border-radius: inherit;
      z-index: -1;
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0;
      padding: 0;
    }
    &:after {
      opacity: .0625;
      box-shadow: 0 2px 0 0 #000;
    }
    .panel-region-left-aside &,
    .panel-region-right-aside &,
    #paragraphs-item-5555 & {
      background: transparent;
      border: none;
      //.b-text-wrapper {
      //  @include rem(padding,$space 15px);
      //}
      &:after {
        box-shadow: none;
        opacity: 0;
      }
    }
  }
  &.hover .inner,
  a.inner:hover,
  .inner.hover {
    background-color: $gray-lighter;
    .bg-gray-light & {
      background: none;
    }
  }
  &:focus {
    .inner {
      background-color: $gray-lighter;
      .bg-gray-light & {
        background: none;
      }
    }
  }
  &.file {
    border: none;
  }
  //.description {
  //  @include word-break(break-word);
  //  @include hyphenation;
  //}
  .file .file-size {
    display: none;
  }
  &.animate {
    * {
      color: #fff;
    }
    img {
      display: block;
      transition: transform .5s ease-out;
    }
    .b-text-wrapper {
      transition: all 300ms ease-out;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 16%;
      text-shadow: 1px 1px 3px rgba(black,0.45);
    }
    .b-media-wrapper {
      overflow: hidden;
    }
    .hovertext {
      transition: all 350ms ease-in;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 25%;
      font-weight: 300;
      @include rem(padding, $space-half $pad-half $space);
      opacity: 0;
    }
    .b-text-wrapper,
    .hovertext {
      text-align: center;
      z-index: 10;
    }
    .hovertext.caption {
      text-align: left;
    }
    .h3, .large.hovertext {
      @include rem(font-size, $font-size-h2);
      line-height: $line-height-h2;
    }
    .inner {
      background: #000;
      border: none;
      &:after {
        content: '';
        display: inline-block;
        transition: opacity 300ms ease-in;
        background-image: linear-gradient(
            rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8) 40%,
            rgba(0, 0, 0, 0.95) 65%);
        opacity: .35;
        bottom: 0;
        left: 0;
        right:0;
        top: 0;
        z-index: 1;
      }
      a {	@include link-colors(white, $yellow, $yellow, white, $yellow);}
    }
    .expand-icon {
      @include svg-background('expand-white');
    }
    a:hover,
    a:focus{
      .expand-icon {
        @include svg-background('expand-yellow');
      }
    }
    .display-text {
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 100;
      @include rem(font-size, $font-size-small);
    }
    //&:focus {
    //  &:after {
    //    opacity: .85;
    //  }
    //}
  &:focus .inner,
    .inner:hover {
      //&:after {
      //  opacity: .85;
      //}
      img {
        transform: perspective(1px) scale(1.03);
        transition-duration: 2s
      }
      .b-text-wrapper {
        transform: perspective(1px) translateY(-10px);
        transition: all 100ms ease-out;
        opacity: 0;
      }
      .hovertext {
        transform: perspective(1px) translateY(0);
        opacity: 1;
      }
    }
    &.has-text {
      .b-media-wrapper {
        position: relative;
      }
      .title {
        @include rem(padding, $space-half $pad-half);
        transition: all 300ms ease-out;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 16%;
        z-index: 10;
        text-align: center;
        text-shadow: 1px 1px 3px rgba(black,0.45);
      }
      .caption,
      .b-text-wrapper {
        position: static;
        bottom: 0;
        opacity: 1;
      }
      .inner:hover {
        .b-text-wrapper {
          opacity: 1;
          transform: none;
        }
      }
    }

  }
}
.teaser-long.border{
  background: none;
}


@include breakpoint($tab) {
  .teaser {
    .b-text-wrapper {
      @include rem(padding-bottom, $space-half);
    }
  }
  .teaser-list {
    &.has-media {
      .inner {
        &,
        .paragraphs-item-grid-layout.col-sm-2 &,
        .paragraphs-item-grid-layout.col-sm-2 & {
          display: grid;
          grid-template-columns: 3fr 7fr;
          @include rem(grid-column-gap, $pad);
        }
        .paragraphs-item-grid-layout & {
          display: block;
        }
      }
      .b-text-wrapper {
        padding: 0;
      }
      //.b-media-wrapper {
      //  &,
      //  .paragraphs-item-grid-layout.col-sm-2 &,
      //  .paragraphs-item-grid-layout.col-sm-2 & {
      //    @include rem(padding,$pad-half);
      //    padding-right: 0;
      //  }
      //  .paragraphs-item-grid-layout & {
      //   padding: 0;
      //  }
      //}

      .no-cssgrid & {
        .inner {
          @include clearfix;
        }
        .b-media-wrapper {
          width: 40%;
          float: left;
        }
        .b-text-wrapper {
          width: 69%;
          float: right;
        }
      }
      .panel-region-left-aside &,
      .panel-region-right-aside & {
        .inner {
          display: flex;
          flex-flow: column;
          max-width: 100%;
          width: 100%;
        }
        .b-media-wrapper {
          padding: 0;
        }
        .b-text-wrapper {
          @include rem(padding,$space $pad);
        }
      }
    }
    &.border {
      .b-text-wrapper {
        @include rem(padding, $space-half $pad-half);
        padding-left: 0;
      }
    }
  }
}

@include breakpoint($desk) {
  .teaser .bg-image {
    height: 12vw;
  }

  .teaser-long {
    &.animate.file {
      .inner {
        @include rem(padding-bottom, $space-and-half);
      }
      .display-text {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        @include rem(padding, $space-half $pad-half $space-quarter);
      }
    }
    &.animate.has-text {
      .b-media-wrapper {
        position: static;
      }
      .title {
        transition: all 300ms ease-out;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 25%;
        z-index: 10;
      }
      .hovertext.caption {
        transition: all 350ms ease-in;
        position: absolute;
        left: 0;
        right: 0;
        font-weight: 300;
        @include rem(bottom, $space-and-half);
        @include rem(padding, $space-half $pad-half);
        opacity: 0;
        @include rem(font-size, $font-size-small);
        background: #000;
      }
      &:focus .inner,
      .inner:hover {
        .title {
          transform: perspective(1px) translateY(-20px);
          transition: all 100ms ease-out;
          opacity: 0;
        }
        .hovertext.caption {
          transform: perspective(1px) translateY(0);
          opacity: 1;
        }
      }
      &:focus,
      &:hover {
        overflow: visible;
      }
    }
  }
}
@include breakpoint($wide) {
  .teaser-list {
    &.has-media {
      .inner {
        &,
        .paragraphs-item-grid-layout.col-sm-2 &,
        .paragraphs-item-grid-layout.col-sm-2 & {
          grid-template-columns: 1fr 2fr;
        }
      }
    }
  }
}

