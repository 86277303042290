//Pagination
.pager{
  @extend %reset-list;
  display: flex;
  overflow: hidden;
  @include rem(margin, $space 0 !important);
  .no-flexbox & {
    @extend %clearfix;
  }
  li.pager-item,
  li {
    border: 1px solid darken($gray-light2, 7%);
    border-width: 1px 1px 1px 0;
    background: $gray-light2;
    padding: 0 !important;
    margin: 0 !important;
    &:first-child {
      border-width: 1px 1px 1px 1px;
    }
    .no-flexbox & {
      @include rem(margin, 0 $pad/8);
    }
    text-align: center;
    position: relative;
    &.pager-current,
    &.active {
      @include rem(padding, $space-half $pad-half);
      @include rem(min-width, 50px);
      background: $gray-lighter;
      display: flex;
      align-items: center;
      justify-content: center;
      &:after {
        content: '';
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid darken($gray-light2, 7%);
        position: absolute;
        left: 30%;
        bottom: -1px;
      }
    }
    &.pager-previous,
    &.pager-next,
    &.pager-ellipsis {
      display: none;
    }
    a {
      display: block;
      @include rem(padding, $space-half $pad-half);
      @include rem(min-width, 50px);
      @include link-colors($text-color, $link-hover, $link-hover, $text-color, $text-color);
      &:hover {
        background-color: $gray-lighter;
      }
    }
  }

}


.js .search-results-container .pager {
  @include rem(margin, $space-and-half 0 auto);
  li.pager-item {
    float: none;
    display: none;
  }
  li.pager-item-next {
    display: inline-block !important;
    a {
      @include button($gray-lighter, $gray-dark, darken($gray-lighter, 10%));
     // font-size: $font-size-h4;
      @include rem(padding, $space $pad);
      text-transform: capitalize;
    }
  }
}

.pager-wrapper > .item-list {
  @include rem(margin-bottom, $space);
  display: flex;
  justify-content:center;}
