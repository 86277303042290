.side-nav {
  &,
  select{
    width: 100%;
  }
    p {
    margin: 0;
  }
  h2 {
    margin-bottom: $space-half;
    font-family: $font-secondary;
    @include rem(font-size, $font-size-h4);
    font-weight: 600;
    a {
      &:hover {color:$link-hover}
      @include font-icon("\f101", 24px, 3px, 4px, $font-size-base, $header-color, $link-hover, true);
      &:after {
        color: inherit;
        &:hover {
          color: inherit;
        }
      }
    }
  }

  .sf-accordion-toggle a {
    width: 100%;
    @include button;
    text-transform: uppercase;
    letter-spacing: .1px;
    margin: 0;
    &.sf-expanded {
      background: $blue;
      border-bottom: none;
    }
  }
  .sf-arrows .sf-with-ul:after {
    border: none;
  }
  .menu {
    @extend %reset-list;
    width: 100%;
    background-color: $gray-lightest;
    z-index: 0;
    ul {
      transition: opacity .2s ease-in, max-height .4s ease-in;
      max-height: 0;
      overflow: hidden;
      opacity:0;
      display: block !important;
      position: static;
      width: 100%;
    }
    .menu-mlid-928 {
      ul ul {display: none !important;}
    }
    // start first level
    li {
      transition: all .5s ease-in;
      width: 100%;
      float: none;
      z-index: 0;
      &.active-trail {
        a {
          background-color: darken($gray-lightest, 5%);
        }
        //.active-trail a {
        //  background-color: $blue-lighter;
        //}
      }
      // start second level
      li {
        a {
          @include rem(padding-left, 30px);
        }
      }
      li li a {
        @include rem(padding-left, 45px);
      }
      li li li a {
        @include rem(padding-left, 60px);
      }
      li li li li a {
        @include rem(padding-left, 75px);
      }
      &.active {

        a {
          background-color: darken($gray-lightest, 5%);
        }
        > a {
          color: $burgundy;
          font-weight: 400;
        }
      }
      &.expanded,
      &.menuparent{
        > a {
          @include font-icon("\f078", 24px, 8px, 6px, 14px, $text-color, $burgundy, true, false);
          &:after {
            transition: transform .3s ease-out;
          }
          &.active,
          &.active-trail{
            &:after {
              content: '';
            }
          }
        }
        &.sf-depth-1 {
          > a:after {
            content: '';
          }
        }
      } //end hasChildren
      &.sfHover,
      &.active-trail,
      &.sfHover.active-trail {
        > ul {
          max-height: 9999px;
          opacity: 1;
        }
      }
      &.active-trail {
        > a:after {
          content: '';
        }
      }
      &.sfHover {
        a {
          background-color: darken($gray-lightest, 5%);
        }
        > a:after {
          transform: rotateX(180deg);
        }
      }
    } // end li
    > li {
      border-top:1px solid $gray-lighter;
      > a.active:after {
        content: '';
      }
    }
  }


  //display: none;
  //.node-type-book &,
  //.node-type-exhibition & {
    position: absolute;
    top: 0;
    display: block;
    //.menu .active + ul,
    //.menu-block-wrapper > ul  > li > ul{
    //  max-height: 9999px;
    //  opacity: 1;
    //}
    //.menu-block-wrapper > ul  > li.expanded > a:after{
    //    content: '';
    //}
  //}

  @include  breakpoint($desk) {
    display: block;
    @include clearfix;
    @include rem(margin-bottom, $space-and-half);
    border: 1px solid $gray-lighter;
    border-width: 0 1px 1px;
    .sf-hidden {
      position: static;
      left: auto !important;
      top: auto !important;
    }

    h2 a,
    .menu a {
      background-color: $gray-lightest;
      display: block;
      @include rem(padding, $space-half 15px);
      transition:background-color .5s;
      @include link-colors($text-color, $burgundy, $burgundy, $text-color, $text-color);

      &.active {
        background-color: darken($gray-lightest, 5%);
        color: $burgundy;
        font-weight: 600;
      }
      &:hover{
        background-color: darken($gray-lightest, 5%);
        text-decoration: none;
        font-weight: 400;
      }
    }
    h2 {
      border-top:1px solid $gray-lighter;
      @include rem(font-size, $font-size-base);
      line-height: $baseLineHeight;
      font-family: $font;
      margin: 0;
      font-weight: 400;
      a:after {
        content: '';
      }
    }
    //.node-type-book &,
    //.node-type-exhibition & {
      position: static;
    //}


  }

} //end side-nav
