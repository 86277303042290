.calendar-calendar {
  div.calendar {
//    font-size: em($font-size-small);
    @include rem(font-size, $font-size-small);

  }
  div.day {
    width: auto;
  }
  th.days {
    background: $gray-lighter;
  }

  td {
    color: $text-color;
  }

  .day-view .full td.multi-day,
  .month-view .full td.multi-day,
  .week-view .full td.multi-day {
    div.monthview,
    div.weekview,
    div.dayview {
      background: $blue-lighter;
      color: $text-color;
    }
    .inner {
      .monthview,
      .weekview {
        .cutoff, .continues {
          background: $blue-lighter;
        }
        @include rem(margin-bottom, $space/8);
      }
    }
  }
  .month-view .full .inner,
  .week-view .full .inner,
  .day-view .full .inner {
    .monthview,
    .weekview,
    .dayview {
      border-radius: 0;
    }
  }

  .month-view {
    .month.day {
//      font-size: em($font-size-small);
      @include rem(font-size, $font-size-small);

    }
    .single-day.empty,
    .single-day.no-entry{
      @include rem(height, 70px);
    }
    .full {
      td.date-box.today,
      tr td.single-day.today,
      tr td.multi-day.starts-today{
        border-color: $burgundy;
      }
      tr td.single-day.today {
        border-bottom: 2px solid $burgundy;
      }

      th.days,
      td,
      td.empty {
        border-color: darken($gray-lighter, 10%);
      }

      td.single-day{
        div.monthview {
          background: $blue-lighter;
        }
      }
      td.date-box.empty {
        background: $gray-lightest;
        color: $gray-dark;
      }
    }
  }
  .day-view {
    .dayview {
      .contents {
        a {
          display: block;
        }
      }
    }
  }
  .week-view #multi-day-container,
  .day-view #multi-day-container {
    @include rem(height, 108px);
  }
}
.view {
  .date-nav-wrapper {
    position: relative;
    .date-prev,
    .date-next {
      background: none;
      border-radius: 0;
      position: absolute;
      top: 4px;
      font-size: inherit;
      margin: 0;
      right: auto;
      a {
        padding: 0;
        margin: 0;
        min-width: auto;
        //background-color: $blue-darkest;
        display: inline-block;
        //  height: $font-size-h2;
        @include rem(height, $font-size-h2);
        @include rem(width, $font-size-h2);

        //width: $font-size-h2;
        border-radius: 50%;
        background: {
          color: lighten($blue-darkest, 20%);
          repeat: no-repeat;
          size: contain;
          position: 0 50%;
        }
        @include svg-background('prev');
        &:hover {
          background-color: $blue-darkest;
        }
      }
    }
    .date-prev {
      padding-right: .5em;
      left: 0;
      right: auto;
    }
    .date-next {
      padding-left: .5em;
      left: auto;
      right: 0;

      a {
        @include svg-background('next');
      }
    }
    .date-heading h3 {
      //font-size: em($font-size-h2);
      @include rem(font-size, $font-size-h2);
      line-height: $line-height-h2;
    }
  }
}

.view.view-display-id-panel_pane_quarter {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
  .wrapper--content {
    display: flex;
    position: relative;
    justify-content: space-between
  }
  .wrapper--month {
    width: span(4);
    max-width: span(4);
  }
  .date-nav-wrapper {
    margin: 0;
    .date-nav {
      min-width: auto;
      margin: 0;
      padding: 0;
    }
    .date-next,
    .date-prev {
      @include rem(top, -$space/3);
      a {
        @include rem(height, $font-size-h1);
        @include rem(width, $font-size-h1);
      }
    }
  }
  .date-nav-wrapper,
  .date-nav-wrapper .date-nav,
  .date-nav-wrapper .date-heading,
  .item-list .pager, .pager, .pagination{
    position: static;
  }
  h3, .gamma {
    text-align: center;
  }
}
