.inline-list {
	li{
		display: inline-block;
	}
  &.reset {
    @extend %reset-list;
  }
}

.float-list {
	@include clearfix;
	@extend %reset-list;
	li {
		display: block;
		float: left;
	}
}
/* Social List */
//.social-list {
//	li {
//		margin: 0 0.4rem $space 0;
//	}
//
//	a {
//		font-size: 1.6em;
//	}
//}
li.spacing,
li.spacing-bottom,
li.spacing-extra,
li.spacing-extra{
	padding-bottom: 0;
}

/* Text List */
.reset-list {
	&,
	.item-list & {
		@extend %reset-list;
		li {
			margin: 0;
			@include rem(margin-bottom, $space);
			&.spacing-half {
				@include rem(margin-bottom, $space-half);
			}
			&.spacing-bottom {
				@include rem(margin-bottom, $space-and-half);
			}
			&.spacing {
				@include rem(margin, $space-and-half 0);
			}
			&.no-spacing {
				margin-top: 0;
				margin-bottom: 0;
			}
		}
	}

}

