#page-wrapper {
  position: relative;
}
#first-time,
.container,
.layout-constrain > .content,
.page-constrain .region-content .span-width,
.layout-constrain .section-title,
.page-help,
.node-type-landing .paragraphs-item-accordion-tab{
  @extend %set_layout;
}
.container-wide {
  @include set_layout($max-wide);
}

.layout-default{
  @extend %set_layout;
  .container-wide,
  .layout-constrain > .content {
    width: 100%;
    max-width: 100%;

  }
}

.layout-wide .section-title,
.layout-wide > .content,
.layout-wide > .paragraphs-inner{
  @include set_layout($max-wide);
}

.padding,
.padding-top-bottom{
  @include rem(padding-top, $space !important);
  @include rem(padding-bottom, $space !important);
}

.padding-all {
  @include rem(padding, $space $pad);
}
.padding-all-half {
  @include rem(padding, $space-half $pad-half);
}
.padding-extra {
  @include rem(padding-top, $space !important);
  @include rem(padding-bottom, $space !important);
}
.padding-extra-top {
  @include rem(padding-top, $space !important);
}
.padding-extra-bottom {
  @include rem(padding-bottom, $space !important);
}
.padding-left {
  @include rem(padding-left, $pad);
}
.padding-right {
  @include rem(padding-right, $pad);
}
.spacing,
.spacing-extra {
    @include rem(margin-top, $space !important);
    @include rem(margin-bottom, $space !important);
}

.spacing-extra-top {
    @include rem(margin-top, $space !important);
}
.spacing-extra-bottom {
  .reset-list &, & {
    @include rem(margin-bottom, $space !important);
  }
}
.spacing-half {
    @include rem(margin-top, $space-half !important);
    @include rem(margin-bottom, $space-half !important);
}

.spacing-half-top {
    @include rem(margin-top, $space-half !important);
}
.spacing-half-bottom {
    @include rem(margin-bottom, $space-half !important);
}

.spacing-top{
  @include rem(margin-top, $space !important);
}

.spacing-bottom {
  @include rem(margin-bottom, $space !important);
}

.no-spacing {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.cf, .clearfix {
  @extend %clearfix;
}
.ds-3col-equal > .group-left,
.ds-3col-equal > .group-middle ,
.ds-3col-equal > .group-right,
.ds-2col-stacked-fluid > .group-left,
.ds-2col-stacked-fluid > .group-right{
  float: none;
  width: 100%;
}
.layout-default {
  position: relative;
  &.has-side-nav {
    padding-top: $space-quad;
  }
}
@include breakpoint($tab) {
  .spacing-extra{
    @include rem(margin-top, $space-double !important);
    @include rem(margin-bottom, $space-double !important);
  }
  .spacing-extra-top {
      @include rem(margin-top, $space-double !important);
  }
  .spacing-extra-bottom {
      @include rem(margin-bottom, $space-double !important);
  }
  .padding-extra {
    @include rem(padding-top, $space-double !important);
    @include rem(padding-bottom, $space-double !important);
  }
  .padding-extra-top {
    @include rem(padding-top, $space-double !important);
  }
  .padding-extra-bottom {
    @include rem(padding-bottom, $space-double !important);
  }
  //.layout,
  //.layout > .content {
  //  //@include clearfix;
  //  > .col-sm-6 {
  //    &:nth-child(2n) {
  //      @include last;
  //    }
  //    @include span(6);
	//
  //     .col-sm-8 {
  //       @include span(4 of 6);
  //    }
  //     .col-sm-4 {
  //      @include span(2 of 6);
  //    }
	//
  //  }
  //  > .col-sm-9 {
  //    @include span(9);
  //  }
  //  > .col-sm-3 {
  //    @include span(3);
  //  }
  //  > .col-sm-8 {
  //    @include span(8);
  //  }
  //  > .col-sm-4 {
  //    @include span(4);
  //  }
  //  > .col-sm-5 {
  //    @include span(5);
  //  }
  //  > .col-sm-7 {
  //    @include span(7);
  //  }
  //  .col.last {
  //    @include last;
  //  }
  //}

  .paragraphs-inner .panel-region-right-aside {
    margin-top: 0;
  }
}

@include breakpoint($desk) {
  .l-container-md {
    @include set_layout($max-width);
  }

  //.entity-paragraphs-item .layout > .col {
  //  margin-bottom: 0;
  //}
  //.layout,
  //.layout > .content {
  //  > .col-md-3 {
  //    @include span(3);
  //    &.col-sm-6:nth-child(2n) {
  //      @include span(3);
  //      &.last {
  //        @include last;
  //      }
  //    }
  //  }
  //  > .col-md-4 {
  //    @include span(4);
  //  }
  //  > .col-md-5 {
  //    @include span(5);
  //  }
  //  > .col-md-6 {
  //    @include span(6);
  //  }
  //  > .col-md-7 {
  //    @include span(7);
  //  }
  //  > .col-md-8 {
  //    @include span(8);
  //  }
  //  .width-70-30 & {
  //    > .col-sm-8 {
  //      width: span(9);
  //    }
  //    > .col-sm-4 {
  //      width: span(3);
  //    }
  //  }
  //  > .col-md-9 {
  //    @include span(9);
  //    .col-md-9 & {
  //      @include span(6 of 9);
  //    }
  //  }
  //  > .last,
  //  .last-md {
  //    @include last;
  //  }
  //  &.four-column {
  //    .col-fourth {
  //      margin-right: 0;
  //    }
  //  }
  //}
  .layout-default.has-side-nav {
      padding-top: 0;
    }
}

//@include breakpoint($wide) {
//  .layout,
//  .layout > .content {
//    > .col-wd-3 {
//      @include span(3);
//    }
//    > .col-wd-4 {
//      @include span(4);
//    }
//    > .col.last {
//      @include last;
//    }
//  }
//}
@include breakpoint('all' $bp-mega*1.04) {
  .l-container-wide {
    max-width: 100%;
  }
}
