//.panel-region-hero .pane-node-field-caption-image {
//
//}
.overlay {
  //  @include background-image(linear-gradient(transparent, rgba(black,0.75)));
  .cssgradients & {
    position: absolute;
    @include rem(bottom, -$space);
    left: 0;
    right: 0;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+70,000000+100&0+0,0.4+70,0.98+100 */
    background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 60%, rgba(0,0,0,0.95) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.4) 60%,rgba(0,0,0,0.95) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.4) 60%,rgba(0,0,0,0.95) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#fa000000',GradientType=0 ); /* IE6-9 */
    height: 50%;
  }
}
.pane-page-header-image{
  @include breakpoint('screen' 0) {
    height: 75vw;
    max-height: 450px;
    position: relative;
    overflow: hidden;

    .pane-node-field-image,
    .bg-image{
      position: absolute;
      top: 0;
      bottom: 0;
      left:0;
      right:0;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
    }

    .panel-region-main{
      position: absolute;
      left: 0;
      right: 0;
      @include rem(bottom, $space);
      height: 52.5vw;
      max-height: 315px;
      text-shadow: 1px 1px 3px rgba(black,0.65);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 10;
      * {
        color: white;
      }
      .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 10;
      }
    }
  }


  @include breakpoint('screen' $bp-med) {
    height: 56vw;
    .panel-region-main {
      height: 40vw;
    }
    .container {
      padding-top: 5%;
    }
  }


  @include breakpoint('screen' $bp-large) {
    height: 33vw;
    max-height: 533px;
    .panel-region-main {
      height: 23vw;
      max-height: 373px;
    }
    h1 {
      @include rem(font-size, $font-size-h1-alt);
      line-height: $line-height-h1-alt;
    }
  }

  @include breakpoint('screen' $bp-mega) {
    height: 24vw;
    max-height: 610px;
    .panel-region-main {
      height: 17vw;
      max-height: 430px;
    }
    .overlay {
      @include rem(height, 300px);
    }
  }
} // end hero
