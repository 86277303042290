.panel-region-left-aside,
.panel-region-right-aside{
  @include rem(margin-top,$space-and-half);
  @include rem(margin-bottom,$space-and-half);

  > div,
  .pane-node-field-sidebar > div{
    @include rem(margin-bottom,$space-and-half);
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  ul {
    @include rem(margin, 0 0 $space $pad-and-half);
    &.links {
      margin-left: 0;
    }
  }
  h2 {
    @include rem(margin, 0 auto $space-half);
  }
  .section-title,
  .title {
    background: $header-color;
    color: #fff;
    @include rem(padding, $space-half 15px);
    margin: 0;
  }

  .entity-paragraphs-item {
    border: 1px solid $gray-lighter;
    background: $gray-lightest;
    //padding: em($pad-half) em($pad);
    @include rem(padding, $space-half 15px);
    img {
      border: none;
      padding: 0;
      margin-bottom: 0;
      width: 100%;
    }

    .reset-list li,
    .item-list .reset-list li {
      &:last-child {
        margin-bottom: 0
      }
    }
    &.paragraphs-item-embedded-view ,
    &.paragraphs-item-captioned-image{
      padding: 0;
    }
  }
  figure.entity-paragraphs-item {
    padding: 0;
    figcaption {
      max-width: 100%;
      @include rem(padding, $space-half $pad-half);
    }
  }
  h3,
  h4,
  h5{
    margin-bottom: 0;
  }
  @include breakpoint($tab) {
    margin-top: 0;
  }

}
