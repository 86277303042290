.si-tabs {
  @include rem(margin-bottom, $space);
  .fade {
    transition: opacity 0.15s linear; }
  .fade:not(.show) {
    opacity: 0; }

  .collapse:not(.show) {
    display: none; }

  .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease; }


  .card-body {
    flex: 1 1 auto;
    @include rem(padding, 24px 24px);
  }
  .card-header {
    .btn {
      margin: 0;
      display: block;
      border: none;
      width: 100%;
      text-align: left;
    }
  }

  &.accordion {
    clear: both;
    > .card {
      overflow: hidden;
      margin-bottom: -1px;
      //&:first-of-type {
      //	border-bottom: 0;
      //}
    }
  }


  &.r-tabs {
    .r-tabs-nav {
      display: none;
    }
    .r-tabs-accordion-title {
      display: block;
      .no-js & {
        display: none;
      }
    }
    //.r-tabs-panel {
    //  display: none;
    //  //.no-js & {
    //  //  display: block;
    //  //}
    //}
    .panel-heading {
      @extend h4;
      margin-bottom: 0;
    }
    .r-tabs-panel,
    .tab-content-title {
      transition: opacity .5s, height .8s;
      opacity: 0;
      height: 0;
      overflow: hidden;
      //display: none;
      .no-js & {
        height: 100%;
      }
    }

    .r-tabs-panel.r-tabs-state-active {
      display: block;
      opacity: 1;
      height: 100%;
    }
    /* Disabled tab */
    .r-tabs-nav .r-tabs-state-disabled {
      opacity: 0.5;
    }
  } // end r-tabs

  // Accordion Tabs
  .card-header,
  .r-tabs-accordion-title {
    font-family: $font;
    margin: 0;
    margin-bottom: -1px;
    a,
    .btn {
      @include rem(font-size, $font-size-h3);
      @include rem(padding,$space-half $pad*.75);
      @include rem(padding-left, $pad-and-half);
      &:before {
        font-family: FontAwesome;
            font-style: normal;
            font-weight: 400;
            text-decoration: inherit;
        @include rem(font-size, $font-size-base);
        position: absolute;
        display: inline-block;
        color: inherit;
      }
      &:hover,
      &:focus,
      &:active {
        box-shadow: none;
      }
    }
  }
  .card-header .btn.collapsed,
  .r-tabs-accordion-title a{
    display: block;
    position: relative;
    font-weight: bold;
    background-color: transparent;
    border: none;
    transition: all ease .35s;
    color: $text-color;
    &:before {
      @include rem(top, 20px);
      left: 0;
      content: "\f054";
    }
    //&:after {
    //  content:'';
    //}
    &:hover,
    &:focus,
    &:active {
      background: transparent;
      color: $link-hover;
      box-shadow: none;
    }
  }

  //.card-header .btn.collapsed {
  //  border-bottom: 1px solid $tab-border-color;
  //}

  //.card-header .btn.collapsed:before,
  //.r-tabs-accordion-title a:before {
  //  @include rem(top, 20px);
  //  @include rem(left, 7px);
	//
  //  display: inline-block;
  //    content: "\f054";
  //    color: $text-color;
  //}
  .r-tabs-accordion-title.r-tabs-state-active a,
  .card-header .btn {
    background: transparent;
    //border-color: darken($tab-header-active, 5%);
    color: $tab-header-active;
    &:before {
      @include rem(top, 16px);
      left: 0;
      content: "\f078";
    }
    &:focus {
      box-shadow: none;
    }
  }

  &.accordion .card {
    .tab-pane {
      @include rem(padding, 0 $pad $space);
      @include rem(padding-left, 30px);
      //border: 1px solid $tab-border-color;
      //border-width: 0 1px 1px;
    }
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;

    > hr {
      margin-right: 0;
      margin-left: 0; }
    &:last-child {
      .card-header {
        margin-bottom: 0;
      }
    }
  }
  // End Accordion Tabs
  // Responsive Tabs
  &.responsive-tab {
    .r-tabs-nav {
      // default tab style
      .r-tabs-anchor {
        @include rem(padding, 11px $pad $space-half);
        color: $gray-dark;
        border: 1px solid $gray-lighter;
        border-width: 1px 0 0 1px;
        position: relative;
        background: $gray-lightest;
        top: 2px;
        display: block;
        margin: 0;
        margin-bottom: 1px;
        &:hover {
          box-shadow: none;
          color: $link-hover;
        }
        &:active,
        &:focus {
          box-shadow: none;
        }
      }
      .last .r-tabs-anchor {
        border-right-width: 1px;
      }
      // Active state tab anchor
      .r-tabs-state-active .r-tabs-anchor {
        background: white;
        @include rem(padding, $space-half $pad);
        border-bottom: 1px transparent;
        color: $blue;
      //  bottom: -1px;
        font-weight: bold;
      }
    } //end r-tabs-nav
    //.tab-wrapper {
    //  border-bottom: 1px solid $gray-light;
       .r-tabs-panel.r-tabs-state-active {
        border: 1px solid $gray-lighter;
       // border-width: 1px 1px 0px;
         @include rem(padding, $space $pad);
      }
    //}
  }  // End Responsive Tabs

  @include breakpoint($desk) {
    .tab-content-title {
      display: block;
    }
    &.r-tabs {
      .r-tabs-nav {
        display: flex;
        flex-wrap: nowrap;
      }
      .r-tabs-accordion-title {
        display: none;
      }
      .r-tabs-tab {
        display: flex;
        flex: 0 1 auto;
        // float: left;
      }
      .panel-heading {
        display: flex;
      }
    }
  } //end breakpoint($desk)
} //end .si-tabs
