.flickr-wrap {
	display: block;
	position: relative;
}

span.flickr-credit {
  @include rem(padding, $space-half $pad-half);
  line-height: 1.5;
}
.paragraphs-item-flickr-slideshow {
  .flickr-wrap {
    background: $gray-lightest;
    display: flex;
    justify-content: space-between;
  }
	.flickr-credit {
		text-align: center;
	}
}
.paragraphs-item-flickr-grid,
.flickr-albums{
		.flickr-wrap{
			margin: 0;
			&.overlay-processed {
				overflow: hidden;
			}

			img {
				display: block;
				width: 100% !important;
				height: auto !important;
				min-height: 1px;
			}
		}
		//.inner, .flickr-image {
		//	display: block;
		//}
		.flickr-credit {
			background: rgba(0, 0, 0, 0.5);
			position: absolute;
			@include rem(bottom, 6px);
			left: 0;
			right: 5px;
			line-height: 24px;
			color: white;
			* {
				color: white;
			}
		}

	.teaser-long.animate .hovertext {
		@include rem(font-size, $font-size-small);
		.metatdata {display: block}
	}
		.overlay-processed {
			.flickr-credit {
				-webkit-transition: bottom .3s, opacity .6s;
				transition: bottom .3s, opacity .6s;
				opacity: 0;
				bottom: -999px;
				overflow: hidden;
			}
			&.open {
				.flickr-credit {
					opacity: 1;
					bottom: 6px;
				}
				img {
					transform: perspective(1px) scale(1.03);
					transition-duration: 2s
				}
				.b-text-wrapper {
					transform: perspective(1px) translateY(-10px);
					transition: all 100ms ease-out;
					opacity: 0;
				}
				.hovertext {
					transform: perspective(1px) translateY(0);
					opacity: 1;
					bottom: 0;
				}
			}

		}


}
@include breakpoint($small) {
	.flickr-albums,
	.paragraphs-item-flickr-grid .flickr-photoset {
      @include clearfix;
      .flickr-wrap {
        @include rem(padding, 0 5px 5px 0);
				//  //position: relative;
        // margin: 0 5px 5px 0;
        margin: 0;
      }
    }
  .paragraphs-item-flickr-slideshow {
    .flickr-wrap.grid-item {
      @include rem(margin, 0 5px);
    }
  }
}

