@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-family: "FontAwesome" !default;
$slick-loader-path: "../images/" !default;
$slick-arrow-color: #000 !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 10px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
	@if function-exists(image-url) {
		@return image-url($url);
	} @else {
		@return url($slick-loader-path + $url);
	}
}

/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
	outline: none;
  }

  &.dragging {
	cursor: pointer;
	cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track, .slick-list {
  display: flex;
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  //display: block;
  margin-left: auto;
  margin-right: auto;
  //transition: .25s;
  //&:before,
  //&:after {
	//content: "";
	//display: block;
  //}
  //
  //&:after {
	//clear: none;
  //}

  .slick-loading & {
	visibility: hidden;
  }
}
.slick-slide {
  //float: left;
  height: 100%;
  min-height: 1px;
  //[dir="rtl"] & {
	//float: right;
  //}
  img {
	display: block;
  }
  &.slick-loading img {
	display: none;
  }

  display: none;

  &.dragging img {
	pointer-events: none;
  }

  .slick-initialized & {
	display: block;
  }

  .slick-loading & {
	visibility: hidden;
  }

  .slick-vertical & {
	display: block;
	height: auto;
	border: 1px solid transparent;
  }
}
.slick-arrow.slick-hidden {
  display: none;
}



/* Slider */

.slick-list {
	.slick-loading & {
		background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
	}
}

/* Icons */
@if $slick-font-family == "slick" {
	@font-face {
		font-family: "slick";
		src: slick-font-url("slick.eot");
		src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
		font-weight: normal;
		font-style: normal;
	}
}

.slick, .slick-slider {
	position: relative;
  button {
	box-shadow: none;
	margin: 0;

		&:focus,
		&:active,
		&:hover {
			opacity: 1;
			background-color: transparent;
			box-shadow: none;
			border: none;
		}
  }
}


/* Arrows */

.slick-prev,
.slick-next {
	text-decoration: none;
	display: block;
	position: absolute;
	z-index: 10;
	margin: 0;
	top: 40%;
	width: 30px;
	height: 30px;
	@include opacity(.5);
	overflow: hidden;
	@include svg-background('prev');
	background-color: transparent;
	border: none;
	cursor: pointer;
	@include transition(all 0.3s ease-in-out);
 	&:hover {
	  opacity: .8;
	}
	&.slick-disabled:before {
		opacity: $slick-opacity-not-active;
	}
	//&:before {
	//font-family: $slick-font-family;
	//font-size: 40px;
	//line-height: 1;
	//color: $slick-arrow-color;
	//opacity: $slick-opacity-default;
	//-webkit-font-smoothing: antialiased;
	//-moz-osx-font-smoothing: grayscale;
	//}
}


.slick-prev {
	left: 1%;
	[dir="rtl"] & {
		left: auto;
		right: 1%;
	}

	//&:before {
	//content: $slick-prev-character;
	//[dir="rtl"] & {
	// content: $slick-next-character;
	//}
	//}

}

.slick-next {
	right: 1%;
	[dir="rtl"] & {
		left: 1%;
		right: auto;
	}
	@include svg-background('next');
	//&:before {
	//content: $slick-next-character;
	//[dir="rtl"] & {
	// content: $slick-prev-character;
	//}
	//}
}
.multiple-slides {
	padding: 0 35px;
	.slick-prev {
		left: 0;
	}
	.slick-next {
		right: 0;
	}
}

/* Dots */

.slick-dotted.slick-slider {
	@include rem(margin-bottom, $space-double);
}

.slick-dots {
	position: absolute;
	@include rem(bottom, -$space);
	list-style: none;
	display: block;
	text-align: center;
	padding: 0;
	margin: 0;
	width: 100%;
	z-index: 100;
	line-height: 0;
	li {
		position: relative;
		display: inline-block;
		//height: 12px;
		//width: 12px;
		margin: 0 5px;
		padding: 0;
		cursor: pointer;
		button {
			border: 1px solid #000;
			background: transparent;
			display: block;
			height: 6px;
			width: 6px;
			outline: none;
			line-height: 1;
			color: transparent;
			padding: 4px;
			cursor: pointer;
			border-radius: 6px;
			transition: background .4ms ease-in;
			box-shadow: none;
			.dark-bg & {
				border-color: $gray-lightest;
			}
			&:hover, &:focus {
				outline: none;
				background: #555;
				border: 1px solid #555;
			}
		}
		&.slick-active button {
			background: #000;
			.dark-bg & {
				background: $gray-lightest;
			}
		}
	}
}

.slider-control {
	position: absolute;
	top: 24px;
	line-height: 1;
	right: 1%;
	display: block;
	width: 26px;
	height: 24px;
	opacity: .4;
	background: transparent;
	border: none;
	z-index: 10;
	overflow: hidden;
	cursor: pointer;
	color: #fff;
	text-shadow: 1px 1px rgba(0, 0, 0, .4);
  .node-type-exhibition & {
	color: #000 !important;
  }
	&:before {
		font-family: FontAwesome;
		    font-style: normal;
		    font-weight: normal;
		    text-decoration: inherit;
		font-size: 20px;
		color: inherit;
		top: 0;
		left: 0;
		display: inline-block;
		content: "\f04c";
	}

	&:focus,
	&:active,
	&:hover {
		opacity: 1;
		background-color: transparent;
		box-shadow: none;
		border: none;
	}


	&.play:before {
		content: "\f04b";
	}

}

@include breakpoint($tab) {
	.slick-prev,
	.slick-next {
		width: 40px;
		height: 40px;
	}
	.multiple-slides {
		padding: 0 45px;
	}
}

@include breakpoint($wide) {
	.slick-prev,
	.slick-next {
		width: 60px;
		height: 60px;
	}
	.multiple-slides {
		padding: 0 65px;
	}
}
