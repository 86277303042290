.wrapper--tabs {
  background: $gray-lighter;
}

ul.tabs.primary {
  @extend %set_layout;
  @extend %clearfix;
  border: none;
  li {
    text-align: center;
    border: none;
    background: none;
    display: block;
    float: left;
    padding: 0;
  }
  a {
    display: block;
    padding: $pad-half;
    @include link-colors($gray-light, $link-hover, $link-hover, $gray-light, $gray-light);
    border: none;

    &:hover,
    &.active {
      background: white;
      color: $text-color;
      border: none;
      border-top: 3px solid $blue;
      margin-top: -3px;
    }
  }
}


.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }


.card-body {
  flex: 1 1 auto;
  @include rem(padding, 24px 24px);
}
.card-header {
  .btn {
    margin: 0;
    display: block;
    border: none;
    width: 100%;
    text-align: left;
  }
}

.accordion {
  clear: both;
  > .card {
    overflow: hidden;
    margin-bottom: -1px;
    //&:first-of-type {
    //	border-bottom: 0;
    //}
  }
}
.tabWrapper {
  .tabHeading {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(170px, 1fr) );
    button {
      margin: 0;
    }
    .btn + .btn {
      margin: 0;
    }
    button.btn {
      border: 1px solid desaturate(darken($gray-lighter, 5%), 5%);
      border-bottom-color: #fff;
      background: #fff;

      &.collapsed {
        @include button($gray-lighter, $gray-dark, darken($gray-lighter, 10%));
      }
    }
  }
  .tab-pane {
    border: 1px solid desaturate(darken($gray-lighter, 5%), 5%);
    border-top-color: #fff;
    @include rem(padding, $space $pad);

  }

}
