#block-panels-mini-exhibit-header,
.pane-exhibit-header {
	@extend %set_layout;
  @include rem(padding, $space 0);
  .breadcrumbs {
	margin-bottom: $space;
  }
}
#mini-panel-related_header {
  .field-type-entityreference a {
	@include font-icon("\f100", 24px,0, 6px, $font-size-base, $link-color, $link-hover);

  }
}
.pane-exhibit-title {
  @include rem(margin-bottom, $space);
  &.bg {
	* {
	  color: white
	}
	.exhibit-title {
	  a {
		@include link-colors(white, $link-color-alt, $link-color-alt, white, white)
	  }
	  margin: 0;
	}
	@include rem(padding, $space-half 0);
	.field-name-field-tagline {
	  @include rem(font-size, $font-size-h5);
	  line-height: $line-height-h5;
	  margin: 0;
	}
	.panel-display {
	  @extend %set_layout
	}
  }
}

.pane-node-field-slides {
  .section-title {
	@extend %set_layout;
	text-align: center;
  }
}

.pane-press-header {
  .field-name-field-date-single {
	display: inline-block;
  }
  .pane-node-field-date-single,
  .pane-node-created,
  .pane-node-content-type {
	display: inline-block;
	@include rem(margin, $space 0);
  }
  .pane-node-field-date-single:after,
  .pane-node-created:after {
	content: ' • ';
	@include rem(font-size, 14px);
	color: $gray-light;
  }
}

.pane-node-field-image-ref {
  .h3, p {
	@include rem(margin-bottom, $space-half);
  }
  .colorbox {
	display: block;
	text-align: center;
	@include rem(margin-bottom, $space-half);
	@include breakpoint($tab) {
	  margin-bottom: 0;
	}
  }
}

.pane-taxonomy-term-field-block-content,
.pane-node-field-block-content {
  > .paragraphs-item-captioned-image,
  > .paragraphs-item-caption-text.teaser-long {
	@extend %set_layout;
	@include rem(margin-bottom, $space);
	.panel-region-main.gs8 & {
	  width: 100%;
	}
  }
}

.pane-topics-header {
  border: 1px solid $gray;
  @include rem(margin-top, $space);
  > .panel-display {
	> .gs6:first-child {
	  @include rem(padding, $space-half $pad-half);
	}

  }
  .g-12-ns {
	> .gs6:last-child {
	  background: $gray;
	  color: #fff;
	}
  }

  figure {
	margin: 0;
	img {
	  padding: 0;
	  border: none;
	}
  }
  figcaption {
	margin: 0;
	@include rem(padding, $space-half 0);
  }
}

@include breakpoint($small) {
  .pane-topics-header {
	.g-12-ns {
	  display: block;
	}
  }
}

@include breakpoint($tab) {
  .pane-topics-header {
	.g-12-ns {
	  display: flex;
	  justify-content: space-between;
	  > .gs6:first-child {
		@include rem(padding-right, $pad);
	  }
	  > .gs6:last-child {
		max-width: 40%;
	  }
	}
	img {
	  max-height: 300px !important;
	  width: auto !important;
	}
  }
	#block-panels-mini-exhibit-header,
  .pane-exhibit-header {
		position: relative;
		@include rem(padding, $space-and-half 0);
		>.panel-display {
			display: flex;
			justify-content: space-between;
		}
		.breadcrumbs {
			margin-bottom: 0;
		}
		.pane-addtoany-addtoany-button {
			margin-left: auto;
		}
  }
}

@include breakpoint($desk) {
  .pane-topics-header {
	@include rem(margin-top, $space-and-half);
	> .panel-display {
	  > .gs6:first-child {
		@include rem(padding, $space $pad);
	  }

	}
  }
}
