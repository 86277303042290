/*------------------------------------*\
    $MENU BUTTONS
\*------------------------------------*/
$button-size : 24px;
$transition: .3s; // increase this to see the transformations in slow-motion
$color: white;

@mixin line {
  background: $color;
  border-radius: 2px;
  display: block;
  height: 3px;
  transition: transform .3s ease-out, transform .3s ease-out, top .3s ease-out;
  width: $button-size;
}


.lines-button {
  background: $mobile-nav-btn;
  border: 0;
  position: relative;
  // box-shadow: -1px 0 2px 0 rgba(0,0,0,0.24) , 1px 0 2px 0 rgba(0,0,0,0.24) , 0 1px 1px 0 rgba(0,0,0,0.34);
  cursor: pointer;
  display: inline-block;
  @include rem(padding, $space*.75 $pad-half);
  //padding: 20px 12px 26px;
  user-select: none;
  border-radius: 0;
  // @include rem(margin, 18px 0);
  margin: 0;
  &:hover {
    opacity: 1;
  }

  &:active {
    background: rgba(0,0,0,.1);
    transition: none;
  }
  .text {
    //bottom: 1px;
    //display: block;
    //@include rem(font-size,$font-size-small);
    //left: 0;
    //position: absolute;
    //text-align: center;
    //width: 100%;
    @extend %hide-text
  }
}


.lines {
  //create middle line
  @include line;
  position: relative;
  top: -.13em;

  /*create the upper and lower lines as pseudo-elements of the middle line*/
  &:before, &:after {

    @include line;
    position: absolute;
    left:0;
    content: '';
    transform-origin: 1.71429px center;
  }
  &:before { top: $button-size/3; }
  &:after { top: -$button-size/3; }
}

.sf-expanded.lines-button {
  //.text {
  //  opacity: 0;
  //}
  .lines {
    background: transparent;
    top: .4em;
    &:before, &:after {
      transform-origin: 50% 50%;
      top:-4px;
    }
    &:before{
      transform: rotate3d(0,0,1,45deg);
    }
    &:after{
      transform: rotate3d(0,0,1,-45deg);
    }
  }
}
/*------------------------------------*\
    $BUTTONS
\*------------------------------------*/
.btn,
.btn-blue,
.btn-orange,
.btn-burgundy,
.btn-green,
.btn-alt{
  //@include rem(margin-bottom, $space);
  text-transform: capitalize;
  letter-spacing: .1px;
  @include rem(padding,$space/6 $pad*1.25);
}
.btn {
  @include button;
  &.read-more {
    @include font-icon("\f101", $font-size-base*2.5, 4px, $font-size-base, $font-size-base, white, white, true);
  }
  &.rounded {
    border-radius: $font-size-base;
  }
}
.btn-blue {
    @include button($blue, white, darken($blue, 10%));
  &.btn-text:after {
    @include rem(top, 6px);
  }
  &:hover,
  &.btn-text:hover{
    color: #fff;
  }
}
  .btn-orange {
    @include button($orange, white, darken($orange, 10%));
    // @include font-smoothing;
    &.inactive {
      //background-color: darken($orange, 10%);
      opacity:.3;
      color: $gray-lightest;
    }
  }


  .btn-green {
    @include button($green, white, darken($green, 10%));

  }
 .btn-burgundy {
    @include button($burgundy, white, darken($burgundy, 10%));

  }
  .btn-text-alt,
  .btn-alt {
    @include button($gray-lighter, $gray-dark, darken($gray-lighter, 10%));
    &.active {
      @include button($burgundy, white, darken($burgundy, 10%));
    }
  }
  .btn-large {
    //font-size: em($font-size-h4, $btn-font-size);
    @include button--large;
  }


.btn-alt.fullsize {
  background-repeat: no-repeat;
  padding: 1em;
}
button:focus {
  border-color: $blue;
  outline:2px solid $blue;
}
.btn + .btn {
  @include rem(margin-left, $pad*.75);

  [dir="rtl"] & {
    margin-left: 0;
    @include rem(margin-right, $pad*.75);
  }
}

.btn-small {
  @include rem(padding, $space/6 $pad-half);
}
.btn-text-alt,
.btn-text,
.btn-text-large,
.field-name-more-link > a{
  @include font-icon("\f101", 24px,1.5px, 6px, $font-size-base, $link-color, $link-hover, true);
  &:after {
    color: inherit !important;
    font-size: inherit;
    &:hover {
      color: inherit !important;
    }
  }
  &:hover {
    color: $link-hover;
  }

}
.btn-text-alt:after {
  @include rem(top, 6px);
}
.btn-text-large {
  @include button--large;
  padding: 0;
  @include rem(padding-right, 40px);
  &:after {
    @include rem(right, 10px);
    @include rem(top, 2px);
  }
}
button.btn-svg {
  background: transparent;
  border: none;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  padding: 0;
  .label {
    @extend %hide-text;
  }
  .fill {
    fill: $link-color;
  }
  &:hover,
  &:focus{
    .fill {
      fill: $burgundy;
    }
  }
  &:hover,
  &:focus,
  &:active {
    background: transparent;
    box-shadow: none;
  }
  svg {
    width: 38px;
  }
  &.reset {
    svg {
      width: 26px;
    }
  }
  //header & {
  //
  //}
}
