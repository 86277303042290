.panel-region-main {
  //.page-title {
  //  @extend %set_layout;
  //}
  &.col {
    .page-title {
      max-width: 100%;
      width: 100%;
    }
  }
  #mini-panel-page_header {
    @include rem(margin, $space-double auto $space-and-half);
  }
}

.section-title {
  width: 100%;
}

.page-title {
  @include rem(margin, $space auto);
  .page-title-wrapper & {
    margin-top: 0;
  }
}
