//.logo-icon {
//  display: block;
//  @include svg-background('logo-sun');
//  background: {
//    repeat: no-repeat;
//    position:  50%;
//  }
//  float: left;
//}
header {
  position: relative;
  background: $header-bg;
  p {
    margin: 0;
  }
  .btn-burgundy {
    @include rem(height, 35px);
    line-height: 1.5;
    //@include rem(line-height, 35px);
    margin-bottom: 0;
  }
  .captcha-admin-links,
  .block-gtranslate {
    display: none;}
}


.header-wrapper {
  > .inner {
    @extend %set_layout;
    //display: grid;
    //grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
    //@include rem(grid-column-gap, $pad);
  }
}
.header-preface-wrapper {
  background: $preface-bg;
  * {
    color: white;
  }
  .inner {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}
.region-header-preface-left,
.region-header-preface-right {
@include rem(padding, $space-quarter 0);
}
.region-header-preface-left {
  @include rem(padding, $pad-half);
  a {
    @include link-colors(white, $yellow, $yellow, white, white);
  }
}
.region-header-preface-right {
  display: flex;
  p {
    line-height: 1;
  }
  > .block {
    @include rem(margin-left, $pad-half);
    &:first-child {
      margin-left: 0;
    }
  }

}
.header-wrapper {
  position: relative;
}
.header-preface-wrapper .inner {
  @extend %set_layout;
}

.wrapper--main-menu {
  position: absolute;
  right: 2%;
  top: $space-half;
}

.branding {
  @include rem(padding, $pad-half 0);
  a{@include link-colors(white, white, white, white, white);}

} // end l-branding
.branding-icon-wrapper {
  font-family: $font-secondary;
  font-weight: normal;
  margin: 0;
  .site-logo {
    display: flex;
    flex-wrap: nowrap;
  }
  span {
    display: block;
  }
  .site-info-wrapper {
    @include rem(padding, 3px 0 0 10px);
  }
  .logo-icon {
    width: 57px;
    height: 57px;
  }
  .si-name {
    @include rem(font-size, 28px);
    line-height: 1;
  }
  .site-name {
    @include rem(font-size, 18px);
    line-height: 1.2;
    font-style: italic;
  }
}

@include breakpoint($tab) {
  header {
    .block-gtranslate {
      display: block;
      position: absolute;
      right: 0;
      @include rem(top, 9px);
    }
    .switcher .selected a {
      padding: 7px 5px;
    }

  }
  .region-header-preface-right {
    padding-right: 173px;
    position: relative;
  }
  .header-wrapper > .inner{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .branding{
    overflow: hidden;
  }

  .region-header-preface-right {
    align-items: center;
  }
  #page-wrapper {
    .branding-icon-wrapper {
      .logo-icon {
        @include rem(width, 66px);
        @include rem(height, 66px);
      }
      .site-info-wrapper {
        @include rem(padding-top, 8px);
      }
      .si-name {
        @include rem(font-size, 30px);
      }
      .site-name {
        @include rem(font-size, 21px);
      }
    }
  }
}

@include breakpoint($desk) {
  .branding{
    width: 30%;
    max-width: 30%;
  }
  .wrapper--main-menu {
    position: static;
    right: auto;
    top: auto;
  }
  .region-header {
    width: 67%;
    max-width: 67%;
  }

  .header-wrapper {
    position: relative;
  }


}


@include breakpoint($wider) {
  .header-preface-wrapper {
    > .inner {
      @include rem(grid-column-gap, $pad-double);
    }
  }
}
