  .breadcrumbs {
    @include rem(font-size, $font-size-small);
    line-height: $line-height-small;
    @include float-list(inline-block);
    padding: 0;
    li {
      &:after {
        content: ' / ';
        color: #778288;
      }
      padding: 0;
      span {
        display: inline-block;
        @include rem(margin, 0 $pad/4);
        color: #778288;
      }

      a {
        @include link-colors($link-color, $link-hover, $link-hover, $link-color, $link-color);

      }
      &.active {
          color: #778288;
      }
      &:last-child {
        &:after {
          content: '';
        }
      }
    }
  }
