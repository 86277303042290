.block-google-appliance,
.search-form, #search-block-form,
.edan-search-form {
  .container-inline {
	//position: relative;
	display: flex;
	align-items: center;
  }
  .form-item {
	margin-top: 0;
	margin-bottom: 0;
  }
  .fill {
    fill: #FFF;
  }

  .sb-search-open {
    svg {
      .fill {
        fill: $blue;
      }
    }
  }
}


.main {
  .search-form,
  .edan-search-form,
  .search-form-wrapper {
	position: relative;
	@include rem(margin-bottom, $space-and-half);
	max-width: 980px;
  }

  .search-form,
  .edan-search-form {
	input[type="text"] {
	  display: inline-block;
	  width: 100%;
	  height: $space*2;
	  padding-right: ($space*2) + 10px;
	  border-radius: 0;
	  -webkit-appearance: none;
	  border: 1px solid darken($gray-lighter, 5);
	  @include rem(padding-left, 10px);
	  color: $text-color;
	  line-height: 1;
	}
    .form-actions {
      position: absolute;
      right: 0;
      top: 0;
      margin: 0;
      display: flex;
    }
    &.show-label {
      .form-actions {
        @include rem(top, 58px);
      }
    }

	.form-text {
	  input:placeholder-shown {
		color: $gray-dark;
		text-transform: uppercase;
	  }
	}
    .js & button.btn-svg.reset {
      display: none;
    }
    &.search-executed {
      //.search {
      //  @include rem(right, 42px);
      //}
      button.btn-svg.reset {
        display: block;
        position: relative;
        top: 2px;
      }
    }
    .entity-paragraphs-item & {
      button {
        background: $yellow;
        .fill {
          fill: #FFF;
        }
        &:hover {
          background: $blue;
          .fill {
            fill: #FFF;
          }
        }
      }
    }
  }

  .edan-search-form {
	.form-type-textfield label {
	  @extend h2;
	  @include rem(margin-bottom, $space-half);
	  display: block;

	}
	input[type="submit"] {
	  @include rem(top, 59px);
	}
  }
  .bg-burgundy,
  .bg-gray-dark,
  .bg-blue {
	.edan-search-form {
	  label {
		color: #fff;
	  }
	}
  }
}

header .block-google-appliance,
header .block-edan-searchtab{
  form {
	//float: right;
	position: relative;
	@include rem(width, 35px);
	@include rem(min-width, 35px);
	@include rem(height, 36px);
	overflow: hidden;
	// float: right;
  }
  // end form
  .form-type-textfield {
	min-width: 0;
	overflow: hidden;
	margin: 0;
	opacity: 0;
	transition: all .4s ease;
  }
  input[type="text"] {
	//-webkit-appearance: none;
	//// width: em(143px);
	//@include rem(height, 34px);
	 padding-right: 50px;
	@include rem(font-size, $font-size-small);
	color: $text-color;
	background: transparent;
		border-color: transparent;
	//  border-radius: 3px;
	width: 100%;
  }

  button.search {
		@include rem(height, 36px);
    width: 44px;
		svg {
			@include rem(width, 32px);
		}
    .fill {
      fill: #fff;
    }
  }
  .form-actions {
		@include rem(height, 36px);
	padding: 0;
	margin: 0;
	cursor: pointer;
	z-index: 100;
    position: absolute;
    right: 0;
    top: 3px;
  }

  -webkit-backface-visibility: hidden;
  &.sb-search-open,
  .no-js & {
	form {
	  width: 100%;
	  height: auto;
	  overflow: visible;
	  min-width: 100%;
	}
	.form-text {
	  // right: 30px;

	  //@include transition(top .4s ease, visibility top .4s ease);

	}
	.form-type-textfield {
	  opacity: 1;
	  min-width: 175px;
	  position: absolute;
		@include rem(top, 36px);
	  right: 0;
		z-index: 100;
	  @include breakpoint($tab) {
		max-width: 250px;
		top: auto;
		position: static;
	  }
	}
	input[type="text"] {
	  @include rem(height, 36px);
		background: white;

	}
	input[type="submit"] {
	  border-radius: 0;
	}
    button.search {
      .fill {
        fill: $blue;
      }
    }
  }

}

//end .header #block-search-form


