
.wrapper--ckeditor {
  padding: 1em;
}
.content-wrapper {
	background: #FFF;
	overflow-wrap: break-word;
	word-wrap: break-word;
	min-height: 300px;
	min-height: 55vh;
}
