.edan-search {
  @include set_layout($max-wide);
  .layout-default & {
    width: 100%;
  }
}

.edan-search {
  ul.search-results {
    li {
      margin-bottom: $space;
    }
  }
  .edan-search-result {
    background-color: transparent;
    border: 1px solid $gray-lightest, 2%;
    padding: 0;
  }
  img {
    display: block;
    @include rem(margin-bottom, $space-half);
  }
  .title {
    display: block;
  }
  .title, .field {
    @include rem(padding, 0 $pad-half);
  }
  h3 {
    @include rem(margin-bottom, $space-half);
  }
  dl {
    @include rem(margin-bottom, $space-half);
  }
}
.wrapper--search-tabs .wrapper--btn {
  .btn{
    &.active {
      background: $burgundy;
      @include link-colors(#fff, #fff, #fff, #fff, #fff);
    }
  }
}
@include breakpoint('all' 600px) {
  .edan-results-wrapper.view--grid {
    ul.search-results li {
      margin-bottom: 0;
    }
  }
}
