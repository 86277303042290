// Font Settings
$font-path                : '../fonts/BrandFonts';
//$font                    : 'CooperHewitt', "Helvetica Neue", Helvetica, Arial, sans-serif;
//$font                    : 'BasierCircle', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font                    : 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;

$font-secondary          : 'MinionPro', Georgia, Times, 'Times New Roman', serif;
$font-serif              : Georgia,Times,'Times New Roman',sans-serif;
$baseFontSize            : 10;
$font-size-base          : 17px;
//font sizes are based on perfect fourth scall
$font-size-h1-alt        : 54px;
$font-size-h1            : 48px;
$font-size-h2            : 34px;
$font-size-h3            : 24px;
$font-size-h4            : 21px;
$font-size-h5            : 19px; // minor 3rd
$font-size-tagline       : $font-size-h4;
$font-size-h6            : $font-size-base; // 16x

$font-size-small         :  14px; ; // major 3rd
$font-size-giga          : $font-size-base * 5.063; // 68px
$font-size-mega          : $font-size-base * 3.375;

$baseLineHeight          : 1.5;
$line-height-base        : 24px;

$line-height-h1-alt      : ($line-height-base*3)/$font-size-h1-alt;
$line-height-h1          : ($line-height-base*2.5)/$font-size-h1;
$line-height-h2          : ($line-height-base*2)/$font-size-h2;
$line-height-h3          : ($line-height-base*1.5)/$font-size-h3;
$line-height-h4          :  ($line-height-base*1.5)/$font-size-h4;
$line-height-h5          : $line-height-base/$font-size-h5;
$line-height-tagline     :  $line-height-base/$font-size-h5;
$line-height-h6          : $line-height-base/$font-size-h6;

$line-height-small       : $line-height-base/$font-size-small ;
$line-height-giga        : ($line-height-base*3)/$font-size-giga;
$line-height-mega        : ($line-height-base*2)/$font-size-mega;

// Font Weights
$fw-normal: 400;
$fw-bold: 700;
