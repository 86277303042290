@mixin facet-box() {
	@include rem(margin-bottom, $space);
	margin-left: 0;
	background-color: #fff;
	border: 1px solid $gray-lighter;
	@include rem(padding-bottom, $space-half);
}
.active-facet-list.reset-list {
	@include rem(margin-left, $pad-half);
}

.edan-search-facets {
	@include facet-box();

	// @include rem(margin-bottom, $space);
	// margin-left: 0;
	// background-color: #fff;
	// border: 1px solid $gray-lighter;
	// @include rem(padding-bottom, $space-half);
	//    @include rem(padding, $space-half 0 0);
	//background: lighten($tan, 50%);
	//border: 1px solid $tan;
	h3 {
		@include rem(padding, 0 $pad/2);
		margin-bottom: 0;
		a:after {
			content: '';

		}
	}
	a {
		@include link-colors($blue, $link-hover, $link-hover, $blue, $blue);
	}
	.icon {
		background: none;
		position: relative;
		display: inline-block;
		&:before, &:after {
			//border-radius: 2px;
			display: block;
			height: 3px;
			@include rem(width, 14px);
			position: absolute;
			left: 25%;
			content: '';
		}
	}

	p {
		@include rem(margin, $space/2 $pad/2 0);
	}

	.edan-search-facet-list {
		@include rem(padding, $space-half 0);
		&.filter {
			padding-bottom: 0;
		}
		//&.exapnd {
		//  a {
		//    @include font-icon("\f057", $font-size-base, 18px, 0);
		//    color: $red-dark;
		//
		//  }
		//}
	}
	.active-facet-list {
		> li {
			a {
				display: block;
				@include rem(padding-left, 19px);
			}
			.icon {
				@include rem(width, 12px);
				@include rem(height, 12px);
				@include rem(margin-right, 10px);
				@include rem(margin-left, -24px);
				&:before,
				&:after {
					background: $burgundy;
					@include rem(top, 4px);
				}
				&:before {
					transform: rotate(45deg);
				}
				&:after {
					transform: rotate(-45deg);
				}
			}
		}
		&.clear-all {
			.icon {
				width: 0;
				height: 0;
				margin: 0;
			}
		}
	}
	ul {
		@extend %reset-list;
		// @include rem(margin-bottom, floor($space/4));
		li {
			@include rem(padding, floor($space/4) $pad/2);
		}
		&.facets {
			li {
				padding: 0;
			}
		}
	}

	.root-facet-list {
		//background: lighten($tan, 35%); //508 fix
		> li {
			//border: 1px solid $tan;
			//border-width: 0 0 1px;
			&:first-child {
				border-top-width: 1px;
			}
			&:last-child {
				border-bottom: 0;
			}
			> a,
			> a.expand {
				.icon {
					// border: 3px solid $gray-lighter;
					@include rem(width, 32px);
					@include rem(height, 32px);
					@include rem(min-width, 32px);
					//@include rem(border-radius, 32px);
					@include rem(margin-right, 5px);
					position: relative;
					@include rem(top, 10px);
					&:before, &:after {
						background: $blue;
						//border-radius: 2px;
						display: block;
						transition: transform .3s ease-out, transform .3s ease-out, top .3s ease-out;
						content: '';
						top: 45%;
					}
					&:after {
						transform: rotate3d(0, 0, 1, -90deg);
					}
				}
			}
			> a.expand .icon {
				&:after {
					transform: rotate3d(0, 0, 1, 0deg);
				}
			}
			//> a.category {
			//   font-weight: 500;
			//   @include link-colors($link-color, $link-hover, $link-color, $link-color, $link-color);
			//   span {
			//     font-style: italic;
			//     font-family: $font-secondary;
			//   }
			//   .js & {
			//     &:before {
			//       @include transition(all .4s cubic-bezier(.55, .085, 0, .99));
			//     }
			//     @include font-icon("\f0da");
			//     //&:hover {
			//     //  @include font-icon("\f0da");
			//     //}
			//     &.expand {
			//       @include font-icon("\f0d7");
			//     }
			//   }
			// }
			ul {
				display: none;
				height: 0;
				visibility: hidden;
				opacity: 0;
				@include transition(all .4s cubic-bezier(.55, .085, 0, .99));
			}
			&.expand {
				ul {
					display: block !important;
					height: auto;
					visibility: visible;
					opacity: 1;
				}
				//li {
				//  border: 1px dotted $tan;
				//  border-width: 0 0 1px;
				//  @include rem(padding, floor($space/4) $pad/2);
				//  &:last-child{
				//    border-bottom: 0;
				//  }
				//}
			}
		}

		.facets {
			@include rem(padding-left, 38px);
			margin-bottom: 0;
			.facet {
				@include rem(padding-top, $space-quarter);
				span {
					font-size: 1.4rem;
					&:before {
						content: "(";
					}
					&:after {
						content: ")";
					}
				}
			}
		}

	}
	//end root-facet-list

}

//end .edan-search-facets

// OSS Search Results
.search-results__container {
	&.with-facets {
		display: flex;
		flex-direction: column;
		@include breakpoint($desk) {
			display: grid;
			grid-template-columns: 3fr 1fr;
			grid-gap: 4rem;
		}
	}
	.google-appliance-results-control-bar {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.google-appliance-search-stats {
    flex-grow: 1;
	}
	.search-results__skip-to-facets {
		@include breakpoint($desk) {
			@include hide-text();

			&:active,
      &:focus {
				@include restore-hide();
			}
		}
	}

	.search-results__facets {
		@include facet-box();
		@include rem(padding, $space);

	}

	summary {
		cursor: pointer;
		display: list-item;
		@include link-colors($blue, $link-hover, $link-hover, $blue, $link-hover);
	}
	.facets-block__title,
	.facets-group__title {
		color: inherit;
		display: inline-block;
	}

	.dynamic_nav_list {
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		margin: 0 0 0 2rem;

		@include breakpoint($desk) {
			display: block;
		}
	}
	.facet-item {
    padding-right: 2rem;
    // flex: 0 1 50%;

		&.separator {
			flex-basis: 100%;
			height: 0;
      margin: 0;
		}

		.icon--clear {
			background: none;
			position: relative;
			display: inline-block;
			@include rem(width, 12px);
			@include rem(height, 12px);
			@include rem(margin-right, 10px);
			// @include rem(margin-left, -24px);
			&:before,
			&:after {
				display: block;
				height: 3px;
				@include rem(width, 14px);
				position: absolute;
				left: 25%;
				content: '';
				background: $burgundy;
				@include rem(top, 4px);
			}
			&:before {
				transform: rotate(45deg);
			}
			&:after {
				transform: rotate(-45deg);
			}
		}
	}
}
