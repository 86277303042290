@import url('https://fonts.googleapis.com/css?family=Libre+Baskerville:400,400i,700|Open+Sans:300,300i,400,400i,600,600i,700,700i');

html{
  font-size: 62.5%;
  @include breakpoint($wide) {
    font-size: 74.21875%; //increase font to 19px
    //font-size: 70.3125%; // increase font to 18px

    //font-size: 70.3125%;
   // font-size: 77.205%; // increase to 21px
  }
}

body {
  font-family: $font;
  @include rem(font-size, $font-size-base);
  font-weight: 400;
  line-height: $baseLineHeight;
  color: $text-color;
  background: $footer-bg;
//  -webkit-text-size-adjust: 100%;
  overflow-x: hidden;
}

/* Blockquote */
blockquote {
    font-style:italic;
    border-left: 1px solid $gray;
    color: $gray;
  @include rem(padding-left, $pad);
  @include rem(margin-bottom, $space);
}
cite {
  display: block;
  text-align: center;
  font-style: normal;
  @include rem(margin-top, $space-half);
}


/* Horizontal Rule */
hr {
	border: 0;
    height: 2px;
    background: $gray-lighter;
  @include rem(margin, $space 0)
}

abbr {
    border-bottom: 1px dotted $gray;
    cursor: help;
}


#{headings()} {
  a {
    @include link-colors($text-color, $text-color, $text-color, $text-color, $text-color);
    &:focus,
    &:hover,
    &:active {
      text-decoration: none;
      cursor: hand;
      border-bottom: 1px dotted $text-color;
    }
  }
}

/* Headings */
/*Further Reading: http:/csswizardry.com/2012/02/pragmatic-practical-font-sizing-in-css/ */
h1, .h1{
  font-family: $font;
  @include rem(font-size, $font-size-h1);
  line-height: $line-height-h1;
  color: $header-color;
  font-weight: bold;
  &.container {
    @include rem(margin-bottom, $space);
  }
}
h2.h1 {
  @include rem(margin-bottom, $space);
  color: $text-color;
}

h2, .h2    {
  font-family: $font-secondary;
  //@include font-smoothing();
  font-weight: bold;
  @include rem(font-size, $font-size-h2);
  line-height: $line-height-h2;
  color: $blue-darkest;
  @include rem(margin, 0 auto $space);
  a {
    @include link-colors($blue-darkest, $blue-darkest, $blue-darkest, $blue-darkest, $blue-darkest);
    &:focus,
    &:hover,
    &:active {
      text-decoration: none;
      cursor: hand;
      border-bottom: 1px dotted $blue-darkest;
    }
  }
}
h1.h2 {
  margin-top: 0;
}

h3, .h3, .panel-region-left-aside h2, .panel-region-right-aside h2, h2.h3   {
  font-family: $font;
  @include rem(font-size, $font-size-h3);
  line-height: $line-height-h3;
  color: $text-color;
  font-weight: 700;
}

h4, .h4, .panel-region-left-aside h3, .panel-region-right-aside h3   {
  color: $text-color;
  font-family: $font;
  @include rem(font-size, $font-size-h4);
  line-height: $line-height-h4;
  font-weight: 700;
}

h5, .h5, .panel-region-left-aside h4, .panel-region-right-aside h4  {
  color: $text-color;
  font-family: $font;
  @include rem(font-size, $font-size-h5);
  line-height: $line-height-h5;
  font-weight: 700;
}

h6, .h6    {
  color: $text-color;
  font-family: $font;
  @include rem(font-size, $font-size-h6);
  line-height: $line-height-h6;
  font-weight: 700;
}

h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  @include rem(margin-bottom, $space);
}

  /* Text-Related Elements */
p {
  @include rem(margin, 0 0 $space);
}
.extra-line-height {
  &.h4 {
    line-height: 2;
  }
}
.regular {
  font-weight: normal;
}
