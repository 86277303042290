.masonry-grid-wrapper {
	margin: 0 auto;
	.fillers {
		display: none;
	}
	.filler, .mason-filler, .filler-fade {
		background: $gray-dark;
	}
}
.masonry-grid  {
	img {
		display: block;
		width: 100%;
	}
	.grid-item.shadow {
		@include box-shadow(rgba(0, 0, 0, 0.1) 1px 1px 4px);
	}
	.grid-item .teaser {
		@include rem(margin-bottom, $space);
	}

	@include breakpoint('screen' em(600px)) {
		display: grid;
		//grid-column-gap: 15px;
		grid-gap: 20px;
		//grid-template-columns: repeat(auto-fill, minmax(245px,1fr));
		transition: all 300ms ease-in-out;

		//.js & {
		//	grid-auto-rows: 20px;
		//}

		&.reset-list li {
			margin-bottom: 0;
		}
		.flexbox .wrapper--search-tabs & {
			.edan-search-result .inner {
				background-color: transparent;
				border: none;
				&:after {
					box-shadow: none;
					opacity: 0;
				}
			}
		}
		.grid-item .teaser {
			margin-bottom: 0;
			.inner {
				background-color: transparent;
				border: none;
				&:after {
					box-shadow: none;
					opacity: 0;
				}
			}
		}
		&.hover-title {
			.has-media .teaser,
			.has-media.teaser{
				.b-text-wrapper {
					opacity: 0;
					position: absolute;
					display: flex;
					align-items:center;
					justify-content:center;
					flex-direction: column;
					z-index: 100;
					top: 0;
					left: 0;
					bottom: 0;
					right:0;
					width: 100%;
					height: 100%;
					text-align: center;
					background-color: rgba(0, 0, 0, .6);
					@include transition(opacity .2s ease-out);

				}
				.inner {
					display: block;
					position: relative;
					background: none;
					&:hover {
						color: white;
						.b-text-wrapper {
							opacity: 1;
						}
					}
				}
				h3, h2, h4,
				.beta, .gamma, .delta,.epsilon {
					color: white;
				}
				&.no-media {
					.b-text-wrapper {
						position: static;
						opacity: 1;
					}
				}
			}
		}
		.node--teaser-long {
			display: block;
		}

		&:not(.hover-title) {
			.grid-item {
				background-color: $gray-lightest;
				border: 1px solid darken($gray-lightest, 2%);
				&:after, &:before {
					border-radius: inherit;
					z-index: -1;
					position: absolute;
					content: "";
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					margin: 0;
					padding: 0;
					-webkit-transition: -webkit-box-shadow .7s 10ms cubic-bezier(.19, 1, .22, 1);
					transition: -webkit-box-shadow .7s 10ms cubic-bezier(.19, 1, .22, 1);
					transition: box-shadow .7s 10ms cubic-bezier(.19, 1, .22, 1);
					transition: box-shadow .7s 10ms cubic-bezier(.19, 1, .22, 1), -webkit-box-shadow .7s 10ms cubic-bezier(.19, 1, .22, 1)
				}
				&:after {
					@include opacity(.0625);
					-webkit-box-shadow: 0 2px 0 0 #000;
					box-shadow: 0 2px 0 0 #000;
				}
			}
		}
	}
	@include breakpoint('all' 1870px) {
		grid-template-columns: repeat(auto-fill, minmax(300px,1fr));
	}
}
