
.modal-footer, .modal-header {
  border: none;
}

.related-object-groups {
  @extend %reset-list;
  .edan-search-result a {
    display: grid;
    @include rem(grid-column-gap, $pad);
    grid-template-columns: 150px 1fr;
  }
}
.edan-viewer-container {
  .card-header .btn,
  .btn {
    @include rem(margin-bottom, $space-half);
    text-align: center;
  }
  .btn + .btn {
    margin-left: 0;
  }
}

.edan-record {
  .page-title {
    text-align: center;
  }
  .btn {
    color: $text-color;
    &:hover {
      box-shadow: none;
    }
  }
  .caption {
    font-style: italic;
    max-width: 98%;
    @include rem(font-size, $font-size-small);
    line-height: $line-height-small;
  }
  .no-media {
    @include set_layout($max-width);
    .media-metadata {
      display: none;
    }
  }
  figure {
    display: block !important;
  }
  .record-section {
    @include rem(margin-bottom, $space-and-half);
  }
  .has-media {
    .record-section {
      flex-direction: column;
    }
    .pageHeader,
    .media-wrapper {
      order: 1;
    }
    .recordDetails {
      order: 2;
    }
  }
  .accordion .inner {
    width: 100%;
  }

  .media-slider {
    @include rem(margin-top, 0);
    border-top: 1px solid $gray-lighter;
    @include rem(padding-top, $space-half);
    .slide {
      display: flex;
    }
  }
  .media-metadata {
    border: none;
    @include rem(padding-bottom, $space-half);
    @include rem(margin, $space-half auto 0);
  }

  .btn-tab {
    @include rem(padding, $space-half $pad-half);
    text-align: center;
  }

}

.popover {
  @include rem(font-size, $font-size-small);
  line-height: $line-height-small;
}
@include breakpoint('all' 768px) {
  .edan-record .has-media {
    .record-section {
      grid-template-columns: 60% 1fr;
    }
    .recordDetails {
      overflow-y: initial;
      background: transparent;
      padding: 0;
      height: auto;
    }
  }
}
