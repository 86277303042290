//== Buttons
//
$btn-font:                      $font;
$btn-font-weight:                700;
$btn-font-size:                 $font-size-base;
$btn-default-color:              #FFF;
$btn-bg-color:                 $gray-dark;
$btn-border:             lighten($gray-dark, 10);
$btn-hover:				$blue;
$fab-size: $space-and-half;
