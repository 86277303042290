.front {
		#paragraphs-item-5555 {
			.group-right {
				.entity-paragraphs-item {
					border: 1px solid $gray-lighter;
					background: $gray-lightest;
					//padding: em($pad-half) em($pad);
				}
				.section-title{
					@extend h3;
					background: $header-color;
					color: #fff;
					@include rem(padding, $space-half 15px);
					margin: 0;
					width: 100%;
				}
			}

	}

	@include breakpoint($tab) {
		#paragraphs-item-5348 {

		}
		#paragraphs-item-5555 {
			.col {
				display: flex;
			}
			.gs4 {
				grid-column-end: span 6;
				&.group-left {
					grid-column-end: span 12;
				}
			}
		}
	}
	@include breakpoint($desk) {
		#paragraphs-item-5555 {
			.gs4 {
				grid-column-end: span 3;
				&.group-left {
					grid-column-end: span 6;
				}
			}
		}
	}
}
