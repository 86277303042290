/*------------------------------------*\
    $GLOBAL CLASSES
\*------------------------------------*/

/* Clearfix */
.cf {
  @extend %clearfix;
}

/* Completely remove from the flow and screen readers. */
.is-hidden {
  display: none !important;
  visibility: hidden !important;
}

/* Completely remove from the flow but leave available to screen readers. */
.sr-only,
.element-invisible {
  @extend %hide-text;
}

/* Text-Align */
.align-right {
  text-align: right;
}

.align-center, .center {
  text-align: center;
}

.align-left {
  text-align: left;
}

/* Display Classes */
.b-error,
.hide {
  display: none !important;
}
.hide-small {
  @media all and (max-width: $bp-med) {
    display: none;
  }
}

.hide-med {
  @include breakpoint($bp-med $bp-large) {
    display: none;
  }
}

.hide-large {
  @media all and (min-width: $bp-large) {
    display: none;
  }
}

.preface, .small {
  @include rem(font-size, $font-size-small);
  line-height:$line-height-small;
}
.preface {
  font-family: $font-secondary;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: bold;
}
//States
.valid {
  color: $valid;
}

.error {
  color: $error;
}

.italic {
  font-style: italic;
}

.warning {
  color: $warning;
}

.information {
  color: $information;
}

.font-secondary {
  font-family: $font-secondary;
}
.font-primary {
  font-family: $font;
}

.clear-l {
  clear: left;
}

.clear-r {
  clear: right;
}

.clear-all {
  clear: both;
}
.border {
  border: 1px solid $gray-lighter;
  .content {
    @include rem(padding, $space-half $pad-half);
  }
}
.bg-blue {
  background: $blue;
}

.bg-gray-dark {
  background: $gray-dark;
}
.bg-gray-light {
  background: $gray-lighter;
  //&.paragraphs-item-grid-layout {
  //  background: none;
  //}
}
.bg-gray-lightest {
  background: $gray-lightest;
  &.border {
    border: 1px solid darken($gray-lightest, 2%);
  }
  //&.paragraphs-item-grid-layout {
  //  background: none;
  //}
}

.border-bottom {
  border-bottom: 1px solid $gray-lighter;
  @include rem(padding-bottom, $space);
  &:last-child {border-bottom: 0; padding-bottom: 0}
}
.border-top {
  border-top: 1px solid $gray-lighter;
  @include rem(padding-top, $space);
}

.bg-green {
  background: $green;
}
.bg-orange {
  background: $orange;
}

.border-top-yellow {
  border-top: 3px solid $yellow;
  @include rem(padding-top, $space);
}
.bg-purple {
  background: $purple;
}
.bg-burgundy {
  background: $burgundy;
}
.bg-burgundy,
.bg-green,
.bg-gray-dark,
.bg-blue,
.bg-orange,
.bg-purple {
  * {
    color: white;
  }
  a {
    @include link-colors(white, $link-color-alt, $link-color-alt, white, white);
  }
  //&.paragraphs-item-grid-layout {
  //  background: none;
  //  * {
  //    color: inherit;
  //  }
  //}
}

.emergency-msg {
  background: $gray-lightest;
  border: 2px solid $red;
  @include rem(padding, $space-half $pad-half);
  @include rem(margin, $space auto);
  p:last-child {
    margin-bottom: 0;
  }

}

.text-blue {
  color: $blue;
}

.text-green {
  color: $green;
}

.alt-rows {
  @include rem(margin, $space 0);
  .g-12-ns, .g-12 {
    @include rem(grid-column-gap, $pad);
    grid-row-gap: 0;
  }
  .label {
    font-weight: 600;
  }
  .row {
    border: 1px solid darken($gray-lightest, 5%);
    border-width: 1px 1px 0;
    @include rem(padding, $space-half $pad-half);
    &:nth-child(odd) {
      background: $gray-lightest;
    }
    &:last-child {
      border-bottom-width: 1px;
    }
  }
}
.sr-only {
  @extend %hide-text;
}

.constrain-text {
  max-width: 1000px;
}


@include breakpoint($small) {
  .alt-rows {
    .label {
      text-align: right;
    }
  }
}
@include breakpoint($tab) {

  /* Floats */
  .left,
  .right {
    display: block;
    // &.width-30 {
    width: 30%;
    // }
    &.width-40 {
      width: 40%;
    }
    &.width-50 {
      width: 50%;
    }
    &.width-60 {
      width: 60%;
    }

    img {
      display: block;
      width: 100% !important;
      height: auto !important;
    }
  }


  [style*="float: left"]
  [style*="float:left"],
  figure[style*="float:left"],
  [style*="margin-right:15px; float:left"],
  .left {
    float: left;
    @include rem(margin-right, $pad);
    @include rem(margin-bottom, $space);
  }
  [style*="float: right"],
  [style*="float:right"],
  figure[style*="float:right"],
  [style*="margin-right:15px; float:right"],
  .right {
    float: right;
    @include rem(margin-left, $space);
    @include rem(margin-bottom, $space);
  }
  [style*="float: left"]
  [style*="float:left"],
  figure[style*="float:left"],
  [style*="margin-right:15px; float:left"],
  .left,
  [style*="float: right"],
  [style*="float:right"],
  figure[style*="float:right"],
  [style*="margin-right:15px; float:right"],
  .right {
    max-width: 40% !important;
  }
}

@include breakpoint('screen' max-width 979px) {
  .bp-desk {
    display: none;
  }
}

@include breakpoint($desk) {
  .bp-desk {
    display: block;
  }
  .bp-mobile {
    display: none;
  }
}
