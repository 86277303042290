//.node {
//  &.view-mode-full {
//    > .field {
//      @include rem(margin, $space-and-half 0);
//
//    }
//  }
//}
.node-tdiph {
  .field-type-datetime {
    @extend h4;
  }
	&.teaser {
		.field-type-datetime {
			@include rem(margin-bottom, $space-half);
		}
	}
}
.node-type-recipient,
.node-type-staff {
  .ds-2col-stacked-fluid {
    .group-left {
      background: $gray-lightest;
      display: flex;
      align-items: center;
      justify-content: center;
      @include rem(padding, 6px);
      border: 1px solid $gray-lighter;
      img {
        display: block;
        margin: auto;
      }
    }
    h1.h2 {
      @include rem(margin, $space-half 0 0);
    }
  }
  .field-name-field-title-long {
    @extend h3;
  }
}

.page-node {
  .content-wrapper.has-sidebar-nav .layout-default {
    position: relative;
    padding-top: 100px;
    @include breakpoint($desk) {
      padding-top: 0;
    }
  }
}
.node-type-book, .node-type-exhibition, .node-exhibition {
  .metatdata {
    @include rem(margin-bottom, $space);
    color: $header-color;
    @include rem(font-size, $font-size-h5);
    line-height: $line-height-h5;
  }
}
.node-museum-highlight {
  .field-name-field-title-long {
	@include rem(margin, $space 0);
  }
	&.node-teaser {
		.field-name-field-title-long {
			@include rem(margin, $space-half 0);
		}
	}
}
.pane-exhibit-title,
.node-type-book {
  h1.pane-title {
    @include rem(margin-bottom, $space-and-half);
  }
}

.node-type-press {
	.panel-region-bottom {
		background: $gray-lightest;
		@include rem(padding, $space 0);
		@include rem(margin-top, $space);
	}
}
.node-promotion.teaser {
  .info {
	@include rem(margin-bottom, $space-half);
  }
  a.colorbox-link-close {
	@include font-icon("\f101", 24px, 2px, 6px, $font-size-base,  $link-color, $link-hover, true);
	@include rem(margin-top, $space-half);

	&:after {
	  color: inherit !important;
	  font-size: inherit;
	  &:hover {
		color: inherit !important;
	  }
	}
  }
}
.node-slideshow {
  &.teaser {
    .b-text-wrapper {
	  * {
		color: white;
	  }
	  text-shadow: 1px 1px 3px rgba(black, 0.65);
	  background: linear-gradient(transparent, rgba(0, 0, 0, 0.75));
	  background-repeat: repeat-x;
	  background-size: auto 100%;
	  @include rem(padding, $space-and-half 0);
	  position: absolute;
	  bottom: 0;
      left: 0;
	  right: 0;
  	}
	.bg-image {
	  height: 75vw;
	  min-height: 320px;
	  max-height: 853px;
	}
  }
  .h2 {
	font-family: $font;
  }
  .field-name-field-title-long {
    font-weight:600;
  }
  .text-inner {
    @extend %set_layout;
  }
  .caption {
    display: none;
  }
}

.node-type-landing-page {
	.paragraphs-item-accordion-tab,
	.paragraphs-item-tabs {
		@include set_layout($max-width);
	}
}
@include breakpoint($tab) {
  .node-slideshow {
    .caption {
      display: block;
    }
	&.teaser {
	  .bg-image {
		height: 56.25vw;
	  }
	}
  }

	.node-type-press {
		.panel-region-bottom {
			background: $gray-lightest;
			@include rem(padding, $space-and-half 0);
			@include rem(margin-top, $space-double);
		}
	}
}

@include breakpoint($desk) {
	 .node-slideshow {
		 &.teaser {
			 @include rem(font-size, $font-size-h5);
		   line-height: $line-height-h5;
		   .h2 {
			 @include rem(font-size, $font-size-h1);
			 line-height: $line-height-h1;
		   }
			 .bg-image {
				 height: 33.33vw;
			 }
			 .b-text-wrapper {
				 @include rem(padding, $space-and-half 0 $space-double);
			 }
		 }
	 }
 }

@include breakpoint($wide) {
	.node-slideshow {
		&.teaser {
			.b-text-wrapper {
				@include rem(padding, $space-and-half 0 60px);
			}
		}
	}
}
