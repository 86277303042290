$button-size : 24px;
$transition: .3s; // increase this to see the transformations in slow-motion
$color: white;

@mixin line {
  background: $color;
  border-radius: 2px;
  display: block;
  height: 3px;
  transition: transform .3s ease-out, transform .3s ease-out, top .3s ease-out;
  width: $button-size;
}


.lines-button {
  background: $mobile-nav-btn;
  border: 0;
  position: relative;
  // box-shadow: -1px 0 2px 0 rgba(0,0,0,0.24) , 1px 0 2px 0 rgba(0,0,0,0.24) , 0 1px 1px 0 rgba(0,0,0,0.34);
  cursor: pointer;
  display: inline-block;
  @include rem(padding, $space*.75 $pad-half);
  //padding: 20px 12px 26px;
  user-select: none;
  border-radius: 0;
  @include rem(margin, 9px 0 0);
  &:hover {
    opacity: 1;
  }

  &:active {
    background: rgba(0,0,0,.1);
    transition: none;
  }
  .text {
    @extend %hide-text;
    //bottom: 1px;
    //display: block;
    //@include rem(font-size,$font-size-small);
    //left: 0;
    //position: absolute;
    //text-align: center;
    //width: 100%;

  }
}


.lines {
  //create middle line
  @include line;
  position: relative;
  top: -.13em;

  /*create the upper and lower lines as pseudo-elements of the middle line*/
  &:before, &:after {

    @include line;
    position: absolute;
    left:0;
    content: '';
    transform-origin: 1.71429px center;
  }
  &:before { top: $button-size/3; }
  &:after { top: -$button-size/3; }
}

.sf-expanded.lines-button {
  //.text {
  //  opacity: 0;
  //}
  .lines {
    background: transparent;
    top: .4em;
    &:before, &:after {
      transform-origin: 50% 50%;
      top:-4px;
    }
    &:before{
      transform: rotate3d(0,0,1,45deg);
    }
    &:after{
      transform: rotate3d(0,0,1,-45deg);
    }
  }
}

.region-navigation,
.region-header {
  .lines-button {
    position: absolute;
    right: 0;
  }
  ul.sf-menu.sf-accordion, ul.sf-menu.sf-accordion ul, ul.sf-menu.sf-accordion li {
    float: none !important;
  }
  .sf-menu,
  .menu{
    line-height: $baseLineHeight;
    margin: 0;
    padding: 0;
    background: $mobile-nav-btn;
    width: 100%;
    max-width: 30em;
    &.sf-accordion.sf-expanded {
      //  top: -19px !important;
      top: 48px !important;
    }
    &.sf-navbar {
      width: 100%;
    }
    ul {
      padding-left: 0;
    }
    li {
      a {
        font-size: em($font-size-base);
        font-family: $font;
        letter-spacing: 1px;
        //text-transform: uppercase;
        font-weight: 500;
        @include link-colors(white, white, white, white, white);
        @include rem(padding, $space/3 $space-half);
      }
      ul {
        background: $mobile-menu;
        //@include rem(padding-left, $pad*1.5);
      }

    }
    //top-level
    > li {
      > a {
        @include rem(padding-left, $pad);
        @include rem(margin-left, $pad-half);
        &.btn {
          display: inline-block;
        }
      }
    }
    .sf-mega-section {
      display: none;
      &.wrapper-sub-menu {
        display: block;
      }
    }
    .sf-depth-1.sf-expanded {
      .sf-mega .sub-menu > ul,
      .sf-expanded > ul {
        display: block !important;
        position: relative;
        top:0 !important;
        left: 0 !important;
      }
    }
    ul li,
    ul ul li {
      background: transparent;
    }

  } //end mobile .sf-menu

  @include breakpoint($desk) {
    .sf-accordion-toggle {
      display: none;
    }
    background: $header-bg;

    .sf-menu.sf-style-none {
      background: $header-bg;
      box-shadow: none;
      max-width: 100%;

      display: flex !important;
      align-items: center;
      justify-content: flex-end;
      .no-flexbox & {
        @include clearfix;
        float: right;
      }
      li {
        position: static;
        a {
          //  @include font-smoothing;
          @include link-colors(white, $link-color-alt, $link-color-alt, white, $link-color-alt);
          &:focus,
          &:hover,
          &:active {
            border-bottom: none;
          }

        }
        ul {
          background: $nav-sub-bg;
        }
        &.sf-depth-2 {
          ul {
            display: none !important;
          }
        }
        &.active-trail > a{
          // @include background-image(linear-gradient(to bottom, $blue, $mobile-menu 70%));
          // background: darken($header-bg, 12%);// $header-bg-darkest;
          color: $link-color-alt;
        }
      } //end li
      a > .sf-sub-indicator, span.nolink > .sf-sub-indicator {
        top: .8em;
        right: .5em;
      }
      >li {
        flex: 0 0 auto;
        //&.sfHover {
        //
        //}
        > a {
          border: none;
          // border-top: 3px solid transparent;
          background: transparent;
          display: block;
          margin-left: 0;
          transition: all ease-in .4ms;
          @include rem(font-size, $font-size-h5);
          line-height: $line-height-h5;
          //@include link-colors($blue-darkest, $nav-sub-bg, $nav-sub-bg, $blue-darkest, $nav-sub-bg);
          @include transition(all .4s cubic-bezier(.55, .085, 0, .99));
          text-align: center;
          @include rem(padding, $space/3 $space-half $space-half);

        }
        > a.active,
        > a:hover,
        &.active-trail > a,
        &.sfHover > a{
          color: #2fa4da;
        }
        > a:hover,
        &.sfHover > a{
          &:after {
            bottom: -2px;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-bottom-color: $nav-sub-bg;
            border-width: 10px;
            margin-left: -10px;
            opacity: 1;
            animation: menuArrow .7s cubic-bezier(.55, .085, 0, .99) forwards
          }
        }

        > ul {
          padding-left: 0;
          // @include box-shadow(rgba(black, .2) 0 5px 5px);
          @include box-shadow(rgba(black, .3) 1px 1px 2px);
          // box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
          border-bottom: none;
          min-width: 3em;
        }
        &.sfHover {
          ul {
            float: none !important;
          }
          .sub-list {
            display: block !important;
          }
          .no-js & {
            ul {
              display: block!important;
            }
          }
          .wrapper-sub-menu {
            ul {
              display: block !important;
            }
          }
        }
      } // end top-level li
      .sf-mega {

        * {
          color: white;
        }
        .form-text {
          color: $gray-dark;
        }
        background-color: $nav-sub-bg;
        @include rem(padding, $space-and-half 0 $space);
        p {
          line-height: $baseLineHeight;
          @include rem(margin, 0 0 $space-half);
          &:last-child {
            margin: 0;
          }
        }
        background: $nav-sub-bg;
        .gamma a,
        h2 a {
          font-weight: 600;
        }
        .gamma {
          @include rem(margin, 0 0 $space-half);
          line-height: normal;
        }
        left: 0;
        right: 0;
        .inner {
          display: block;
          margin-left: 0;
        }
        .b-text-wrapper {
          @include rem(padding, $space-half 0 0);
        }
        ul {
          position: static;
          ul li {
            background: $nav-sub-bg;
          }
        }
        > .inner {
          @include set_layout($max-width);
          display: grid;
          grid-template-columns: repeat(12, 1fr);
          @include rem(grid-gap, $pad-double);
          .no-cssgrid & {
            @include clearfix;
            .sf-mega-section {
              float: left;
            }
          }
          // second level menu
          > .sf-mega-section {
            display: block;
            grid-column-end: span 2;
            .colorbox-link-close {
              @include rem(padding-right, 24px);
            }
              &.last {
              grid-column-end: span 3;
              .teaser img {
                width: 215px !important;
              }
            }
            &.wrapper-sub-menu {
              grid-column-end: span 7;
              border-right: lighten($nav-sub-bg, 5%) 1px solid;
              border-left: lighten($nav-sub-bg, 5%) 1px solid;
              @include rem(padding, 0 $pad-double);
              //.no-flexbox & {
              //  @include clearfix;
              //  .sub-menu,
              //  .section-image{
              //    float: left;
              //    width: span(6);
              //    max-width: span(6);
              //    padding-right: gutter(6);
              //  }
              //}
              ul a {
                @include rem(padding, $space/2 $pad 0);
              }
              .sf-depth-2.first > a {
                padding-top: 0;
              }
              .sub-menu {
                //width: span(9);
                //max-width: span(9);
                //margin-right: gutter();
                li {
                  @include rem(font-size, $font-size-h5);
                }
                > ul {
                  display: grid !important;
                  grid-template-columns: repeat(12, 1fr);
                  //align-content: stretch;
                  justify-items: center;
                  @include rem(grid-gap, $pad);
                  width: 100%;
                  > li {
                    grid-column-end: span 6;
                    > a {
                      padding: 0;
                    }
                  }
                }
              }
              .section-image {
                display: block;
                //width: span(3);
                //max-width: span(3);
              }
            }
            .section-title {
              border-bottom: 2px $orange solid;
              text-align: center;
              @include rem(font-size, $font-size-h5);
              a {
                padding: 0;
                text-align: center;
              }
              .btn-text {
                display: inline-block;
                padding: 0;
                @include rem(padding, 0  30px $space-half 0);
                line-height: normal;

                &:after {
                  @include rem(font-size, 27px);
                  // @include rem(top, 8px);
                  right: 0;
                }
              }
            }
            &.last {
              margin-right: 0;
              border-right: none;
            }
          }
        }
      }
      .view {
       // @include rem(padding-top, $space);
        a {
          padding: 0;
        }
      }
      ul ul {
        @include rem(margin-left, $pad);
      }
      .menu-mlid-928 {
        ul ul {display: none !important;}
      }
    } //end sf-menu.sf-style-none

  } // end breakpoint($desk)
  @include breakpoint('all' 1680px) {
    .sf-menu.sf-style-none {
      > li.sf-depth-1.last.sfHover {
        > ul {
          // top: 5.125em !important;
          left: 0 !important;
          right: auto !important;

        }
      }
    }

  }
} //end l-navigation

