/*------------------------------------*\
    $FORMS
\*------------------------------------*/
form ol, form ul {
	list-style: none;
	margin-left: 0;
}
//
//input[type=checkbox], input[type=radio] {
//	@include rem(margin-left, -20px);
//}
fieldset {
	//border-top: 3px solid $blue;
	padding: 0;
	@include rem(margin, $space-half 0 $space);
	transition: all .4s ease-out;
	> .form-item {
		margin: 0;
	}
	&.fieldset-invisible > legend {
		@include rem(font-size, $font-size-base);
	}
	html.js & {
		&.collapsible .fieldset-legend,
		&.collapsed .fieldset-legend{
			background: #fff;
		}
		&.collapsible {
			@include rem(margin-top, $space);
			border: solid 1px $gray-lighter;
			.fieldset-title {

			}
			div.fieldset-wrapper {
				overflow: visible;
				opacity: 1;
				@include rem(padding, $space  $pad);
				transition: all .2s ease-out;
			}
			.fieldset-legend {
				position: absolute;
				display: flex;
				@include rem(top, -14px);
				@include rem(left, $pad-half);
				@include rem(padding, 0  $pad-half);
				.fieldset-title {
					@include rem(padding-left, 20px);
				}
				.icon {
					background: none;
					position: relative;
					display: inline;
					@include rem(width, 24px);
					@include rem(height, 24px);
					@include rem(min-width, 24px);
					//@include rem(border-radius, 24px);
					@include rem(margin-right, 5px);
					@include rem(top, 2px);
					@include rem(left, -20px);
					&:before, &:after {
						//border-radius: 2px;
						display: block;
						height: 3px;
						@include rem(width, 14px);
						position: absolute;
						left: 25%;
						content: '';
						background: $blue;
						//border-radius: 2px;
						transition: transform .3s ease-out, transform .3s ease-out, top .3s ease-out;
						top: 45%;
					}
					&:after {
						transform: rotate3d(0, 0, 1, 0deg);
					}
				}
			}
		}
		&.collapsed {
			border: none;
			.fieldset-legend .icon:after {
				transform: rotate3d(0, 0, 1, -90deg);
			}
			div.fieldset-wrapper {
				opacity: 0;
			}
		}
	}
}
.fieldset-title {
	font-weight: bold;
	@include link-colors($text-color, $link-hover, $link-hover, $link-hover, $text-color);
}

label {
	display: block;
//	@include rem(padding-bottom, $space-half);
	@include rem(padding-bottom, $space/3);
}

button, input, select, textarea {
    font-family: inherit;
    font-size: 100%;
    margin: 0 1px 0;
}

input, textarea {
	max-width: 100%;
	border: 1px solid $gray-lighter;
	@include rem(padding, $space-half/2 $pad-half);
  &:focus {
    border-color: $blue;
    outline:2px solid $blue;
  }
}

input[type=text], input[type=search], input[type=url], input[type=number], textarea, input[type="submit"] {
	-webkit-appearance: none;
	border-radius: 0;
}
input[type="submit"] + input[type="submit"] {
	@include rem(margin-right, $pad-half);
}
	button, input[type="submit"] {
		//padding: $pad-half;
		//background: $gray-dark;
		//border: 1px solid $gray;
		@include button($blue, white, darken($blue, 10%));
		@include rem(margin, $space 0);
		cursor: pointer;
		width: auto;
	}


//.form-checkboxes .form-item, .form-radios .form-item {
//	@include rem(margin-left, 20px);
//}
//input[type="checkbox"],
//input[type="radio"] {
//    width: auto;
//	@include rem(margin, 8px $pad-half 0 -20px);
//	float: left;
//
//}
//.checkbox {
//	text-align: left;
//}

/****checkbox***/
.form-type-checkboxes .form-type-checkbox,
.form-type-radios .form-type-radio,
.form-type-checkbox {
	position: relative;
  @include rem(padding, $space-half 0);
  label {padding-bottom: 0; padding-left: 2px;}
	input[type="checkbox"],
	input[type="radio"] {
		position: relative;
		z-index: 10;
		opacity: 0;
		vertical-align: middle;
		&, & + label:before,
		&:checked + label:before {
			width: 14px;
			height: 14px;
		}
		&, & + label {
			cursor: pointer;
		}
		& + label:before,
		&:checked + label:before {
			border: 1px solid $gray-light;
		}
		&:checked + label:before {
			background-color: $blue;
		}
		& + label:before,
		&:checked + label:before {
			content: " ";
			position: absolute;
			left: 0;
      @include rem(top, 18px);
			display: inline-block;
		}
    &:focus {
      border-color: $blue;
      outline:2px solid $blue;
    }
	}
	input[type="radio"] {
		& + label:before,
		&:checked + label:before {
			border-radius: 100%;
		}
	}
}


input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

//Form Field Container
.field-container {
	@include rem(margin-bottom, $space);
}

.inline-form {
	fieldset, .inline-container {
		position: relative;
	}
	.l-main & {
		input[type=submit], button, .btn {
			@include rem(font-size, $font-size-base);
			@include rem(padding, $space-quarter $pad-half);
			background: $gray-dark;
			position: absolute;
			top: 0;
			right: 0;
			z-index: 1;
			width: auto;

			&:hover, &:focus {
				background: $gray;
				color: white;
			}
		}
	}

}

/* Validation */
.has-error {
	border-color: $error;
}
.is-valid {
	border-color: $valid;
}


select {
	display: block;
	@include rem(font-size, $font-size-small);
	font-family: sans-serif;
	color: $text-color;
	line-height: 1.3;
	@include rem(padding, 4px 24px 3px 10px);
	box-sizing: border-box;
	margin: 0;
	border: 1px solid #aaa;
	box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
	linear-gradient(to bottom, #ffffff 0%,$gray-lightest 100%);
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
}
select::-ms-expand {
	display: none;
}
select:hover {
	border-color: #888;
}
select:focus {
	border-color: #aaa;
	box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
	box-shadow: 0 0 0 3px -moz-mac-focusring;
	color: #222;
	outline: none;
}


/*------------------------------------*\
    $SPECIFIC FORMS
\*------------------------------------*/

/* Search Form */
.search-field {
	padding-right: 3em;
	@include rem(padding-right, 48px);
}
.inline-form .search-submit {
	background: none;
	@include rem(padding, $space/3 15px);
	border: 0;
	border-left: 1px solid $gray;
	color: $gray;
}
