$space : $line-height-base ;
$space-and-half : $space*1.5;
$space-double : $space*2;
$space-quad : $space*4;
$space-half : $space/2;
$space-quarter: $space/4;
$pad : 20px;
$pad-and-half : $pad*1.5;
$pad-double : $pad*2;
$pad-half : $pad/2;
$pad-quarter :$pad/4;

$horz-spacing-unit: 20px;

// Breakpoint Settings
$bp-phone               : 320px;
$bp-small               : 550px;
$bp-med                 : 768px;
$bp-large               : 980px;
$bp-wide                : 1280px;
$bp-mega                : 1600px;

$small					: 'all' $bp-small;
$tab                    : 'all' $bp-med;
$desk                   : 'all' $bp-large;
$wide                   : 'all' $bp-wide;
$wider                   : 'all' $bp-mega;

$max-width              : 1366px;

$max-wide              : 1920px;

$columns                        : 12;
$col-width                      :percentage(1/$columns);
$col-half-width                 :percentage(1/($columns*2));

//grid settings
$susy: (
        columns:            $columns,
        container:          96%,
        global-box-sizing:  border-box,
        gutters:            1/2,
  //  debug:              (image:show),
);

$grid-breakpoints: (
	xs: 0,
	sm: 550px,
	md: 768px,
	lg: 980px,
	xl: 1280px
);
