.entity-paragraphs-item {
	//.section-title {
	//  @extend %set_layout;
	//  margin: em($space) auto;
	//}
	.section-title {
		display: block;
	}
	&.spacing,
	&.spacing-top,
	&.spacing-extra,
	&.spacing-extra-top,
	&.field-padding {
		.section-title {
			margin-top: 0;
		}
		.section-title + .content {
			@include rem(margin-top, $space);
		}
	}
	&.spacing,
	&.spacing-bottom,
	&.spacing-extra,
	&.spacing-extra-bottom,
	&.field-padding {
		p:last-child {
			margin-bottom: 0;
		}
	}
}

.paragraphs-item-image-with-download {
	.teaser-list {
		img {
			width: auto !important;
		}
	}
	&.grid {
		.filesize {
			display: none;
		}
		@include rem(font-size, $font-size-small);
	}
	&.view-mode-default {
		.panel-display {
			@include rem(margin-bottom, $space);
		}
		.teaser-list {
			border-bottom: 1px solid $gray-lighter;
			@include rem(padding-bottom, $space);
			@include rem(margin-bottom, $space);
			&:last-child {
				border-bottom: none;
				padding-bottom: 0;
			}
		}
	}
}

.paragraphs-item-text-block {
	@extend %clearfix
}

.paragraphs-item-slideshow-images {
	//.slick-track {
	//  display: flex;
	//}
	.paragraphs-item-figure-image {
		background: #000;
		@include rem(padding, $space-half $pad-half);
	}
	figcaption {
		color: #fff;
	}

}

.paragraphs-item-grid-layout {

	> .content > .entity {
		background-color: $gray-lightest;
		border: 1px solid darken($gray-lightest, 2%);
		position: relative;
		z-index: 1;
		border-radius: inherit;
		&:after, &:before {
			border-radius: inherit;
			z-index: -1;
			position: absolute;
			content: "";
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: 0;
			padding: 0;
		}
		&:after {
			opacity: .0625;
			box-shadow: 0 2px 0 0 #000;
		}
		&:hover,
		&.hover{
			background-color: $gray-lighter;
		}
	}
	.teaser-long .inner,
	.inner {
		display: block;
		background: transparent;
		border: none;
		@include link-colors($text-color, $text-color, $text-color, $text-color, $text-color);
		&:after {
			opacity: 0;
			box-shadow: none;
		}

	}
	&.center-content {
		.teaser-long .inner,
		.inner {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	> .content > .entity.field-bg {
		.inner,
		.teaser-long .inner {
			&:hover {
				background: transparent;
			}
		}

	}
	&.field-bg {
		> .content > .entity {
			background: transparent;
			border: none;
			&:after {
				opacity: 0;
				box-shadow: none;
			}
		}
	}
	> .content > .entity.bg-purple {
		background: $purple;
		border: 1px solid darken($purple, 2%);
		&.hover, &:hover {
			background: darken($purple, 5%);
		}
	}
	//&.bg-gray-dark{
	//  > .content >.entity {
	//    background: $gray-dark;
	//    &.hover, &:hover {
	//      background: darken($gray-dark, 5%);
	//    }
	//  }
	//}
	> .content > .entity.bg-gray-dark {
		background: $gray-dark;
		border: 1px solid darken($gray-dark, 2%);
		&.hover, &:hover {
			background: darken($gray-dark, 5%);
		}
	}
	> .content > .entity.bg-blue {
		background: $blue;
		border: 1px solid darken($blue, 2%);
		&.hover, &:hover {
			background: darken($blue, 5%);
		}
	}
	> .content > .entity.bg-green {
		background: $green;
		border: 1px solid darken($green, 2%);
		&.hover, &:hover {
			background: darken($green, 5%);
		}

	}
	> .content > .entity.bg-orange {
		background: $orange;
		border: 1px solid darken($orange, 2%);
		&.hover, &:hover {
			background: darken($orange, 5%);
		}
	}
	> .content > .entity.bg-burgundy {
		background: $burgundy;
		border: 1px solid darken($burgundy, 2%);
		&.hover, &:hover {
			background: darken($burgundy, 5%);
		}
	}

	> .content > .entity {
		margin: 0;
		@include rem(margin-bottom, $space);
	}

}

//.paragraphs-item-text-block.layout-constrain {
//  .content {
//    max-width: em(980px);
//  }
//}

.paragraphs-item-three-col-equal {
	.col {
		@include rem(margin-bottom, $space);

	}
}

.paragraphs-item-embedded-block {
	.sf-menu {
		float: none !important;
		a {
			padding-left: 0;
			padding-right: 0;
		}
		* {
			list-style: disc;
		}
		&,
		ul {
			@include rem(margin-left, $pad !important);
		}
		ul {
			display: block !important;
			position: static !important;
		}
		li {
			background: transparent;
			float: none !important;
		}
		> li {
			float: none !important;
		}
	}

}

//.span-width {
////  > .panel-pane > .paragraphs-item-accordion-tab,
////  > .paragraphs-item-accordion-tab,
////  > .panel-pane > .paragraphs-item-tabs,
////  > .paragraphs-item-tabs,
////  > .panel-pane > .paragraphs-item-text-block,
////  > .paragraphs-item-text-block,
////  > .panel-pane > .node > .paragraphs-item-text-block {
////    > .content {
////      @extend %set_layout;
////      >.field-name-field-text-block {
////        max-width: 900px;
////      }
////    }
////  }
////  > .panel-pane > .paragraphs-item-text-block,
////  > .paragraphs-item-text-block {
////    > .content {
////      @extend %set_layout;
////      >.field-name-field-text-block {
////        max-width: 900px;
////      }
////    }
////  }
////}

.paragraphs-item-text-block.spacing-bottom,
.paragraphs-item-text-block.spacing,
.paragraphs-item-text-block.spacing-extra-bottom {
	.content {
		p:last-child,
		ul:last-child {
			margin-bottom: 0;
		}
	}
}

@include breakpoint($small) {
	.paragraphs-item-slideshow-images {
		figure {
			@include rem(margin, $space $pad-half);
		}
	}
	.paragraphs-item-image-with-download {
		&.grid {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			@include rem(grid-gap, $space);
			.filesize {
				@include rem(font-size, 12px);
				line-height: $line-height-base/12px;
			}
		}
	}
	.paragraphs-item-grid-layout {
		> .content {
			display: grid;
			grid-template-columns: repeat(12, 1fr);
			@include rem(grid-gap, $space);
			> .entity {
				display: flex;
				grid-column-end: span 6;
				margin-bottom: 0;
				justify-content: center;
			}
		}
	}
	//.paragraphs-item-three-col-equal {
	//  > .content {
	//    display: grid;
	//    grid-template-columns: repeat(6, 1fr);
	//    @include rem(grid-gap, $pad-double);
	//    > .paragraphs-items {
	//      grid-column-end: span 3;
	//      &:first-child {
	//        grid-column-end: span 6;
	//      }
	//    }
	//  }
	//}
}

@include breakpoint($tab) {
	.paragraphs-item-three-col-equal {
		.col {
			margin-bottom: 0;

		}
	}
}

@include breakpoint($desk) {
	.paragraphs-item-grid-layout {
		&.col-md-4,
		&.col-wd-2 {
			> .content {
				> .entity {
					grid-column-end: span 4;
				}
			}

		}
		&.col-md-3 {
			> .content {
				> .entity {
					grid-column-end: span 3;
				}
			}

		}
	}
	.paragraphs-item-image-with-download {
		&.grid {
			grid-template-columns: repeat(3, 1fr);
		}
	}
	//.paragraphs-item-three-col-equal {
	//  > .content {
	//    > .paragraphs-items {
	//      grid-column-end: span 2;
	//      &:first-child {
	//        grid-column-end: span 2;
	//      }
	//    }
	//  }
	//}
}

@include breakpoint($wide) {
	.paragraphs-item-grid-layout {
		&.col-wd-2 {
			> .content {
				> .entity {
					grid-column-end: span 2;
				}
			}
		}
	}
	.paragraphs-item-image-with-download {
		&.grid {
			grid-template-columns: repeat(4, 1fr);
		}
	}
}
