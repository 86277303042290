
.addtoany_list {
  display: block;
  text-align: center;
  position: relative;
  width: 175px;
  margin: auto;
  a:hover {
    svg path {
      fill: $link-hover !important;
      stroke: $gray-lightest !important;
    }
    span {
      opacity: 1;
    }
  }
}

.share-btn {
  width: 32px;
  height: 32px;
  display: block;
  border: 1px solid transparent;
  position: relative;
  z-index: 20;
  fill: $blue;
  stroke: $blue;
  &:hover,
  &.active{
    fill: $burgundy;
    stroke: $burgundy !important;
  }

}
.social-media {
  position: absolute;
  top: -999px;
  right: 0;
  min-width: 217px;
  z-index: 10;
  opacity: 0;
  background: $gray-lightest;
  text-align: center;
  a {
    padding: 2px;
    line-height: 1;
    &:hover {
      border: none;
    }
  }
  transition: opacity 400ms ease-in-out;
  &.active {
    top: -36px;
   opacity:1;
    &:after {
      content: '';
      display: table;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid $gray-lightest;
      margin-bottom: -10px;
      margin-left: 60px;
    }
  }
}

@include breakpoint($tab) {
  .addtoany_list {
    text-align: right;
    margin: 0;
  }
}