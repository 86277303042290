// Text overflow
// Requires inline-block or block for proper styling
@mixin text-overflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// [converter] $parent hack
@mixin text-emphasis-variant($parent, $color) {
  #{$parent} {
    color: $color;
  }
  a#{$parent}:hover {
    color: darken($color, 10%);
  }
}

@mixin letterpress($opacity){
  // ($hoff, $voff, $blur, $spread, $color)
  @include text-shadow(0, 1px, 0, 0, rgba(white, $opacity));
}

@mixin reset-text() {
  font-family: $font;
  // We deliberately do NOT reset font-size.
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: $line-height-base;
  text-align: left; // Fallback for where `start` is not supported
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
}

// Create REM values with PX fall back
//
// Generate a REM with PX fallback from
// $baseFontSize. Enter the desired size based
// on pixels in numerical form. Supports shorthand.
//
// Forked from: http://codepen.io/thejameskyle/pen/JmBjc
//
// @author Greg Rickaby
// @since 1.0
//
// Usage: @include rem($property, $values);
// Example Usage:
//    @include rem(font-size, (16px);
//    @include rem(margin, 0 24px 0 12px);
//
// Outputs:
//    font-size: 16px;
//    font-size: 1.6rem;
//    margin: 0 24px 0 12px;
//    margin: 0 2.4rem 0 1.2rem;
// ----------------------------------

@function parseInt($n) {
  @return $n / ($n * 0 + 1);
}

@mixin rem($property, $values) {
  $px : ();
  $rem: ();

  $root: $baseFontSize;

  @each $value in $values {
    @if $value == 0 or $value == auto {
      $px : append($px , $value);
      $rem: append($rem, $value);
    }

    @else if type-of($value) == number {
      $unit: unit($value);
      $val: parseInt($value);

      @if $unit == "px" {
        $px : append($px,  $value);
        $rem: append($rem, ($val / $root + rem));
      }

      @if $unit == "rem" {
        $px : append($px,  ($val * $root + px));
        $rem: append($rem, $value);
      }
    }

    @else {
      $px : append($px,  $value);
      $rem: append($rem, $value);
    }
  }

  @if $px == $rem {
    #{$property}: $px;
  } @else {
    #{$property}: $px;
    #{$property}: $rem;
  }
}

@function rem($value) {
  $root: $baseFontSize;
  $val: parseInt($value);
  $return: ();

  @if unit($value) == "px" {
    $return: append($return, ($val / $root + rem));
  } @else {
    $return: append($return, ($val * $root + px));
  }

  @return $return;
}

@function em($pixels, $base: $font-size-base) {
  @if $pixels == 0 {
    @return 0;
  }
  @else {
    @return ($pixels / $base) * 1em;
  }
}


/* Rem Unit font sizes with relative fallback http:/seesparkbox.com/foundry/scss_rem_mixin_now_with_a_better_fallback
	Usage: @include font-size(1, large);
*/
@mixin font-size( $decimal-size, $keyword: null ) {
  @if $keyword{ font-size: $keyword; }
  @else { font-size: $decimal-size * $base-font-multiplier * 16px;}
  font-size: $decimal-size * 1rem;
}

@mixin link-colors($normal, $hover: false, $active: false, $visited: false, $focus: false) {
  color: $normal;
  @if $visited {
    &:visited {
      color: $visited;
    }
  }
  @if $focus {
    &:focus {
      color: $focus;
    }
  }
  @if $hover {
    &:hover {
      color: $hover;
    }
  }
  @if $active {
    &:active {
      color: $active;
    }
  }
}