@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Lato:300,300i,400,400i,700,700i");
@import url("https://fonts.googleapis.com/css?family=Libre+Baskerville:400,400i,700|Open+Sans:300,300i,400,400i,600,600i,700,700i");
/* line 22, sass/helpers/_buttons.scss */
button, input[type="submit"], .btn, .btn-blue, .btn-orange, .btn-green, .btn-burgundy, .btn-text-alt,
.btn-alt, .btn-text-alt.active,
.btn-alt.active, .tabWrapper .tabHeading button.btn.collapsed, .js .search-results-container .pager li.pager-item-next a, .side-nav .sf-accordion-toggle a {
  background-size: 100% 100%;
  cursor: pointer;
  display: inline-block;
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  *display: inline;
  *zoom: 1;
  font-weight: 700;
  text-align: center;
  touch-action: manipulation;
  background-image: none;
  text-decoration: none;
  text-transform: capitalize;
  vertical-align: baseline; }
  /* line 40, sass/helpers/_buttons.scss */
  button:focus, input:focus[type="submit"], .btn:focus, .btn-blue:focus, .btn-orange:focus, .btn-green:focus, .btn-burgundy:focus, .btn-text-alt:focus,
  .btn-alt:focus, .tabWrapper .tabHeading button.btn.collapsed:focus, .js .search-results-container .pager li.pager-item-next a:focus, .side-nav .sf-accordion-toggle a:focus, button:hover, input:hover[type="submit"], .btn:hover, .btn-blue:hover, .btn-orange:hover, .btn-green:hover, .btn-burgundy:hover, .btn-text-alt:hover,
  .btn-alt:hover, .tabWrapper .tabHeading button.btn.collapsed:hover, .js .search-results-container .pager li.pager-item-next a:hover, .side-nav .sf-accordion-toggle a:hover, button:active, input:active[type="submit"], .btn:active, .btn-blue:active, .btn-orange:active, .btn-green:active, .btn-burgundy:active, .btn-text-alt:active,
  .btn-alt:active, .tabWrapper .tabHeading button.btn.collapsed:active, .js .search-results-container .pager li.pager-item-next a:active, .side-nav .sf-accordion-toggle a:active {
    text-decoration: none; }

/* line 26, sass/helpers/_clearfix.scss */
.cf, .no-flexbox footer .menu, .clearfix, .paragraphs-item-text-block, ul.tabs.primary, .no-flexbox .pager {
  *zoom: 1; }
  /* line 15, sass/helpers/_clearfix.scss */
  .cf:before, .no-flexbox footer .menu:before, .clearfix:before, .paragraphs-item-text-block:before, ul.tabs.primary:before, .no-flexbox .pager:before, .cf:after, .no-flexbox footer .menu:after, .clearfix:after, .paragraphs-item-text-block:after, ul.tabs.primary:after, .no-flexbox .pager:after {
    content: " ";
    display: table;
    line-height: 0; }
  /* line 21, sass/helpers/_clearfix.scss */
  .cf:after, .no-flexbox footer .menu:after, .clearfix:after, .paragraphs-item-text-block:after, ul.tabs.primary:after, .no-flexbox .pager:after {
    clear: both; }

/* line 17, sass/helpers/_hide-text.scss */
.sr-only,
.element-invisible, .lines-button .text, button.btn-svg .label, footer #block-menu-menu-social-media .menu-title, .block-menu-menu-social-media .menu-title {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  padding: 0;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px); }

/* line 17, sass/helpers/_layout.scss */
#first-time,
.container,
.layout-constrain > .content,
.page-constrain .region-content .span-width,
.layout-constrain .section-title,
.page-help,
.node-type-landing .paragraphs-item-accordion-tab, .layout-default, footer .region-footer-top,
footer .region-footer-bottom, .header-wrapper > .inner, .header-preface-wrapper .inner, .node-slideshow .text-inner, #block-panels-mini-exhibit-header,
.pane-exhibit-header, .pane-exhibit-title.bg .panel-display, .pane-node-field-slides .section-title, .pane-taxonomy-term-field-block-content > .paragraphs-item-captioned-image,
.pane-taxonomy-term-field-block-content > .paragraphs-item-caption-text.teaser-long,
.pane-node-field-block-content > .paragraphs-item-captioned-image,
.pane-node-field-block-content > .paragraphs-item-caption-text.teaser-long, ul.tabs.primary, .pane-views-panes .panel-title, .paragraphs-item-flickr-slideshow .b-text-wrapper,
.paragraphs-item-slideshow-images .b-text-wrapper, .page-search .content-wrapper, .page-user .content-wrapper .region-content,
.page-user .content-wrapper .messages {
  width: 96%;
  max-width: 1366px;
  margin-left: auto;
  margin-right: auto; }

/* line 56, sass/helpers/_list.scss */
footer .menu, .inline-list.reset, .float-list, .reset-list,
.item-list .reset-list, .view .item-list ul, .related-object-groups, .edan-search-facets ul, .pager, .side-nav .menu, .block-menu-menu-social-media .menu {
  list-style-type: none;
  padding: 0;
  margin: 0; }
  /* line 60, sass/helpers/_list.scss */
  footer .menu > li, .inline-list.reset > li, .float-list > li, .reset-list > li, .item-list .reset-list > li, .view .item-list ul > li, .related-object-groups > li, .edan-search-facets ul > li, .pager > li, .side-nav .menu > li, .block-menu-menu-social-media .menu > li {
    list-style-type: none;
    list-style-image: none;
    padding: 0;
    margin: 0; }

/* Rem Unit font sizes with relative fallback http:/seesparkbox.com/foundry/scss_rem_mixin_now_with_a_better_fallback
	Usage: @include font-size(1, large);
*/
/*** MOBILE STYLES ***/
/* line 3, sass/vendor/superfish/_default.scss */
ul.sf-menu.sf-accordion {
  display: none;
  position: absolute; }
  /* line 6, sass/vendor/superfish/_default.scss */
  ul.sf-menu.sf-accordion,
  ul.sf-menu.sf-accordion ul,
  ul.sf-menu.sf-accordion li {
    float: left;
    width: 100%; }
  /* line 12, sass/vendor/superfish/_default.scss */
  ul.sf-menu.sf-accordion ul {
    margin: 0;
    padding: 0; }
  /* line 16, sass/vendor/superfish/_default.scss */
  ul.sf-menu.sf-accordion.sf-expanded,
  ul.sf-menu.sf-accordion li.sf-expanded > ul,
  ul.sf-menu.sf-accordion li.sf-expanded > .sf-mega {
    left: auto !important;
    position: relative;
    top: auto !important; }
  /* line 24, sass/vendor/superfish/_default.scss */
  ul.sf-menu.sf-accordion li.menuparent > a {
    position: relative;
    display: inline-block;
    padding-left: 24px;
    padding-left: 2.4rem; }
    /* line 68, sass/helpers/_icon.scss */
    ul.sf-menu.sf-accordion li.menuparent > a:before {
      content: "";
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
      color: true;
      position: absolute;
      font-size: 6px;
      font-size: 0.6rem;
      top: 29px;
      top: 2.9rem;
      left: 6px;
      left: 0.6rem; }
    /* line 80, sass/helpers/_icon.scss */
    ul.sf-menu.sf-accordion li.menuparent > a:hover:before {
      color: false; }
    /* line 26, sass/vendor/superfish/_default.scss */
    ul.sf-menu.sf-accordion li.menuparent > a:after {
      transition: all 0.4s cubic-bezier(0.55, 0.085, 0, 0.99); }
  /* line 31, sass/vendor/superfish/_default.scss */
  ul.sf-menu.sf-accordion li.menuparent.sf-expanded > a:after {
    transform: rotate(180deg); }

/*** ESSENTIAL STYLES ***/
/* line 40, sass/vendor/superfish/_default.scss */
.sf-menu {
  float: left; }
  /* line 43, sass/vendor/superfish/_default.scss */
  .sf-menu,
  .sf-menu * {
    margin: 0;
    padding: 0;
    list-style: none; }
  /* line 49, sass/vendor/superfish/_default.scss */
  .sf-menu li {
    position: relative;
    background: #333F48;
    transition: background .2s, color .2s; }
    /* line 56, sass/vendor/superfish/_default.scss */
    .sf-menu li p {
      white-space: normal; }
  /* line 60, sass/vendor/superfish/_default.scss */
  .sf-menu .sf-mega,
  .sf-menu ul {
    position: absolute;
    display: none;
    top: 100%;
    left: 0;
    z-index: 200;
    min-width: 12em;
    /* allow long menu items to determine submenu width */
    *width: 12em;
    /* no auto sub width for IE7, see white-space comment below */ }
  /* line 70, sass/vendor/superfish/_default.scss */
  .sf-menu .sf-mega ul {
    display: block;
    position: relative;
    top: auto;
    width: 100%; }
  /* line 76, sass/vendor/superfish/_default.scss */
  .sf-menu > li {
    float: left; }
  /* line 80, sass/vendor/superfish/_default.scss */
  .sf-menu li:hover > ul,
  .sf-menu li.sfHover > ul {
    display: block; }
  /* line 85, sass/vendor/superfish/_default.scss */
  .sf-menu a {
    display: block;
    position: relative;
    padding: 12px 18px;
    padding: 1.2rem 1.8rem;
    text-decoration: none;
    zoom: 1;
    /* IE7 */ }
  /* line 94, sass/vendor/superfish/_default.scss */
  .sf-menu ul ul {
    top: 0;
    left: 100%; }
  /* line 98, sass/vendor/superfish/_default.scss */
  .sf-menu ul li {
    background: #165C7D; }
  /* line 101, sass/vendor/superfish/_default.scss */
  .sf-menu ul ul li {
    background: #6c7a89; }
  /* line 104, sass/vendor/superfish/_default.scss */
  .sf-menu li:hover,
  .sf-menu li.sfHover {
    /* only transition out, not in */
    -webkit-transition: none;
    transition: none; }

/*** arrows (for all except IE7) **/
/* line 114, sass/vendor/superfish/_default.scss */
.sf-arrows .sf-with-ul {
  padding-right: 2.5em;
  *padding-right: 1em;
  /* no CSS arrows for IE7 (lack pseudo-elements) */ }

/* styling for both css and generated arrows */
/* line 119, sass/vendor/superfish/_default.scss */
.sf-arrows .sf-with-ul:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 1em;
  margin-top: -3px;
  height: 0;
  width: 0;
  /* order of following 3 rules important for fallbacks to work */
  border: 5px solid transparent;
  border-top-color: #dFeEFF;
  /* edit this to suit design (no rgba in IE8) */
  border-top-color: rgba(255, 255, 255, 0.5); }

/* line 132, sass/vendor/superfish/_default.scss */
.sf-arrows > li > .sf-with-ul:focus:after,
.sf-arrows > li:hover > .sf-with-ul:after,
.sf-arrows > .sfHover > .sf-with-ul:after {
  border-top-color: white;
  /* IE8 fallback colour */ }

/* styling for right-facing arrows */
/* line 138, sass/vendor/superfish/_default.scss */
.sf-arrows ul .sf-with-ul:after {
  margin-top: -5px;
  margin-right: -3px;
  border-color: transparent;
  border-left-color: #dFeEFF;
  /* edit this to suit design (no rgba in IE8) */
  border-left-color: rgba(255, 255, 255, 0.5); }

/* line 145, sass/vendor/superfish/_default.scss */
.sf-arrows ul li > .sf-with-ul:focus:after,
.sf-arrows ul li:hover > .sf-with-ul:after,
.sf-arrows ul .sfHover > .sf-with-ul:after {
  border-left-color: white; }

@font-face {
  font-family: 'BasierCircle';
  src: url("../fonts/BrandFonts/basiercircle-bolditalic-webfont.woff2") format("woff2"), url("../fonts/BrandFonts/basiercircle-bolditalic-webfont.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: auto; }

@font-face {
  font-family: 'BasierCircle';
  src: url("../fonts/BrandFonts/basiercircle-mediumitalic-webfont.woff2") format("woff2"), url("../fonts/BrandFonts/basiercircle-mediumitalic-webfont.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: auto; }

@font-face {
  font-family: 'BasierCircle';
  src: url("../fonts/BrandFonts/basiercircle-regularitalic-webfont.woff2") format("woff2"), url("../fonts/BrandFonts/basiercircle-regularitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: auto; }

@font-face {
  font-family: 'BasierCircle';
  src: url("../fonts/BrandFonts/basiercircle-semibolditalic-webfont.woff2") format("woff2"), url("../fonts/BrandFonts/basiercircle-semibolditalic-webfont.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: auto; }

@font-face {
  font-family: 'BasierCircle';
  src: url("../fonts/BrandFonts/basiercircle-bold-webfont.woff2") format("woff2"), url("../fonts/BrandFonts/basiercircle-bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: auto; }

@font-face {
  font-family: 'BasierCircle';
  src: url("../fonts/BrandFonts/basiercircle-medium-webfont.woff2") format("woff2"), url("../fonts/BrandFonts/basiercircle-medium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: auto; }

@font-face {
  font-family: 'BasierCircle';
  src: url("../fonts/BrandFonts/basiercircle-regular-webfont.woff2") format("woff2"), url("../fonts/BrandFonts/basiercircle-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: auto; }

@font-face {
  font-family: 'BasierCircle';
  src: url("../fonts/BrandFonts/basiercircle-semibold-webfont.woff2") format("woff2"), url("../fonts/BrandFonts/basiercircle-semibold-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: auto; }

@font-face {
  font-family: 'MinionPro-Cond';
  src: url("../fonts/BrandFonts/MinionPro-BoldCnIt.eot");
  src: url("../fonts/BrandFonts/MinionPro-BoldCnIt.eot?#iefix") format("embedded-opentype"), url("../fonts/BrandFonts/MinionPro-BoldCnIt.woff2") format("woff2"), url("../fonts/BrandFonts/MinionPro-BoldCnIt.woff") format("woff"), url("../fonts/BrandFonts/MinionPro-BoldCnIt.svg#MinionPro-BoldCnIt") format("svg");
  font-weight: 700;
  font-style: italic;
  font-stretch: condensed;
  unicode-range: U+0020-00FE;
  font-display: auto; }

@font-face {
  font-family: 'MinionPro-Cond';
  src: url("../fonts/BrandFonts/MinionPro-BoldCn.eot");
  src: url("../fonts/BrandFonts/MinionPro-BoldCn.eot?#iefix") format("embedded-opentype"), url("../fonts/BrandFonts/MinionPro-BoldCn.woff2") format("woff2"), url("../fonts/BrandFonts/MinionPro-BoldCn.woff") format("woff"), url("../fonts/BrandFonts/MinionPro-BoldCn.svg#MinionPro-BoldCn") format("svg");
  font-weight: 700;
  font-style: normal;
  font-stretch: condensed;
  unicode-range: U+0020-00FE;
  font-display: auto; }

@font-face {
  font-family: 'MinionPro';
  src: url("../fonts/BrandFonts/MinionPro-Regular-MinionPro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: auto; }

@font-face {
  font-family: 'MinionPro';
  src: url("../fonts/BrandFonts/MinionPro-It.eot");
  src: url("../fonts/BrandFonts/MinionPro-It.eot?#iefix") format("embedded-opentype"), url("../fonts/BrandFonts/MinionPro-It.woff2") format("woff2"), url("../fonts/BrandFonts/MinionPro-It.woff") format("woff"), url("../fonts/BrandFonts/MinionPro-It.svg#MinionPro-It") format("svg");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
  font-display: auto; }

@font-face {
  font-family: 'MinionPro';
  src: url("../fonts/BrandFonts/MinionPro-Medium.eot");
  src: url("../fonts/BrandFonts/MinionPro-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/BrandFonts/MinionPro-Medium.woff2") format("woff2"), url("../fonts/BrandFonts/MinionPro-Medium.woff") format("woff"), url("../fonts/BrandFonts/MinionPro-Medium.svg#MinionPro-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
  font-display: auto; }

@font-face {
  font-family: 'MinionPro';
  src: url("../fonts/BrandFonts/MinionPro-MediumIt.eot");
  src: url("../fonts/BrandFonts/MinionPro-MediumIt.eot?#iefix") format("embedded-opentype"), url("../fonts/BrandFonts/MinionPro-MediumIt.woff2") format("woff2"), url("../fonts/BrandFonts/MinionPro-MediumIt.woff") format("woff"), url("../fonts/BrandFonts/MinionPro-MediumIt.svg#MinionPro-MediumIt") format("svg");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
  font-display: auto; }

@font-face {
  font-family: 'MinionPro';
  src: url("../fonts/BrandFonts/MinionPro-Semibold.eot");
  src: url("../fonts/BrandFonts/MinionPro-Semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/BrandFonts/MinionPro-Semibold.woff2") format("woff2"), url("../fonts/BrandFonts/MinionPro-Semibold.woff") format("woff"), url("../fonts/BrandFonts/MinionPro-Semibold.svg#MinionPro-Semibold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
  font-display: auto; }

@font-face {
  font-family: 'MinionPro';
  src: url("../fonts/BrandFonts/MinionPro-SemiboldIt.eot");
  src: url("../fonts/BrandFonts/MinionPro-SemiboldIt.eot?#iefix") format("embedded-opentype"), url("../fonts/BrandFonts/MinionPro-SemiboldIt.woff2") format("woff2"), url("../fonts/BrandFonts/MinionPro-SemiboldIt.woff") format("woff"), url("../fonts/BrandFonts/MinionPro-SemiboldIt.svg#MinionPro-SemiboldIt") format("svg");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
  font-display: auto; }

@font-face {
  font-family: 'MinionPro';
  src: url("../fonts/BrandFonts/MinionPro-Semibold.eot");
  src: url("../fonts/BrandFonts/MinionPro-Semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/BrandFonts/MinionPro-Semibold.woff2") format("woff2"), url("../fonts/BrandFonts/MinionPro-Semibold.woff") format("woff"), url("../fonts/BrandFonts/MinionPro-Semibold.svg#MinionPro-Semibold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
  font-display: auto; }

@font-face {
  font-family: 'MinionPro';
  src: url("../fonts/BrandFonts/MinionPro-SemiboldIt.eot");
  src: url("../fonts/BrandFonts/MinionPro-SemiboldIt.eot?#iefix") format("embedded-opentype"), url("../fonts/BrandFonts/MinionPro-SemiboldIt.woff2") format("woff2"), url("../fonts/BrandFonts/MinionPro-SemiboldIt.woff") format("woff"), url("../fonts/BrandFonts/MinionPro-SemiboldIt.svg#MinionPro-SemiboldIt") format("svg");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
  font-display: auto; }

@font-face {
  font-family: 'Ionicons';
  src: url("../fonts/BrandFonts/ionicons.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: auto; }

@font-face {
  font-family: 'material';
  src: url("../fonts/BrandFonts/material.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: auto; }

@font-face {
  font-family: 'simple-line-icons';
  src: url("../fonts/BrandFonts/simple-line-icons.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: auto; }

/*------------------------------------*\
    $RESET
\*------------------------------------*/
/* Border-Box http:/paulirish.com/2012/box-sizing-border-box-ftw/ */
/* line 6, sass/base/_01-reset.scss */
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

/* line 12, sass/base/_01-reset.scss */
html, body, div, object, iframe, h1, h2, .main .edan-search-form .form-type-textfield label, h3, .node-type-recipient .field-name-field-title-long,
.node-type-staff .field-name-field-title-long, .front #paragraphs-item-5555 .group-right .section-title, h4, .node-tdiph .field-type-datetime, .si-tabs.r-tabs .panel-heading, h5, h6, p, blockquote, ol, ul, li, form, legend, label, table, header, footer, nav, section, figure {
  margin: 0;
  padding: 0; }

/* line 17, sass/base/_01-reset.scss */
header, footer, nav, section, article, hgroup, figure {
  display: block; }

/* line 21, sass/base/_01-reset.scss */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block; }

/* line 36, sass/base/_01-reset.scss */
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

/* line 44, sass/base/_01-reset.scss */
audio:not([controls]) {
  display: none;
  height: 0; }

/* line 49, sass/base/_01-reset.scss */
[hidden],
template {
  display: none; }

/* line 54, sass/base/_01-reset.scss */
html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  overflow-x: hidden; }

/* line 61, sass/base/_01-reset.scss */
body {
  margin: 0; }

/* line 65, sass/base/_01-reset.scss */
a {
  background: transparent; }

/* line 69, sass/base/_01-reset.scss */
a:active,
a:hover {
  outline: 0; }

/* line 74, sass/base/_01-reset.scss */
abbr[title] {
  border-bottom: 1px dotted; }

/* line 78, sass/base/_01-reset.scss */
b,
strong {
  font-weight: bold; }

/* line 83, sass/base/_01-reset.scss */
dfn {
  font-style: italic; }

/* line 87, sass/base/_01-reset.scss */
mark {
  background: #ff0;
  color: #000; }

/* line 92, sass/base/_01-reset.scss */
small {
  font-size: 80%; }

/* line 96, sass/base/_01-reset.scss */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

/* line 104, sass/base/_01-reset.scss */
sup {
  top: -0.5em; }

/* line 108, sass/base/_01-reset.scss */
sub {
  bottom: -0.25em; }

/* line 112, sass/base/_01-reset.scss */
img {
  border: 0; }

/* line 116, sass/base/_01-reset.scss */
svg:not(:root) {
  overflow: hidden; }

/* line 120, sass/base/_01-reset.scss */
figure {
  margin: 1em 40px; }

/* line 124, sass/base/_01-reset.scss */
hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

/* line 130, sass/base/_01-reset.scss */
pre {
  overflow: auto; }

/* line 134, sass/base/_01-reset.scss */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/* line 142, sass/base/_01-reset.scss */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

/* line 152, sass/base/_01-reset.scss */
button {
  overflow: visible; }

/* line 156, sass/base/_01-reset.scss */
button,
select {
  text-transform: none; }

/* line 161, sass/base/_01-reset.scss */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

/* line 169, sass/base/_01-reset.scss */
button[disabled],
html input[disabled] {
  cursor: default; }

/* line 174, sass/base/_01-reset.scss */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/* line 180, sass/base/_01-reset.scss */
input {
  line-height: normal; }

/* line 184, sass/base/_01-reset.scss */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

/* line 190, sass/base/_01-reset.scss */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/* line 195, sass/base/_01-reset.scss */
input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

/* line 202, sass/base/_01-reset.scss */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/* line 207, sass/base/_01-reset.scss */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/* line 213, sass/base/_01-reset.scss */
legend {
  border: 0;
  padding: 0; }

/* line 218, sass/base/_01-reset.scss */
textarea {
  overflow: auto; }

/* line 222, sass/base/_01-reset.scss */
optgroup {
  font-weight: bold; }

/* line 225, sass/base/_01-reset.scss */
table {
  border-collapse: collapse;
  border-spacing: 0; }

/* line 230, sass/base/_01-reset.scss */
td,
th {
  padding: 0; }

/* line 3, sass/base/_02-text.scss */
html {
  font-size: 62.5%; }
  @media (min-width: 1280px) {
    /* line 3, sass/base/_02-text.scss */
    html {
      font-size: 74.21875%; } }

/* line 14, sass/base/_02-text.scss */
body {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 17px;
  font-size: 1.7rem;
  font-weight: 400;
  line-height: 1.5;
  color: #333F48;
  background: #333F48;
  overflow-x: hidden; }

/* Blockquote */
/* line 26, sass/base/_02-text.scss */
blockquote {
  font-style: italic;
  border-left: 1px solid #6c7a89;
  color: #6c7a89;
  padding-left: 20px;
  padding-left: 2rem;
  margin-bottom: 24px;
  margin-bottom: 2.4rem; }

/* line 33, sass/base/_02-text.scss */
cite {
  display: block;
  text-align: center;
  font-style: normal;
  margin-top: 12px;
  margin-top: 1.2rem; }

/* Horizontal Rule */
/* line 42, sass/base/_02-text.scss */
hr {
  border: 0;
  height: 2px;
  background: #D0D7DE;
  margin: 24px 0;
  margin: 2.4rem 0; }

/* line 49, sass/base/_02-text.scss */
abbr {
  border-bottom: 1px dotted #6c7a89;
  cursor: help; }

/* line 56, sass/base/_02-text.scss */
headings a {
  color: #333F48; }
  /* line 147, sass/helpers/_text.scss */
  headings a:visited {
    color: #333F48; }
  /* line 152, sass/helpers/_text.scss */
  headings a:focus {
    color: #333F48; }
  /* line 157, sass/helpers/_text.scss */
  headings a:hover {
    color: #333F48; }
  /* line 162, sass/helpers/_text.scss */
  headings a:active {
    color: #333F48; }
  /* line 58, sass/base/_02-text.scss */
  headings a:focus, headings a:hover, headings a:active {
    text-decoration: none;
    cursor: hand;
    border-bottom: 1px dotted #333F48; }

/* Headings */
/*Further Reading: http:/csswizardry.com/2012/02/pragmatic-practical-font-sizing-in-css/ */
/* line 70, sass/base/_02-text.scss */
h1, .h1 {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 48px;
  font-size: 4.8rem;
  line-height: 1.25;
  color: #101820;
  font-weight: bold; }
  /* line 76, sass/base/_02-text.scss */
  h1.container, .h1.container {
    margin-bottom: 24px;
    margin-bottom: 2.4rem; }

/* line 80, sass/base/_02-text.scss */
h2.h1, .main .edan-search-form .form-type-textfield label.h1 {
  margin-bottom: 24px;
  margin-bottom: 2.4rem;
  color: #333F48; }

/* line 85, sass/base/_02-text.scss */
h2, .main .edan-search-form .form-type-textfield label, .h2 {
  font-family: "MinionPro", Georgia, Times, "Times New Roman", serif;
  font-weight: bold;
  font-size: 34px;
  font-size: 3.4rem;
  line-height: 1.41176;
  color: #002554;
  margin: 0 auto 24px;
  margin: 0 auto 2.4rem; }
  /* line 93, sass/base/_02-text.scss */
  h2 a, .main .edan-search-form .form-type-textfield label a, .h2 a {
    color: #002554; }
    /* line 147, sass/helpers/_text.scss */
    h2 a:visited, .main .edan-search-form .form-type-textfield label a:visited, .h2 a:visited {
      color: #002554; }
    /* line 152, sass/helpers/_text.scss */
    h2 a:focus, .main .edan-search-form .form-type-textfield label a:focus, .h2 a:focus {
      color: #002554; }
    /* line 157, sass/helpers/_text.scss */
    h2 a:hover, .main .edan-search-form .form-type-textfield label a:hover, .h2 a:hover {
      color: #002554; }
    /* line 162, sass/helpers/_text.scss */
    h2 a:active, .main .edan-search-form .form-type-textfield label a:active, .h2 a:active {
      color: #002554; }
    /* line 95, sass/base/_02-text.scss */
    h2 a:focus, .main .edan-search-form .form-type-textfield label a:focus, h2 a:hover, .main .edan-search-form .form-type-textfield label a:hover, h2 a:active, .main .edan-search-form .form-type-textfield label a:active, .h2 a:focus, .h2 a:hover, .h2 a:active {
      text-decoration: none;
      cursor: hand;
      border-bottom: 1px dotted #002554; }

/* line 104, sass/base/_02-text.scss */
h1.h2 {
  margin-top: 0; }

/* line 108, sass/base/_02-text.scss */
h3, .node-type-recipient .field-name-field-title-long,
.node-type-staff .field-name-field-title-long, .front #paragraphs-item-5555 .group-right .section-title, .h3, .panel-region-left-aside h2, .panel-region-left-aside .main .edan-search-form .form-type-textfield label, .main .edan-search-form .form-type-textfield .panel-region-left-aside label, .panel-region-right-aside h2, .panel-region-right-aside .main .edan-search-form .form-type-textfield label, .main .edan-search-form .form-type-textfield .panel-region-right-aside label, h2.h3, .main .edan-search-form .form-type-textfield label.h3 {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1.5;
  color: #333F48;
  font-weight: 700; }

/* line 116, sass/base/_02-text.scss */
h4, .node-tdiph .field-type-datetime, .si-tabs.r-tabs .panel-heading, .h4, .panel-region-left-aside h3, .panel-region-left-aside .node-type-recipient .field-name-field-title-long, .node-type-recipient .panel-region-left-aside .field-name-field-title-long,
.panel-region-left-aside .node-type-staff .field-name-field-title-long, .node-type-staff .panel-region-left-aside .field-name-field-title-long, .panel-region-left-aside .front #paragraphs-item-5555 .group-right .section-title, .front #paragraphs-item-5555 .group-right .panel-region-left-aside .section-title, .panel-region-right-aside h3, .panel-region-right-aside .node-type-recipient .field-name-field-title-long, .node-type-recipient .panel-region-right-aside .field-name-field-title-long,
.panel-region-right-aside .node-type-staff .field-name-field-title-long, .node-type-staff .panel-region-right-aside .field-name-field-title-long, .panel-region-right-aside .front #paragraphs-item-5555 .group-right .section-title, .front #paragraphs-item-5555 .group-right .panel-region-right-aside .section-title {
  color: #333F48;
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 21px;
  font-size: 2.1rem;
  line-height: 1.71429;
  font-weight: 700; }

/* line 124, sass/base/_02-text.scss */
h5, .h5, .panel-region-left-aside h4, .panel-region-left-aside .node-tdiph .field-type-datetime, .node-tdiph .panel-region-left-aside .field-type-datetime, .panel-region-left-aside .si-tabs.r-tabs .panel-heading, .si-tabs.r-tabs .panel-region-left-aside .panel-heading, .panel-region-right-aside h4, .panel-region-right-aside .node-tdiph .field-type-datetime, .node-tdiph .panel-region-right-aside .field-type-datetime, .panel-region-right-aside .si-tabs.r-tabs .panel-heading, .si-tabs.r-tabs .panel-region-right-aside .panel-heading {
  color: #333F48;
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 19px;
  font-size: 1.9rem;
  line-height: 1.26316;
  font-weight: 700; }

/* line 132, sass/base/_02-text.scss */
h6, .h6 {
  color: #333F48;
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 17px;
  font-size: 1.7rem;
  line-height: 1.41176;
  font-weight: 700; }

/* line 140, sass/base/_02-text.scss */
h3, .node-type-recipient .field-name-field-title-long,
.node-type-staff .field-name-field-title-long, .front #paragraphs-item-5555 .group-right .section-title, .h3, h4, .node-tdiph .field-type-datetime, .si-tabs.r-tabs .panel-heading, .h4,
h5, .h5,
h6, .h6 {
  margin-bottom: 24px;
  margin-bottom: 2.4rem; }

/* Text-Related Elements */
/* line 148, sass/base/_02-text.scss */
p {
  margin: 0 0 24px;
  margin: 0 0 2.4rem; }

/* line 152, sass/base/_02-text.scss */
.extra-line-height.h4 {
  line-height: 2; }

/* line 156, sass/base/_02-text.scss */
.regular {
  font-weight: normal; }

/*------------------------------------*\
    $FORMS
\*------------------------------------*/
/* line 4, sass/base/_forms.scss */
form ol, form ul {
  list-style: none;
  margin-left: 0; }

/* line 12, sass/base/_forms.scss */
fieldset {
  padding: 0;
  margin: 12px 0 24px;
  margin: 1.2rem 0 2.4rem;
  transition: all .4s ease-out; }
  /* line 17, sass/base/_forms.scss */
  fieldset > .form-item {
    margin: 0; }
  /* line 20, sass/base/_forms.scss */
  fieldset.fieldset-invisible > legend {
    font-size: 17px;
    font-size: 1.7rem; }
  /* line 24, sass/base/_forms.scss */
  html.js fieldset.collapsible .fieldset-legend,
  html.js fieldset.collapsed .fieldset-legend {
    background: #fff; }
  /* line 28, sass/base/_forms.scss */
  html.js fieldset.collapsible {
    margin-top: 24px;
    margin-top: 2.4rem;
    border: solid 1px #D0D7DE; }
    /* line 34, sass/base/_forms.scss */
    html.js fieldset.collapsible div.fieldset-wrapper {
      overflow: visible;
      opacity: 1;
      padding: 24px 20px;
      padding: 2.4rem 2rem;
      transition: all .2s ease-out; }
    /* line 40, sass/base/_forms.scss */
    html.js fieldset.collapsible .fieldset-legend {
      position: absolute;
      display: flex;
      top: -14px;
      top: -1.4rem;
      left: 10px;
      left: 1rem;
      padding: 0 10px;
      padding: 0 1rem; }
      /* line 46, sass/base/_forms.scss */
      html.js fieldset.collapsible .fieldset-legend .fieldset-title {
        padding-left: 20px;
        padding-left: 2rem; }
      /* line 49, sass/base/_forms.scss */
      html.js fieldset.collapsible .fieldset-legend .icon {
        background: none;
        position: relative;
        display: inline;
        width: 24px;
        width: 2.4rem;
        height: 24px;
        height: 2.4rem;
        min-width: 24px;
        min-width: 2.4rem;
        margin-right: 5px;
        margin-right: 0.5rem;
        top: 2px;
        top: 0.2rem;
        left: -20px;
        left: -2rem; }
        /* line 60, sass/base/_forms.scss */
        html.js fieldset.collapsible .fieldset-legend .icon:before, html.js fieldset.collapsible .fieldset-legend .icon:after {
          display: block;
          height: 3px;
          width: 14px;
          width: 1.4rem;
          position: absolute;
          left: 25%;
          content: '';
          background: #165C7D;
          transition: transform .3s ease-out, transform .3s ease-out, top .3s ease-out;
          top: 45%; }
        /* line 73, sass/base/_forms.scss */
        html.js fieldset.collapsible .fieldset-legend .icon:after {
          transform: rotate3d(0, 0, 1, 0deg); }
  /* line 79, sass/base/_forms.scss */
  html.js fieldset.collapsed {
    border: none; }
    /* line 81, sass/base/_forms.scss */
    html.js fieldset.collapsed .fieldset-legend .icon:after {
      transform: rotate3d(0, 0, 1, -90deg); }
    /* line 84, sass/base/_forms.scss */
    html.js fieldset.collapsed div.fieldset-wrapper {
      opacity: 0; }

/* line 90, sass/base/_forms.scss */
.fieldset-title {
  font-weight: bold;
  color: #333F48; }
  /* line 147, sass/helpers/_text.scss */
  .fieldset-title:visited {
    color: #9f3050; }
  /* line 152, sass/helpers/_text.scss */
  .fieldset-title:focus {
    color: #333F48; }
  /* line 157, sass/helpers/_text.scss */
  .fieldset-title:hover {
    color: #9f3050; }
  /* line 162, sass/helpers/_text.scss */
  .fieldset-title:active {
    color: #9f3050; }

/* line 95, sass/base/_forms.scss */
label {
  display: block;
  padding-bottom: 8px;
  padding-bottom: 0.8rem; }

/* line 101, sass/base/_forms.scss */
button, input, select, textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0 1px 0; }

/* line 107, sass/base/_forms.scss */
input, textarea {
  max-width: 100%;
  border: 1px solid #D0D7DE;
  padding: 6px 10px;
  padding: 0.6rem 1rem; }
  /* line 111, sass/base/_forms.scss */
  input:focus, textarea:focus {
    border-color: #165C7D;
    outline: 2px solid #165C7D; }

/* line 117, sass/base/_forms.scss */
input[type=text], input[type=search], input[type=url], input[type=number], textarea, input[type="submit"] {
  -webkit-appearance: none;
  border-radius: 0; }

/* line 121, sass/base/_forms.scss */
input[type="submit"] + input[type="submit"] {
  margin-right: 10px;
  margin-right: 1rem; }

/* line 124, sass/base/_forms.scss */
button, input[type="submit"] {
  font-size: 17px;
  font-size: 1.7rem;
  transition: background-color 300ms ease-out, border-color 320ms ease-out;
  background-color: #165C7D;
  border: 1px solid #278ab8;
  color: white;
  position: relative;
  z-index: 1;
  margin: 24px 0;
  margin: 2.4rem 0;
  cursor: pointer;
  width: auto; }
  /* line 147, sass/helpers/_text.scss */
  button:visited, input[type="submit"]:visited {
    color: white; }
  /* line 152, sass/helpers/_text.scss */
  button:focus, input[type="submit"]:focus {
    color: white; }
  /* line 157, sass/helpers/_text.scss */
  button:hover, input[type="submit"]:hover {
    color: white; }
  /* line 162, sass/helpers/_text.scss */
  button:active, input[type="submit"]:active {
    color: white; }
  /* line 78, sass/helpers/_buttons.scss */
  button:focus, button:hover, button:active, input[type="submit"]:focus, input[type="submit"]:hover, input[type="submit"]:active {
    background-color: #0e3c52;
    border-color: #278ab8;
    text-decoration: none;
    box-shadow: rgba(0, 0, 0, 0.16) -2px 0 2px 0, rgba(0, 0, 0, 0.16) 2px 0 2px 0, rgba(0, 0, 0, 0.32) 0 2px 2px 0; }

/****checkbox***/
/* line 150, sass/base/_forms.scss */
.form-type-checkboxes .form-type-checkbox,
.form-type-radios .form-type-radio,
.form-type-checkbox {
  position: relative;
  padding: 12px 0;
  padding: 1.2rem 0; }
  /* line 155, sass/base/_forms.scss */
  .form-type-checkboxes .form-type-checkbox label,
  .form-type-radios .form-type-radio label,
  .form-type-checkbox label {
    padding-bottom: 0;
    padding-left: 2px; }
  /* line 156, sass/base/_forms.scss */
  .form-type-checkboxes .form-type-checkbox input[type="checkbox"],
  .form-type-checkboxes .form-type-checkbox input[type="radio"],
  .form-type-radios .form-type-radio input[type="checkbox"],
  .form-type-radios .form-type-radio input[type="radio"],
  .form-type-checkbox input[type="checkbox"],
  .form-type-checkbox input[type="radio"] {
    position: relative;
    z-index: 10;
    opacity: 0;
    vertical-align: middle; }
    /* line 162, sass/base/_forms.scss */
    .form-type-checkboxes .form-type-checkbox input[type="checkbox"], .form-type-checkboxes .form-type-checkbox input[type="checkbox"] + label:before,
    .form-type-checkboxes .form-type-checkbox input[type="checkbox"]:checked + label:before,
    .form-type-checkboxes .form-type-checkbox input[type="radio"],
    .form-type-checkboxes .form-type-checkbox input[type="radio"] + label:before,
    .form-type-checkboxes .form-type-checkbox input[type="radio"]:checked + label:before,
    .form-type-radios .form-type-radio input[type="checkbox"],
    .form-type-radios .form-type-radio input[type="checkbox"] + label:before,
    .form-type-radios .form-type-radio input[type="checkbox"]:checked + label:before,
    .form-type-radios .form-type-radio input[type="radio"],
    .form-type-radios .form-type-radio input[type="radio"] + label:before,
    .form-type-radios .form-type-radio input[type="radio"]:checked + label:before,
    .form-type-checkbox input[type="checkbox"],
    .form-type-checkbox input[type="checkbox"] + label:before,
    .form-type-checkbox input[type="checkbox"]:checked + label:before,
    .form-type-checkbox input[type="radio"],
    .form-type-checkbox input[type="radio"] + label:before,
    .form-type-checkbox input[type="radio"]:checked + label:before {
      width: 14px;
      height: 14px; }
    /* line 167, sass/base/_forms.scss */
    .form-type-checkboxes .form-type-checkbox input[type="checkbox"], .form-type-checkboxes .form-type-checkbox input[type="checkbox"] + label,
    .form-type-checkboxes .form-type-checkbox input[type="radio"],
    .form-type-checkboxes .form-type-checkbox input[type="radio"] + label,
    .form-type-radios .form-type-radio input[type="checkbox"],
    .form-type-radios .form-type-radio input[type="checkbox"] + label,
    .form-type-radios .form-type-radio input[type="radio"],
    .form-type-radios .form-type-radio input[type="radio"] + label,
    .form-type-checkbox input[type="checkbox"],
    .form-type-checkbox input[type="checkbox"] + label,
    .form-type-checkbox input[type="radio"],
    .form-type-checkbox input[type="radio"] + label {
      cursor: pointer; }
    /* line 170, sass/base/_forms.scss */
    .form-type-checkboxes .form-type-checkbox input[type="checkbox"] + label:before,
    .form-type-checkboxes .form-type-checkbox input[type="checkbox"]:checked + label:before,
    .form-type-checkboxes .form-type-checkbox input[type="radio"] + label:before,
    .form-type-checkboxes .form-type-checkbox input[type="radio"]:checked + label:before,
    .form-type-radios .form-type-radio input[type="checkbox"] + label:before,
    .form-type-radios .form-type-radio input[type="checkbox"]:checked + label:before,
    .form-type-radios .form-type-radio input[type="radio"] + label:before,
    .form-type-radios .form-type-radio input[type="radio"]:checked + label:before,
    .form-type-checkbox input[type="checkbox"] + label:before,
    .form-type-checkbox input[type="checkbox"]:checked + label:before,
    .form-type-checkbox input[type="radio"] + label:before,
    .form-type-checkbox input[type="radio"]:checked + label:before {
      border: 1px solid #75787B; }
    /* line 174, sass/base/_forms.scss */
    .form-type-checkboxes .form-type-checkbox input[type="checkbox"]:checked + label:before,
    .form-type-checkboxes .form-type-checkbox input[type="radio"]:checked + label:before,
    .form-type-radios .form-type-radio input[type="checkbox"]:checked + label:before,
    .form-type-radios .form-type-radio input[type="radio"]:checked + label:before,
    .form-type-checkbox input[type="checkbox"]:checked + label:before,
    .form-type-checkbox input[type="radio"]:checked + label:before {
      background-color: #165C7D; }
    /* line 177, sass/base/_forms.scss */
    .form-type-checkboxes .form-type-checkbox input[type="checkbox"] + label:before,
    .form-type-checkboxes .form-type-checkbox input[type="checkbox"]:checked + label:before,
    .form-type-checkboxes .form-type-checkbox input[type="radio"] + label:before,
    .form-type-checkboxes .form-type-checkbox input[type="radio"]:checked + label:before,
    .form-type-radios .form-type-radio input[type="checkbox"] + label:before,
    .form-type-radios .form-type-radio input[type="checkbox"]:checked + label:before,
    .form-type-radios .form-type-radio input[type="radio"] + label:before,
    .form-type-radios .form-type-radio input[type="radio"]:checked + label:before,
    .form-type-checkbox input[type="checkbox"] + label:before,
    .form-type-checkbox input[type="checkbox"]:checked + label:before,
    .form-type-checkbox input[type="radio"] + label:before,
    .form-type-checkbox input[type="radio"]:checked + label:before {
      content: " ";
      position: absolute;
      left: 0;
      top: 18px;
      top: 1.8rem;
      display: inline-block; }
    /* line 185, sass/base/_forms.scss */
    .form-type-checkboxes .form-type-checkbox input[type="checkbox"]:focus,
    .form-type-checkboxes .form-type-checkbox input[type="radio"]:focus,
    .form-type-radios .form-type-radio input[type="checkbox"]:focus,
    .form-type-radios .form-type-radio input[type="radio"]:focus,
    .form-type-checkbox input[type="checkbox"]:focus,
    .form-type-checkbox input[type="radio"]:focus {
      border-color: #165C7D;
      outline: 2px solid #165C7D; }
  /* line 191, sass/base/_forms.scss */
  .form-type-checkboxes .form-type-checkbox input[type="radio"] + label:before,
  .form-type-checkboxes .form-type-checkbox input[type="radio"]:checked + label:before,
  .form-type-radios .form-type-radio input[type="radio"] + label:before,
  .form-type-radios .form-type-radio input[type="radio"]:checked + label:before,
  .form-type-checkbox input[type="radio"] + label:before,
  .form-type-checkbox input[type="radio"]:checked + label:before {
    border-radius: 100%; }

/* line 199, sass/base/_forms.scss */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/* line 205, sass/base/_forms.scss */
.field-container {
  margin-bottom: 24px;
  margin-bottom: 2.4rem; }

/* line 210, sass/base/_forms.scss */
.inline-form fieldset, .inline-form .inline-container {
  position: relative; }

/* line 214, sass/base/_forms.scss */
.l-main .inline-form input[type=submit], .l-main .inline-form button, .l-main .inline-form .btn {
  font-size: 17px;
  font-size: 1.7rem;
  padding: 6px 10px;
  padding: 0.6rem 1rem;
  background: #41505c;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: auto; }
  /* line 224, sass/base/_forms.scss */
  .l-main .inline-form input[type=submit]:hover, .l-main .inline-form input[type=submit]:focus, .l-main .inline-form button:hover, .l-main .inline-form button:focus, .l-main .inline-form .btn:hover, .l-main .inline-form .btn:focus {
    background: #6c7a89;
    color: white; }

/* Validation */
/* line 234, sass/base/_forms.scss */
.has-error {
  border-color: #BE1512; }

/* line 237, sass/base/_forms.scss */
.is-valid {
  border-color: #298732; }

/* line 242, sass/base/_forms.scss */
select {
  display: block;
  font-size: 14px;
  font-size: 1.4rem;
  font-family: sans-serif;
  color: #333F48;
  line-height: 1.3;
  padding: 4px 24px 3px 10px;
  padding: 0.4rem 2.4rem 0.3rem 1rem;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"), linear-gradient(to bottom, #ffffff 0%, #eef0f3 100%);
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, 0 0;
  background-size: .65em auto, 100%; }

/* line 263, sass/base/_forms.scss */
select::-ms-expand {
  display: none; }

/* line 266, sass/base/_forms.scss */
select:hover {
  border-color: #888; }

/* line 269, sass/base/_forms.scss */
select:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none; }

/*------------------------------------*\
    $SPECIFIC FORMS
\*------------------------------------*/
/* Search Form */
/* line 283, sass/base/_forms.scss */
.search-field {
  padding-right: 3em;
  padding-right: 48px;
  padding-right: 4.8rem; }

/* line 287, sass/base/_forms.scss */
.inline-form .search-submit {
  background: none;
  padding: 8px 15px;
  padding: 0.8rem 1.5rem;
  border: 0;
  border-left: 1px solid #6c7a89;
  color: #6c7a89; }

/*------------------------------------*\
    $GLOBAL CLASSES
\*------------------------------------*/
/* Clearfix */
/* Completely remove from the flow and screen readers. */
/* line 11, sass/base/_global-classes.scss */
.is-hidden {
  display: none !important;
  visibility: hidden !important; }

/* Completely remove from the flow but leave available to screen readers. */
/* Text-Align */
/* line 23, sass/base/_global-classes.scss */
.align-right {
  text-align: right; }

/* line 27, sass/base/_global-classes.scss */
.align-center, .center {
  text-align: center; }

/* line 31, sass/base/_global-classes.scss */
.align-left {
  text-align: left; }

/* Display Classes */
/* line 36, sass/base/_global-classes.scss */
.b-error,
.hide {
  display: none !important; }

@media all and (max-width: 768px) {
  /* line 40, sass/base/_global-classes.scss */
  .hide-small {
    display: none; } }

@media (min-width: 768px) and (max-width: 980px) {
  /* line 46, sass/base/_global-classes.scss */
  .hide-med {
    display: none; } }

@media all and (min-width: 980px) {
  /* line 52, sass/base/_global-classes.scss */
  .hide-large {
    display: none; } }

/* line 58, sass/base/_global-classes.scss */
.preface, .small {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.71429; }

/* line 62, sass/base/_global-classes.scss */
.preface {
  font-family: "MinionPro", Georgia, Times, "Times New Roman", serif; }

/* line 66, sass/base/_global-classes.scss */
.semi-bold {
  font-weight: 600; }

/* line 70, sass/base/_global-classes.scss */
.bold {
  font-weight: bold; }

/* line 74, sass/base/_global-classes.scss */
.valid {
  color: #298732; }

/* line 78, sass/base/_global-classes.scss */
.error {
  color: #BE1512; }

/* line 82, sass/base/_global-classes.scss */
.italic {
  font-style: italic; }

/* line 86, sass/base/_global-classes.scss */
.warning {
  color: #78070e; }

/* line 90, sass/base/_global-classes.scss */
.information {
  color: #0047A0; }

/* line 94, sass/base/_global-classes.scss */
.font-secondary {
  font-family: "MinionPro", Georgia, Times, "Times New Roman", serif; }

/* line 97, sass/base/_global-classes.scss */
.font-primary {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif; }

/* line 101, sass/base/_global-classes.scss */
.clear-l {
  clear: left; }

/* line 105, sass/base/_global-classes.scss */
.clear-r {
  clear: right; }

/* line 109, sass/base/_global-classes.scss */
.clear-all {
  clear: both; }

/* line 112, sass/base/_global-classes.scss */
.border {
  border: 1px solid #D0D7DE; }
  /* line 114, sass/base/_global-classes.scss */
  .border .content {
    padding: 12px 10px;
    padding: 1.2rem 1rem; }

/* line 118, sass/base/_global-classes.scss */
.bg-blue {
  background: #165C7D; }

/* line 122, sass/base/_global-classes.scss */
.bg-gray-dark {
  background: #41505c; }

/* line 125, sass/base/_global-classes.scss */
.bg-gray-light {
  background: #D0D7DE; }

/* line 131, sass/base/_global-classes.scss */
.bg-gray-lightest {
  background: #eef0f3; }
  /* line 133, sass/base/_global-classes.scss */
  .bg-gray-lightest.border {
    border: 1px solid #e8ebef; }

/* line 141, sass/base/_global-classes.scss */
.border-bottom {
  border-bottom: 1px solid #D0D7DE;
  padding-bottom: 24px;
  padding-bottom: 2.4rem; }
  /* line 144, sass/base/_global-classes.scss */
  .border-bottom:last-child {
    border-bottom: 0;
    padding-bottom: 0; }

/* line 146, sass/base/_global-classes.scss */
.border-top {
  border-top: 1px solid #D0D7DE;
  padding-top: 24px;
  padding-top: 2.4rem; }

/* line 151, sass/base/_global-classes.scss */
.bg-green {
  background: #298732; }

/* line 154, sass/base/_global-classes.scss */
.bg-orange {
  background: #E87722; }

/* line 158, sass/base/_global-classes.scss */
.border-top-yellow {
  border-top: 3px solid #F0B323;
  padding-top: 24px;
  padding-top: 2.4rem; }

/* line 162, sass/base/_global-classes.scss */
.bg-purple {
  background: #394299; }

/* line 165, sass/base/_global-classes.scss */
.bg-burgundy {
  background: #9f3050; }

/* line 174, sass/base/_global-classes.scss */
.bg-burgundy *,
.bg-green *,
.bg-gray-dark *,
.bg-blue *,
.bg-orange *,
.bg-purple * {
  color: white; }

/* line 177, sass/base/_global-classes.scss */
.bg-burgundy a,
.bg-green a,
.bg-gray-dark a,
.bg-blue a,
.bg-orange a,
.bg-purple a {
  color: white; }
  /* line 147, sass/helpers/_text.scss */
  .bg-burgundy a:visited,
  .bg-green a:visited,
  .bg-gray-dark a:visited,
  .bg-blue a:visited,
  .bg-orange a:visited,
  .bg-purple a:visited {
    color: white; }
  /* line 152, sass/helpers/_text.scss */
  .bg-burgundy a:focus,
  .bg-green a:focus,
  .bg-gray-dark a:focus,
  .bg-blue a:focus,
  .bg-orange a:focus,
  .bg-purple a:focus {
    color: white; }
  /* line 157, sass/helpers/_text.scss */
  .bg-burgundy a:hover,
  .bg-green a:hover,
  .bg-gray-dark a:hover,
  .bg-blue a:hover,
  .bg-orange a:hover,
  .bg-purple a:hover {
    color: #ffd400; }
  /* line 162, sass/helpers/_text.scss */
  .bg-burgundy a:active,
  .bg-green a:active,
  .bg-gray-dark a:active,
  .bg-blue a:active,
  .bg-orange a:active,
  .bg-purple a:active {
    color: #ffd400; }

/* line 188, sass/base/_global-classes.scss */
.emergency-msg {
  background: #eef0f3;
  border: 2px solid #BE1512;
  padding: 12px 10px;
  padding: 1.2rem 1rem;
  margin: 24px auto;
  margin: 2.4rem auto; }
  /* line 193, sass/base/_global-classes.scss */
  .emergency-msg p:last-child {
    margin-bottom: 0; }

/* line 199, sass/base/_global-classes.scss */
.text-blue {
  color: #165C7D; }

/* line 203, sass/base/_global-classes.scss */
.text-green {
  color: #298732; }

/* line 207, sass/base/_global-classes.scss */
.alt-rows {
  margin: 24px 0;
  margin: 2.4rem 0; }
  /* line 209, sass/base/_global-classes.scss */
  .alt-rows .g-12-ns, .alt-rows .g-12 {
    grid-column-gap: 20px;
    grid-column-gap: 2rem;
    grid-row-gap: 0; }
  /* line 213, sass/base/_global-classes.scss */
  .alt-rows .label {
    font-weight: 600; }
  /* line 216, sass/base/_global-classes.scss */
  .alt-rows .row {
    border: 1px solid #dfe3e8;
    border-width: 1px 1px 0;
    padding: 12px 10px;
    padding: 1.2rem 1rem; }
    /* line 220, sass/base/_global-classes.scss */
    .alt-rows .row:nth-child(odd) {
      background: #eef0f3; }
    /* line 223, sass/base/_global-classes.scss */
    .alt-rows .row:last-child {
      border-bottom-width: 1px; }

/* line 232, sass/base/_global-classes.scss */
.constrain-text {
  max-width: 1000px; }

@media (min-width: 550px) {
  /* line 239, sass/base/_global-classes.scss */
  .alt-rows .label {
    text-align: right; } }

@media (min-width: 768px) {
  /* Floats */
  /* line 247, sass/base/_global-classes.scss */
  .left,
  .right {
    display: block;
    width: 30%; }
    /* line 253, sass/base/_global-classes.scss */
    .left.width-40,
    .right.width-40 {
      width: 40%; }
    /* line 256, sass/base/_global-classes.scss */
    .left.width-50,
    .right.width-50 {
      width: 50%; }
    /* line 259, sass/base/_global-classes.scss */
    .left.width-60,
    .right.width-60 {
      width: 60%; }
    /* line 263, sass/base/_global-classes.scss */
    .left img,
    .right img {
      display: block;
      width: 100% !important;
      height: auto !important; }
  /* line 271, sass/base/_global-classes.scss */
  [style*="float: left"] [style*="float:left"],
  figure[style*="float:left"],
  [style*="margin-right:15px; float:left"],
  .left {
    float: left;
    margin-right: 20px;
    margin-right: 2rem;
    margin-bottom: 24px;
    margin-bottom: 2.4rem; }
  /* line 280, sass/base/_global-classes.scss */
  [style*="float: right"],
  [style*="float:right"],
  figure[style*="float:right"],
  [style*="margin-right:15px; float:right"],
  .right {
    float: right;
    margin-left: 24px;
    margin-left: 2.4rem;
    margin-bottom: 24px;
    margin-bottom: 2.4rem; }
  /* line 289, sass/base/_global-classes.scss */
  [style*="float: left"] [style*="float:left"],
  figure[style*="float:left"],
  [style*="margin-right:15px; float:left"],
  .left,
  [style*="float: right"],
  [style*="float:right"],
  figure[style*="float:right"],
  [style*="margin-right:15px; float:right"],
  .right {
    max-width: 40% !important; } }

@media screen and (max-width: 979px) {
  /* line 304, sass/base/_global-classes.scss */
  .bp-desk {
    display: none; } }

@media (min-width: 980px) {
  /* line 310, sass/base/_global-classes.scss */
  .bp-desk {
    display: block; }
  /* line 313, sass/base/_global-classes.scss */
  .bp-mobile {
    display: none; } }

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    -moz-transform: translate3d(0, 100%, 0);
    -ms-transform: translate3d(0, 100%, 0);
    -o-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; } }

@-ms-keyframes fadeInUp {
  /* line 2, sass/base/_keyframes.scss */
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    -moz-transform: translate3d(0, 100%, 0);
    -ms-transform: translate3d(0, 100%, 0);
    -o-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  /* line 7, sass/base/_keyframes.scss */
  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; } }

@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    -moz-transform: translate3d(0, 100%, 0);
    -ms-transform: translate3d(0, 100%, 0);
    -o-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; } }

@-o-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    -moz-transform: translate3d(0, 100%, 0);
    -ms-transform: translate3d(0, 100%, 0);
    -o-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; } }

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    -moz-transform: translate3d(0, 100%, 0);
    -ms-transform: translate3d(0, 100%, 0);
    -o-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; } }

@-webkit-keyframes fadeInUpHalf {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100px, 0);
    -moz-transform: translate3d(0, 100px, 0);
    -ms-transform: translate3d(0, 100px, 0);
    -o-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; } }

@-ms-keyframes fadeInUpHalf {
  /* line 14, sass/base/_keyframes.scss */
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100px, 0);
    -moz-transform: translate3d(0, 100px, 0);
    -ms-transform: translate3d(0, 100px, 0);
    -o-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0); }
  /* line 19, sass/base/_keyframes.scss */
  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; } }

@-moz-keyframes fadeInUpHalf {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100px, 0);
    -moz-transform: translate3d(0, 100px, 0);
    -ms-transform: translate3d(0, 100px, 0);
    -o-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; } }

@-o-keyframes fadeInUpHalf {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100px, 0);
    -moz-transform: translate3d(0, 100px, 0);
    -ms-transform: translate3d(0, 100px, 0);
    -o-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; } }

@keyframes fadeInUpHalf {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100px, 0);
    -moz-transform: translate3d(0, 100px, 0);
    -ms-transform: translate3d(0, 100px, 0);
    -o-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; } }

@-webkit-keyframes fadeinFromSide {
  0% {
    opacity: 0;
    -webkit-transform: translate(100%, -50%);
    -moz-transform: translate(100%, -50%);
    -ms-transform: translate(100%, -50%);
    -o-transform: translate(100%, -50%);
    transform: translate(100%, -50%); }
  100% {
    opacity: 1;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%); } }

@-ms-keyframes fadeinFromSide {
  /* line 26, sass/base/_keyframes.scss */
  0% {
    opacity: 0;
    -webkit-transform: translate(100%, -50%);
    -moz-transform: translate(100%, -50%);
    -ms-transform: translate(100%, -50%);
    -o-transform: translate(100%, -50%);
    transform: translate(100%, -50%); }
  /* line 31, sass/base/_keyframes.scss */
  100% {
    opacity: 1;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%); } }

@-moz-keyframes fadeinFromSide {
  0% {
    opacity: 0;
    -webkit-transform: translate(100%, -50%);
    -moz-transform: translate(100%, -50%);
    -ms-transform: translate(100%, -50%);
    -o-transform: translate(100%, -50%);
    transform: translate(100%, -50%); }
  100% {
    opacity: 1;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%); } }

@-o-keyframes fadeinFromSide {
  0% {
    opacity: 0;
    -webkit-transform: translate(100%, -50%);
    -moz-transform: translate(100%, -50%);
    -ms-transform: translate(100%, -50%);
    -o-transform: translate(100%, -50%);
    transform: translate(100%, -50%); }
  100% {
    opacity: 1;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%); } }

@keyframes fadeinFromSide {
  0% {
    opacity: 0;
    -webkit-transform: translate(100%, -50%);
    -moz-transform: translate(100%, -50%);
    -ms-transform: translate(100%, -50%);
    -o-transform: translate(100%, -50%);
    transform: translate(100%, -50%); }
  100% {
    opacity: 1;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%); } }

@-webkit-keyframes fadeOutToSide {
  0% {
    opacity: 1;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%); }
  100% {
    opacity: 0;
    -webkit-transform: translate(100%, -50%);
    -moz-transform: translate(100%, -50%);
    -ms-transform: translate(100%, -50%);
    -o-transform: translate(100%, -50%);
    transform: translate(100%, -50%); } }

@-ms-keyframes fadeOutToSide {
  /* line 38, sass/base/_keyframes.scss */
  0% {
    opacity: 1;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%); }
  /* line 43, sass/base/_keyframes.scss */
  100% {
    opacity: 0;
    -webkit-transform: translate(100%, -50%);
    -moz-transform: translate(100%, -50%);
    -ms-transform: translate(100%, -50%);
    -o-transform: translate(100%, -50%);
    transform: translate(100%, -50%); } }

@-moz-keyframes fadeOutToSide {
  0% {
    opacity: 1;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%); }
  100% {
    opacity: 0;
    -webkit-transform: translate(100%, -50%);
    -moz-transform: translate(100%, -50%);
    -ms-transform: translate(100%, -50%);
    -o-transform: translate(100%, -50%);
    transform: translate(100%, -50%); } }

@-o-keyframes fadeOutToSide {
  0% {
    opacity: 1;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%); }
  100% {
    opacity: 0;
    -webkit-transform: translate(100%, -50%);
    -moz-transform: translate(100%, -50%);
    -ms-transform: translate(100%, -50%);
    -o-transform: translate(100%, -50%);
    transform: translate(100%, -50%); } }

@keyframes fadeOutToSide {
  0% {
    opacity: 1;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%); }
  100% {
    opacity: 0;
    -webkit-transform: translate(100%, -50%);
    -moz-transform: translate(100%, -50%);
    -ms-transform: translate(100%, -50%);
    -o-transform: translate(100%, -50%);
    transform: translate(100%, -50%); } }

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    -moz-transform: translate3d(0, -100%, 0);
    -ms-transform: translate3d(0, -100%, 0);
    -o-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; } }

@-ms-keyframes fadeInDown {
  /* line 50, sass/base/_keyframes.scss */
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    -moz-transform: translate3d(0, -100%, 0);
    -ms-transform: translate3d(0, -100%, 0);
    -o-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  /* line 55, sass/base/_keyframes.scss */
  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; } }

@-moz-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    -moz-transform: translate3d(0, -100%, 0);
    -ms-transform: translate3d(0, -100%, 0);
    -o-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; } }

@-o-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    -moz-transform: translate3d(0, -100%, 0);
    -ms-transform: translate3d(0, -100%, 0);
    -o-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; } }

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    -moz-transform: translate3d(0, -100%, 0);
    -ms-transform: translate3d(0, -100%, 0);
    -o-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; } }

@-webkit-keyframes fadeInDownHalf {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -50px, 0);
    -moz-transform: translate3d(0, -50px, 0);
    -ms-transform: translate3d(0, -50px, 0);
    -o-transform: translate3d(0, -50px, 0);
    transform: translate3d(0, -50px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; } }

@-ms-keyframes fadeInDownHalf {
  /* line 62, sass/base/_keyframes.scss */
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -50px, 0);
    -moz-transform: translate3d(0, -50px, 0);
    -ms-transform: translate3d(0, -50px, 0);
    -o-transform: translate3d(0, -50px, 0);
    transform: translate3d(0, -50px, 0); }
  /* line 67, sass/base/_keyframes.scss */
  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; } }

@-moz-keyframes fadeInDownHalf {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -50px, 0);
    -moz-transform: translate3d(0, -50px, 0);
    -ms-transform: translate3d(0, -50px, 0);
    -o-transform: translate3d(0, -50px, 0);
    transform: translate3d(0, -50px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; } }

@-o-keyframes fadeInDownHalf {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -50px, 0);
    -moz-transform: translate3d(0, -50px, 0);
    -ms-transform: translate3d(0, -50px, 0);
    -o-transform: translate3d(0, -50px, 0);
    transform: translate3d(0, -50px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; } }

@keyframes fadeInDownHalf {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -50px, 0);
    -moz-transform: translate3d(0, -50px, 0);
    -ms-transform: translate3d(0, -50px, 0);
    -o-transform: translate3d(0, -50px, 0);
    transform: translate3d(0, -50px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; } }

@-webkit-keyframes menuDropdown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -15px, 0);
    -moz-transform: translate3d(0, -15px, 0);
    -ms-transform: translate3d(0, -15px, 0);
    -o-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; } }

@-ms-keyframes menuDropdown {
  /* line 74, sass/base/_keyframes.scss */
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -15px, 0);
    -moz-transform: translate3d(0, -15px, 0);
    -ms-transform: translate3d(0, -15px, 0);
    -o-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0); }
  /* line 79, sass/base/_keyframes.scss */
  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; } }

@-moz-keyframes menuDropdown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -15px, 0);
    -moz-transform: translate3d(0, -15px, 0);
    -ms-transform: translate3d(0, -15px, 0);
    -o-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; } }

@-o-keyframes menuDropdown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -15px, 0);
    -moz-transform: translate3d(0, -15px, 0);
    -ms-transform: translate3d(0, -15px, 0);
    -o-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; } }

@keyframes menuDropdown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -15px, 0);
    -moz-transform: translate3d(0, -15px, 0);
    -ms-transform: translate3d(0, -15px, 0);
    -o-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; } }

@-webkit-keyframes menuArrow {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 15px, 0);
    -moz-transform: translate3d(0, 15px, 0);
    -ms-transform: translate3d(0, 15px, 0);
    -o-transform: translate3d(0, 15px, 0);
    transform: translate3d(0, 15px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; } }

@-ms-keyframes menuArrow {
  /* line 86, sass/base/_keyframes.scss */
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 15px, 0);
    -moz-transform: translate3d(0, 15px, 0);
    -ms-transform: translate3d(0, 15px, 0);
    -o-transform: translate3d(0, 15px, 0);
    transform: translate3d(0, 15px, 0); }
  /* line 91, sass/base/_keyframes.scss */
  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; } }

@-moz-keyframes menuArrow {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 15px, 0);
    -moz-transform: translate3d(0, 15px, 0);
    -ms-transform: translate3d(0, 15px, 0);
    -o-transform: translate3d(0, 15px, 0);
    transform: translate3d(0, 15px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; } }

@-o-keyframes menuArrow {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 15px, 0);
    -moz-transform: translate3d(0, 15px, 0);
    -ms-transform: translate3d(0, 15px, 0);
    -o-transform: translate3d(0, 15px, 0);
    transform: translate3d(0, 15px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; } }

@keyframes menuArrow {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 15px, 0);
    -moz-transform: translate3d(0, 15px, 0);
    -ms-transform: translate3d(0, 15px, 0);
    -o-transform: translate3d(0, 15px, 0);
    transform: translate3d(0, 15px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; } }

@-webkit-keyframes fadeInDownCenter {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-50%, -100%, 0);
    -moz-transform: translate3d(-50%, -100%, 0);
    -ms-transform: translate3d(-50%, -100%, 0);
    -o-transform: translate3d(-50%, -100%, 0);
    transform: translate3d(-50%, -100%, 0); }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(-50%, 0, 0);
    -moz-transform: translate3d(-50%, 0, 0);
    -ms-transform: translate3d(-50%, 0, 0);
    -o-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0); } }

@-ms-keyframes fadeInDownCenter {
  /* line 98, sass/base/_keyframes.scss */
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-50%, -100%, 0);
    -moz-transform: translate3d(-50%, -100%, 0);
    -ms-transform: translate3d(-50%, -100%, 0);
    -o-transform: translate3d(-50%, -100%, 0);
    transform: translate3d(-50%, -100%, 0); }
  /* line 103, sass/base/_keyframes.scss */
  100% {
    opacity: 1;
    -webkit-transform: translate3d(-50%, 0, 0);
    -moz-transform: translate3d(-50%, 0, 0);
    -ms-transform: translate3d(-50%, 0, 0);
    -o-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0); } }

@-moz-keyframes fadeInDownCenter {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-50%, -100%, 0);
    -moz-transform: translate3d(-50%, -100%, 0);
    -ms-transform: translate3d(-50%, -100%, 0);
    -o-transform: translate3d(-50%, -100%, 0);
    transform: translate3d(-50%, -100%, 0); }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(-50%, 0, 0);
    -moz-transform: translate3d(-50%, 0, 0);
    -ms-transform: translate3d(-50%, 0, 0);
    -o-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0); } }

@-o-keyframes fadeInDownCenter {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-50%, -100%, 0);
    -moz-transform: translate3d(-50%, -100%, 0);
    -ms-transform: translate3d(-50%, -100%, 0);
    -o-transform: translate3d(-50%, -100%, 0);
    transform: translate3d(-50%, -100%, 0); }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(-50%, 0, 0);
    -moz-transform: translate3d(-50%, 0, 0);
    -ms-transform: translate3d(-50%, 0, 0);
    -o-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0); } }

@keyframes fadeInDownCenter {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-50%, -100%, 0);
    -moz-transform: translate3d(-50%, -100%, 0);
    -ms-transform: translate3d(-50%, -100%, 0);
    -o-transform: translate3d(-50%, -100%, 0);
    transform: translate3d(-50%, -100%, 0); }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(-50%, 0, 0);
    -moz-transform: translate3d(-50%, 0, 0);
    -ms-transform: translate3d(-50%, 0, 0);
    -o-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0); } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-ms-keyframes fadeIn {
  /* line 110, sass/base/_keyframes.scss */
  0% {
    opacity: 0; }
  /* line 113, sass/base/_keyframes.scss */
  100% {
    opacity: 1; } }

@-moz-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-o-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-ms-keyframes fadeOut {
  /* line 119, sass/base/_keyframes.scss */
  0% {
    opacity: 1; }
  /* line 123, sass/base/_keyframes.scss */
  100% {
    opacity: 0; } }

@-moz-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-o-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes scaleAndFadeIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@-ms-keyframes scaleAndFadeIn {
  /* line 129, sass/base/_keyframes.scss */
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0); }
  /* line 133, sass/base/_keyframes.scss */
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@-moz-keyframes scaleAndFadeIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@-o-keyframes scaleAndFadeIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@keyframes scaleAndFadeIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes fadeInLine {
  0% {
    opacity: 0;
    height: 0;
    top: 50%; }
  100% {
    opacity: 1;
    height: 100%;
    top: 0; } }

@-ms-keyframes fadeInLine {
  /* line 140, sass/base/_keyframes.scss */
  0% {
    opacity: 0;
    height: 0;
    top: 50%; }
  /* line 145, sass/base/_keyframes.scss */
  100% {
    opacity: 1;
    height: 100%;
    top: 0; } }

@-moz-keyframes fadeInLine {
  0% {
    opacity: 0;
    height: 0;
    top: 50%; }
  100% {
    opacity: 1;
    height: 100%;
    top: 0; } }

@-o-keyframes fadeInLine {
  0% {
    opacity: 0;
    height: 0;
    top: 50%; }
  100% {
    opacity: 1;
    height: 100%;
    top: 0; } }

@keyframes fadeInLine {
  0% {
    opacity: 0;
    height: 0;
    top: 50%; }
  100% {
    opacity: 1;
    height: 100%;
    top: 0; } }

@-webkit-keyframes view-more-pulse {
  0% {
    background-color: transparent; }
  50% {
    background-color: rgba(231, 231, 231, 0.5); }
  100% {
    background-color: transparent; } }

@-ms-keyframes view-more-pulse {
  /* line 153, sass/base/_keyframes.scss */
  0% {
    background-color: transparent; }
  /* line 156, sass/base/_keyframes.scss */
  50% {
    background-color: rgba(231, 231, 231, 0.5); }
  /* line 159, sass/base/_keyframes.scss */
  100% {
    background-color: transparent; } }

@-moz-keyframes view-more-pulse {
  0% {
    background-color: transparent; }
  50% {
    background-color: rgba(231, 231, 231, 0.5); }
  100% {
    background-color: transparent; } }

@-o-keyframes view-more-pulse {
  0% {
    background-color: transparent; }
  50% {
    background-color: rgba(231, 231, 231, 0.5); }
  100% {
    background-color: transparent; } }

@keyframes view-more-pulse {
  0% {
    background-color: transparent; }
  50% {
    background-color: rgba(231, 231, 231, 0.5); }
  100% {
    background-color: transparent; } }

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  40% {
    -webkit-transform: translate3d(0, -10px, 0);
    -moz-transform: translate3d(0, -10px, 0);
    -ms-transform: translate3d(0, -10px, 0);
    -o-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  60% {
    -webkit-transform: translate3d(0, -5px, 0);
    -moz-transform: translate3d(0, -5px, 0);
    -ms-transform: translate3d(0, -5px, 0);
    -o-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0); } }

@-ms-keyframes bounce {
  /* line 165, sass/base/_keyframes.scss */
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  /* line 168, sass/base/_keyframes.scss */
  40% {
    -webkit-transform: translate3d(0, -10px, 0);
    -moz-transform: translate3d(0, -10px, 0);
    -ms-transform: translate3d(0, -10px, 0);
    -o-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  /* line 171, sass/base/_keyframes.scss */
  60% {
    -webkit-transform: translate3d(0, -5px, 0);
    -moz-transform: translate3d(0, -5px, 0);
    -ms-transform: translate3d(0, -5px, 0);
    -o-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0); } }

@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  40% {
    -webkit-transform: translate3d(0, -10px, 0);
    -moz-transform: translate3d(0, -10px, 0);
    -ms-transform: translate3d(0, -10px, 0);
    -o-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  60% {
    -webkit-transform: translate3d(0, -5px, 0);
    -moz-transform: translate3d(0, -5px, 0);
    -ms-transform: translate3d(0, -5px, 0);
    -o-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0); } }

@-o-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  40% {
    -webkit-transform: translate3d(0, -10px, 0);
    -moz-transform: translate3d(0, -10px, 0);
    -ms-transform: translate3d(0, -10px, 0);
    -o-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  60% {
    -webkit-transform: translate3d(0, -5px, 0);
    -moz-transform: translate3d(0, -5px, 0);
    -ms-transform: translate3d(0, -5px, 0);
    -o-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0); } }

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  40% {
    -webkit-transform: translate3d(0, -10px, 0);
    -moz-transform: translate3d(0, -10px, 0);
    -ms-transform: translate3d(0, -10px, 0);
    -o-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  60% {
    -webkit-transform: translate3d(0, -5px, 0);
    -moz-transform: translate3d(0, -5px, 0);
    -ms-transform: translate3d(0, -5px, 0);
    -o-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0); } }

@-webkit-keyframes outOneSide {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  50% {
    -webkit-transform: translate3d(66%, 0, 0);
    transform: translate3d(66%, 0, 0); }
  51% {
    -webkit-transform: translate3d(-173%, 0, 0);
    transform: translate3d(-173%, 0, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-ms-keyframes outOneSide {
  /* line 177, sass/base/_keyframes.scss */
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  /* line 181, sass/base/_keyframes.scss */
  50% {
    -webkit-transform: translate3d(66%, 0, 0);
    transform: translate3d(66%, 0, 0); }
  /* line 185, sass/base/_keyframes.scss */
  51% {
    -webkit-transform: translate3d(-173%, 0, 0);
    transform: translate3d(-173%, 0, 0); }
  /* line 189, sass/base/_keyframes.scss */
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-moz-keyframes outOneSide {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  50% {
    -webkit-transform: translate3d(66%, 0, 0);
    transform: translate3d(66%, 0, 0); }
  51% {
    -webkit-transform: translate3d(-173%, 0, 0);
    transform: translate3d(-173%, 0, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-o-keyframes outOneSide {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  50% {
    -webkit-transform: translate3d(66%, 0, 0);
    transform: translate3d(66%, 0, 0); }
  51% {
    -webkit-transform: translate3d(-173%, 0, 0);
    transform: translate3d(-173%, 0, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes outOneSide {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  50% {
    -webkit-transform: translate3d(66%, 0, 0);
    transform: translate3d(66%, 0, 0); }
  51% {
    -webkit-transform: translate3d(-173%, 0, 0);
    transform: translate3d(-173%, 0, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes bounceSide {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  40% {
    -webkit-transform: translateX(12px);
    -moz-transform: translateX(12px);
    -ms-transform: translateX(12px);
    -o-transform: translateX(12px);
    transform: translateX(12px); }
  60% {
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
    -ms-transform: translateX(5px);
    -o-transform: translateX(5px);
    transform: translateX(5px); } }

@-ms-keyframes bounceSide {
  /* line 196, sass/base/_keyframes.scss */
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  /* line 199, sass/base/_keyframes.scss */
  40% {
    -webkit-transform: translateX(12px);
    -moz-transform: translateX(12px);
    -ms-transform: translateX(12px);
    -o-transform: translateX(12px);
    transform: translateX(12px); }
  /* line 202, sass/base/_keyframes.scss */
  60% {
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
    -ms-transform: translateX(5px);
    -o-transform: translateX(5px);
    transform: translateX(5px); } }

@-moz-keyframes bounceSide {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  40% {
    -webkit-transform: translateX(12px);
    -moz-transform: translateX(12px);
    -ms-transform: translateX(12px);
    -o-transform: translateX(12px);
    transform: translateX(12px); }
  60% {
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
    -ms-transform: translateX(5px);
    -o-transform: translateX(5px);
    transform: translateX(5px); } }

@-o-keyframes bounceSide {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  40% {
    -webkit-transform: translateX(12px);
    -moz-transform: translateX(12px);
    -ms-transform: translateX(12px);
    -o-transform: translateX(12px);
    transform: translateX(12px); }
  60% {
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
    -ms-transform: translateX(5px);
    -o-transform: translateX(5px);
    transform: translateX(5px); } }

@keyframes bounceSide {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  40% {
    -webkit-transform: translateX(12px);
    -moz-transform: translateX(12px);
    -ms-transform: translateX(12px);
    -o-transform: translateX(12px);
    transform: translateX(12px); }
  60% {
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
    -ms-transform: translateX(5px);
    -o-transform: translateX(5px);
    transform: translateX(5px); } }

@-webkit-keyframes faveBounce {
  setStep(0),
  setStep(5) {
    transform: translate(-50%, -50%) scale(0); }
  setStep(12) {
    transform: translate(-50%, -50%) scale(1.1); }
  setStep(17) {
    transform: translate(-50%, -50%) scale(1); }
  setStep(22) {
    transform: translate(-50%, -50%) scale(1.05); }
  setStep(27) {
    transform: translate(-50%, -50%) scale(1); } }

@-ms-keyframes faveBounce {
  /* line 208, sass/base/_keyframes.scss */
  setStep(0),
  setStep(5) {
    transform: translate(-50%, -50%) scale(0); }
  /* line 212, sass/base/_keyframes.scss */
  setStep(12) {
    transform: translate(-50%, -50%) scale(1.1); }
  /* line 215, sass/base/_keyframes.scss */
  setStep(17) {
    transform: translate(-50%, -50%) scale(1); }
  /* line 218, sass/base/_keyframes.scss */
  setStep(22) {
    transform: translate(-50%, -50%) scale(1.05); }
  /* line 221, sass/base/_keyframes.scss */
  setStep(27) {
    transform: translate(-50%, -50%) scale(1); } }

@-moz-keyframes faveBounce {
  setStep(0),
  setStep(5) {
    transform: translate(-50%, -50%) scale(0); }
  setStep(12) {
    transform: translate(-50%, -50%) scale(1.1); }
  setStep(17) {
    transform: translate(-50%, -50%) scale(1); }
  setStep(22) {
    transform: translate(-50%, -50%) scale(1.05); }
  setStep(27) {
    transform: translate(-50%, -50%) scale(1); } }

@-o-keyframes faveBounce {
  setStep(0),
  setStep(5) {
    transform: translate(-50%, -50%) scale(0); }
  setStep(12) {
    transform: translate(-50%, -50%) scale(1.1); }
  setStep(17) {
    transform: translate(-50%, -50%) scale(1); }
  setStep(22) {
    transform: translate(-50%, -50%) scale(1.05); }
  setStep(27) {
    transform: translate(-50%, -50%) scale(1); } }

@keyframes faveBounce {
  setStep(0),
  setStep(5) {
    transform: translate(-50%, -50%) scale(0); }
  setStep(12) {
    transform: translate(-50%, -50%) scale(1.1); }
  setStep(17) {
    transform: translate(-50%, -50%) scale(1); }
  setStep(22) {
    transform: translate(-50%, -50%) scale(1.05); }
  setStep(27) {
    transform: translate(-50%, -50%) scale(1); } }

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@-moz-keyframes spinner {
  0% {
    -moz-transform: rotate(0deg); }
  100% {
    -moz-transform: rotate(360deg); } }

@-ms-keyframes spinner {
  /* line 233, sass/base/_keyframes.scss */
  0% {
    -ms-transform: rotate(0deg); }
  /* line 234, sass/base/_keyframes.scss */
  100% {
    -ms-transform: rotate(360deg); } }

@-o-keyframes spinner {
  0% {
    -o-transform: rotate(0deg); }
  100% {
    -o-transform: rotate(360deg); } }

@keyframes spinner {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/* Border Animation */
/* Links */
/* line 2, sass/base/_links.scss */
a {
  color: #0177A8;
  text-decoration: none;
  outline: 0;
  border-bottom: 1px solid transparent; }
  /* line 147, sass/helpers/_text.scss */
  a:visited {
    color: #0177A8; }
  /* line 152, sass/helpers/_text.scss */
  a:focus {
    color: #9f3050; }
  /* line 157, sass/helpers/_text.scss */
  a:hover {
    color: #9f3050; }
  /* line 162, sass/helpers/_text.scss */
  a:active {
    color: #9f3050; }
  /* line 8, sass/base/_links.scss */
  a.active {
    color: #9f3050; }
  /* line 11, sass/base/_links.scss */
  a:focus, a:hover, a:active {
    text-decoration: none;
    cursor: hand;
    cursor: pointer;
    border-bottom: 1px dotted; }
  /* line 19, sass/base/_links.scss */
  a.external-link {
    position: relative;
    display: inline-block;
    padding-right: 20px;
    padding-right: 2rem; }
    /* line 50, sass/helpers/_icon.scss */
    a.external-link:after {
      content: "";
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
      color: inherit;
      position: absolute;
      font-size: 14px;
      font-size: 1.4rem;
      top: 3px;
      top: 0.3rem;
      right: 0; }
    /* line 62, sass/helpers/_icon.scss */
    a.external-link:hover:after {
      color: inherit; }

/* line 1, sass/base/_lists.scss */
ol, ul {
  margin: 24px 0 24px 30px;
  margin: 2.4rem 0 2.4rem 3rem;
  padding: 0; }
  /* line 4, sass/base/_lists.scss */
  ol li, ul li {
    margin-bottom: 12px;
    margin-bottom: 1.2rem; }
    /* line 6, sass/base/_lists.scss */
    ol li:last-child, ul li:last-child {
      margin-bottom: 0; }

/* Definition Lists */
/* line 13, sass/base/_lists.scss */
dl {
  overflow: hidden;
  margin: 0 0 24px;
  margin: 0 0 2.4rem; }

/* line 18, sass/base/_lists.scss */
dt {
  font-weight: bold; }

/* line 22, sass/base/_lists.scss */
dd {
  margin-left: 0; }

/* line 26, sass/base/_lists.scss */
ul ul {
  list-style-type: circle; }

/* line 2, sass/base/_main.scss */
.wrapper--ckeditor {
  padding: 1em; }

/* line 5, sass/base/_main.scss */
.content-wrapper {
  background: #FFF;
  overflow-wrap: break-word;
  word-wrap: break-word;
  min-height: 300px;
  min-height: 55vh; }

/*------------------------------------*\
    $MEDIA ELEMENTS
\*------------------------------------*/
/* Flexible Media */
/* line 6, sass/base/_media.scss */
img, video, object, iframe {
  max-width: 100% !important;
  border: none; }

/* line 12, sass/base/_media.scss */
img {
  height: auto; }

/* line 23, sass/base/_media.scss */
figure {
  margin: 0 0 24px;
  margin: 0 0 2.4rem;
  display: flex !important;
  flex-direction: column;
  align-items: center; }
  /* line 25, sass/base/_media.scss */
  figure img {
    border: 1px solid #D0D7DE;
    padding: 3px;
    padding: 0.3rem; }
    /* line 28, sass/base/_media.scss */
    figure img .slick-slide {
      border-color: #444; }

/* line 39, sass/base/_media.scss */
figure[style="float:right"], figure[style="float:left"],
img[style="float:right"],
img[style="float:left"] {
  float: none !important; }

/* line 45, sass/base/_media.scss */
div[style="text-align:center"] {
  margin-right: auto;
  margin-left: auto; }

/* line 50, sass/base/_media.scss */
figcaption {
  font-style: italic;
  max-width: 90%;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  flex-flow: column;
  margin-top: 12px;
  margin-top: 1.2rem; }
  /* line 59, sass/base/_media.scss */
  figcaption p {
    margin-bottom: 12px;
    margin-bottom: 1.2rem; }
    /* line 61, sass/base/_media.scss */
    figcaption p:last-child {
      margin-bottom: 0; }

/* line 75, sass/base/_media.scss */
.wrapper--video, .mejs__video {
  padding-bottom: 56.25%;
  /* 16:9 */
  position: relative;
  height: 0; }

/* line 81, sass/base/_media.scss */
.mejs__video,
.mejs__video .mejs__overlay {
  width: 100% !important;
  height: 100% !important; }

/* line 87, sass/base/_media.scss */
.wrapper--video iframe,
.mejs__video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  margin: 0; }

/* line 97, sass/base/_media.scss */
.wrapper--image-text {
  margin-bottom: 24px;
  margin-bottom: 2.4rem; }
  /* line 99, sass/base/_media.scss */
  .wrapper--image-text .image img {
    border: 1px solid #D0D7DE; }

/* line 103, sass/base/_media.scss */
.media--loading {
  display: block; }

@media (min-width: 768px) {
  /* line 107, sass/base/_media.scss */
  img[style="float:right"],
  figure[style="float:right"] {
    float: right !important;
    width: 40% !important;
    margin: 0 0 12px 20px;
    margin: 0 0 1.2rem 2rem;
    display: block; }
  /* line 114, sass/base/_media.scss */
  img[style="float:left"],
  figure[style="float:left"] {
    float: left !important;
    width: 40% !important;
    margin: 0 20px 12px 0;
    margin: 0 2rem 1.2rem 0;
    display: block; }
  /* line 121, sass/base/_media.scss */
  .wrapper--image-text {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-content: stretch;
    grid-gap: 24px;
    grid-gap: 2.4rem; }
    /* line 127, sass/base/_media.scss */
    .wrapper--image-text .image.right, .wrapper--image-text .image.left {
      margin: 0;
      float: none;
      width: 100%;
      max-width: 100% !important; }
    /* line 134, sass/base/_media.scss */
    .wrapper--image-text .image.right {
      order: 2; }
    /* line 138, sass/base/_media.scss */
    .wrapper--image-text .text {
      grid-column-end: span 3; } }

/*------------------------------------*\
    $Table
\*------------------------------------*/
/* line 4, sass/base/_tables.scss */
table {
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #c1cad3;
  width: 100%; }

/* line 10, sass/base/_tables.scss */
th {
  text-align: left;
  border: 1px solid #c1cad3;
  border-width: 0 0 1px;
  padding: 8px 10px;
  padding: 0.8rem 1rem;
  background: #D0D7DE; }
  /* line 16, sass/base/_tables.scss */
  th a.active {
    color: #0047A0; }
  /* line 19, sass/base/_tables.scss */
  th.active {
    background: #165C7D; }
    /* line 21, sass/base/_tables.scss */
    th.active a.active {
      color: white; }

/* line 26, sass/base/_tables.scss */
td {
  border: none;
  padding: 8px 10px;
  padding: 0.8rem 1rem; }

/* line 31, sass/base/_tables.scss */
table tr.odd td.active,
table td.active {
  background: transparent; }

/* line 36, sass/base/_tables.scss */
tr.odd {
  background: white; }

/* line 40, sass/base/_tables.scss */
tr.even {
  background: #eef0f3; }

/* line 1, sass/layouts/_00-layout.scss */
#page-wrapper {
  position: relative; }

/* line 13, sass/layouts/_00-layout.scss */
.container-wide {
  width: 96%;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto; }

/* line 19, sass/layouts/_00-layout.scss */
.layout-default .container-wide,
.layout-default .layout-constrain > .content {
  width: 100%;
  max-width: 100%; }

/* line 27, sass/layouts/_00-layout.scss */
.layout-wide .section-title,
.layout-wide > .content,
.layout-wide > .paragraphs-inner {
  width: 96%;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto; }

/* line 33, sass/layouts/_00-layout.scss */
.padding,
.padding-top-bottom {
  padding-top: 24px !important;
  padding-top: 2.4rem !important;
  padding-bottom: 24px !important;
  padding-bottom: 2.4rem !important; }

/* line 39, sass/layouts/_00-layout.scss */
.padding-all {
  padding: 24px 20px;
  padding: 2.4rem 2rem; }

/* line 42, sass/layouts/_00-layout.scss */
.padding-all-half {
  padding: 12px 10px;
  padding: 1.2rem 1rem; }

/* line 45, sass/layouts/_00-layout.scss */
.padding-extra {
  padding-top: 24px !important;
  padding-top: 2.4rem !important;
  padding-bottom: 24px !important;
  padding-bottom: 2.4rem !important; }

/* line 49, sass/layouts/_00-layout.scss */
.padding-extra-top {
  padding-top: 24px !important;
  padding-top: 2.4rem !important; }

/* line 52, sass/layouts/_00-layout.scss */
.padding-extra-bottom {
  padding-bottom: 24px !important;
  padding-bottom: 2.4rem !important; }

/* line 55, sass/layouts/_00-layout.scss */
.padding-left {
  padding-left: 20px;
  padding-left: 2rem; }

/* line 58, sass/layouts/_00-layout.scss */
.padding-right {
  padding-right: 20px;
  padding-right: 2rem; }

/* line 61, sass/layouts/_00-layout.scss */
.spacing,
.spacing-extra {
  margin-top: 24px !important;
  margin-top: 2.4rem !important;
  margin-bottom: 24px !important;
  margin-bottom: 2.4rem !important; }

/* line 67, sass/layouts/_00-layout.scss */
.spacing-extra-top {
  margin-top: 24px !important;
  margin-top: 2.4rem !important; }

/* line 71, sass/layouts/_00-layout.scss */
.reset-list .spacing-extra-bottom, .spacing-extra-bottom {
  margin-bottom: 24px !important;
  margin-bottom: 2.4rem !important; }

/* line 75, sass/layouts/_00-layout.scss */
.spacing-half {
  margin-top: 12px !important;
  margin-top: 1.2rem !important;
  margin-bottom: 12px !important;
  margin-bottom: 1.2rem !important; }

/* line 80, sass/layouts/_00-layout.scss */
.spacing-half-top {
  margin-top: 12px !important;
  margin-top: 1.2rem !important; }

/* line 83, sass/layouts/_00-layout.scss */
.spacing-half-bottom {
  margin-bottom: 12px !important;
  margin-bottom: 1.2rem !important; }

/* line 87, sass/layouts/_00-layout.scss */
.spacing-top {
  margin-top: 24px !important;
  margin-top: 2.4rem !important; }

/* line 91, sass/layouts/_00-layout.scss */
.spacing-bottom {
  margin-bottom: 24px !important;
  margin-bottom: 2.4rem !important; }

/* line 95, sass/layouts/_00-layout.scss */
.no-spacing {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

/* line 100, sass/layouts/_00-layout.scss */
.no-padding {
  padding: 0 !important; }

/* line 107, sass/layouts/_00-layout.scss */
.ds-3col-equal > .group-left,
.ds-3col-equal > .group-middle,
.ds-3col-equal > .group-right,
.ds-2col-stacked-fluid > .group-left,
.ds-2col-stacked-fluid > .group-right {
  float: none;
  width: 100%; }

/* line 115, sass/layouts/_00-layout.scss */
.layout-default {
  position: relative; }
  /* line 117, sass/layouts/_00-layout.scss */
  .layout-default.has-side-nav {
    padding-top: 96px; }

@media (min-width: 768px) {
  /* line 122, sass/layouts/_00-layout.scss */
  .spacing-extra {
    margin-top: 48px !important;
    margin-top: 4.8rem !important;
    margin-bottom: 48px !important;
    margin-bottom: 4.8rem !important; }
  /* line 126, sass/layouts/_00-layout.scss */
  .spacing-extra-top {
    margin-top: 48px !important;
    margin-top: 4.8rem !important; }
  /* line 129, sass/layouts/_00-layout.scss */
  .spacing-extra-bottom {
    margin-bottom: 48px !important;
    margin-bottom: 4.8rem !important; }
  /* line 132, sass/layouts/_00-layout.scss */
  .padding-extra {
    padding-top: 48px !important;
    padding-top: 4.8rem !important;
    padding-bottom: 48px !important;
    padding-bottom: 4.8rem !important; }
  /* line 136, sass/layouts/_00-layout.scss */
  .padding-extra-top {
    padding-top: 48px !important;
    padding-top: 4.8rem !important; }
  /* line 139, sass/layouts/_00-layout.scss */
  .padding-extra-bottom {
    padding-bottom: 48px !important;
    padding-bottom: 4.8rem !important; }
  /* line 182, sass/layouts/_00-layout.scss */
  .paragraphs-inner .panel-region-right-aside {
    margin-top: 0; } }

@media (min-width: 980px) {
  /* line 188, sass/layouts/_00-layout.scss */
  .l-container-md {
    width: 96%;
    max-width: 1366px;
    margin-left: auto;
    margin-right: auto; }
  /* line 245, sass/layouts/_00-layout.scss */
  .layout-default.has-side-nav {
    padding-top: 0; } }

@media (min-width: 1664px) {
  /* line 265, sass/layouts/_00-layout.scss */
  .l-container-wide {
    max-width: 100%; } }

@media (min-width: 0) {
  /* line 254, sass/layouts/_01-flex.scss */
  .flex-xs {
    display: flex; }
  /* line 255, sass/layouts/_01-flex.scss */
  .flex-xs-row {
    flex-direction: row !important; }
  /* line 256, sass/layouts/_01-flex.scss */
  .flex-xs-column {
    flex-direction: column !important; }
  /* line 257, sass/layouts/_01-flex.scss */
  .flex-xs-row-reverse {
    flex-direction: row-reverse !important; }
  /* line 258, sass/layouts/_01-flex.scss */
  .flex-xs-column-reverse {
    flex-direction: column-reverse !important; }
  /* line 260, sass/layouts/_01-flex.scss */
  .flex-xs-wrap {
    flex-wrap: wrap !important; }
  /* line 261, sass/layouts/_01-flex.scss */
  .flex-xs-nowrap {
    flex-wrap: nowrap !important; }
  /* line 262, sass/layouts/_01-flex.scss */
  .flex-xs-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  /* line 263, sass/layouts/_01-flex.scss */
  .flex-xs-fill {
    flex: 1 1 auto !important; }
  /* line 264, sass/layouts/_01-flex.scss */
  .flex-xs-grow-0 {
    flex-grow: 0 !important; }
  /* line 265, sass/layouts/_01-flex.scss */
  .flex-xs-grow-1 {
    flex-grow: 1 !important; }
  /* line 266, sass/layouts/_01-flex.scss */
  .flex-xs-shrink-0 {
    flex-shrink: 0 !important; }
  /* line 267, sass/layouts/_01-flex.scss */
  .flex-xs-shrink-1 {
    flex-shrink: 1 !important; }
  /* line 269, sass/layouts/_01-flex.scss */
  .justify-content-xs-start {
    justify-content: flex-start !important; }
  /* line 270, sass/layouts/_01-flex.scss */
  .justify-content-xs-end {
    justify-content: flex-end !important; }
  /* line 271, sass/layouts/_01-flex.scss */
  .justify-content-xs-center {
    justify-content: center !important; }
  /* line 272, sass/layouts/_01-flex.scss */
  .justify-content-xs-between {
    justify-content: space-between !important; }
  /* line 273, sass/layouts/_01-flex.scss */
  .justify-content-xs-around {
    justify-content: space-around !important; }
  /* line 275, sass/layouts/_01-flex.scss */
  .align-items-xs-start {
    align-items: flex-start !important; }
  /* line 276, sass/layouts/_01-flex.scss */
  .align-items-xs-end {
    align-items: flex-end !important; }
  /* line 277, sass/layouts/_01-flex.scss */
  .align-items-xs-center {
    align-items: center !important; }
  /* line 278, sass/layouts/_01-flex.scss */
  .align-items-xs-baseline {
    align-items: baseline !important; }
  /* line 279, sass/layouts/_01-flex.scss */
  .align-items-xs-stretch {
    align-items: stretch !important; }
  /* line 281, sass/layouts/_01-flex.scss */
  .align-content-xs-start {
    align-content: flex-start !important; }
  /* line 282, sass/layouts/_01-flex.scss */
  .align-content-xs-end {
    align-content: flex-end !important; }
  /* line 283, sass/layouts/_01-flex.scss */
  .align-content-xs-center {
    align-content: center !important; }
  /* line 284, sass/layouts/_01-flex.scss */
  .align-content-xs-between {
    align-content: space-between !important; }
  /* line 285, sass/layouts/_01-flex.scss */
  .align-content-xs-around {
    align-content: space-around !important; }
  /* line 286, sass/layouts/_01-flex.scss */
  .align-content-xs-stretch {
    align-content: stretch !important; }
  /* line 288, sass/layouts/_01-flex.scss */
  .align-self-xs-auto {
    align-self: auto !important; }
  /* line 289, sass/layouts/_01-flex.scss */
  .align-self-xs-start {
    align-self: flex-start !important; }
  /* line 290, sass/layouts/_01-flex.scss */
  .align-self-xs-end {
    align-self: flex-end !important; }
  /* line 291, sass/layouts/_01-flex.scss */
  .align-self-xs-center {
    align-self: center !important; }
  /* line 292, sass/layouts/_01-flex.scss */
  .align-self-xs-baseline {
    align-self: baseline !important; }
  /* line 293, sass/layouts/_01-flex.scss */
  .align-self-xs-stretch {
    align-self: stretch !important; } }

@media (min-width: 550px) {
  /* line 254, sass/layouts/_01-flex.scss */
  .flex-sm {
    display: flex; }
  /* line 255, sass/layouts/_01-flex.scss */
  .flex-sm-row {
    flex-direction: row !important; }
  /* line 256, sass/layouts/_01-flex.scss */
  .flex-sm-column {
    flex-direction: column !important; }
  /* line 257, sass/layouts/_01-flex.scss */
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  /* line 258, sass/layouts/_01-flex.scss */
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  /* line 260, sass/layouts/_01-flex.scss */
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  /* line 261, sass/layouts/_01-flex.scss */
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  /* line 262, sass/layouts/_01-flex.scss */
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  /* line 263, sass/layouts/_01-flex.scss */
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  /* line 264, sass/layouts/_01-flex.scss */
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  /* line 265, sass/layouts/_01-flex.scss */
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  /* line 266, sass/layouts/_01-flex.scss */
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  /* line 267, sass/layouts/_01-flex.scss */
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  /* line 269, sass/layouts/_01-flex.scss */
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  /* line 270, sass/layouts/_01-flex.scss */
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  /* line 271, sass/layouts/_01-flex.scss */
  .justify-content-sm-center {
    justify-content: center !important; }
  /* line 272, sass/layouts/_01-flex.scss */
  .justify-content-sm-between {
    justify-content: space-between !important; }
  /* line 273, sass/layouts/_01-flex.scss */
  .justify-content-sm-around {
    justify-content: space-around !important; }
  /* line 275, sass/layouts/_01-flex.scss */
  .align-items-sm-start {
    align-items: flex-start !important; }
  /* line 276, sass/layouts/_01-flex.scss */
  .align-items-sm-end {
    align-items: flex-end !important; }
  /* line 277, sass/layouts/_01-flex.scss */
  .align-items-sm-center {
    align-items: center !important; }
  /* line 278, sass/layouts/_01-flex.scss */
  .align-items-sm-baseline {
    align-items: baseline !important; }
  /* line 279, sass/layouts/_01-flex.scss */
  .align-items-sm-stretch {
    align-items: stretch !important; }
  /* line 281, sass/layouts/_01-flex.scss */
  .align-content-sm-start {
    align-content: flex-start !important; }
  /* line 282, sass/layouts/_01-flex.scss */
  .align-content-sm-end {
    align-content: flex-end !important; }
  /* line 283, sass/layouts/_01-flex.scss */
  .align-content-sm-center {
    align-content: center !important; }
  /* line 284, sass/layouts/_01-flex.scss */
  .align-content-sm-between {
    align-content: space-between !important; }
  /* line 285, sass/layouts/_01-flex.scss */
  .align-content-sm-around {
    align-content: space-around !important; }
  /* line 286, sass/layouts/_01-flex.scss */
  .align-content-sm-stretch {
    align-content: stretch !important; }
  /* line 288, sass/layouts/_01-flex.scss */
  .align-self-sm-auto {
    align-self: auto !important; }
  /* line 289, sass/layouts/_01-flex.scss */
  .align-self-sm-start {
    align-self: flex-start !important; }
  /* line 290, sass/layouts/_01-flex.scss */
  .align-self-sm-end {
    align-self: flex-end !important; }
  /* line 291, sass/layouts/_01-flex.scss */
  .align-self-sm-center {
    align-self: center !important; }
  /* line 292, sass/layouts/_01-flex.scss */
  .align-self-sm-baseline {
    align-self: baseline !important; }
  /* line 293, sass/layouts/_01-flex.scss */
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  /* line 254, sass/layouts/_01-flex.scss */
  .flex-md {
    display: flex; }
  /* line 255, sass/layouts/_01-flex.scss */
  .flex-md-row {
    flex-direction: row !important; }
  /* line 256, sass/layouts/_01-flex.scss */
  .flex-md-column {
    flex-direction: column !important; }
  /* line 257, sass/layouts/_01-flex.scss */
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  /* line 258, sass/layouts/_01-flex.scss */
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  /* line 260, sass/layouts/_01-flex.scss */
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  /* line 261, sass/layouts/_01-flex.scss */
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  /* line 262, sass/layouts/_01-flex.scss */
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  /* line 263, sass/layouts/_01-flex.scss */
  .flex-md-fill {
    flex: 1 1 auto !important; }
  /* line 264, sass/layouts/_01-flex.scss */
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  /* line 265, sass/layouts/_01-flex.scss */
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  /* line 266, sass/layouts/_01-flex.scss */
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  /* line 267, sass/layouts/_01-flex.scss */
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  /* line 269, sass/layouts/_01-flex.scss */
  .justify-content-md-start {
    justify-content: flex-start !important; }
  /* line 270, sass/layouts/_01-flex.scss */
  .justify-content-md-end {
    justify-content: flex-end !important; }
  /* line 271, sass/layouts/_01-flex.scss */
  .justify-content-md-center {
    justify-content: center !important; }
  /* line 272, sass/layouts/_01-flex.scss */
  .justify-content-md-between {
    justify-content: space-between !important; }
  /* line 273, sass/layouts/_01-flex.scss */
  .justify-content-md-around {
    justify-content: space-around !important; }
  /* line 275, sass/layouts/_01-flex.scss */
  .align-items-md-start {
    align-items: flex-start !important; }
  /* line 276, sass/layouts/_01-flex.scss */
  .align-items-md-end {
    align-items: flex-end !important; }
  /* line 277, sass/layouts/_01-flex.scss */
  .align-items-md-center {
    align-items: center !important; }
  /* line 278, sass/layouts/_01-flex.scss */
  .align-items-md-baseline {
    align-items: baseline !important; }
  /* line 279, sass/layouts/_01-flex.scss */
  .align-items-md-stretch {
    align-items: stretch !important; }
  /* line 281, sass/layouts/_01-flex.scss */
  .align-content-md-start {
    align-content: flex-start !important; }
  /* line 282, sass/layouts/_01-flex.scss */
  .align-content-md-end {
    align-content: flex-end !important; }
  /* line 283, sass/layouts/_01-flex.scss */
  .align-content-md-center {
    align-content: center !important; }
  /* line 284, sass/layouts/_01-flex.scss */
  .align-content-md-between {
    align-content: space-between !important; }
  /* line 285, sass/layouts/_01-flex.scss */
  .align-content-md-around {
    align-content: space-around !important; }
  /* line 286, sass/layouts/_01-flex.scss */
  .align-content-md-stretch {
    align-content: stretch !important; }
  /* line 288, sass/layouts/_01-flex.scss */
  .align-self-md-auto {
    align-self: auto !important; }
  /* line 289, sass/layouts/_01-flex.scss */
  .align-self-md-start {
    align-self: flex-start !important; }
  /* line 290, sass/layouts/_01-flex.scss */
  .align-self-md-end {
    align-self: flex-end !important; }
  /* line 291, sass/layouts/_01-flex.scss */
  .align-self-md-center {
    align-self: center !important; }
  /* line 292, sass/layouts/_01-flex.scss */
  .align-self-md-baseline {
    align-self: baseline !important; }
  /* line 293, sass/layouts/_01-flex.scss */
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 980px) {
  /* line 254, sass/layouts/_01-flex.scss */
  .flex-lg {
    display: flex; }
  /* line 255, sass/layouts/_01-flex.scss */
  .flex-lg-row {
    flex-direction: row !important; }
  /* line 256, sass/layouts/_01-flex.scss */
  .flex-lg-column {
    flex-direction: column !important; }
  /* line 257, sass/layouts/_01-flex.scss */
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  /* line 258, sass/layouts/_01-flex.scss */
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  /* line 260, sass/layouts/_01-flex.scss */
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  /* line 261, sass/layouts/_01-flex.scss */
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  /* line 262, sass/layouts/_01-flex.scss */
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  /* line 263, sass/layouts/_01-flex.scss */
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  /* line 264, sass/layouts/_01-flex.scss */
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  /* line 265, sass/layouts/_01-flex.scss */
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  /* line 266, sass/layouts/_01-flex.scss */
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  /* line 267, sass/layouts/_01-flex.scss */
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  /* line 269, sass/layouts/_01-flex.scss */
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  /* line 270, sass/layouts/_01-flex.scss */
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  /* line 271, sass/layouts/_01-flex.scss */
  .justify-content-lg-center {
    justify-content: center !important; }
  /* line 272, sass/layouts/_01-flex.scss */
  .justify-content-lg-between {
    justify-content: space-between !important; }
  /* line 273, sass/layouts/_01-flex.scss */
  .justify-content-lg-around {
    justify-content: space-around !important; }
  /* line 275, sass/layouts/_01-flex.scss */
  .align-items-lg-start {
    align-items: flex-start !important; }
  /* line 276, sass/layouts/_01-flex.scss */
  .align-items-lg-end {
    align-items: flex-end !important; }
  /* line 277, sass/layouts/_01-flex.scss */
  .align-items-lg-center {
    align-items: center !important; }
  /* line 278, sass/layouts/_01-flex.scss */
  .align-items-lg-baseline {
    align-items: baseline !important; }
  /* line 279, sass/layouts/_01-flex.scss */
  .align-items-lg-stretch {
    align-items: stretch !important; }
  /* line 281, sass/layouts/_01-flex.scss */
  .align-content-lg-start {
    align-content: flex-start !important; }
  /* line 282, sass/layouts/_01-flex.scss */
  .align-content-lg-end {
    align-content: flex-end !important; }
  /* line 283, sass/layouts/_01-flex.scss */
  .align-content-lg-center {
    align-content: center !important; }
  /* line 284, sass/layouts/_01-flex.scss */
  .align-content-lg-between {
    align-content: space-between !important; }
  /* line 285, sass/layouts/_01-flex.scss */
  .align-content-lg-around {
    align-content: space-around !important; }
  /* line 286, sass/layouts/_01-flex.scss */
  .align-content-lg-stretch {
    align-content: stretch !important; }
  /* line 288, sass/layouts/_01-flex.scss */
  .align-self-lg-auto {
    align-self: auto !important; }
  /* line 289, sass/layouts/_01-flex.scss */
  .align-self-lg-start {
    align-self: flex-start !important; }
  /* line 290, sass/layouts/_01-flex.scss */
  .align-self-lg-end {
    align-self: flex-end !important; }
  /* line 291, sass/layouts/_01-flex.scss */
  .align-self-lg-center {
    align-self: center !important; }
  /* line 292, sass/layouts/_01-flex.scss */
  .align-self-lg-baseline {
    align-self: baseline !important; }
  /* line 293, sass/layouts/_01-flex.scss */
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1280px) {
  /* line 254, sass/layouts/_01-flex.scss */
  .flex-xl {
    display: flex; }
  /* line 255, sass/layouts/_01-flex.scss */
  .flex-xl-row {
    flex-direction: row !important; }
  /* line 256, sass/layouts/_01-flex.scss */
  .flex-xl-column {
    flex-direction: column !important; }
  /* line 257, sass/layouts/_01-flex.scss */
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  /* line 258, sass/layouts/_01-flex.scss */
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  /* line 260, sass/layouts/_01-flex.scss */
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  /* line 261, sass/layouts/_01-flex.scss */
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  /* line 262, sass/layouts/_01-flex.scss */
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  /* line 263, sass/layouts/_01-flex.scss */
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  /* line 264, sass/layouts/_01-flex.scss */
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  /* line 265, sass/layouts/_01-flex.scss */
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  /* line 266, sass/layouts/_01-flex.scss */
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  /* line 267, sass/layouts/_01-flex.scss */
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  /* line 269, sass/layouts/_01-flex.scss */
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  /* line 270, sass/layouts/_01-flex.scss */
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  /* line 271, sass/layouts/_01-flex.scss */
  .justify-content-xl-center {
    justify-content: center !important; }
  /* line 272, sass/layouts/_01-flex.scss */
  .justify-content-xl-between {
    justify-content: space-between !important; }
  /* line 273, sass/layouts/_01-flex.scss */
  .justify-content-xl-around {
    justify-content: space-around !important; }
  /* line 275, sass/layouts/_01-flex.scss */
  .align-items-xl-start {
    align-items: flex-start !important; }
  /* line 276, sass/layouts/_01-flex.scss */
  .align-items-xl-end {
    align-items: flex-end !important; }
  /* line 277, sass/layouts/_01-flex.scss */
  .align-items-xl-center {
    align-items: center !important; }
  /* line 278, sass/layouts/_01-flex.scss */
  .align-items-xl-baseline {
    align-items: baseline !important; }
  /* line 279, sass/layouts/_01-flex.scss */
  .align-items-xl-stretch {
    align-items: stretch !important; }
  /* line 281, sass/layouts/_01-flex.scss */
  .align-content-xl-start {
    align-content: flex-start !important; }
  /* line 282, sass/layouts/_01-flex.scss */
  .align-content-xl-end {
    align-content: flex-end !important; }
  /* line 283, sass/layouts/_01-flex.scss */
  .align-content-xl-center {
    align-content: center !important; }
  /* line 284, sass/layouts/_01-flex.scss */
  .align-content-xl-between {
    align-content: space-between !important; }
  /* line 285, sass/layouts/_01-flex.scss */
  .align-content-xl-around {
    align-content: space-around !important; }
  /* line 286, sass/layouts/_01-flex.scss */
  .align-content-xl-stretch {
    align-content: stretch !important; }
  /* line 288, sass/layouts/_01-flex.scss */
  .align-self-xl-auto {
    align-self: auto !important; }
  /* line 289, sass/layouts/_01-flex.scss */
  .align-self-xl-start {
    align-self: flex-start !important; }
  /* line 290, sass/layouts/_01-flex.scss */
  .align-self-xl-end {
    align-self: flex-end !important; }
  /* line 291, sass/layouts/_01-flex.scss */
  .align-self-xl-center {
    align-self: center !important; }
  /* line 292, sass/layouts/_01-flex.scss */
  .align-self-xl-baseline {
    align-self: baseline !important; }
  /* line 293, sass/layouts/_01-flex.scss */
  .align-self-xl-stretch {
    align-self: stretch !important; } }

/* line 1, sass/layouts/_atomic-grid.scss */
.g-12 {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-content: stretch; }

/* line 7, sass/layouts/_atomic-grid.scss */
.g-20 {
  display: grid;
  grid-template-columns: repeat(20, 1fr); }

/* line 12, sass/layouts/_atomic-grid.scss */
.g-24 {
  display: grid;
  grid-template-columns: repeat(24, 1fr); }

/* line 17, sass/layouts/_atomic-grid.scss */
.g-fit {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(275px, 1fr)); }

/* line 21, sass/layouts/_atomic-grid.scss */
.g-fit,
.g-12, .g-20, .g-24,
.g-12-ns, .g-ns-20, .g-ns-24,
.g-12-m, .g-20-m, .g-24-m,
.g-12-l, .g-20-l, .g-24-l {
  grid-gap: 40px;
  grid-gap: 4rem; }
  /* line 27, sass/layouts/_atomic-grid.scss */
  .view .g-fit, .view
  .g-12, .view .g-20, .view .g-24, .view
  .g-12-ns, .view .g-ns-20, .view .g-ns-24, .view
  .g-12-m, .view .g-20-m, .view .g-24-m, .view
  .g-12-l, .view .g-20-l, .view .g-24-l {
    grid-gap: 20px;
    grid-gap: 2rem; }
    /* line 29, sass/layouts/_atomic-grid.scss */
    .view .g-fit > div, .view .g-fit > li, .view
    .g-12 > div, .view
    .g-12 > li, .view .g-20 > div, .view .g-20 > li, .view .g-24 > div, .view .g-24 > li, .view
    .g-12-ns > div, .view
    .g-12-ns > li, .view .g-ns-20 > div, .view .g-ns-20 > li, .view .g-ns-24 > div, .view .g-ns-24 > li, .view
    .g-12-m > div, .view
    .g-12-m > li, .view .g-20-m > div, .view .g-20-m > li, .view .g-24-m > div, .view .g-24-m > li, .view
    .g-12-l > div, .view
    .g-12-l > li, .view .g-20-l > div, .view .g-20-l > li, .view .g-24-l > div, .view .g-24-l > li {
      margin-bottom: 24px;
      margin-bottom: 2.4rem; }
  /* line 33, sass/layouts/_atomic-grid.scss */
  .g-fit.no-gap,
  .no-gap.view .g-fit,
  .g-12.no-gap,
  .no-gap.view
  .g-12, .g-20.no-gap,
  .no-gap.view .g-20, .g-24.no-gap,
  .no-gap.view .g-24,
  .g-12-ns.no-gap,
  .no-gap.view
  .g-12-ns, .g-ns-20.no-gap,
  .no-gap.view .g-ns-20, .g-ns-24.no-gap,
  .no-gap.view .g-ns-24,
  .g-12-m.no-gap,
  .no-gap.view
  .g-12-m, .g-20-m.no-gap,
  .no-gap.view .g-20-m, .g-24-m.no-gap,
  .no-gap.view .g-24-m,
  .g-12-l.no-gap,
  .no-gap.view
  .g-12-l, .g-20-l.no-gap,
  .no-gap.view .g-20-l, .g-24-l.no-gap,
  .no-gap.view .g-24-l {
    grid-gap: 0; }
  /* line 40, sass/layouts/_atomic-grid.scss */
  .g-fit.no-margin,
  .g-12.no-margin, .g-20.no-margin, .g-24.no-margin,
  .g-12-ns.no-margin, .g-ns-20.no-margin, .g-ns-24.no-margin,
  .g-12-m.no-margin, .g-20-m.no-margin, .g-24-m.no-margin,
  .g-12-l.no-margin, .g-20-l.no-margin, .g-24-l.no-margin {
    grid-column-gap: 0; }
  /* line 43, sass/layouts/_atomic-grid.scss */
  .g-fit .order-1,
  .g-12 .order-1, .g-20 .order-1, .g-24 .order-1,
  .g-12-ns .order-1, .g-ns-20 .order-1, .g-ns-24 .order-1,
  .g-12-m .order-1, .g-20-m .order-1, .g-24-m .order-1,
  .g-12-l .order-1, .g-20-l .order-1, .g-24-l .order-1 {
    order: 1; }
  /* line 46, sass/layouts/_atomic-grid.scss */
  .g-fit .order-2,
  .g-12 .order-2, .g-20 .order-2, .g-24 .order-2,
  .g-12-ns .order-2, .g-ns-20 .order-2, .g-ns-24 .order-2,
  .g-12-m .order-2, .g-20-m .order-2, .g-24-m .order-2,
  .g-12-l .order-2, .g-20-l .order-2, .g-24-l .order-2 {
    order: 2; }
  /* line 49, sass/layouts/_atomic-grid.scss */
  .g-fit .order-3,
  .g-12 .order-3, .g-20 .order-3, .g-24 .order-3,
  .g-12-ns .order-3, .g-ns-20 .order-3, .g-ns-24 .order-3,
  .g-12-m .order-3, .g-20-m .order-3, .g-24-m .order-3,
  .g-12-l .order-3, .g-20-l .order-3, .g-24-l .order-3 {
    order: 3; }
  /* line 52, sass/layouts/_atomic-grid.scss */
  .g-fit .order-4,
  .g-12 .order-4, .g-20 .order-4, .g-24 .order-4,
  .g-12-ns .order-4, .g-ns-20 .order-4, .g-ns-24 .order-4,
  .g-12-m .order-4, .g-20-m .order-4, .g-24-m .order-4,
  .g-12-l .order-4, .g-20-l .order-4, .g-24-l .order-4 {
    order: 4; }
  /* line 55, sass/layouts/_atomic-grid.scss */
  .g-fit .order-5,
  .g-12 .order-5, .g-20 .order-5, .g-24 .order-5,
  .g-12-ns .order-5, .g-ns-20 .order-5, .g-ns-24 .order-5,
  .g-12-m .order-5, .g-20-m .order-5, .g-24-m .order-5,
  .g-12-l .order-5, .g-20-l .order-5, .g-24-l .order-5 {
    order: 5; }
  /* line 58, sass/layouts/_atomic-grid.scss */
  .g-fit .order-6,
  .g-12 .order-6, .g-20 .order-6, .g-24 .order-6,
  .g-12-ns .order-6, .g-ns-20 .order-6, .g-ns-24 .order-6,
  .g-12-m .order-6, .g-20-m .order-6, .g-24-m .order-6,
  .g-12-l .order-6, .g-20-l .order-6, .g-24-l .order-6 {
    order: 6; }
  /* line 61, sass/layouts/_atomic-grid.scss */
  .g-fit > h1, .g-fit > h2, .main .edan-search-form .form-type-textfield .g-fit > label, .g-fit > h3, .node-type-recipient .g-fit > .field-name-field-title-long,
  .node-type-staff .g-fit > .field-name-field-title-long, .front #paragraphs-item-5555 .group-right .g-fit > .section-title,
  .g-fit > div,
  .g-12 > h1,
  .g-12 > h2,
  .main .edan-search-form .form-type-textfield .g-12 > label,
  .g-12 > h3,
  .node-type-recipient .g-12 > .field-name-field-title-long,
  .node-type-staff .g-12 > .field-name-field-title-long,
  .front #paragraphs-item-5555 .group-right .g-12 > .section-title,
  .g-12 > div, .g-20 > h1, .g-20 > h2, .main .edan-search-form .form-type-textfield .g-20 > label, .g-20 > h3, .node-type-recipient .g-20 > .field-name-field-title-long,
  .node-type-staff .g-20 > .field-name-field-title-long, .front #paragraphs-item-5555 .group-right .g-20 > .section-title,
  .g-20 > div, .g-24 > h1, .g-24 > h2, .main .edan-search-form .form-type-textfield .g-24 > label, .g-24 > h3, .node-type-recipient .g-24 > .field-name-field-title-long,
  .node-type-staff .g-24 > .field-name-field-title-long, .front #paragraphs-item-5555 .group-right .g-24 > .section-title,
  .g-24 > div,
  .g-12-ns > h1,
  .g-12-ns > h2,
  .main .edan-search-form .form-type-textfield .g-12-ns > label,
  .g-12-ns > h3,
  .node-type-recipient .g-12-ns > .field-name-field-title-long,
  .node-type-staff .g-12-ns > .field-name-field-title-long,
  .front #paragraphs-item-5555 .group-right .g-12-ns > .section-title,
  .g-12-ns > div, .g-ns-20 > h1, .g-ns-20 > h2, .main .edan-search-form .form-type-textfield .g-ns-20 > label, .g-ns-20 > h3, .node-type-recipient .g-ns-20 > .field-name-field-title-long,
  .node-type-staff .g-ns-20 > .field-name-field-title-long, .front #paragraphs-item-5555 .group-right .g-ns-20 > .section-title,
  .g-ns-20 > div, .g-ns-24 > h1, .g-ns-24 > h2, .main .edan-search-form .form-type-textfield .g-ns-24 > label, .g-ns-24 > h3, .node-type-recipient .g-ns-24 > .field-name-field-title-long,
  .node-type-staff .g-ns-24 > .field-name-field-title-long, .front #paragraphs-item-5555 .group-right .g-ns-24 > .section-title,
  .g-ns-24 > div,
  .g-12-m > h1,
  .g-12-m > h2,
  .main .edan-search-form .form-type-textfield .g-12-m > label,
  .g-12-m > h3,
  .node-type-recipient .g-12-m > .field-name-field-title-long,
  .node-type-staff .g-12-m > .field-name-field-title-long,
  .front #paragraphs-item-5555 .group-right .g-12-m > .section-title,
  .g-12-m > div, .g-20-m > h1, .g-20-m > h2, .main .edan-search-form .form-type-textfield .g-20-m > label, .g-20-m > h3, .node-type-recipient .g-20-m > .field-name-field-title-long,
  .node-type-staff .g-20-m > .field-name-field-title-long, .front #paragraphs-item-5555 .group-right .g-20-m > .section-title,
  .g-20-m > div, .g-24-m > h1, .g-24-m > h2, .main .edan-search-form .form-type-textfield .g-24-m > label, .g-24-m > h3, .node-type-recipient .g-24-m > .field-name-field-title-long,
  .node-type-staff .g-24-m > .field-name-field-title-long, .front #paragraphs-item-5555 .group-right .g-24-m > .section-title,
  .g-24-m > div,
  .g-12-l > h1,
  .g-12-l > h2,
  .main .edan-search-form .form-type-textfield .g-12-l > label,
  .g-12-l > h3,
  .node-type-recipient .g-12-l > .field-name-field-title-long,
  .node-type-staff .g-12-l > .field-name-field-title-long,
  .front #paragraphs-item-5555 .group-right .g-12-l > .section-title,
  .g-12-l > div, .g-20-l > h1, .g-20-l > h2, .main .edan-search-form .form-type-textfield .g-20-l > label, .g-20-l > h3, .node-type-recipient .g-20-l > .field-name-field-title-long,
  .node-type-staff .g-20-l > .field-name-field-title-long, .front #paragraphs-item-5555 .group-right .g-20-l > .section-title,
  .g-20-l > div, .g-24-l > h1, .g-24-l > h2, .main .edan-search-form .form-type-textfield .g-24-l > label, .g-24-l > h3, .node-type-recipient .g-24-l > .field-name-field-title-long,
  .node-type-staff .g-24-l > .field-name-field-title-long, .front #paragraphs-item-5555 .group-right .g-24-l > .section-title,
  .g-24-l > div {
    max-width: 100%; }

/* line 67, sass/layouts/_atomic-grid.scss */
.gr {
  grid-column-end: auto; }

/* line 69, sass/layouts/_atomic-grid.scss */
.gs1 {
  grid-column-end: span 1; }

/* line 70, sass/layouts/_atomic-grid.scss */
.gs2 {
  grid-column-end: span 2; }

/* line 71, sass/layouts/_atomic-grid.scss */
.gs3 {
  grid-column-end: span 3; }

/* line 72, sass/layouts/_atomic-grid.scss */
.gs4 {
  grid-column-end: span 4; }

/* line 73, sass/layouts/_atomic-grid.scss */
.gs5 {
  grid-column-end: span 5; }

/* line 74, sass/layouts/_atomic-grid.scss */
.gs6 {
  grid-column-end: span 6; }

/* line 75, sass/layouts/_atomic-grid.scss */
.gs7 {
  grid-column-end: span 7; }

/* line 76, sass/layouts/_atomic-grid.scss */
.gs8 {
  grid-column-end: span 8; }

/* line 77, sass/layouts/_atomic-grid.scss */
.gs9 {
  grid-column-end: span 9; }

/* line 78, sass/layouts/_atomic-grid.scss */
.gs10 {
  grid-column-end: span 10; }

/* line 79, sass/layouts/_atomic-grid.scss */
.gs11 {
  grid-column-end: span 11; }

/* line 80, sass/layouts/_atomic-grid.scss */
.gs12 {
  grid-column-end: span 12; }

/* line 81, sass/layouts/_atomic-grid.scss */
.gs13 {
  grid-column-end: span 13; }

/* line 82, sass/layouts/_atomic-grid.scss */
.gs14 {
  grid-column-end: span 14; }

/* line 83, sass/layouts/_atomic-grid.scss */
.gs15 {
  grid-column-end: span 15; }

/* line 84, sass/layouts/_atomic-grid.scss */
.gs16 {
  grid-column-end: span 16; }

/* line 85, sass/layouts/_atomic-grid.scss */
.gs17 {
  grid-column-end: span 17; }

/* line 86, sass/layouts/_atomic-grid.scss */
.gs18 {
  grid-column-end: span 18; }

/* line 87, sass/layouts/_atomic-grid.scss */
.gs19 {
  grid-column-end: span 19; }

/* line 88, sass/layouts/_atomic-grid.scss */
.gs20 {
  grid-column-end: span 20; }

/* line 89, sass/layouts/_atomic-grid.scss */
.gs21 {
  grid-column-end: span 21; }

/* line 90, sass/layouts/_atomic-grid.scss */
.gs22 {
  grid-column-end: span 22; }

/* line 91, sass/layouts/_atomic-grid.scss */
.gs23 {
  grid-column-end: span 23; }

/* line 92, sass/layouts/_atomic-grid.scss */
.gs24 {
  grid-column-end: span 24; }

/* line 94, sass/layouts/_atomic-grid.scss */
.go1 {
  grid-column-start: 2; }

/* line 95, sass/layouts/_atomic-grid.scss */
.go2 {
  grid-column-start: 3; }

/* line 96, sass/layouts/_atomic-grid.scss */
.go3 {
  grid-column-start: 4; }

/* line 97, sass/layouts/_atomic-grid.scss */
.go4 {
  grid-column-start: 5; }

/* line 98, sass/layouts/_atomic-grid.scss */
.go5 {
  grid-column-start: 6; }

/* line 99, sass/layouts/_atomic-grid.scss */
.go6 {
  grid-column-start: 7; }

/* line 100, sass/layouts/_atomic-grid.scss */
.go7 {
  grid-column-start: 8; }

/* line 101, sass/layouts/_atomic-grid.scss */
.go8 {
  grid-column-start: 9; }

/* line 102, sass/layouts/_atomic-grid.scss */
.go9 {
  grid-column-start: 10; }

/* line 103, sass/layouts/_atomic-grid.scss */
.go10 {
  grid-column-start: 11; }

/* line 104, sass/layouts/_atomic-grid.scss */
.go11 {
  grid-column-start: 12; }

/* line 105, sass/layouts/_atomic-grid.scss */
.go12 {
  grid-column-start: 13; }

/* line 106, sass/layouts/_atomic-grid.scss */
.go13 {
  grid-column-start: 14; }

/* line 107, sass/layouts/_atomic-grid.scss */
.go14 {
  grid-column-start: 15; }

/* line 108, sass/layouts/_atomic-grid.scss */
.go15 {
  grid-column-start: 16; }

/* line 109, sass/layouts/_atomic-grid.scss */
.go16 {
  grid-column-start: 17; }

/* line 110, sass/layouts/_atomic-grid.scss */
.go17 {
  grid-column-start: 18; }

/* line 111, sass/layouts/_atomic-grid.scss */
.go18 {
  grid-column-start: 19; }

/* line 112, sass/layouts/_atomic-grid.scss */
.go19 {
  grid-column-start: 20; }

/* line 113, sass/layouts/_atomic-grid.scss */
.go20 {
  grid-column-start: 21; }

/* line 114, sass/layouts/_atomic-grid.scss */
.go21 {
  grid-column-start: 22; }

/* line 115, sass/layouts/_atomic-grid.scss */
.go22 {
  grid-column-start: 23; }

/* line 116, sass/layouts/_atomic-grid.scss */
.go23 {
  grid-column-start: 24; }

@media (min-width: 550px) {
  /* line 120, sass/layouts/_atomic-grid.scss */
  .g-12-ns {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    align-content: stretch; }
  /* line 125, sass/layouts/_atomic-grid.scss */
  .g-20-ns {
    display: grid;
    grid-template-columns: repeat(20, 1fr); }
  /* line 130, sass/layouts/_atomic-grid.scss */
  .g-24-ns {
    display: grid;
    grid-template-columns: repeat(24, 1fr); }
  /* line 140, sass/layouts/_atomic-grid.scss */
  .view .g-fit, .view .g-fit.reset-list, .view
  .g-12, .view
  .g-12.reset-list, .view .g-20, .view .g-20.reset-list, .view .g-24, .view .g-24.reset-list, .view
  .g-12-ns, .view
  .g-12-ns.reset-list, .view .g-ns-20, .view .g-ns-20.reset-list, .view .g-ns-24, .view .g-ns-24.reset-list, .view
  .g-12-m, .view
  .g-12-m.reset-list, .view .g-20-m, .view .g-20-m.reset-list, .view .g-24-m, .view .g-24-m.reset-list, .view
  .g-12-l, .view
  .g-12-l.reset-list, .view .g-20-l, .view .g-20-l.reset-list, .view .g-24-l, .view .g-24-l.reset-list {
    grid-gap: 24px;
    grid-gap: 2.4rem; }
  /* line 144, sass/layouts/_atomic-grid.scss */
  .view .g-fit > li,
  .view .g-fit > div, .view
  .g-12 > li,
  .view
  .g-12 > div, .view .g-20 > li,
  .view .g-20 > div, .view .g-24 > li,
  .view .g-24 > div, .view
  .g-12-ns > li,
  .view
  .g-12-ns > div, .view .g-ns-20 > li,
  .view .g-ns-20 > div, .view .g-ns-24 > li,
  .view .g-ns-24 > div, .view
  .g-12-m > li,
  .view
  .g-12-m > div, .view .g-20-m > li,
  .view .g-20-m > div, .view .g-24-m > li,
  .view .g-24-m > div, .view
  .g-12-l > li,
  .view
  .g-12-l > div, .view .g-20-l > li,
  .view .g-20-l > div, .view .g-24-l > li,
  .view .g-24-l > div {
    margin-bottom: 0;
    display: flex; }
  /* line 150, sass/layouts/_atomic-grid.scss */
  .g-fit.no-gap,
  .no-gap.view .g-fit,
  .g-12.no-gap,
  .no-gap.view
  .g-12, .g-20.no-gap,
  .no-gap.view .g-20, .g-24.no-gap,
  .no-gap.view .g-24,
  .g-12-ns.no-gap,
  .no-gap.view
  .g-12-ns, .g-ns-20.no-gap,
  .no-gap.view .g-ns-20, .g-ns-24.no-gap,
  .no-gap.view .g-ns-24,
  .g-12-m.no-gap,
  .no-gap.view
  .g-12-m, .g-20-m.no-gap,
  .no-gap.view .g-20-m, .g-24-m.no-gap,
  .no-gap.view .g-24-m,
  .g-12-l.no-gap,
  .no-gap.view
  .g-12-l, .g-20-l.no-gap,
  .no-gap.view .g-20-l, .g-24-l.no-gap,
  .no-gap.view .g-24-l {
    grid-gap: 0; }
  /* line 155, sass/layouts/_atomic-grid.scss */
  .gs1-ns {
    grid-column-end: span 1; }
  /* line 156, sass/layouts/_atomic-grid.scss */
  .gs2-ns {
    grid-column-end: span 2; }
  /* line 157, sass/layouts/_atomic-grid.scss */
  .gs3-ns {
    grid-column-end: span 3; }
  /* line 158, sass/layouts/_atomic-grid.scss */
  .gs4-ns {
    grid-column-end: span 4; }
  /* line 159, sass/layouts/_atomic-grid.scss */
  .gs5-ns {
    grid-column-end: span 5; }
  /* line 160, sass/layouts/_atomic-grid.scss */
  .gs6-ns {
    grid-column-end: span 6; }
  /* line 161, sass/layouts/_atomic-grid.scss */
  .gs7-ns {
    grid-column-end: span 7; }
  /* line 162, sass/layouts/_atomic-grid.scss */
  .gs8-ns {
    grid-column-end: span 8; }
  /* line 163, sass/layouts/_atomic-grid.scss */
  .gs9-ns {
    grid-column-end: span 9; }
  /* line 164, sass/layouts/_atomic-grid.scss */
  .gs10-ns {
    grid-column-end: span 10; }
  /* line 165, sass/layouts/_atomic-grid.scss */
  .gs11-ns {
    grid-column-end: span 11; }
  /* line 166, sass/layouts/_atomic-grid.scss */
  .gs12-ns {
    grid-column-end: span 12; }
  /* line 167, sass/layouts/_atomic-grid.scss */
  .gs13-ns {
    grid-column-end: span 13; }
  /* line 168, sass/layouts/_atomic-grid.scss */
  .gs14-ns {
    grid-column-end: span 14; }
  /* line 169, sass/layouts/_atomic-grid.scss */
  .gs15-ns {
    grid-column-end: span 15; }
  /* line 170, sass/layouts/_atomic-grid.scss */
  .gs16-ns {
    grid-column-end: span 16; }
  /* line 171, sass/layouts/_atomic-grid.scss */
  .gs17-ns {
    grid-column-end: span 17; }
  /* line 172, sass/layouts/_atomic-grid.scss */
  .gs18-ns {
    grid-column-end: span 18; }
  /* line 173, sass/layouts/_atomic-grid.scss */
  .gs19-ns {
    grid-column-end: span 19; }
  /* line 174, sass/layouts/_atomic-grid.scss */
  .gs20-ns {
    grid-column-end: span 20; }
  /* line 175, sass/layouts/_atomic-grid.scss */
  .gs21-ns {
    grid-column-end: span 21; }
  /* line 176, sass/layouts/_atomic-grid.scss */
  .gs22-ns {
    grid-column-end: span 22; }
  /* line 177, sass/layouts/_atomic-grid.scss */
  .gs23-ns {
    grid-column-end: span 23; }
  /* line 178, sass/layouts/_atomic-grid.scss */
  .gs24-ns {
    grid-column-end: span 24; }
  /* line 180, sass/layouts/_atomic-grid.scss */
  .go1-ns {
    grid-column-start: 2; }
  /* line 181, sass/layouts/_atomic-grid.scss */
  .go2-ns {
    grid-column-start: 3; }
  /* line 182, sass/layouts/_atomic-grid.scss */
  .go3-ns {
    grid-column-start: 4; }
  /* line 183, sass/layouts/_atomic-grid.scss */
  .go4-ns {
    grid-column-start: 5; }
  /* line 184, sass/layouts/_atomic-grid.scss */
  .go5-ns {
    grid-column-start: 6; }
  /* line 185, sass/layouts/_atomic-grid.scss */
  .go6-ns {
    grid-column-start: 7; }
  /* line 186, sass/layouts/_atomic-grid.scss */
  .go7-ns {
    grid-column-start: 8; }
  /* line 187, sass/layouts/_atomic-grid.scss */
  .go8-ns {
    grid-column-start: 9; }
  /* line 188, sass/layouts/_atomic-grid.scss */
  .go9-ns {
    grid-column-start: 10; }
  /* line 189, sass/layouts/_atomic-grid.scss */
  .go10-ns {
    grid-column-start: 11; }
  /* line 190, sass/layouts/_atomic-grid.scss */
  .go11-ns {
    grid-column-start: 12; }
  /* line 191, sass/layouts/_atomic-grid.scss */
  .go12-ns {
    grid-column-start: 13; }
  /* line 192, sass/layouts/_atomic-grid.scss */
  .go13-ns {
    grid-column-start: 14; }
  /* line 193, sass/layouts/_atomic-grid.scss */
  .go14-ns {
    grid-column-start: 15; }
  /* line 194, sass/layouts/_atomic-grid.scss */
  .go15-ns {
    grid-column-start: 16; }
  /* line 195, sass/layouts/_atomic-grid.scss */
  .go16-ns {
    grid-column-start: 17; }
  /* line 196, sass/layouts/_atomic-grid.scss */
  .go17-ns {
    grid-column-start: 18; }
  /* line 197, sass/layouts/_atomic-grid.scss */
  .go18-ns {
    grid-column-start: 19; }
  /* line 198, sass/layouts/_atomic-grid.scss */
  .go19-ns {
    grid-column-start: 20; }
  /* line 199, sass/layouts/_atomic-grid.scss */
  .go20-ns {
    grid-column-start: 21; }
  /* line 200, sass/layouts/_atomic-grid.scss */
  .go21-ns {
    grid-column-start: 22; }
  /* line 201, sass/layouts/_atomic-grid.scss */
  .go22-ns {
    grid-column-start: 23; }
  /* line 202, sass/layouts/_atomic-grid.scss */
  .go23-ns {
    grid-column-start: 24; }
  /* line 203, sass/layouts/_atomic-grid.scss */
  .order-1-ns {
    order: 1; }
  /* line 206, sass/layouts/_atomic-grid.scss */
  .order-2-ns {
    order: 2; }
  /* line 209, sass/layouts/_atomic-grid.scss */
  .order-3-ns {
    order: 3; }
  /* line 212, sass/layouts/_atomic-grid.scss */
  .order-4-ns {
    order: 4; }
  /* line 215, sass/layouts/_atomic-grid.scss */
  .order-5-ns {
    order: 5; }
  /* line 218, sass/layouts/_atomic-grid.scss */
  .order-6-ns {
    order: 6; } }

@media (min-width: 768px) {
  /* line 224, sass/layouts/_atomic-grid.scss */
  .g-12-m {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    align-content: stretch; }
  /* line 230, sass/layouts/_atomic-grid.scss */
  .g-20-m {
    display: grid;
    grid-template-columns: repeat(20, 1fr); }
  /* line 235, sass/layouts/_atomic-grid.scss */
  .g-24-m {
    display: grid;
    grid-template-columns: repeat(24, 1fr); }
  /* line 239, sass/layouts/_atomic-grid.scss */
  .gs1-m {
    grid-column-end: span 1; }
  /* line 240, sass/layouts/_atomic-grid.scss */
  .gs2-m {
    grid-column-end: span 2; }
  /* line 241, sass/layouts/_atomic-grid.scss */
  .gs3-m {
    grid-column-end: span 3; }
  /* line 242, sass/layouts/_atomic-grid.scss */
  .gs4-m {
    grid-column-end: span 4; }
  /* line 243, sass/layouts/_atomic-grid.scss */
  .gs5-m {
    grid-column-end: span 5; }
  /* line 244, sass/layouts/_atomic-grid.scss */
  .gs6-m {
    grid-column-end: span 6; }
  /* line 245, sass/layouts/_atomic-grid.scss */
  .gs7-m {
    grid-column-end: span 7; }
  /* line 246, sass/layouts/_atomic-grid.scss */
  .gs8-m {
    grid-column-end: span 8; }
  /* line 247, sass/layouts/_atomic-grid.scss */
  .gs9-m {
    grid-column-end: span 9; }
  /* line 248, sass/layouts/_atomic-grid.scss */
  .gs10-m {
    grid-column-end: span 10; }
  /* line 249, sass/layouts/_atomic-grid.scss */
  .gs11-m {
    grid-column-end: span 11; }
  /* line 250, sass/layouts/_atomic-grid.scss */
  .gs12-m {
    grid-column-end: span 12; }
  /* line 251, sass/layouts/_atomic-grid.scss */
  .gs13-m {
    grid-column-end: span 13; }
  /* line 252, sass/layouts/_atomic-grid.scss */
  .gs14-m {
    grid-column-end: span 14; }
  /* line 253, sass/layouts/_atomic-grid.scss */
  .gs15-m {
    grid-column-end: span 15; }
  /* line 254, sass/layouts/_atomic-grid.scss */
  .gs16-m {
    grid-column-end: span 16; }
  /* line 255, sass/layouts/_atomic-grid.scss */
  .gs17-m {
    grid-column-end: span 17; }
  /* line 256, sass/layouts/_atomic-grid.scss */
  .gs18-m {
    grid-column-end: span 18; }
  /* line 257, sass/layouts/_atomic-grid.scss */
  .gs19-m {
    grid-column-end: span 19; }
  /* line 258, sass/layouts/_atomic-grid.scss */
  .gs20-m {
    grid-column-end: span 20; }
  /* line 259, sass/layouts/_atomic-grid.scss */
  .gs21-m {
    grid-column-end: span 21; }
  /* line 260, sass/layouts/_atomic-grid.scss */
  .gs22-m {
    grid-column-end: span 22; }
  /* line 261, sass/layouts/_atomic-grid.scss */
  .gs23-m {
    grid-column-end: span 23; }
  /* line 262, sass/layouts/_atomic-grid.scss */
  .gs24-m {
    grid-column-end: span 24; }
  /* line 264, sass/layouts/_atomic-grid.scss */
  .go1-m {
    grid-column-start: 2; }
  /* line 265, sass/layouts/_atomic-grid.scss */
  .go2-m {
    grid-column-start: 3; }
  /* line 266, sass/layouts/_atomic-grid.scss */
  .go3-m {
    grid-column-start: 4; }
  /* line 267, sass/layouts/_atomic-grid.scss */
  .go4-m {
    grid-column-start: 5; }
  /* line 268, sass/layouts/_atomic-grid.scss */
  .go5-m {
    grid-column-start: 6; }
  /* line 269, sass/layouts/_atomic-grid.scss */
  .go6-m {
    grid-column-start: 7; }
  /* line 270, sass/layouts/_atomic-grid.scss */
  .go7-m {
    grid-column-start: 8; }
  /* line 271, sass/layouts/_atomic-grid.scss */
  .go8-m {
    grid-column-start: 9; }
  /* line 272, sass/layouts/_atomic-grid.scss */
  .go9-m {
    grid-column-start: 10; }
  /* line 273, sass/layouts/_atomic-grid.scss */
  .go10-m {
    grid-column-start: 11; }
  /* line 274, sass/layouts/_atomic-grid.scss */
  .go11-m {
    grid-column-start: 12; }
  /* line 275, sass/layouts/_atomic-grid.scss */
  .go12-m {
    grid-column-start: 13; }
  /* line 276, sass/layouts/_atomic-grid.scss */
  .go13-m {
    grid-column-start: 14; }
  /* line 277, sass/layouts/_atomic-grid.scss */
  .go14-m {
    grid-column-start: 15; }
  /* line 278, sass/layouts/_atomic-grid.scss */
  .go15-m {
    grid-column-start: 16; }
  /* line 279, sass/layouts/_atomic-grid.scss */
  .go16-m {
    grid-column-start: 17; }
  /* line 280, sass/layouts/_atomic-grid.scss */
  .go17-m {
    grid-column-start: 18; }
  /* line 281, sass/layouts/_atomic-grid.scss */
  .go18-m {
    grid-column-start: 19; }
  /* line 282, sass/layouts/_atomic-grid.scss */
  .go19-m {
    grid-column-start: 20; }
  /* line 283, sass/layouts/_atomic-grid.scss */
  .go20-m {
    grid-column-start: 21; }
  /* line 284, sass/layouts/_atomic-grid.scss */
  .go21-m {
    grid-column-start: 22; }
  /* line 285, sass/layouts/_atomic-grid.scss */
  .go22-m {
    grid-column-start: 23; }
  /* line 286, sass/layouts/_atomic-grid.scss */
  .go23-m {
    grid-column-start: 24; }
  /* line 287, sass/layouts/_atomic-grid.scss */
  .order-1-m {
    order: 1; }
  /* line 290, sass/layouts/_atomic-grid.scss */
  .order-2-m {
    order: 2; }
  /* line 293, sass/layouts/_atomic-grid.scss */
  .order-3-m {
    order: 3; }
  /* line 296, sass/layouts/_atomic-grid.scss */
  .order-4-m {
    order: 4; }
  /* line 299, sass/layouts/_atomic-grid.scss */
  .order-5-m {
    order: 5; }
  /* line 302, sass/layouts/_atomic-grid.scss */
  .order-6-m {
    order: 6; }
  /* line 305, sass/layouts/_atomic-grid.scss */
  .has-sidebar-nav .panel-region-main.gs8 {
    grid-column-end: span 12; } }

@media (min-width: 980px) {
  /* line 311, sass/layouts/_atomic-grid.scss */
  .g-12-l {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    align-content: stretch; }
  /* line 317, sass/layouts/_atomic-grid.scss */
  .g-20-l {
    display: grid;
    grid-template-columns: repeat(20, 1fr); }
  /* line 322, sass/layouts/_atomic-grid.scss */
  .g-24-l {
    display: grid;
    grid-template-columns: repeat(24, 1fr); }
  /* line 326, sass/layouts/_atomic-grid.scss */
  .gs1-l {
    grid-column-end: span 1; }
  /* line 327, sass/layouts/_atomic-grid.scss */
  .gs2-l {
    grid-column-end: span 2; }
  /* line 328, sass/layouts/_atomic-grid.scss */
  .gs3-l {
    grid-column-end: span 3; }
  /* line 329, sass/layouts/_atomic-grid.scss */
  .gs4-l {
    grid-column-end: span 4; }
  /* line 330, sass/layouts/_atomic-grid.scss */
  .gs5-l {
    grid-column-end: span 5; }
  /* line 331, sass/layouts/_atomic-grid.scss */
  .gs6-l {
    grid-column-end: span 6; }
  /* line 332, sass/layouts/_atomic-grid.scss */
  .gs7-l {
    grid-column-end: span 7; }
  /* line 333, sass/layouts/_atomic-grid.scss */
  .gs8-l {
    grid-column-end: span 8; }
  /* line 334, sass/layouts/_atomic-grid.scss */
  .has-sidebar-nav .panel-region-main.gs9-l, .gs9-l {
    grid-column-end: span 9; }
  /* line 335, sass/layouts/_atomic-grid.scss */
  .gs10-l {
    grid-column-end: span 10; }
  /* line 336, sass/layouts/_atomic-grid.scss */
  .gs11-l {
    grid-column-end: span 11; }
  /* line 337, sass/layouts/_atomic-grid.scss */
  .gs12-l {
    grid-column-end: span 12; }
  /* line 338, sass/layouts/_atomic-grid.scss */
  .gs13-l {
    grid-column-end: span 13; }
  /* line 339, sass/layouts/_atomic-grid.scss */
  .gs14-l {
    grid-column-end: span 14; }
  /* line 340, sass/layouts/_atomic-grid.scss */
  .gs15-l {
    grid-column-end: span 15; }
  /* line 341, sass/layouts/_atomic-grid.scss */
  .gs16-l {
    grid-column-end: span 16; }
  /* line 342, sass/layouts/_atomic-grid.scss */
  .gs17-l {
    grid-column-end: span 17; }
  /* line 343, sass/layouts/_atomic-grid.scss */
  .gs18-l {
    grid-column-end: span 18; }
  /* line 344, sass/layouts/_atomic-grid.scss */
  .gs19-l {
    grid-column-end: span 19; }
  /* line 345, sass/layouts/_atomic-grid.scss */
  .gs20-l {
    grid-column-end: span 20; }
  /* line 346, sass/layouts/_atomic-grid.scss */
  .gs21-l {
    grid-column-end: span 21; }
  /* line 347, sass/layouts/_atomic-grid.scss */
  .gs22-l {
    grid-column-end: span 22; }
  /* line 348, sass/layouts/_atomic-grid.scss */
  .gs23-l {
    grid-column-end: span 23; }
  /* line 349, sass/layouts/_atomic-grid.scss */
  .gs24-l {
    grid-column-end: span 24; }
  /* line 351, sass/layouts/_atomic-grid.scss */
  .go1-l {
    grid-column-start: 2; }
  /* line 352, sass/layouts/_atomic-grid.scss */
  .go2-l {
    grid-column-start: 3; }
  /* line 353, sass/layouts/_atomic-grid.scss */
  .go3-l {
    grid-column-start: 4; }
  /* line 354, sass/layouts/_atomic-grid.scss */
  .go4-l {
    grid-column-start: 5; }
  /* line 355, sass/layouts/_atomic-grid.scss */
  .go5-l {
    grid-column-start: 6; }
  /* line 356, sass/layouts/_atomic-grid.scss */
  .go6-l {
    grid-column-start: 7; }
  /* line 357, sass/layouts/_atomic-grid.scss */
  .go7-l {
    grid-column-start: 8; }
  /* line 358, sass/layouts/_atomic-grid.scss */
  .go8-l {
    grid-column-start: 9; }
  /* line 359, sass/layouts/_atomic-grid.scss */
  .go9-l {
    grid-column-start: 10; }
  /* line 360, sass/layouts/_atomic-grid.scss */
  .go10-l {
    grid-column-start: 11; }
  /* line 361, sass/layouts/_atomic-grid.scss */
  .go11-l {
    grid-column-start: 12; }
  /* line 362, sass/layouts/_atomic-grid.scss */
  .go12-l {
    grid-column-start: 13; }
  /* line 363, sass/layouts/_atomic-grid.scss */
  .go13-l {
    grid-column-start: 14; }
  /* line 364, sass/layouts/_atomic-grid.scss */
  .go14-l {
    grid-column-start: 15; }
  /* line 365, sass/layouts/_atomic-grid.scss */
  .go15-l {
    grid-column-start: 16; }
  /* line 366, sass/layouts/_atomic-grid.scss */
  .go16-l {
    grid-column-start: 17; }
  /* line 367, sass/layouts/_atomic-grid.scss */
  .go17-l {
    grid-column-start: 18; }
  /* line 368, sass/layouts/_atomic-grid.scss */
  .go18-l {
    grid-column-start: 19; }
  /* line 369, sass/layouts/_atomic-grid.scss */
  .go19-l {
    grid-column-start: 20; }
  /* line 370, sass/layouts/_atomic-grid.scss */
  .go20-l {
    grid-column-start: 21; }
  /* line 371, sass/layouts/_atomic-grid.scss */
  .go21-l {
    grid-column-start: 22; }
  /* line 372, sass/layouts/_atomic-grid.scss */
  .go22-l {
    grid-column-start: 23; }
  /* line 373, sass/layouts/_atomic-grid.scss */
  .go23-l {
    grid-column-start: 24; }
  /* line 374, sass/layouts/_atomic-grid.scss */
  .order-1-l {
    order: 1; }
  /* line 377, sass/layouts/_atomic-grid.scss */
  .order-2-l {
    order: 2; }
  /* line 380, sass/layouts/_atomic-grid.scss */
  .order-3-l {
    order: 3; }
  /* line 383, sass/layouts/_atomic-grid.scss */
  .order-4-l {
    order: 4; }
  /* line 386, sass/layouts/_atomic-grid.scss */
  .order-5-l {
    order: 5; }
  /* line 389, sass/layouts/_atomic-grid.scss */
  .order-6-l {
    order: 6; } }

@media (min-width: 1280px) {
  /* line 395, sass/layouts/_atomic-grid.scss */
  .g-fit,
  .g-12, .g-20, .g-24,
  .g-12-ns, .g-ns-20, .g-ns-24,
  .g-12-m, .g-20-m, .g-24-m,
  .g-12-l, .g-20-l, .g-24-l,
  .g-12-w, .g-20-w, .g-24-w {
    grid-column-gap: 40px;
    grid-column-gap: 4rem; }
  /* line 409, sass/layouts/_atomic-grid.scss */
  .view .g-fit, .view .g-fit.reset-list, .view
  .g-12, .view
  .g-12.reset-list, .view .g-20, .view .g-20.reset-list, .view .g-24, .view .g-24.reset-list, .view
  .g-12-ns, .view
  .g-12-ns.reset-list, .view .g-ns-20, .view .g-ns-20.reset-list, .view .g-ns-24, .view .g-ns-24.reset-list, .view
  .g-12-m, .view
  .g-12-m.reset-list, .view .g-20-m, .view .g-20-m.reset-list, .view .g-24-m, .view .g-24-m.reset-list, .view
  .g-12-l, .view
  .g-12-l.reset-list, .view .g-20-l, .view .g-20-l.reset-list, .view .g-24-l, .view .g-24-l.reset-list {
    grid-gap: 36px;
    grid-gap: 3.6rem; }
  /* line 414, sass/layouts/_atomic-grid.scss */
  .g-fit.no-gap,
  .no-gap.view .g-fit,
  .g-12.no-gap,
  .no-gap.view
  .g-12, .g-20.no-gap,
  .no-gap.view .g-20, .g-24.no-gap,
  .no-gap.view .g-24,
  .g-12-ns.no-gap,
  .no-gap.view
  .g-12-ns, .g-ns-20.no-gap,
  .no-gap.view .g-ns-20, .g-ns-24.no-gap,
  .no-gap.view .g-ns-24,
  .g-12-m.no-gap,
  .no-gap.view
  .g-12-m, .g-20-m.no-gap,
  .no-gap.view .g-20-m, .g-24-m.no-gap,
  .no-gap.view .g-24-m,
  .g-12-l.no-gap,
  .no-gap.view
  .g-12-l, .g-20-l.no-gap,
  .no-gap.view .g-20-l, .g-24-l.no-gap,
  .no-gap.view .g-24-l {
    grid-gap: 0; } }

@media (min-width: 1600px) {
  /* line 422, sass/layouts/_atomic-grid.scss */
  .g-12-w {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    align-content: stretch; }
  /* line 428, sass/layouts/_atomic-grid.scss */
  .g-20-w {
    display: grid;
    grid-template-columns: repeat(20, 1fr); }
  /* line 433, sass/layouts/_atomic-grid.scss */
  .g-24-w {
    display: grid;
    grid-template-columns: repeat(24, 1fr); }
  /* line 438, sass/layouts/_atomic-grid.scss */
  .gs1-w {
    grid-column-end: span 1; }
  /* line 439, sass/layouts/_atomic-grid.scss */
  .gs2-w {
    grid-column-end: span 2; }
  /* line 440, sass/layouts/_atomic-grid.scss */
  .gs3-w {
    grid-column-end: span 3; }
  /* line 441, sass/layouts/_atomic-grid.scss */
  .gs4-w {
    grid-column-end: span 4; }
  /* line 442, sass/layouts/_atomic-grid.scss */
  .gs5-w {
    grid-column-end: span 5; }
  /* line 443, sass/layouts/_atomic-grid.scss */
  .gs6-w {
    grid-column-end: span 6; }
  /* line 444, sass/layouts/_atomic-grid.scss */
  .gs7-w {
    grid-column-end: span 7; }
  /* line 445, sass/layouts/_atomic-grid.scss */
  .gs8-w {
    grid-column-end: span 8; }
  /* line 446, sass/layouts/_atomic-grid.scss */
  .gs9-w {
    grid-column-end: span 9; }
  /* line 447, sass/layouts/_atomic-grid.scss */
  .gs10-w {
    grid-column-end: span 10; }
  /* line 448, sass/layouts/_atomic-grid.scss */
  .gs11-w {
    grid-column-end: span 11; }
  /* line 449, sass/layouts/_atomic-grid.scss */
  .gs12-w {
    grid-column-end: span 12; }
  /* line 450, sass/layouts/_atomic-grid.scss */
  .gs13-w {
    grid-column-end: span 13; }
  /* line 451, sass/layouts/_atomic-grid.scss */
  .gs14-w {
    grid-column-end: span 14; }
  /* line 452, sass/layouts/_atomic-grid.scss */
  .gs15-w {
    grid-column-end: span 15; }
  /* line 453, sass/layouts/_atomic-grid.scss */
  .gs16-w {
    grid-column-end: span 16; }
  /* line 454, sass/layouts/_atomic-grid.scss */
  .gs17-w {
    grid-column-end: span 17; }
  /* line 455, sass/layouts/_atomic-grid.scss */
  .gs18-w {
    grid-column-end: span 18; }
  /* line 456, sass/layouts/_atomic-grid.scss */
  .gs19-w {
    grid-column-end: span 19; }
  /* line 457, sass/layouts/_atomic-grid.scss */
  .gs20-w {
    grid-column-end: span 20; }
  /* line 458, sass/layouts/_atomic-grid.scss */
  .gs21-w {
    grid-column-end: span 21; }
  /* line 459, sass/layouts/_atomic-grid.scss */
  .gs22-w {
    grid-column-end: span 22; }
  /* line 460, sass/layouts/_atomic-grid.scss */
  .gs23-w {
    grid-column-end: span 23; }
  /* line 461, sass/layouts/_atomic-grid.scss */
  .gs24-w {
    grid-column-end: span 24; }
  /* line 463, sass/layouts/_atomic-grid.scss */
  .go1-w {
    grid-column-start: 2; }
  /* line 464, sass/layouts/_atomic-grid.scss */
  .go2-w {
    grid-column-start: 3; }
  /* line 465, sass/layouts/_atomic-grid.scss */
  .go3-w {
    grid-column-start: 4; }
  /* line 466, sass/layouts/_atomic-grid.scss */
  .go4-w {
    grid-column-start: 5; }
  /* line 467, sass/layouts/_atomic-grid.scss */
  .go5-w {
    grid-column-start: 6; }
  /* line 468, sass/layouts/_atomic-grid.scss */
  .go6-w {
    grid-column-start: 7; }
  /* line 469, sass/layouts/_atomic-grid.scss */
  .go7-w {
    grid-column-start: 8; }
  /* line 470, sass/layouts/_atomic-grid.scss */
  .go8-w {
    grid-column-start: 9; }
  /* line 471, sass/layouts/_atomic-grid.scss */
  .go9-w {
    grid-column-start: 10; }
  /* line 472, sass/layouts/_atomic-grid.scss */
  .go10-w {
    grid-column-start: 11; }
  /* line 473, sass/layouts/_atomic-grid.scss */
  .go11-w {
    grid-column-start: 12; }
  /* line 474, sass/layouts/_atomic-grid.scss */
  .go12-w {
    grid-column-start: 13; }
  /* line 475, sass/layouts/_atomic-grid.scss */
  .go13-w {
    grid-column-start: 14; }
  /* line 476, sass/layouts/_atomic-grid.scss */
  .go14-w {
    grid-column-start: 15; }
  /* line 477, sass/layouts/_atomic-grid.scss */
  .go15-w {
    grid-column-start: 16; }
  /* line 478, sass/layouts/_atomic-grid.scss */
  .go16-w {
    grid-column-start: 17; }
  /* line 479, sass/layouts/_atomic-grid.scss */
  .go17-w {
    grid-column-start: 18; }
  /* line 480, sass/layouts/_atomic-grid.scss */
  .go18-w {
    grid-column-start: 19; }
  /* line 481, sass/layouts/_atomic-grid.scss */
  .go19-w {
    grid-column-start: 20; }
  /* line 482, sass/layouts/_atomic-grid.scss */
  .go20-w {
    grid-column-start: 21; }
  /* line 483, sass/layouts/_atomic-grid.scss */
  .go21-w {
    grid-column-start: 22; }
  /* line 484, sass/layouts/_atomic-grid.scss */
  .go22-w {
    grid-column-start: 23; }
  /* line 485, sass/layouts/_atomic-grid.scss */
  .go23-w {
    grid-column-start: 24; }
  /* line 486, sass/layouts/_atomic-grid.scss */
  .order-1-w {
    order: 1; }
  /* line 489, sass/layouts/_atomic-grid.scss */
  .order-2-w {
    order: 2; }
  /* line 492, sass/layouts/_atomic-grid.scss */
  .order-3-w {
    order: 3; }
  /* line 495, sass/layouts/_atomic-grid.scss */
  .order-4-w {
    order: 4; }
  /* line 498, sass/layouts/_atomic-grid.scss */
  .order-5-w {
    order: 5; }
  /* line 501, sass/layouts/_atomic-grid.scss */
  .order-6-w {
    order: 6; } }

/* line 2, sass/components/_book.scss */
.book-navigation .page-links {
  display: flex;
  width: 100%;
  margin: 36px 0 24px;
  margin: 3.6rem 0 2.4rem;
  border-top-color: #75787B;
  border-bottom: none;
  justify-content: space-between; }

/* line 15, sass/components/_book.scss */
.panel-region-bottom .pane-node-book-nav {
  border-top: 1px solid #75787B; }

/* line 18, sass/components/_book.scss */
.panel-region-bottom .book-navigation {
  width: 96%;
  max-width: 1366px;
  margin-left: auto;
  margin-right: auto; }

/* line 21, sass/components/_book.scss */
.panel-region-bottom .page-links {
  border-top: none; }

/* line 1, sass/components/_breadcrumbs.scss */
.breadcrumbs {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.71429;
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
  padding: 0; }
  /* line 39, sass/helpers/_list.scss */
  .breadcrumbs > li {
    list-style-type: none;
    list-style-image: none;
    display: inline-block; }
    /* line 49, sass/helpers/_list.scss */
    .breadcrumbs > li:last-child {
      margin-right: 0px; }
  /* line 6, sass/components/_breadcrumbs.scss */
  .breadcrumbs li {
    padding: 0; }
    /* line 7, sass/components/_breadcrumbs.scss */
    .breadcrumbs li:after {
      content: ' / ';
      color: #778288; }
    /* line 12, sass/components/_breadcrumbs.scss */
    .breadcrumbs li span {
      display: inline-block;
      margin: 0 5px;
      margin: 0 0.5rem;
      color: #778288; }
    /* line 18, sass/components/_breadcrumbs.scss */
    .breadcrumbs li a {
      color: #0177A8; }
      /* line 147, sass/helpers/_text.scss */
      .breadcrumbs li a:visited {
        color: #0177A8; }
      /* line 152, sass/helpers/_text.scss */
      .breadcrumbs li a:focus {
        color: #0177A8; }
      /* line 157, sass/helpers/_text.scss */
      .breadcrumbs li a:hover {
        color: #9f3050; }
      /* line 162, sass/helpers/_text.scss */
      .breadcrumbs li a:active {
        color: #9f3050; }
    /* line 22, sass/components/_breadcrumbs.scss */
    .breadcrumbs li.active {
      color: #778288; }
    /* line 26, sass/components/_breadcrumbs.scss */
    .breadcrumbs li:last-child:after {
      content: ''; }

/*------------------------------------*\
    $MENU BUTTONS
\*------------------------------------*/
/* line 18, sass/components/_buttons.scss */
.lines-button {
  background: #165C7D;
  border: 0;
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 18px 10px;
  padding: 1.8rem 1rem;
  user-select: none;
  border-radius: 0;
  margin: 0; }
  /* line 31, sass/components/_buttons.scss */
  .lines-button:hover {
    opacity: 1; }
  /* line 35, sass/components/_buttons.scss */
  .lines-button:active {
    background: rgba(0, 0, 0, 0.1);
    transition: none; }

/* line 52, sass/components/_buttons.scss */
.lines {
  background: white;
  border-radius: 2px;
  display: block;
  height: 3px;
  transition: transform .3s ease-out, transform .3s ease-out, top .3s ease-out;
  width: 24px;
  position: relative;
  top: -.13em;
  /*create the upper and lower lines as pseudo-elements of the middle line*/ }
  /* line 59, sass/components/_buttons.scss */
  .lines:before, .lines:after {
    background: white;
    border-radius: 2px;
    display: block;
    height: 3px;
    transition: transform .3s ease-out, transform .3s ease-out, top .3s ease-out;
    width: 24px;
    position: absolute;
    left: 0;
    content: '';
    transform-origin: 1.71429px center; }
  /* line 67, sass/components/_buttons.scss */
  .lines:before {
    top: 8px; }
  /* line 68, sass/components/_buttons.scss */
  .lines:after {
    top: -8px; }

/* line 75, sass/components/_buttons.scss */
.sf-expanded.lines-button .lines {
  background: transparent;
  top: .4em; }
  /* line 78, sass/components/_buttons.scss */
  .sf-expanded.lines-button .lines:before, .sf-expanded.lines-button .lines:after {
    transform-origin: 50% 50%;
    top: -4px; }
  /* line 82, sass/components/_buttons.scss */
  .sf-expanded.lines-button .lines:before {
    transform: rotate3d(0, 0, 1, 45deg); }
  /* line 85, sass/components/_buttons.scss */
  .sf-expanded.lines-button .lines:after {
    transform: rotate3d(0, 0, 1, -45deg); }

/*------------------------------------*\
    $BUTTONS
\*------------------------------------*/
/* line 93, sass/components/_buttons.scss */
.btn,
.btn-blue,
.btn-orange,
.btn-burgundy,
.btn-green,
.btn-alt {
  text-transform: capitalize;
  letter-spacing: .1px;
  padding: 4px 25px;
  padding: 0.4rem 2.5rem; }

/* line 104, sass/components/_buttons.scss */
.btn {
  font-size: 17px;
  font-size: 1.7rem;
  transition: background-color 300ms ease-out, border-color 320ms ease-out;
  background-color: #41505c;
  border: 1px solid #677683;
  color: #FFF;
  position: relative;
  z-index: 1; }
  /* line 147, sass/helpers/_text.scss */
  .btn:visited {
    color: #FFF; }
  /* line 152, sass/helpers/_text.scss */
  .btn:focus {
    color: #FFF; }
  /* line 157, sass/helpers/_text.scss */
  .btn:hover {
    color: #FFF; }
  /* line 162, sass/helpers/_text.scss */
  .btn:active {
    color: #FFF; }
  /* line 78, sass/helpers/_buttons.scss */
  .btn:focus, .btn:hover, .btn:active {
    background-color: #165C7D;
    border-color: #3da5d6;
    text-decoration: none;
    box-shadow: rgba(0, 0, 0, 0.16) -2px 0 2px 0, rgba(0, 0, 0, 0.16) 2px 0 2px 0, rgba(0, 0, 0, 0.32) 0 2px 2px 0; }
  /* line 106, sass/components/_buttons.scss */
  .btn.read-more {
    position: relative;
    display: inline-block;
    padding-right: 42.5px;
    padding-right: 4.25rem; }
    /* line 50, sass/helpers/_icon.scss */
    .btn.read-more:after {
      content: "";
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
      color: white;
      position: absolute;
      font-size: 17px;
      font-size: 1.7rem;
      top: 4px;
      top: 0.4rem;
      right: 17px;
      right: 1.7rem; }
    /* line 62, sass/helpers/_icon.scss */
    .btn.read-more:hover:after {
      color: white; }
  /* line 109, sass/components/_buttons.scss */
  .btn.rounded {
    border-radius: 17px; }

/* line 113, sass/components/_buttons.scss */
.btn-blue {
  font-size: 17px;
  font-size: 1.7rem;
  transition: background-color 300ms ease-out, border-color 320ms ease-out;
  background-color: #165C7D;
  border: 1px solid #278ab8;
  color: white;
  position: relative;
  z-index: 1; }
  /* line 147, sass/helpers/_text.scss */
  .btn-blue:visited {
    color: white; }
  /* line 152, sass/helpers/_text.scss */
  .btn-blue:focus {
    color: white; }
  /* line 157, sass/helpers/_text.scss */
  .btn-blue:hover {
    color: white; }
  /* line 162, sass/helpers/_text.scss */
  .btn-blue:active {
    color: white; }
  /* line 78, sass/helpers/_buttons.scss */
  .btn-blue:focus, .btn-blue:hover, .btn-blue:active {
    background-color: #0e3c52;
    border-color: #278ab8;
    text-decoration: none;
    box-shadow: rgba(0, 0, 0, 0.16) -2px 0 2px 0, rgba(0, 0, 0, 0.16) 2px 0 2px 0, rgba(0, 0, 0, 0.32) 0 2px 2px 0; }
  /* line 115, sass/components/_buttons.scss */
  .btn-blue.btn-text:after {
    top: 6px;
    top: 0.6rem; }
  /* line 118, sass/components/_buttons.scss */
  .btn-blue:hover, .btn-blue.btn-text:hover {
    color: #fff; }

/* line 123, sass/components/_buttons.scss */
.btn-orange {
  font-size: 17px;
  font-size: 1.7rem;
  transition: background-color 300ms ease-out, border-color 320ms ease-out;
  background-color: #E87722;
  border: 1px solid #eba26b;
  color: white;
  position: relative;
  z-index: 1; }
  /* line 147, sass/helpers/_text.scss */
  .btn-orange:visited {
    color: white; }
  /* line 152, sass/helpers/_text.scss */
  .btn-orange:focus {
    color: white; }
  /* line 157, sass/helpers/_text.scss */
  .btn-orange:hover {
    color: white; }
  /* line 162, sass/helpers/_text.scss */
  .btn-orange:active {
    color: white; }
  /* line 78, sass/helpers/_buttons.scss */
  .btn-orange:focus, .btn-orange:hover, .btn-orange:active {
    background-color: #c35f14;
    border-color: #eba26b;
    text-decoration: none;
    box-shadow: rgba(0, 0, 0, 0.16) -2px 0 2px 0, rgba(0, 0, 0, 0.16) 2px 0 2px 0, rgba(0, 0, 0, 0.32) 0 2px 2px 0; }
  /* line 126, sass/components/_buttons.scss */
  .btn-orange.inactive {
    opacity: .3;
    color: #eef0f3; }

/* line 134, sass/components/_buttons.scss */
.btn-green {
  font-size: 17px;
  font-size: 1.7rem;
  transition: background-color 300ms ease-out, border-color 320ms ease-out;
  background-color: #298732;
  border: 1px solid #41bb4d;
  color: white;
  position: relative;
  z-index: 1; }
  /* line 147, sass/helpers/_text.scss */
  .btn-green:visited {
    color: white; }
  /* line 152, sass/helpers/_text.scss */
  .btn-green:focus {
    color: white; }
  /* line 157, sass/helpers/_text.scss */
  .btn-green:hover {
    color: white; }
  /* line 162, sass/helpers/_text.scss */
  .btn-green:active {
    color: white; }
  /* line 78, sass/helpers/_buttons.scss */
  .btn-green:focus, .btn-green:hover, .btn-green:active {
    background-color: #1d6024;
    border-color: #41bb4d;
    text-decoration: none;
    box-shadow: rgba(0, 0, 0, 0.16) -2px 0 2px 0, rgba(0, 0, 0, 0.16) 2px 0 2px 0, rgba(0, 0, 0, 0.32) 0 2px 2px 0; }

/* line 138, sass/components/_buttons.scss */
.btn-burgundy {
  font-size: 17px;
  font-size: 1.7rem;
  transition: background-color 300ms ease-out, border-color 320ms ease-out;
  background-color: #9f3050;
  border: 1px solid #c55776;
  color: white;
  position: relative;
  z-index: 1; }
  /* line 147, sass/helpers/_text.scss */
  .btn-burgundy:visited {
    color: white; }
  /* line 152, sass/helpers/_text.scss */
  .btn-burgundy:focus {
    color: white; }
  /* line 157, sass/helpers/_text.scss */
  .btn-burgundy:hover {
    color: white; }
  /* line 162, sass/helpers/_text.scss */
  .btn-burgundy:active {
    color: white; }
  /* line 78, sass/helpers/_buttons.scss */
  .btn-burgundy:focus, .btn-burgundy:hover, .btn-burgundy:active {
    background-color: #78243c;
    border-color: #c55776;
    text-decoration: none;
    box-shadow: rgba(0, 0, 0, 0.16) -2px 0 2px 0, rgba(0, 0, 0, 0.16) 2px 0 2px 0, rgba(0, 0, 0, 0.32) 0 2px 2px 0; }

/* line 142, sass/components/_buttons.scss */
.btn-text-alt,
.btn-alt {
  font-size: 17px;
  font-size: 1.7rem;
  transition: background-color 300ms ease-out, border-color 320ms ease-out;
  background-color: #D0D7DE;
  border: 1px solid #fdfdfd;
  color: #41505c;
  position: relative;
  z-index: 1; }
  /* line 147, sass/helpers/_text.scss */
  .btn-text-alt:visited,
  .btn-alt:visited {
    color: #41505c; }
  /* line 152, sass/helpers/_text.scss */
  .btn-text-alt:focus,
  .btn-alt:focus {
    color: #41505c; }
  /* line 157, sass/helpers/_text.scss */
  .btn-text-alt:hover,
  .btn-alt:hover {
    color: #41505c; }
  /* line 162, sass/helpers/_text.scss */
  .btn-text-alt:active,
  .btn-alt:active {
    color: #41505c; }
  /* line 78, sass/helpers/_buttons.scss */
  .btn-text-alt:focus, .btn-text-alt:hover, .btn-text-alt:active,
  .btn-alt:focus,
  .btn-alt:hover,
  .btn-alt:active {
    background-color: #b2bec9;
    border-color: #fdfdfd;
    text-decoration: none;
    box-shadow: rgba(0, 0, 0, 0.16) -2px 0 2px 0, rgba(0, 0, 0, 0.16) 2px 0 2px 0, rgba(0, 0, 0, 0.32) 0 2px 2px 0; }
  /* line 145, sass/components/_buttons.scss */
  .btn-text-alt.active,
  .btn-alt.active {
    font-size: 17px;
    font-size: 1.7rem;
    transition: background-color 300ms ease-out, border-color 320ms ease-out;
    background-color: #9f3050;
    border: 1px solid #c55776;
    color: white;
    position: relative;
    z-index: 1; }
    /* line 147, sass/helpers/_text.scss */
    .btn-text-alt.active:visited,
    .btn-alt.active:visited {
      color: white; }
    /* line 152, sass/helpers/_text.scss */
    .btn-text-alt.active:focus,
    .btn-alt.active:focus {
      color: white; }
    /* line 157, sass/helpers/_text.scss */
    .btn-text-alt.active:hover,
    .btn-alt.active:hover {
      color: white; }
    /* line 162, sass/helpers/_text.scss */
    .btn-text-alt.active:active,
    .btn-alt.active:active {
      color: white; }
    /* line 78, sass/helpers/_buttons.scss */
    .btn-text-alt.active:focus, .btn-text-alt.active:hover, .btn-text-alt.active:active,
    .btn-alt.active:focus,
    .btn-alt.active:hover,
    .btn-alt.active:active {
      background-color: #78243c;
      border-color: #c55776;
      text-decoration: none;
      box-shadow: rgba(0, 0, 0, 0.16) -2px 0 2px 0, rgba(0, 0, 0, 0.16) 2px 0 2px 0, rgba(0, 0, 0, 0.32) 0 2px 2px 0; }

/* line 149, sass/components/_buttons.scss */
.btn-large {
  font-size: 21px;
  font-size: 2.1rem;
  padding: 12px 10px;
  padding: 1.2rem 1rem;
  margin-bottom: 24px;
  margin-bottom: 2.4rem; }
  /* line 55, sass/helpers/_buttons.scss */
  .btn-large.active {
    font-size: 21px;
    font-size: 2.1rem;
    padding: 12px 10px;
    padding: 1.2rem 1rem; }

/* line 155, sass/components/_buttons.scss */
.btn-alt.fullsize {
  background-repeat: no-repeat;
  padding: 1em; }

/* line 159, sass/components/_buttons.scss */
button:focus {
  border-color: #165C7D;
  outline: 2px solid #165C7D; }

/* line 163, sass/components/_buttons.scss */
.btn + .btn {
  margin-left: 15px;
  margin-left: 1.5rem; }
  /* line 166, sass/components/_buttons.scss */
  [dir="rtl"] .btn + .btn {
    margin-left: 0;
    margin-right: 15px;
    margin-right: 1.5rem; }

/* line 172, sass/components/_buttons.scss */
.btn-small {
  padding: 4px 10px;
  padding: 0.4rem 1rem; }

/* line 175, sass/components/_buttons.scss */
.btn-text-alt,
.btn-text,
.btn-text-large,
.field-name-more-link > a {
  position: relative;
  display: inline-block;
  padding-right: 24px;
  padding-right: 2.4rem; }
  /* line 50, sass/helpers/_icon.scss */
  .btn-text-alt:after,
  .btn-text:after,
  .btn-text-large:after,
  .field-name-more-link > a:after {
    content: "";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #0177A8;
    position: absolute;
    font-size: 17px;
    font-size: 1.7rem;
    top: 1.5px;
    top: 0.15rem;
    right: 6px;
    right: 0.6rem; }
  /* line 62, sass/helpers/_icon.scss */
  .btn-text-alt:hover:after,
  .btn-text:hover:after,
  .btn-text-large:hover:after,
  .field-name-more-link > a:hover:after {
    color: #9f3050; }
  /* line 180, sass/components/_buttons.scss */
  .btn-text-alt:after,
  .btn-text:after,
  .btn-text-large:after,
  .field-name-more-link > a:after {
    color: inherit !important;
    font-size: inherit; }
    /* line 183, sass/components/_buttons.scss */
    .btn-text-alt:after:hover,
    .btn-text:after:hover,
    .btn-text-large:after:hover,
    .field-name-more-link > a:after:hover {
      color: inherit !important; }
  /* line 187, sass/components/_buttons.scss */
  .btn-text-alt:hover,
  .btn-text:hover,
  .btn-text-large:hover,
  .field-name-more-link > a:hover {
    color: #9f3050; }

/* line 192, sass/components/_buttons.scss */
.btn-text-alt:after {
  top: 6px;
  top: 0.6rem; }

/* line 195, sass/components/_buttons.scss */
.btn-text-large {
  font-size: 21px;
  font-size: 2.1rem;
  padding: 12px 10px;
  padding: 1.2rem 1rem;
  margin-bottom: 24px;
  margin-bottom: 2.4rem;
  padding: 0;
  padding-right: 40px;
  padding-right: 4rem; }
  /* line 55, sass/helpers/_buttons.scss */
  .btn-text-large.active {
    font-size: 21px;
    font-size: 2.1rem;
    padding: 12px 10px;
    padding: 1.2rem 1rem; }
  /* line 199, sass/components/_buttons.scss */
  .btn-text-large:after {
    right: 10px;
    right: 1rem;
    top: 2px;
    top: 0.2rem; }

/* line 204, sass/components/_buttons.scss */
button.btn-svg {
  background: transparent;
  border: none;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  padding: 0; }
  /* line 217, sass/components/_buttons.scss */
  button.btn-svg .fill {
    fill: #0177A8; }
  /* line 222, sass/components/_buttons.scss */
  button.btn-svg:hover .fill, button.btn-svg:focus .fill {
    fill: #9f3050; }
  /* line 226, sass/components/_buttons.scss */
  button.btn-svg:hover, button.btn-svg:focus, button.btn-svg:active {
    background: transparent;
    box-shadow: none; }
  /* line 232, sass/components/_buttons.scss */
  button.btn-svg svg {
    width: 38px; }
  /* line 236, sass/components/_buttons.scss */
  button.btn-svg.reset svg {
    width: 26px; }

/* line 2, sass/components/_calendar.scss */
.calendar-calendar div.calendar {
  font-size: 14px;
  font-size: 1.4rem; }

/* line 7, sass/components/_calendar.scss */
.calendar-calendar div.day {
  width: auto; }

/* line 10, sass/components/_calendar.scss */
.calendar-calendar th.days {
  background: #D0D7DE; }

/* line 14, sass/components/_calendar.scss */
.calendar-calendar td {
  color: #333F48; }

/* line 21, sass/components/_calendar.scss */
.calendar-calendar .day-view .full td.multi-day div.monthview,
.calendar-calendar .day-view .full td.multi-day div.weekview,
.calendar-calendar .day-view .full td.multi-day div.dayview,
.calendar-calendar .month-view .full td.multi-day div.monthview,
.calendar-calendar .month-view .full td.multi-day div.weekview,
.calendar-calendar .month-view .full td.multi-day div.dayview,
.calendar-calendar .week-view .full td.multi-day div.monthview,
.calendar-calendar .week-view .full td.multi-day div.weekview,
.calendar-calendar .week-view .full td.multi-day div.dayview {
  background: #D8E5EB;
  color: #333F48; }

/* line 28, sass/components/_calendar.scss */
.calendar-calendar .day-view .full td.multi-day .inner .monthview,
.calendar-calendar .day-view .full td.multi-day .inner .weekview,
.calendar-calendar .month-view .full td.multi-day .inner .monthview,
.calendar-calendar .month-view .full td.multi-day .inner .weekview,
.calendar-calendar .week-view .full td.multi-day .inner .monthview,
.calendar-calendar .week-view .full td.multi-day .inner .weekview {
  margin-bottom: 3px;
  margin-bottom: 0.3rem; }
  /* line 30, sass/components/_calendar.scss */
  .calendar-calendar .day-view .full td.multi-day .inner .monthview .cutoff, .calendar-calendar .day-view .full td.multi-day .inner .monthview .continues,
  .calendar-calendar .day-view .full td.multi-day .inner .weekview .cutoff,
  .calendar-calendar .day-view .full td.multi-day .inner .weekview .continues,
  .calendar-calendar .month-view .full td.multi-day .inner .monthview .cutoff,
  .calendar-calendar .month-view .full td.multi-day .inner .monthview .continues,
  .calendar-calendar .month-view .full td.multi-day .inner .weekview .cutoff,
  .calendar-calendar .month-view .full td.multi-day .inner .weekview .continues,
  .calendar-calendar .week-view .full td.multi-day .inner .monthview .cutoff,
  .calendar-calendar .week-view .full td.multi-day .inner .monthview .continues,
  .calendar-calendar .week-view .full td.multi-day .inner .weekview .cutoff,
  .calendar-calendar .week-view .full td.multi-day .inner .weekview .continues {
    background: #D8E5EB; }

/* line 40, sass/components/_calendar.scss */
.calendar-calendar .month-view .full .inner .monthview,
.calendar-calendar .month-view .full .inner .weekview,
.calendar-calendar .month-view .full .inner .dayview,
.calendar-calendar .week-view .full .inner .monthview,
.calendar-calendar .week-view .full .inner .weekview,
.calendar-calendar .week-view .full .inner .dayview,
.calendar-calendar .day-view .full .inner .monthview,
.calendar-calendar .day-view .full .inner .weekview,
.calendar-calendar .day-view .full .inner .dayview {
  border-radius: 0; }

/* line 48, sass/components/_calendar.scss */
.calendar-calendar .month-view .month.day {
  font-size: 14px;
  font-size: 1.4rem; }

/* line 53, sass/components/_calendar.scss */
.calendar-calendar .month-view .single-day.empty,
.calendar-calendar .month-view .single-day.no-entry {
  height: 70px;
  height: 7rem; }

/* line 58, sass/components/_calendar.scss */
.calendar-calendar .month-view .full td.date-box.today,
.calendar-calendar .month-view .full tr td.single-day.today,
.calendar-calendar .month-view .full tr td.multi-day.starts-today {
  border-color: #9f3050; }

/* line 63, sass/components/_calendar.scss */
.calendar-calendar .month-view .full tr td.single-day.today {
  border-bottom: 2px solid #9f3050; }

/* line 67, sass/components/_calendar.scss */
.calendar-calendar .month-view .full th.days,
.calendar-calendar .month-view .full td,
.calendar-calendar .month-view .full td.empty {
  border-color: #b2bec9; }

/* line 74, sass/components/_calendar.scss */
.calendar-calendar .month-view .full td.single-day div.monthview {
  background: #D8E5EB; }

/* line 78, sass/components/_calendar.scss */
.calendar-calendar .month-view .full td.date-box.empty {
  background: #eef0f3;
  color: #41505c; }

/* line 87, sass/components/_calendar.scss */
.calendar-calendar .day-view .dayview .contents a {
  display: block; }

/* line 93, sass/components/_calendar.scss */
.calendar-calendar .week-view #multi-day-container,
.calendar-calendar .day-view #multi-day-container {
  height: 108px;
  height: 10.8rem; }

/* line 99, sass/components/_calendar.scss */
.view .date-nav-wrapper {
  position: relative; }
  /* line 101, sass/components/_calendar.scss */
  .view .date-nav-wrapper .date-prev,
  .view .date-nav-wrapper .date-next {
    background: none;
    border-radius: 0;
    position: absolute;
    top: 4px;
    font-size: inherit;
    margin: 0;
    right: auto; }
    /* line 110, sass/components/_calendar.scss */
    .view .date-nav-wrapper .date-prev a,
    .view .date-nav-wrapper .date-next a {
      padding: 0;
      margin: 0;
      min-width: auto;
      display: inline-block;
      height: 34px;
      height: 3.4rem;
      width: 34px;
      width: 3.4rem;
      border-radius: 50%;
      background-color: #0052ba;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 0 50%;
      background-image: url("../images/bg/prev.png");
      background-image: url("data:image/svg+xml, %3Csvg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50' fill='rgb%28255%2C255%2C255%29'%3E%3Cstyle%3E.st0%7Bopacity:.5%3Bfill:%231b1b1c%7D.st1%7Bfill:%23fff%7D.st2%2C.st3%7Bfill:none%3Bstroke:%23fff%3Bstroke-width:1.547%7D.st3%7Bdisplay:none%7D%3C/style%3E%3Ccircle class='st0' cx='25' cy='25' r='24.5'/%3E%3Cpath class='st1' d='M25 50C11.2 50 0 38.8 0 25S11.2 0 25 0s25 11.2 25 25-11.2 25-25 25zm0-49C11.8 1 1 11.8 1 25s10.8 24 24 24 24-10.8 24-24S38.2 1 25 1z'/%3E%3Cpath class='st2' d='M30.5 14l-11 11 11 11'/%3E%3Cpath class='st3' d='M19.5 14l11 11-11 11'/%3E%3C/svg%3E"); }
      /* line 129, sass/components/_calendar.scss */
      .view .date-nav-wrapper .date-prev a:hover,
      .view .date-nav-wrapper .date-next a:hover {
        background-color: #002554; }
  /* line 134, sass/components/_calendar.scss */
  .view .date-nav-wrapper .date-prev {
    padding-right: .5em;
    left: 0;
    right: auto; }
  /* line 139, sass/components/_calendar.scss */
  .view .date-nav-wrapper .date-next {
    padding-left: .5em;
    left: auto;
    right: 0; }
    /* line 144, sass/components/_calendar.scss */
    .view .date-nav-wrapper .date-next a {
      background-image: url("../images/bg/next.png");
      background-image: url("data:image/svg+xml, %3Csvg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50' fill='rgb%28255%2C255%2C255%29'%3E%3Cstyle%3E.st0%7Bopacity:.5%3Bfill:%231b1b1c%7D.st1%7Bfill:%23fff%7D.st2%7Bdisplay:none%7D.st2%2C.st3%7Bfill:none%3Bstroke:%23fff%3Bstroke-width:1.547%7D%3C/style%3E%3Ccircle class='st0' cx='25' cy='25' r='24.5'/%3E%3Cpath class='st1' d='M25 50C11.2 50 0 38.8 0 25S11.2 0 25 0s25 11.2 25 25-11.2 25-25 25zm0-49C11.8 1 1 11.8 1 25s10.8 24 24 24 24-10.8 24-24S38.2 1 25 1z'/%3E%3Cpath class='st2' d='M30.5 14l-11 11 11 11'/%3E%3Cpath class='st3' d='M19.5 14l11 11-11 11'/%3E%3C/svg%3E"); }
  /* line 148, sass/components/_calendar.scss */
  .view .date-nav-wrapper .date-heading h3, .view .date-nav-wrapper .date-heading .node-type-recipient .field-name-field-title-long, .node-type-recipient .view .date-nav-wrapper .date-heading .field-name-field-title-long,
  .view .date-nav-wrapper .date-heading .node-type-staff .field-name-field-title-long, .node-type-staff .view .date-nav-wrapper .date-heading .field-name-field-title-long, .view .date-nav-wrapper .date-heading .front #paragraphs-item-5555 .group-right .section-title, .front #paragraphs-item-5555 .group-right .view .date-nav-wrapper .date-heading .section-title {
    font-size: 34px;
    font-size: 3.4rem;
    line-height: 1.41176; }

/* line 156, sass/components/_calendar.scss */
.view.view-display-id-panel_pane_quarter {
  width: 96%;
  margin-left: auto;
  margin-right: auto; }
  /* line 160, sass/components/_calendar.scss */
  .view.view-display-id-panel_pane_quarter .wrapper--content {
    display: flex;
    position: relative;
    justify-content: space-between; }
  /* line 165, sass/components/_calendar.scss */
  .view.view-display-id-panel_pane_quarter .wrapper--month {
    width: span(4);
    max-width: span(4); }
  /* line 169, sass/components/_calendar.scss */
  .view.view-display-id-panel_pane_quarter .date-nav-wrapper {
    margin: 0; }
    /* line 171, sass/components/_calendar.scss */
    .view.view-display-id-panel_pane_quarter .date-nav-wrapper .date-nav {
      min-width: auto;
      margin: 0;
      padding: 0; }
    /* line 176, sass/components/_calendar.scss */
    .view.view-display-id-panel_pane_quarter .date-nav-wrapper .date-next,
    .view.view-display-id-panel_pane_quarter .date-nav-wrapper .date-prev {
      top: -8px;
      top: -0.8rem; }
      /* line 179, sass/components/_calendar.scss */
      .view.view-display-id-panel_pane_quarter .date-nav-wrapper .date-next a,
      .view.view-display-id-panel_pane_quarter .date-nav-wrapper .date-prev a {
        height: 48px;
        height: 4.8rem;
        width: 48px;
        width: 4.8rem; }
  /* line 185, sass/components/_calendar.scss */
  .view.view-display-id-panel_pane_quarter .date-nav-wrapper,
  .view.view-display-id-panel_pane_quarter .date-nav-wrapper .date-nav,
  .view.view-display-id-panel_pane_quarter .date-nav-wrapper .date-heading,
  .view.view-display-id-panel_pane_quarter .item-list .pager, .view.view-display-id-panel_pane_quarter .pager, .view.view-display-id-panel_pane_quarter .pagination {
    position: static; }
  /* line 191, sass/components/_calendar.scss */
  .view.view-display-id-panel_pane_quarter h3, .view.view-display-id-panel_pane_quarter .node-type-recipient .field-name-field-title-long, .node-type-recipient .view.view-display-id-panel_pane_quarter .field-name-field-title-long,
  .view.view-display-id-panel_pane_quarter .node-type-staff .field-name-field-title-long, .node-type-staff .view.view-display-id-panel_pane_quarter .field-name-field-title-long, .view.view-display-id-panel_pane_quarter .front #paragraphs-item-5555 .group-right .section-title, .front #paragraphs-item-5555 .group-right .view.view-display-id-panel_pane_quarter .section-title, .view.view-display-id-panel_pane_quarter .gamma {
    text-align: center; }

/**
 * Colorbox Core Style:
 * The following CSS is consistent between example themes and should not be altered.
 */
/* line 5, sass/components/_colorbox.scss */
#colorbox, #cboxOverlay, #cboxWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden; }

/* line 13, sass/components/_colorbox.scss */
#colorbox {
  border-radius: 0; }

/* line 16, sass/components/_colorbox.scss */
#cboxOverlay {
  position: fixed;
  width: 100%;
  height: 100%; }

/* line 21, sass/components/_colorbox.scss */
#cboxMiddleLeft, #cboxBottomLeft {
  clear: left; }

/* line 24, sass/components/_colorbox.scss */
#cboxContent {
  position: relative; }

/* line 27, sass/components/_colorbox.scss */
#cboxLoadedContent {
  overflow: auto;
  -webkit-overflow-scrolling: touch; }

/* line 32, sass/components/_colorbox.scss */
#cboxLoadingOverlay, #cboxLoadingGraphic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

/**
 * These elements are buttons, and may need to have additional
 * styles reset to avoid unwanted base styles.
 */
/* line 43, sass/components/_colorbox.scss */
#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow {
  border: 0;
  padding: 0;
  margin: 0;
  overflow: visible;
  width: auto;
  background: none;
  cursor: pointer; }

/**
 * Avoid outlines on :active (mouseclick),
 * but preserve outlines on :focus (tabbed navigating)
 */
/* line 56, sass/components/_colorbox.scss */
#cboxPrevious:active, #cboxNext:active, #cboxClose:active, #cboxSlideshow:active {
  outline: 0; }

/* line 59, sass/components/_colorbox.scss */
.cboxPhoto {
  float: left;
  margin: auto;
  border: 0;
  display: block;
  max-width: none; }

/* line 66, sass/components/_colorbox.scss */
.cboxIframe {
  width: 100%;
  height: 100%;
  display: block;
  border: 0; }

/* Reset box sizing to content-box if theme is using border-box. */
/* line 73, sass/components/_colorbox.scss */
#colorbox, #cboxContent, #cboxLoadedContent {
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

/**
 * Colorbox module Stockholm syndrome style:
 * The styles are ordered & tabbed in a way that represents
 * the nesting of the generated HTML.
 */
/* line 84, sass/components/_colorbox.scss */
#cboxOverlay {
  background: #000; }

/* line 87, sass/components/_colorbox.scss */
#cboxWrapper {
  border-radius: 0; }

/* line 91, sass/components/_colorbox.scss */
#colorbox {
  background: #fff url(../images/bg_tab.png) center bottom repeat-x;
  -moz-box-shadow: 3px 3px 16px #333;
  -webkit-box-shadow: 3px 3px 16px #333;
  box-shadow: 3px 3px 16px #333;
  -moz-border-radius-bottomleft: 9px;
  -moz-border-radius-bottomright: 9px;
  -webkit-border-bottom-left-radius: 9px;
  -webkit-border-bottom-right-radius: 9px;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  outline: 0; }

/* line 104, sass/components/_colorbox.scss */
#colorbox, #colorbox div {
  overflow: visible;
  /* Required by the close button. */ }

/* line 107, sass/components/_colorbox.scss */
#cboxWrapper {
  -moz-border-radius-bottomleft: 9px;
  -moz-border-radius-bottomright: 9px;
  -webkit-border-bottom-left-radius: 9px;
  -webkit-border-bottom-right-radius: 9px;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px; }

/* line 115, sass/components/_colorbox.scss */
#cboxTopLeft {
  width: 0;
  height: 0; }

/* line 119, sass/components/_colorbox.scss */
#cboxTopCenter {
  height: 0; }

/* line 122, sass/components/_colorbox.scss */
#cboxTopRight {
  width: 0;
  height: 0; }

/* line 126, sass/components/_colorbox.scss */
#cboxBottomLeft {
  width: 15px;
  height: 10px; }

/* line 130, sass/components/_colorbox.scss */
#cboxBottomCenter {
  height: 10px; }

/* line 133, sass/components/_colorbox.scss */
#cboxBottomRight {
  width: 15px;
  height: 10px; }

/* line 137, sass/components/_colorbox.scss */
#cboxMiddleLeft {
  width: 0; }

/* line 140, sass/components/_colorbox.scss */
#cboxMiddleRight {
  width: 0; }

/* line 143, sass/components/_colorbox.scss */
#cboxContent {
  background: #fff;
  overflow: hidden;
  margin-bottom: 24px; }

/* line 148, sass/components/_colorbox.scss */
#cboxError {
  padding: 50px;
  border: 1px solid #ccc; }

/* line 152, sass/components/_colorbox.scss */
#cboxLoadedContent {
  margin-bottom: 0; }

/* line 155, sass/components/_colorbox.scss */
#cboxTitle {
  bottom: 0; }

/* line 165, sass/components/_colorbox.scss */
#cboxCurrent {
  position: absolute;
  bottom: -24px;
  bottom: -2.4rem;
  color: #333F48;
  font-size: 14px;
  font-size: 1.4rem; }

/* Slideshow not implemented. */
/* line 174, sass/components/_colorbox.scss */
.cboxSlideshow_on #cboxSlideshow {
  display: none; }

/* line 179, sass/components/_colorbox.scss */
.cboxSlideshow_off #cboxSlideshow {
  display: none; }

/* line 184, sass/components/_colorbox.scss */
#cboxPrevious {
  position: absolute;
  bottom: -20px;
  bottom: -2rem;
  background: url(../images/controls.png) no-repeat 0 -48px;
  width: 21px;
  height: 15px;
  text-indent: -9999px; }

/* line 192, sass/components/_colorbox.scss */
#cboxPrevious:hover {
  background-position: 0 -111px; }

/* line 195, sass/components/_colorbox.scss */
#cboxNext {
  position: absolute;
  bottom: -20px;
  bottom: -2rem;
  background: url(../images/controls.png) no-repeat 0 -29px;
  width: 21px;
  height: 15px;
  text-indent: -9999px; }

/* line 203, sass/components/_colorbox.scss */
#cboxNext:hover {
  background-position: 0 -92px; }

/* line 206, sass/components/_colorbox.scss */
#cboxLoadingOverlay {
  background: #e6e6e6; }

/* line 209, sass/components/_colorbox.scss */
#cboxLoadingGraphic {
  background: url(../images/preloader_160x160.gif) no-repeat center center; }

/* line 212, sass/components/_colorbox.scss */
#cboxClose {
  position: absolute;
  top: -10px;
  right: -10px;
  background: url(../images/controls.png) no-repeat 0px 0px;
  width: 25px;
  height: 25px;
  text-indent: -9999px; }

/* line 221, sass/components/_colorbox.scss */
#cboxClose:hover {
  background-position: 0 -63px; }

/* line 2, sass/components/_fields.scss */
.field-label-inline .field-label {
  display: inline-block;
  float: none;
  margin-right: 10px;
  margin-right: 1rem; }

/* line 9, sass/components/_fields.scss */
.field-type-image {
  line-height: 0; }

/* line 12, sass/components/_fields.scss */
.field-name-content-type,
.field-name-book-parent-content-type {
  margin-bottom: 12px;
  margin-bottom: 1.2rem;
  font-style: italic; }

/* line 19, sass/components/_fields.scss */
.field-name-field-tagline {
  font-family: "MinionPro", Georgia, Times, "Times New Roman", serif;
  font-style: italic;
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1.5;
  margin-top: 12px;
  margin-top: 1.2rem;
  color: #101820; }

/* line 29, sass/components/_fields.scss */
.inline-block > div {
  display: inline-block; }

/* line 33, sass/components/_fields.scss */
.field-name-field-media-provider {
  margin: 12px 0 24px;
  margin: 1.2rem 0 2.4rem;
  font-style: italic;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.71429; }

/* line 40, sass/components/_fields.scss */
.field-name-field-soundcloud {
  margin-bottom: 12px;
  margin-bottom: 1.2rem; }
  /* line 42, sass/components/_fields.scss */
  .field-name-field-soundcloud iframe {
    width: 100%; }

/* line 46, sass/components/_fields.scss */
.field-name-field-block .sf-menu.menu {
  float: none; }
  /* line 48, sass/components/_fields.scss */
  .field-name-field-block .sf-menu.menu a {
    padding: 0; }
  /* line 51, sass/components/_fields.scss */
  .field-name-field-block .sf-menu.menu li {
    background: none;
    margin: 12px 0 12px 20px;
    margin: 1.2rem 0 1.2rem 2rem;
    float: none; }
    /* line 55, sass/components/_fields.scss */
    .field-name-field-block .sf-menu.menu li.sf-depth-2 {
      margin-left: 0; }
  /* line 59, sass/components/_fields.scss */
  .field-name-field-block .sf-menu.menu > li {
    float: none; }
    /* line 61, sass/components/_fields.scss */
    .field-name-field-block .sf-menu.menu > li > ul {
      margin-left: 20px;
      margin-left: 2rem; }
  /* line 66, sass/components/_fields.scss */
  .field-name-field-block .sf-menu.menu ul {
    position: static;
    display: block; }
  /* line 70, sass/components/_fields.scss */
  .field-name-field-block .sf-menu.menu li,
  .field-name-field-block .sf-menu.menu ul li {
    background: none; }
  /* line 74, sass/components/_fields.scss */
  .field-name-field-block .sf-menu.menu li li {
    list-style-type: disc; }
    /* line 76, sass/components/_fields.scss */
    .field-name-field-block .sf-menu.menu li li li {
      list-style-type: circle; }
      /* line 78, sass/components/_fields.scss */
      .field-name-field-block .sf-menu.menu li li li li {
        list-style-type: square; }

/* line 87, sass/components/_fields.scss */
.field-type-entityreference > a {
  display: block;
  margin-bottom: 12px;
  margin-bottom: 1.2rem; }

/* line 93, sass/components/_fields.scss */
.field-name-field-contact {
  margin-bottom: 24px;
  margin-bottom: 2.4rem; }
  /* line 96, sass/components/_fields.scss */
  .field-name-field-contact .node > div {
    display: inline-block; }
    /* line 98, sass/components/_fields.scss */
    .field-name-field-contact .node > div:after {
      content: ' • ';
      font-size: 14px;
      font-size: 1.4rem;
      color: #6c7a89; }
  /* line 104, sass/components/_fields.scss */
  .field-name-field-contact .node .field-name-field-title {
    margin-right: 5px;
    margin-right: 0.5rem;
    font-weight: 600; }
  /* line 111, sass/components/_fields.scss */
  .field-name-field-contact .node > div:last-child:after,
  .field-name-field-contact .node .contextual-links-wrapper:after,
  .field-name-field-contact .node .field-name-field-title:after {
    content: ''; }

/* line 125, sass/components/_fields.scss */
.view-mode-grid .file-image {
  border: 1px solid #D0D7DE; }
  /* line 127, sass/components/_fields.scss */
  .view-mode-grid .file-image .group-meta {
    padding: 12px 5px;
    padding: 1.2rem 0.5rem;
    display: flex;
    justify-content: space-between; }
  /* line 132, sass/components/_fields.scss */
  .view-mode-grid .file-image .download {
    margin-left: 10px;
    margin-left: 1rem; }

/* line 137, sass/components/_fields.scss */
.expand-icon {
  background-image: url("../images/bg/expand.png");
  background-image: url("data:image/svg+xml, %3Csvg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' fill='rgb%28255%2C255%2C255%29'%3E%3Cstyle%3E.st0%7Bfill:%23333f48%7D%3C/style%3E%3Cpath class='st0' d='M20.4 24.1L7.6 11.3 0 18.8V0h19l-7.5 7.5L28 24.1h-7.6zm15.7.1L52.6 7.5 45 0h19v18.9l-7.6-7.5-12.8 12.8h-7.5zm7.5 15.7l12.8 12.8 7.6-7.5V64H45l7.5-7.5-16.4-16.6h7.5zm-15.7-.1L11.4 56.4l7.5 7.5H0V45.1l7.6 7.5 12.8-12.8h7.5z'/%3E%3C/svg%3E");
  display: block;
  height: 20px;
  height: 2rem;
  width: 20px;
  width: 2rem; }

/* line 143, sass/components/_fields.scss */
span.file > a,
.download a,
a.download {
  position: relative;
  display: inline-block;
  padding-left: 25px;
  padding-left: 2.5rem; }
  /* line 68, sass/helpers/_icon.scss */
  span.file > a:before,
  .download a:before,
  a.download:before {
    content: "";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: inherit;
    position: absolute;
    font-size: 17px;
    font-size: 1.7rem;
    top: 2px;
    top: 0.2rem;
    left: 0; }
  /* line 80, sass/helpers/_icon.scss */
  span.file > a:hover:before,
  .download a:hover:before,
  a.download:hover:before {
    color: inherit; }

/* line 150, sass/components/_fields.scss */
.file-download-link .file-size,
.file .file-size {
  display: block;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 2;
  color: #505a65;
  padding-left: 25px;
  padding-left: 2.5rem; }

/* line 159, sass/components/_fields.scss */
.mediaelement-download-link {
  margin-top: 12px;
  margin-top: 1.2rem; }
  /* line 161, sass/components/_fields.scss */
  .mediaelement-download-link a {
    position: relative;
    display: inline-block;
    padding-left: 25px;
    padding-left: 2.5rem; }
    /* line 68, sass/helpers/_icon.scss */
    .mediaelement-download-link a:before {
      content: "";
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
      color: inherit;
      position: absolute;
      font-size: 17px;
      font-size: 1.7rem;
      top: 2px;
      top: 0.2rem;
      left: 0; }
    /* line 80, sass/helpers/_icon.scss */
    .mediaelement-download-link a:hover:before {
      color: inherit; }

/* line 166, sass/components/_fields.scss */
.field-name-field-transcripts {
  margin: 24px 0;
  margin: 2.4rem 0; }
  /* line 171, sass/components/_fields.scss */
  .field-name-field-transcripts .si-tabs.accordion .card .tab-pane {
    border: none; }
  /* line 175, sass/components/_fields.scss */
  .field-name-field-transcripts .si-tabs.accordion .card-header .btn {
    background: none;
    color: #333F48;
    font-size: 21px;
    font-size: 2.1rem;
    padding-top: 0;
    padding-bottom: 0;
    border: none; }
    /* line 182, sass/components/_fields.scss */
    .field-name-field-transcripts .si-tabs.accordion .card-header .btn:before {
      top: 6px;
      top: 0.6rem; }
    /* line 185, sass/components/_fields.scss */
    .field-name-field-transcripts .si-tabs.accordion .card-header .btn:hover, .field-name-field-transcripts .si-tabs.accordion .card-header .btn:focus {
      background: none;
      color: #9f3050; }
    /* line 190, sass/components/_fields.scss */
    .field-name-field-transcripts .si-tabs.accordion .card-header .btn.collapsed {
      background: none;
      border: none; }
      /* line 193, sass/components/_fields.scss */
      .field-name-field-transcripts .si-tabs.accordion .card-header .btn.collapsed:before {
        top: 4px;
        top: 0.4rem; }

/* line 206, sass/components/_fields.scss */
.field-type-taxonomy-term-reference a:after {
  content: ' • ';
  font-size: 14px;
  font-size: 1.4rem;
  color: inherit; }

/* line 212, sass/components/_fields.scss */
.field-type-taxonomy-term-reference a:last-child:after {
  content: ''; }

/* line 1, sass/components/_flickr-photos.scss */
.flickr-wrap {
  display: block;
  position: relative; }

/* line 6, sass/components/_flickr-photos.scss */
span.flickr-credit {
  padding: 12px 10px;
  padding: 1.2rem 1rem;
  line-height: 1.5; }

/* line 11, sass/components/_flickr-photos.scss */
.paragraphs-item-flickr-slideshow .flickr-wrap {
  background: #eef0f3;
  display: flex;
  justify-content: space-between; }

/* line 16, sass/components/_flickr-photos.scss */
.paragraphs-item-flickr-slideshow .flickr-credit {
  text-align: center; }

/* line 22, sass/components/_flickr-photos.scss */
.paragraphs-item-flickr-grid .flickr-wrap,
.flickr-albums .flickr-wrap {
  margin: 0; }
  /* line 24, sass/components/_flickr-photos.scss */
  .paragraphs-item-flickr-grid .flickr-wrap.overlay-processed,
  .flickr-albums .flickr-wrap.overlay-processed {
    overflow: hidden; }
  /* line 28, sass/components/_flickr-photos.scss */
  .paragraphs-item-flickr-grid .flickr-wrap img,
  .flickr-albums .flickr-wrap img {
    display: block;
    width: 100% !important;
    height: auto !important;
    min-height: 1px; }

/* line 38, sass/components/_flickr-photos.scss */
.paragraphs-item-flickr-grid .flickr-credit,
.flickr-albums .flickr-credit {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 6px;
  bottom: 0.6rem;
  left: 0;
  right: 5px;
  line-height: 24px;
  color: white; }
  /* line 46, sass/components/_flickr-photos.scss */
  .paragraphs-item-flickr-grid .flickr-credit *,
  .flickr-albums .flickr-credit * {
    color: white; }

/* line 51, sass/components/_flickr-photos.scss */
.paragraphs-item-flickr-grid .teaser-long.animate .hovertext,
.flickr-albums .teaser-long.animate .hovertext {
  font-size: 14px;
  font-size: 1.4rem; }
  /* line 53, sass/components/_flickr-photos.scss */
  .paragraphs-item-flickr-grid .teaser-long.animate .hovertext .metatdata,
  .flickr-albums .teaser-long.animate .hovertext .metatdata {
    display: block; }

/* line 56, sass/components/_flickr-photos.scss */
.paragraphs-item-flickr-grid .overlay-processed .flickr-credit,
.flickr-albums .overlay-processed .flickr-credit {
  -webkit-transition: bottom .3s, opacity .6s;
  transition: bottom .3s, opacity .6s;
  opacity: 0;
  bottom: -999px;
  overflow: hidden; }

/* line 64, sass/components/_flickr-photos.scss */
.paragraphs-item-flickr-grid .overlay-processed.open .flickr-credit,
.flickr-albums .overlay-processed.open .flickr-credit {
  opacity: 1;
  bottom: 6px; }

/* line 68, sass/components/_flickr-photos.scss */
.paragraphs-item-flickr-grid .overlay-processed.open img,
.flickr-albums .overlay-processed.open img {
  transform: perspective(1px) scale(1.03);
  transition-duration: 2s; }

/* line 72, sass/components/_flickr-photos.scss */
.paragraphs-item-flickr-grid .overlay-processed.open .b-text-wrapper,
.flickr-albums .overlay-processed.open .b-text-wrapper {
  transform: perspective(1px) translateY(-10px);
  transition: all 100ms ease-out;
  opacity: 0; }

/* line 77, sass/components/_flickr-photos.scss */
.paragraphs-item-flickr-grid .overlay-processed.open .hovertext,
.flickr-albums .overlay-processed.open .hovertext {
  transform: perspective(1px) translateY(0);
  opacity: 1;
  bottom: 0; }

@media (min-width: 550px) {
  /* line 89, sass/components/_flickr-photos.scss */
  .flickr-albums,
  .paragraphs-item-flickr-grid .flickr-photoset {
    *zoom: 1; }
    /* line 15, sass/helpers/_clearfix.scss */
    .flickr-albums:before, .flickr-albums:after,
    .paragraphs-item-flickr-grid .flickr-photoset:before,
    .paragraphs-item-flickr-grid .flickr-photoset:after {
      content: " ";
      display: table;
      line-height: 0; }
    /* line 21, sass/helpers/_clearfix.scss */
    .flickr-albums:after,
    .paragraphs-item-flickr-grid .flickr-photoset:after {
      clear: both; }
    /* line 92, sass/components/_flickr-photos.scss */
    .flickr-albums .flickr-wrap,
    .paragraphs-item-flickr-grid .flickr-photoset .flickr-wrap {
      padding: 0 5px 5px 0;
      padding: 0 0.5rem 0.5rem 0;
      margin: 0; }
  /* line 100, sass/components/_flickr-photos.scss */
  .paragraphs-item-flickr-slideshow .flickr-wrap.grid-item {
    margin: 0 5px;
    margin: 0 0.5rem; } }

/* line 1, sass/components/_footer.scss */
footer {
  border-top: 6px solid #0047A0;
  background: #333F48 url(../images/bg/footer-bg-sm.jpg) no-repeat 50% 50%;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.65); }
  /* line 5, sass/components/_footer.scss */
  .maintenance-page footer {
    background-image: none; }
  /* line 11, sass/components/_footer.scss */
  footer > .inner {
    padding: 24px 0 0;
    padding: 2.4rem 0 0;
    background: rgba(0, 0, 0, 0.7); }
  /* line 15, sass/components/_footer.scss */
  footer .teaser a,
  footer a {
    color: white; }
    /* line 147, sass/helpers/_text.scss */
    footer .teaser a:visited,
    footer a:visited {
      color: white; }
    /* line 152, sass/helpers/_text.scss */
    footer .teaser a:focus,
    footer a:focus {
      color: white; }
    /* line 157, sass/helpers/_text.scss */
    footer .teaser a:hover,
    footer a:hover {
      color: #ffd400; }
    /* line 162, sass/helpers/_text.scss */
    footer .teaser a:active,
    footer a:active {
      color: #ffd400; }
  /* line 20, sass/components/_footer.scss */
  footer, footer .h2, footer .h3, footer .h4 {
    color: white; }
  /* line 23, sass/components/_footer.scss */
  footer p {
    margin: 0; }
  /* line 26, sass/components/_footer.scss */
  footer .h2, footer .h3, footer .h4 {
    margin-bottom: 12px;
    margin-bottom: 1.2rem; }
  /* line 29, sass/components/_footer.scss */
  footer .teaser .b-text-wrapper {
    padding: 0; }
  /* line 30, sass/components/_footer.scss */
  footer .menu {
    display: grid;
    grid-gap: 12px;
    grid-gap: 1.2rem;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); }
    /* line 38, sass/components/_footer.scss */
    footer .menu li {
      flex: 1 1 auto; }
      /* line 39, sass/components/_footer.scss */
      .no-flexbox footer .menu li {
        float: left; }
    /* line 44, sass/components/_footer.scss */
    footer .menu a {
      display: block; }
  /* line 48, sass/components/_footer.scss */
  footer .fa {
    font-size: 21px;
    font-size: 2.1rem;
    line-height: 1.71429;
    color: #ffd400; }
    /* line 147, sass/helpers/_text.scss */
    footer .fa:visited {
      color: #ffd400; }
    /* line 152, sass/helpers/_text.scss */
    footer .fa:focus {
      color: #ffd400; }
    /* line 157, sass/helpers/_text.scss */
    footer .fa:hover {
      color: white; }
    /* line 162, sass/helpers/_text.scss */
    footer .fa:active {
      color: white; }
  /* line 54, sass/components/_footer.scss */
  footer .block, footer .view {
    margin-bottom: 24px;
    margin-bottom: 2.4rem; }
    /* line 56, sass/components/_footer.scss */
    footer .block:last-child, footer .view:last-child {
      margin-bottom: 0; }
  /* line 60, sass/components/_footer.scss */
  footer .owney {
    text-align: center; }
    /* line 62, sass/components/_footer.scss */
    footer .owney .disclaimer {
      display: none; }
  /* line 76, sass/components/_footer.scss */
  footer #block-menu-menu-social-media .menu {
    margin: 0 auto;
    align-items: center;
    text-align: center; }
  /* line 82, sass/components/_footer.scss */
  footer .region-footer-top {
    border-bottom: 1px solid #666;
    padding-bottom: 24px;
    padding-bottom: 2.4rem;
    margin-bottom: 24px;
    margin-bottom: 2.4rem; }
  /* line 88, sass/components/_footer.scss */
  footer .site-logo {
    display: block;
    font-family: "MinionPro", Georgia, Times, "Times New Roman", serif; }
    /* line 91, sass/components/_footer.scss */
    footer .site-logo span {
      padding-left: 45px;
      padding-left: 4.5rem;
      display: block; }
    /* line 95, sass/components/_footer.scss */
    footer .site-logo .logo-icon {
      width: 35px;
      height: 35px;
      padding: 0; }
  /* line 101, sass/components/_footer.scss */
  footer .si-name {
    font-size: 21px;
    font-size: 2.1rem;
    line-height: 1; }
  /* line 105, sass/components/_footer.scss */
  footer .site-name {
    line-height: 1.2;
    font-style: italic; }
  /* line 111, sass/components/_footer.scss */
  footer .view-promotion .field-name-field-text-block {
    display: flex;
    justify-content: space-between; }
  /* line 115, sass/components/_footer.scss */
  footer .view-promotion .info {
    width: 50%; }
  /* line 116, sass/components/_footer.scss */
  footer .view-promotion .map-info {
    width: 45%; }
  @media (min-width: 768px) {
    /* line 1, sass/components/_footer.scss */
    footer {
      padding-bottom: 0;
      background: #333F48 url(../images/bg/footer-bg-lg.jpg) no-repeat 50% 50%; }
      /* line 122, sass/components/_footer.scss */
      footer .region-footer-top {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-column-gap: 24px;
        grid-column-gap: 2.4rem; }
        /* line 126, sass/components/_footer.scss */
        footer .region-footer-top .block {
          grid-column-end: span 4; }
        /* line 129, sass/components/_footer.scss */
        footer .region-footer-top .view-promotion {
          grid-column-end: span 8; }
          /* line 131, sass/components/_footer.scss */
          footer .region-footer-top .view-promotion .info {
            width: 70%; }
          /* line 132, sass/components/_footer.scss */
          footer .region-footer-top .view-promotion .map-info {
            width: 27%; }
        /* line 134, sass/components/_footer.scss */
        footer .region-footer-top .block-menu {
          grid-column-end: span 12; }
      /* line 138, sass/components/_footer.scss */
      footer .menu {
        justify-items: center; }
      /* line 142, sass/components/_footer.scss */
      footer .site-logo .logo-icon {
        width: 45px;
        height: 45px; }
      /* line 146, sass/components/_footer.scss */
      footer .site-logo span {
        padding-left: 55px;
        padding-left: 5.5rem; }
      /* line 151, sass/components/_footer.scss */
      footer .si-name {
        font-size: 28px;
        font-size: 2.8rem;
        padding-top: 4px;
        padding-top: 0.4rem;
        line-height: 1; }
      /* line 156, sass/components/_footer.scss */
      footer .site-name {
        font-size: 19px;
        font-size: 1.9rem;
        line-height: 1.2;
        font-style: italic; }
      /* line 161, sass/components/_footer.scss */
      footer .owney {
        background-image: url(../images/owney.png);
        background-repeat: no-repeat;
        background-position: 10px 100%;
        height: 240px;
        height: 24rem;
        width: 340px;
        width: 34rem;
        margin-top: 24px;
        margin-top: 2.4rem;
        position: relative; }
        /* line 169, sass/components/_footer.scss */
        footer .owney .disclaimer {
          display: block;
          font-size: 14px;
          font-size: 1.4rem;
          font-family: "MinionPro", Georgia, Times, "Times New Roman", serif;
          position: absolute;
          bottom: 24px;
          bottom: 2.4rem;
          right: 35px;
          right: 3.5rem;
          width: 120px;
          width: 12rem; }
        /* line 179, sass/components/_footer.scss */
        footer .owney .disclaimer,
        footer .owney .bubble {
          line-height: 1.25; }
        /* line 183, sass/components/_footer.scss */
        footer .owney .bubble-wrapper {
          background-image: url(../images/bkg_word_bubble_owney.png);
          background-repeat: no-repeat;
          height: 118px;
          height: 11.8rem;
          width: 188px;
          width: 18.8rem;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          text-shadow: none;
          top: 6px;
          top: 0.6rem;
          right: -4px;
          right: -0.4rem;
          padding-bottom: 12px;
          padding-bottom: 1.2rem; }
        /* line 249, sass/components/_footer.scss */
        footer .owney .bubble {
          color: #333F48;
          padding: 12px 10px 0 18px;
          padding: 1.2rem 1rem 0 1.8rem;
          font-size: 14px;
          font-size: 1.4rem; }
          /* line 253, sass/components/_footer.scss */
          footer .owney .bubble a {
            color: #0177A8; }
            /* line 147, sass/helpers/_text.scss */
            footer .owney .bubble a:visited {
              color: #0177A8; }
            /* line 152, sass/helpers/_text.scss */
            footer .owney .bubble a:focus {
              color: #0177A8; }
            /* line 157, sass/helpers/_text.scss */
            footer .owney .bubble a:hover {
              color: #9f3050; }
            /* line 162, sass/helpers/_text.scss */
            footer .owney .bubble a:active {
              color: #9f3050; } }
  @media (min-width: 980px) {
    /* line 1, sass/components/_footer.scss */
    footer {
      background: #333F48 url(../images/bg/footer-bg-wd.jpg) no-repeat 50% 50%; }
      /* line 261, sass/components/_footer.scss */
      footer .region-footer-top {
        grid-column-gap: 40px;
        grid-column-gap: 4rem; }
        /* line 263, sass/components/_footer.scss */
        footer .region-footer-top .block {
          grid-column-end: span 3; }
        /* line 266, sass/components/_footer.scss */
        footer .region-footer-top .view-promotion {
          grid-column-end: span 5; }
          /* line 268, sass/components/_footer.scss */
          footer .region-footer-top .view-promotion .field-name-field-text-block {
            display: flex;
            justify-content: space-between; }
          /* line 272, sass/components/_footer.scss */
          footer .region-footer-top .view-promotion .info {
            width: 70%; }
          /* line 273, sass/components/_footer.scss */
          footer .region-footer-top .view-promotion .map-info {
            width: 27%; }
        /* line 275, sass/components/_footer.scss */
        footer .region-footer-top .block-menu {
          grid-column-end: span 4; }
      /* line 279, sass/components/_footer.scss */
      footer .region-footer-bottom {
        display: grid;
        grid-column-gap: 40px;
        grid-column-gap: 4rem;
        grid-template-columns: repeat(12, 1fr);
        justify-items: center; }
        /* line 284, sass/components/_footer.scss */
        footer .region-footer-bottom .block-menu {
          grid-column-end: span 11;
          width: 100%; }
          /* line 287, sass/components/_footer.scss */
          footer .region-footer-bottom .block-menu .menu {
            display: flex; }
      /* line 292, sass/components/_footer.scss */
      footer .fa {
        font-size: 24px;
        font-size: 2.4rem;
        line-height: 1.5; }
      /* line 296, sass/components/_footer.scss */
      footer .owney {
        height: 200px;
        height: 20rem; }
        /* line 298, sass/components/_footer.scss */
        footer .owney .bubble-wrapper {
          top: -32px;
          top: -3.2rem; }
        /* line 301, sass/components/_footer.scss */
        footer .owney .disclaimer {
          right: 60px;
          right: 6rem; } }
  @media (min-width: 1280px) {
    /* line 308, sass/components/_footer.scss */
    footer .site-logo .logo-icon {
      width: 55px;
      height: 55px; }
    /* line 312, sass/components/_footer.scss */
    footer .site-logo span {
      padding-left: 65px;
      padding-left: 6.5rem; }
    /* line 316, sass/components/_footer.scss */
    footer .owney .bubble {
      padding: 0 40px 0 18px;
      padding: 0 4rem 0 1.8rem; } }

/* line 10, sass/components/_header.scss */
header {
  position: relative;
  background: #333F48; }
  /* line 13, sass/components/_header.scss */
  header p {
    margin: 0; }
  /* line 16, sass/components/_header.scss */
  header .btn-burgundy {
    height: 35px;
    height: 3.5rem;
    line-height: 1.5;
    margin-bottom: 0; }
  /* line 22, sass/components/_header.scss */
  header .captcha-admin-links,
  header .block-gtranslate {
    display: none; }

/* line 36, sass/components/_header.scss */
.header-preface-wrapper {
  background: #202023; }
  /* line 38, sass/components/_header.scss */
  .header-preface-wrapper * {
    color: white; }
  /* line 41, sass/components/_header.scss */
  .header-preface-wrapper .inner {
    align-items: center;
    display: flex;
    justify-content: space-between; }

/* line 47, sass/components/_header.scss */
.region-header-preface-left,
.region-header-preface-right {
  padding: 6px 0;
  padding: 0.6rem 0; }

/* line 51, sass/components/_header.scss */
.region-header-preface-left {
  padding: 10px;
  padding: 1rem; }
  /* line 53, sass/components/_header.scss */
  .region-header-preface-left a {
    color: white; }
    /* line 147, sass/helpers/_text.scss */
    .region-header-preface-left a:visited {
      color: white; }
    /* line 152, sass/helpers/_text.scss */
    .region-header-preface-left a:focus {
      color: white; }
    /* line 157, sass/helpers/_text.scss */
    .region-header-preface-left a:hover {
      color: #F0B323; }
    /* line 162, sass/helpers/_text.scss */
    .region-header-preface-left a:active {
      color: #F0B323; }

/* line 57, sass/components/_header.scss */
.region-header-preface-right {
  display: flex; }
  /* line 59, sass/components/_header.scss */
  .region-header-preface-right p {
    line-height: 1; }
  /* line 62, sass/components/_header.scss */
  .region-header-preface-right > .block {
    margin-left: 10px;
    margin-left: 1rem; }
    /* line 64, sass/components/_header.scss */
    .region-header-preface-right > .block:first-child {
      margin-left: 0; }

/* line 70, sass/components/_header.scss */
.header-wrapper {
  position: relative; }

/* line 77, sass/components/_header.scss */
.wrapper--main-menu {
  position: absolute;
  right: 2%;
  top: 12px; }

/* line 83, sass/components/_header.scss */
.branding {
  padding: 10px 0;
  padding: 1rem 0; }
  /* line 85, sass/components/_header.scss */
  .branding a {
    color: white; }
    /* line 147, sass/helpers/_text.scss */
    .branding a:visited {
      color: white; }
    /* line 152, sass/helpers/_text.scss */
    .branding a:focus {
      color: white; }
    /* line 157, sass/helpers/_text.scss */
    .branding a:hover {
      color: white; }
    /* line 162, sass/helpers/_text.scss */
    .branding a:active {
      color: white; }

/* line 88, sass/components/_header.scss */
.branding-icon-wrapper {
  font-family: "MinionPro", Georgia, Times, "Times New Roman", serif;
  font-weight: normal;
  margin: 0; }
  /* line 92, sass/components/_header.scss */
  .branding-icon-wrapper .site-logo {
    display: flex;
    flex-wrap: nowrap; }
  /* line 96, sass/components/_header.scss */
  .branding-icon-wrapper span {
    display: block; }
  /* line 99, sass/components/_header.scss */
  .branding-icon-wrapper .site-info-wrapper {
    padding: 3px 0 0 10px;
    padding: 0.3rem 0 0 1rem; }
  /* line 102, sass/components/_header.scss */
  .branding-icon-wrapper .logo-icon {
    width: 57px;
    height: 57px; }
  /* line 106, sass/components/_header.scss */
  .branding-icon-wrapper .si-name {
    font-size: 28px;
    font-size: 2.8rem;
    line-height: 1; }
  /* line 110, sass/components/_header.scss */
  .branding-icon-wrapper .site-name {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.2;
    font-style: italic; }

@media (min-width: 768px) {
  /* line 119, sass/components/_header.scss */
  header .block-gtranslate {
    display: block;
    position: absolute;
    right: 0;
    top: 9px;
    top: 0.9rem; }
  /* line 125, sass/components/_header.scss */
  header .switcher .selected a {
    padding: 7px 5px; }
  /* line 130, sass/components/_header.scss */
  .region-header-preface-right {
    padding-right: 173px;
    position: relative; }
  /* line 134, sass/components/_header.scss */
  .header-wrapper > .inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-end; }
  /* line 140, sass/components/_header.scss */
  .branding {
    overflow: hidden; }
  /* line 144, sass/components/_header.scss */
  .region-header-preface-right {
    align-items: center; }
  /* line 149, sass/components/_header.scss */
  #page-wrapper .branding-icon-wrapper .logo-icon {
    width: 66px;
    width: 6.6rem;
    height: 66px;
    height: 6.6rem; }
  /* line 153, sass/components/_header.scss */
  #page-wrapper .branding-icon-wrapper .site-info-wrapper {
    padding-top: 8px;
    padding-top: 0.8rem; }
  /* line 156, sass/components/_header.scss */
  #page-wrapper .branding-icon-wrapper .si-name {
    font-size: 30px;
    font-size: 3rem; }
  /* line 159, sass/components/_header.scss */
  #page-wrapper .branding-icon-wrapper .site-name {
    font-size: 21px;
    font-size: 2.1rem; } }

@media (min-width: 980px) {
  /* line 167, sass/components/_header.scss */
  .branding {
    width: 30%;
    max-width: 30%; }
  /* line 171, sass/components/_header.scss */
  .wrapper--main-menu {
    position: static;
    right: auto;
    top: auto; }
  /* line 176, sass/components/_header.scss */
  .region-header {
    width: 67%;
    max-width: 67%; }
  /* line 181, sass/components/_header.scss */
  .header-wrapper {
    position: relative; } }

@media (min-width: 1600px) {
  /* line 191, sass/components/_header.scss */
  .header-preface-wrapper > .inner {
    grid-column-gap: 40px;
    grid-column-gap: 4rem; } }

/* line 6, sass/components/_hero.scss */
.cssgradients .overlay {
  position: absolute;
  bottom: -24px;
  bottom: -2.4rem;
  left: 0;
  right: 0;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+70,000000+100&0+0,0.4+70,0.98+100 */
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 60%, rgba(0, 0, 0, 0.95) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 60%, rgba(0, 0, 0, 0.95) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 60%, rgba(0, 0, 0, 0.95) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#fa000000',GradientType=0 );
  /* IE6-9 */
  height: 50%; }

@media screen and (min-width: 0) {
  /* line 19, sass/components/_hero.scss */
  .pane-page-header-image {
    height: 75vw;
    max-height: 450px;
    position: relative;
    overflow: hidden; }
    /* line 26, sass/components/_hero.scss */
    .pane-page-header-image .pane-node-field-image,
    .pane-page-header-image .bg-image {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover; }
    /* line 38, sass/components/_hero.scss */
    .pane-page-header-image .panel-region-main {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 24px;
      bottom: 2.4rem;
      height: 52.5vw;
      max-height: 315px;
      text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.65);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 10; }
      /* line 51, sass/components/_hero.scss */
      .pane-page-header-image .panel-region-main * {
        color: white; }
      /* line 54, sass/components/_hero.scss */
      .pane-page-header-image .panel-region-main .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 10; } }

@media screen and (min-width: 768px) {
  /* line 19, sass/components/_hero.scss */
  .pane-page-header-image {
    height: 56vw; }
    /* line 66, sass/components/_hero.scss */
    .pane-page-header-image .panel-region-main {
      height: 40vw; }
    /* line 69, sass/components/_hero.scss */
    .pane-page-header-image .container {
      padding-top: 5%; } }

@media screen and (min-width: 980px) {
  /* line 19, sass/components/_hero.scss */
  .pane-page-header-image {
    height: 33vw;
    max-height: 533px; }
    /* line 78, sass/components/_hero.scss */
    .pane-page-header-image .panel-region-main {
      height: 23vw;
      max-height: 373px; }
    /* line 82, sass/components/_hero.scss */
    .pane-page-header-image h1 {
      font-size: 54px;
      font-size: 5.4rem;
      line-height: 1.33333; } }

@media screen and (min-width: 1600px) {
  /* line 19, sass/components/_hero.scss */
  .pane-page-header-image {
    height: 24vw;
    max-height: 610px; }
    /* line 91, sass/components/_hero.scss */
    .pane-page-header-image .panel-region-main {
      height: 17vw;
      max-height: 430px; }
    /* line 95, sass/components/_hero.scss */
    .pane-page-header-image .overlay {
      height: 300px;
      height: 30rem; } }

/* line 2, sass/components/_lists.scss */
.inline-list li {
  display: inline-block; }

/* line 10, sass/components/_lists.scss */
.float-list {
  *zoom: 1; }
  /* line 15, sass/helpers/_clearfix.scss */
  .float-list:before, .float-list:after {
    content: " ";
    display: table;
    line-height: 0; }
  /* line 21, sass/helpers/_clearfix.scss */
  .float-list:after {
    clear: both; }
  /* line 13, sass/components/_lists.scss */
  .float-list li {
    display: block;
    float: left; }

/* Social List */
/* line 28, sass/components/_lists.scss */
li.spacing,
li.spacing-bottom,
li.spacing-extra,
li.spacing-extra {
  padding-bottom: 0; }

/* Text List */
/* line 40, sass/components/_lists.scss */
.reset-list li,
.item-list .reset-list li {
  margin: 0;
  margin-bottom: 24px;
  margin-bottom: 2.4rem; }
  /* line 43, sass/components/_lists.scss */
  .reset-list li.spacing-half,
  .item-list .reset-list li.spacing-half {
    margin-bottom: 12px;
    margin-bottom: 1.2rem; }
  /* line 46, sass/components/_lists.scss */
  .reset-list li.spacing-bottom,
  .item-list .reset-list li.spacing-bottom {
    margin-bottom: 36px;
    margin-bottom: 3.6rem; }
  /* line 49, sass/components/_lists.scss */
  .reset-list li.spacing,
  .item-list .reset-list li.spacing {
    margin: 36px 0;
    margin: 3.6rem 0; }
  /* line 52, sass/components/_lists.scss */
  .reset-list li.no-spacing,
  .item-list .reset-list li.no-spacing {
    margin-top: 0;
    margin-bottom: 0; }

/* line 1, sass/components/_masonry.scss */
.masonry-grid-wrapper {
  margin: 0 auto; }
  /* line 3, sass/components/_masonry.scss */
  .masonry-grid-wrapper .fillers {
    display: none; }
  /* line 6, sass/components/_masonry.scss */
  .masonry-grid-wrapper .filler, .masonry-grid-wrapper .mason-filler, .masonry-grid-wrapper .filler-fade {
    background: #41505c; }

/* line 11, sass/components/_masonry.scss */
.masonry-grid img {
  display: block;
  width: 100%; }

/* line 15, sass/components/_masonry.scss */
.masonry-grid .grid-item.shadow {
  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 4px; }

/* line 18, sass/components/_masonry.scss */
.masonry-grid .grid-item .teaser {
  margin-bottom: 24px;
  margin-bottom: 2.4rem; }

@media screen and (min-width: 35.29412em) {
  /* line 10, sass/components/_masonry.scss */
  .masonry-grid {
    display: grid;
    grid-gap: 20px;
    transition: all 300ms ease-in-out; }
    /* line 33, sass/components/_masonry.scss */
    .masonry-grid.reset-list li {
      margin-bottom: 0; }
    /* line 37, sass/components/_masonry.scss */
    .flexbox .wrapper--search-tabs .masonry-grid .edan-search-result .inner {
      background-color: transparent;
      border: none; }
      /* line 40, sass/components/_masonry.scss */
      .flexbox .wrapper--search-tabs .masonry-grid .edan-search-result .inner:after {
        box-shadow: none;
        opacity: 0; }
    /* line 46, sass/components/_masonry.scss */
    .masonry-grid .grid-item .teaser {
      margin-bottom: 0; }
      /* line 48, sass/components/_masonry.scss */
      .masonry-grid .grid-item .teaser .inner {
        background-color: transparent;
        border: none; }
        /* line 51, sass/components/_masonry.scss */
        .masonry-grid .grid-item .teaser .inner:after {
          box-shadow: none;
          opacity: 0; }
    /* line 60, sass/components/_masonry.scss */
    .masonry-grid.hover-title .has-media .teaser .b-text-wrapper,
    .masonry-grid.hover-title .has-media.teaser .b-text-wrapper {
      opacity: 0;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      z-index: 100;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      background-color: rgba(0, 0, 0, 0.6);
      -webkit-transition: opacity 0.2s ease-out;
      -o-transition: opacity 0.2s ease-out;
      transition: opacity 0.2s ease-out; }
    /* line 79, sass/components/_masonry.scss */
    .masonry-grid.hover-title .has-media .teaser .inner,
    .masonry-grid.hover-title .has-media.teaser .inner {
      display: block;
      position: relative;
      background: none; }
      /* line 83, sass/components/_masonry.scss */
      .masonry-grid.hover-title .has-media .teaser .inner:hover,
      .masonry-grid.hover-title .has-media.teaser .inner:hover {
        color: white; }
        /* line 85, sass/components/_masonry.scss */
        .masonry-grid.hover-title .has-media .teaser .inner:hover .b-text-wrapper,
        .masonry-grid.hover-title .has-media.teaser .inner:hover .b-text-wrapper {
          opacity: 1; }
    /* line 90, sass/components/_masonry.scss */
    .masonry-grid.hover-title .has-media .teaser h3, .masonry-grid.hover-title .has-media .teaser .node-type-recipient .field-name-field-title-long, .node-type-recipient .masonry-grid.hover-title .has-media .teaser .field-name-field-title-long,
    .masonry-grid.hover-title .has-media .teaser .node-type-staff .field-name-field-title-long, .node-type-staff .masonry-grid.hover-title .has-media .teaser .field-name-field-title-long, .masonry-grid.hover-title .has-media .teaser .front #paragraphs-item-5555 .group-right .section-title, .front #paragraphs-item-5555 .group-right .masonry-grid.hover-title .has-media .teaser .section-title, .masonry-grid.hover-title .has-media .teaser h2, .masonry-grid.hover-title .has-media .teaser .main .edan-search-form .form-type-textfield label, .main .edan-search-form .form-type-textfield .masonry-grid.hover-title .has-media .teaser label, .masonry-grid.hover-title .has-media .teaser h4, .masonry-grid.hover-title .has-media .teaser .node-tdiph .field-type-datetime, .node-tdiph .masonry-grid.hover-title .has-media .teaser .field-type-datetime, .masonry-grid.hover-title .has-media .teaser .si-tabs.r-tabs .panel-heading, .si-tabs.r-tabs .masonry-grid.hover-title .has-media .teaser .panel-heading,
    .masonry-grid.hover-title .has-media .teaser .beta, .masonry-grid.hover-title .has-media .teaser .gamma, .masonry-grid.hover-title .has-media .teaser .delta, .masonry-grid.hover-title .has-media .teaser .epsilon,
    .masonry-grid.hover-title .has-media.teaser h3,
    .masonry-grid.hover-title .has-media.teaser .node-type-recipient .field-name-field-title-long,
    .node-type-recipient .masonry-grid.hover-title .has-media.teaser .field-name-field-title-long,
    .masonry-grid.hover-title .has-media.teaser .node-type-staff .field-name-field-title-long,
    .node-type-staff .masonry-grid.hover-title .has-media.teaser .field-name-field-title-long,
    .masonry-grid.hover-title .has-media.teaser .front #paragraphs-item-5555 .group-right .section-title,
    .front #paragraphs-item-5555 .group-right .masonry-grid.hover-title .has-media.teaser .section-title,
    .masonry-grid.hover-title .has-media.teaser h2,
    .masonry-grid.hover-title .has-media.teaser .main .edan-search-form .form-type-textfield label,
    .main .edan-search-form .form-type-textfield .masonry-grid.hover-title .has-media.teaser label,
    .masonry-grid.hover-title .has-media.teaser h4,
    .masonry-grid.hover-title .has-media.teaser .node-tdiph .field-type-datetime,
    .node-tdiph .masonry-grid.hover-title .has-media.teaser .field-type-datetime,
    .masonry-grid.hover-title .has-media.teaser .si-tabs.r-tabs .panel-heading,
    .si-tabs.r-tabs .masonry-grid.hover-title .has-media.teaser .panel-heading,
    .masonry-grid.hover-title .has-media.teaser .beta,
    .masonry-grid.hover-title .has-media.teaser .gamma,
    .masonry-grid.hover-title .has-media.teaser .delta,
    .masonry-grid.hover-title .has-media.teaser .epsilon {
      color: white; }
    /* line 95, sass/components/_masonry.scss */
    .masonry-grid.hover-title .has-media .teaser.no-media .b-text-wrapper,
    .masonry-grid.hover-title .has-media.teaser.no-media .b-text-wrapper {
      position: static;
      opacity: 1; }
    /* line 102, sass/components/_masonry.scss */
    .masonry-grid .node--teaser-long {
      display: block; }
    /* line 107, sass/components/_masonry.scss */
    .masonry-grid:not(.hover-title) .grid-item {
      background-color: #eef0f3;
      border: 1px solid #e8ebef; }
      /* line 110, sass/components/_masonry.scss */
      .masonry-grid:not(.hover-title) .grid-item:after, .masonry-grid:not(.hover-title) .grid-item:before {
        border-radius: inherit;
        z-index: -1;
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0;
        padding: 0;
        -webkit-transition: -webkit-box-shadow 0.7s 10ms cubic-bezier(0.19, 1, 0.22, 1);
        transition: -webkit-box-shadow 0.7s 10ms cubic-bezier(0.19, 1, 0.22, 1);
        transition: box-shadow 0.7s 10ms cubic-bezier(0.19, 1, 0.22, 1);
        transition: box-shadow 0.7s 10ms cubic-bezier(0.19, 1, 0.22, 1), -webkit-box-shadow 0.7s 10ms cubic-bezier(0.19, 1, 0.22, 1); }
      /* line 126, sass/components/_masonry.scss */
      .masonry-grid:not(.hover-title) .grid-item:after {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=6);
        opacity: 0.0625;
        -webkit-box-shadow: 0 2px 0 0 #000;
        box-shadow: 0 2px 0 0 #000; } }

@media (min-width: 1870px) {
  /* line 10, sass/components/_masonry.scss */
  .masonry-grid {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); } }

/* line 14, sass/components/_node.scss */
.node-tdiph.teaser .field-type-datetime {
  margin-bottom: 12px;
  margin-bottom: 1.2rem; }

/* line 22, sass/components/_node.scss */
.node-type-recipient .ds-2col-stacked-fluid .group-left,
.node-type-staff .ds-2col-stacked-fluid .group-left {
  background: #eef0f3;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  padding: 0.6rem;
  border: 1px solid #D0D7DE; }
  /* line 29, sass/components/_node.scss */
  .node-type-recipient .ds-2col-stacked-fluid .group-left img,
  .node-type-staff .ds-2col-stacked-fluid .group-left img {
    display: block;
    margin: auto; }

/* line 34, sass/components/_node.scss */
.node-type-recipient .ds-2col-stacked-fluid h1.h2,
.node-type-staff .ds-2col-stacked-fluid h1.h2 {
  margin: 12px 0 0;
  margin: 1.2rem 0 0; }

/* line 44, sass/components/_node.scss */
.page-node .content-wrapper.has-sidebar-nav .layout-default {
  position: relative;
  padding-top: 100px; }
  @media (min-width: 980px) {
    /* line 44, sass/components/_node.scss */
    .page-node .content-wrapper.has-sidebar-nav .layout-default {
      padding-top: 0; } }

/* line 53, sass/components/_node.scss */
.node-type-book .metatdata, .node-type-exhibition .metatdata, .node-exhibition .metatdata {
  margin-bottom: 24px;
  margin-bottom: 2.4rem;
  color: #101820;
  font-size: 19px;
  font-size: 1.9rem;
  line-height: 1.26316; }

/* line 61, sass/components/_node.scss */
.node-museum-highlight .field-name-field-title-long {
  margin: 24px 0;
  margin: 2.4rem 0; }

/* line 65, sass/components/_node.scss */
.node-museum-highlight.node-teaser .field-name-field-title-long {
  margin: 12px 0;
  margin: 1.2rem 0; }

/* line 72, sass/components/_node.scss */
.pane-exhibit-title h1.pane-title,
.node-type-book h1.pane-title {
  margin-bottom: 36px;
  margin-bottom: 3.6rem; }

/* line 78, sass/components/_node.scss */
.node-type-press .panel-region-bottom {
  background: #eef0f3;
  padding: 24px 0;
  padding: 2.4rem 0;
  margin-top: 24px;
  margin-top: 2.4rem; }

/* line 85, sass/components/_node.scss */
.node-promotion.teaser .info {
  margin-bottom: 12px;
  margin-bottom: 1.2rem; }

/* line 88, sass/components/_node.scss */
.node-promotion.teaser a.colorbox-link-close {
  position: relative;
  display: inline-block;
  padding-right: 24px;
  padding-right: 2.4rem;
  margin-top: 12px;
  margin-top: 1.2rem; }
  /* line 50, sass/helpers/_icon.scss */
  .node-promotion.teaser a.colorbox-link-close:after {
    content: "";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #0177A8;
    position: absolute;
    font-size: 17px;
    font-size: 1.7rem;
    top: 2px;
    top: 0.2rem;
    right: 6px;
    right: 0.6rem; }
  /* line 62, sass/helpers/_icon.scss */
  .node-promotion.teaser a.colorbox-link-close:hover:after {
    color: #9f3050; }
  /* line 92, sass/components/_node.scss */
  .node-promotion.teaser a.colorbox-link-close:after {
    color: inherit !important;
    font-size: inherit; }
    /* line 95, sass/components/_node.scss */
    .node-promotion.teaser a.colorbox-link-close:after:hover {
      color: inherit !important; }

/* line 103, sass/components/_node.scss */
.node-slideshow.teaser .b-text-wrapper {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.65);
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.75));
  background-repeat: repeat-x;
  background-size: auto 100%;
  padding: 36px 0;
  padding: 3.6rem 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0; }
  /* line 104, sass/components/_node.scss */
  .node-slideshow.teaser .b-text-wrapper * {
    color: white; }

/* line 117, sass/components/_node.scss */
.node-slideshow.teaser .bg-image {
  height: 75vw;
  min-height: 320px;
  max-height: 853px; }

/* line 123, sass/components/_node.scss */
.node-slideshow .h2 {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif; }

/* line 126, sass/components/_node.scss */
.node-slideshow .field-name-field-title-long {
  font-weight: 600; }

/* line 132, sass/components/_node.scss */
.node-slideshow .caption {
  display: none; }

/* line 138, sass/components/_node.scss */
.node-type-landing-page .paragraphs-item-accordion-tab,
.node-type-landing-page .paragraphs-item-tabs {
  width: 96%;
  max-width: 1366px;
  margin-left: auto;
  margin-right: auto; }

@media (min-width: 768px) {
  /* line 145, sass/components/_node.scss */
  .node-slideshow .caption {
    display: block; }
  /* line 149, sass/components/_node.scss */
  .node-slideshow.teaser .bg-image {
    height: 56.25vw; }
  /* line 156, sass/components/_node.scss */
  .node-type-press .panel-region-bottom {
    background: #eef0f3;
    padding: 36px 0;
    padding: 3.6rem 0;
    margin-top: 48px;
    margin-top: 4.8rem; } }

@media (min-width: 980px) {
  /* line 166, sass/components/_node.scss */
  .node-slideshow.teaser {
    font-size: 19px;
    font-size: 1.9rem;
    line-height: 1.26316; }
    /* line 169, sass/components/_node.scss */
    .node-slideshow.teaser .h2 {
      font-size: 48px;
      font-size: 4.8rem;
      line-height: 1.25; }
    /* line 173, sass/components/_node.scss */
    .node-slideshow.teaser .bg-image {
      height: 33.33vw; }
    /* line 176, sass/components/_node.scss */
    .node-slideshow.teaser .b-text-wrapper {
      padding: 36px 0 48px;
      padding: 3.6rem 0 4.8rem; } }

@media (min-width: 1280px) {
  /* line 186, sass/components/_node.scss */
  .node-slideshow.teaser .b-text-wrapper {
    padding: 36px 0 60px;
    padding: 3.6rem 0 6rem; } }

/* line 1, sass/components/_panel-panes.scss */
#block-panels-mini-exhibit-header,
.pane-exhibit-header {
  padding: 24px 0;
  padding: 2.4rem 0; }
  /* line 5, sass/components/_panel-panes.scss */
  #block-panels-mini-exhibit-header .breadcrumbs,
  .pane-exhibit-header .breadcrumbs {
    margin-bottom: 24px; }

/* line 10, sass/components/_panel-panes.scss */
#mini-panel-related_header .field-type-entityreference a {
  position: relative;
  display: inline-block;
  padding-left: 24px;
  padding-left: 2.4rem; }
  /* line 68, sass/helpers/_icon.scss */
  #mini-panel-related_header .field-type-entityreference a:before {
    content: "";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #0177A8;
    position: absolute;
    font-size: 17px;
    font-size: 1.7rem;
    top: 0;
    left: 6px;
    left: 0.6rem; }
  /* line 80, sass/helpers/_icon.scss */
  #mini-panel-related_header .field-type-entityreference a:hover:before {
    color: #9f3050; }

/* line 15, sass/components/_panel-panes.scss */
.pane-exhibit-title {
  margin-bottom: 24px;
  margin-bottom: 2.4rem; }
  /* line 17, sass/components/_panel-panes.scss */
  .pane-exhibit-title.bg {
    padding: 12px 0;
    padding: 1.2rem 0; }
    /* line 18, sass/components/_panel-panes.scss */
    .pane-exhibit-title.bg * {
      color: white; }
    /* line 21, sass/components/_panel-panes.scss */
    .pane-exhibit-title.bg .exhibit-title {
      margin: 0; }
      /* line 22, sass/components/_panel-panes.scss */
      .pane-exhibit-title.bg .exhibit-title a {
        color: white; }
        /* line 147, sass/helpers/_text.scss */
        .pane-exhibit-title.bg .exhibit-title a:visited {
          color: white; }
        /* line 152, sass/helpers/_text.scss */
        .pane-exhibit-title.bg .exhibit-title a:focus {
          color: white; }
        /* line 157, sass/helpers/_text.scss */
        .pane-exhibit-title.bg .exhibit-title a:hover {
          color: #ffd400; }
        /* line 162, sass/helpers/_text.scss */
        .pane-exhibit-title.bg .exhibit-title a:active {
          color: #ffd400; }
    /* line 28, sass/components/_panel-panes.scss */
    .pane-exhibit-title.bg .field-name-field-tagline {
      font-size: 19px;
      font-size: 1.9rem;
      line-height: 1.26316;
      margin: 0; }

/* line 40, sass/components/_panel-panes.scss */
.pane-node-field-slides .section-title {
  text-align: center; }

/* line 47, sass/components/_panel-panes.scss */
.pane-press-header .field-name-field-date-single {
  display: inline-block; }

/* line 50, sass/components/_panel-panes.scss */
.pane-press-header .pane-node-field-date-single,
.pane-press-header .pane-node-created,
.pane-press-header .pane-node-content-type {
  display: inline-block;
  margin: 24px 0;
  margin: 2.4rem 0; }

/* line 56, sass/components/_panel-panes.scss */
.pane-press-header .pane-node-field-date-single:after,
.pane-press-header .pane-node-created:after {
  content: ' • ';
  font-size: 14px;
  font-size: 1.4rem;
  color: #75787B; }

/* line 65, sass/components/_panel-panes.scss */
.pane-node-field-image-ref .h3, .pane-node-field-image-ref p {
  margin-bottom: 12px;
  margin-bottom: 1.2rem; }

/* line 68, sass/components/_panel-panes.scss */
.pane-node-field-image-ref .colorbox {
  display: block;
  text-align: center;
  margin-bottom: 12px;
  margin-bottom: 1.2rem; }
  @media (min-width: 768px) {
    /* line 68, sass/components/_panel-panes.scss */
    .pane-node-field-image-ref .colorbox {
      margin-bottom: 0; } }

/* line 80, sass/components/_panel-panes.scss */
.pane-taxonomy-term-field-block-content > .paragraphs-item-captioned-image,
.pane-taxonomy-term-field-block-content > .paragraphs-item-caption-text.teaser-long,
.pane-node-field-block-content > .paragraphs-item-captioned-image,
.pane-node-field-block-content > .paragraphs-item-caption-text.teaser-long {
  margin-bottom: 24px;
  margin-bottom: 2.4rem; }
  /* line 84, sass/components/_panel-panes.scss */
  .panel-region-main.gs8 .pane-taxonomy-term-field-block-content > .paragraphs-item-captioned-image, .panel-region-main.gs8
  .pane-taxonomy-term-field-block-content > .paragraphs-item-caption-text.teaser-long, .panel-region-main.gs8
  .pane-node-field-block-content > .paragraphs-item-captioned-image, .panel-region-main.gs8
  .pane-node-field-block-content > .paragraphs-item-caption-text.teaser-long {
    width: 100%; }

/* line 90, sass/components/_panel-panes.scss */
.pane-topics-header {
  border: 1px solid #6c7a89;
  margin-top: 24px;
  margin-top: 2.4rem; }
  /* line 94, sass/components/_panel-panes.scss */
  .pane-topics-header > .panel-display > .gs6:first-child {
    padding: 12px 10px;
    padding: 1.2rem 1rem; }
  /* line 100, sass/components/_panel-panes.scss */
  .pane-topics-header .g-12-ns > .gs6:last-child {
    background: #6c7a89;
    color: #fff; }
  /* line 106, sass/components/_panel-panes.scss */
  .pane-topics-header figure {
    margin: 0; }
    /* line 108, sass/components/_panel-panes.scss */
    .pane-topics-header figure img {
      padding: 0;
      border: none; }
  /* line 113, sass/components/_panel-panes.scss */
  .pane-topics-header figcaption {
    margin: 0;
    padding: 12px 0;
    padding: 1.2rem 0; }

@media (min-width: 550px) {
  /* line 121, sass/components/_panel-panes.scss */
  .pane-topics-header .g-12-ns {
    display: block; } }

@media (min-width: 768px) {
  /* line 129, sass/components/_panel-panes.scss */
  .pane-topics-header .g-12-ns {
    display: flex;
    justify-content: space-between; }
    /* line 132, sass/components/_panel-panes.scss */
    .pane-topics-header .g-12-ns > .gs6:first-child {
      padding-right: 20px;
      padding-right: 2rem; }
    /* line 135, sass/components/_panel-panes.scss */
    .pane-topics-header .g-12-ns > .gs6:last-child {
      max-width: 40%; }
  /* line 139, sass/components/_panel-panes.scss */
  .pane-topics-header img {
    max-height: 300px !important;
    width: auto !important; }
  /* line 144, sass/components/_panel-panes.scss */
  #block-panels-mini-exhibit-header,
  .pane-exhibit-header {
    position: relative;
    padding: 36px 0;
    padding: 3.6rem 0; }
    /* line 148, sass/components/_panel-panes.scss */
    #block-panels-mini-exhibit-header > .panel-display,
    .pane-exhibit-header > .panel-display {
      display: flex;
      justify-content: space-between; }
    /* line 152, sass/components/_panel-panes.scss */
    #block-panels-mini-exhibit-header .breadcrumbs,
    .pane-exhibit-header .breadcrumbs {
      margin-bottom: 0; }
    /* line 155, sass/components/_panel-panes.scss */
    #block-panels-mini-exhibit-header .pane-addtoany-addtoany-button,
    .pane-exhibit-header .pane-addtoany-addtoany-button {
      margin-left: auto; } }

@media (min-width: 980px) {
  /* line 162, sass/components/_panel-panes.scss */
  .pane-topics-header {
    margin-top: 36px;
    margin-top: 3.6rem; }
    /* line 165, sass/components/_panel-panes.scss */
    .pane-topics-header > .panel-display > .gs6:first-child {
      padding: 24px 20px;
      padding: 2.4rem 2rem; } }

/* line 6, sass/components/_paragraphs.scss */
.entity-paragraphs-item .section-title {
  display: block; }

/* line 14, sass/components/_paragraphs.scss */
.entity-paragraphs-item.spacing .section-title, .entity-paragraphs-item.spacing-top .section-title, .entity-paragraphs-item.spacing-extra .section-title, .entity-paragraphs-item.spacing-extra-top .section-title, .entity-paragraphs-item.field-padding .section-title {
  margin-top: 0; }

/* line 17, sass/components/_paragraphs.scss */
.entity-paragraphs-item.spacing .section-title + .content, .entity-paragraphs-item.spacing-top .section-title + .content, .entity-paragraphs-item.spacing-extra .section-title + .content, .entity-paragraphs-item.spacing-extra-top .section-title + .content, .entity-paragraphs-item.field-padding .section-title + .content {
  margin-top: 24px;
  margin-top: 2.4rem; }

/* line 26, sass/components/_paragraphs.scss */
.entity-paragraphs-item.spacing p:last-child, .entity-paragraphs-item.spacing-bottom p:last-child, .entity-paragraphs-item.spacing-extra p:last-child, .entity-paragraphs-item.spacing-extra-bottom p:last-child, .entity-paragraphs-item.field-padding p:last-child {
  margin-bottom: 0; }

/* line 34, sass/components/_paragraphs.scss */
.paragraphs-item-image-with-download .teaser-list img {
  width: auto !important; }

/* line 38, sass/components/_paragraphs.scss */
.paragraphs-item-image-with-download.grid {
  font-size: 14px;
  font-size: 1.4rem; }
  /* line 39, sass/components/_paragraphs.scss */
  .paragraphs-item-image-with-download.grid .filesize {
    display: none; }

/* line 45, sass/components/_paragraphs.scss */
.paragraphs-item-image-with-download.view-mode-default .panel-display {
  margin-bottom: 24px;
  margin-bottom: 2.4rem; }

/* line 48, sass/components/_paragraphs.scss */
.paragraphs-item-image-with-download.view-mode-default .teaser-list {
  border-bottom: 1px solid #D0D7DE;
  padding-bottom: 24px;
  padding-bottom: 2.4rem;
  margin-bottom: 24px;
  margin-bottom: 2.4rem; }
  /* line 52, sass/components/_paragraphs.scss */
  .paragraphs-item-image-with-download.view-mode-default .teaser-list:last-child {
    border-bottom: none;
    padding-bottom: 0; }

/* line 68, sass/components/_paragraphs.scss */
.paragraphs-item-slideshow-images .paragraphs-item-figure-image {
  background: #000;
  padding: 12px 10px;
  padding: 1.2rem 1rem; }

/* line 72, sass/components/_paragraphs.scss */
.paragraphs-item-slideshow-images figcaption {
  color: #fff; }

/* line 80, sass/components/_paragraphs.scss */
.paragraphs-item-grid-layout > .content > .entity {
  background-color: #eef0f3;
  border: 1px solid #e8ebef;
  position: relative;
  z-index: 1;
  border-radius: inherit; }
  /* line 86, sass/components/_paragraphs.scss */
  .paragraphs-item-grid-layout > .content > .entity:after, .paragraphs-item-grid-layout > .content > .entity:before {
    border-radius: inherit;
    z-index: -1;
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 0; }
  /* line 98, sass/components/_paragraphs.scss */
  .paragraphs-item-grid-layout > .content > .entity:after {
    opacity: .0625;
    box-shadow: 0 2px 0 0 #000; }
  /* line 102, sass/components/_paragraphs.scss */
  .paragraphs-item-grid-layout > .content > .entity:hover, .paragraphs-item-grid-layout > .content > .entity.hover {
    background-color: #D0D7DE; }

/* line 107, sass/components/_paragraphs.scss */
.paragraphs-item-grid-layout .teaser-long .inner,
.paragraphs-item-grid-layout .inner {
  display: block;
  background: transparent;
  border: none;
  color: #333F48; }
  /* line 147, sass/helpers/_text.scss */
  .paragraphs-item-grid-layout .teaser-long .inner:visited,
  .paragraphs-item-grid-layout .inner:visited {
    color: #333F48; }
  /* line 152, sass/helpers/_text.scss */
  .paragraphs-item-grid-layout .teaser-long .inner:focus,
  .paragraphs-item-grid-layout .inner:focus {
    color: #333F48; }
  /* line 157, sass/helpers/_text.scss */
  .paragraphs-item-grid-layout .teaser-long .inner:hover,
  .paragraphs-item-grid-layout .inner:hover {
    color: #333F48; }
  /* line 162, sass/helpers/_text.scss */
  .paragraphs-item-grid-layout .teaser-long .inner:active,
  .paragraphs-item-grid-layout .inner:active {
    color: #333F48; }
  /* line 113, sass/components/_paragraphs.scss */
  .paragraphs-item-grid-layout .teaser-long .inner:after,
  .paragraphs-item-grid-layout .inner:after {
    opacity: 0;
    box-shadow: none; }

/* line 120, sass/components/_paragraphs.scss */
.paragraphs-item-grid-layout.center-content .teaser-long .inner,
.paragraphs-item-grid-layout.center-content .inner {
  display: flex;
  justify-content: center;
  align-items: center; }

/* line 130, sass/components/_paragraphs.scss */
.paragraphs-item-grid-layout > .content > .entity.field-bg .inner:hover,
.paragraphs-item-grid-layout > .content > .entity.field-bg .teaser-long .inner:hover {
  background: transparent; }

/* line 137, sass/components/_paragraphs.scss */
.paragraphs-item-grid-layout.field-bg > .content > .entity {
  background: transparent;
  border: none; }
  /* line 140, sass/components/_paragraphs.scss */
  .paragraphs-item-grid-layout.field-bg > .content > .entity:after {
    opacity: 0;
    box-shadow: none; }

/* line 146, sass/components/_paragraphs.scss */
.paragraphs-item-grid-layout > .content > .entity.bg-purple {
  background: #394299;
  border: 1px solid #363f92; }
  /* line 149, sass/components/_paragraphs.scss */
  .paragraphs-item-grid-layout > .content > .entity.bg-purple.hover, .paragraphs-item-grid-layout > .content > .entity.bg-purple:hover {
    background: #323a86; }

/* line 161, sass/components/_paragraphs.scss */
.paragraphs-item-grid-layout > .content > .entity.bg-gray-dark {
  background: #41505c;
  border: 1px solid #3d4b56; }
  /* line 164, sass/components/_paragraphs.scss */
  .paragraphs-item-grid-layout > .content > .entity.bg-gray-dark.hover, .paragraphs-item-grid-layout > .content > .entity.bg-gray-dark:hover {
    background: #36434d; }

/* line 168, sass/components/_paragraphs.scss */
.paragraphs-item-grid-layout > .content > .entity.bg-blue {
  background: #165C7D;
  border: 1px solid #145674; }
  /* line 171, sass/components/_paragraphs.scss */
  .paragraphs-item-grid-layout > .content > .entity.bg-blue.hover, .paragraphs-item-grid-layout > .content > .entity.bg-blue:hover {
    background: #124c67; }

/* line 175, sass/components/_paragraphs.scss */
.paragraphs-item-grid-layout > .content > .entity.bg-green {
  background: #298732;
  border: 1px solid #277f2f; }
  /* line 178, sass/components/_paragraphs.scss */
  .paragraphs-item-grid-layout > .content > .entity.bg-green.hover, .paragraphs-item-grid-layout > .content > .entity.bg-green:hover {
    background: #23732b; }

/* line 183, sass/components/_paragraphs.scss */
.paragraphs-item-grid-layout > .content > .entity.bg-orange {
  background: #E87722;
  border: 1px solid #e77119; }
  /* line 186, sass/components/_paragraphs.scss */
  .paragraphs-item-grid-layout > .content > .entity.bg-orange.hover, .paragraphs-item-grid-layout > .content > .entity.bg-orange:hover {
    background: #da6a17; }

/* line 190, sass/components/_paragraphs.scss */
.paragraphs-item-grid-layout > .content > .entity.bg-burgundy {
  background: #9f3050;
  border: 1px solid #972e4c; }
  /* line 193, sass/components/_paragraphs.scss */
  .paragraphs-item-grid-layout > .content > .entity.bg-burgundy.hover, .paragraphs-item-grid-layout > .content > .entity.bg-burgundy:hover {
    background: #8b2a46; }

/* line 198, sass/components/_paragraphs.scss */
.paragraphs-item-grid-layout > .content > .entity {
  margin: 0;
  margin-bottom: 24px;
  margin-bottom: 2.4rem; }

/* line 212, sass/components/_paragraphs.scss */
.paragraphs-item-three-col-equal .col {
  margin-bottom: 24px;
  margin-bottom: 2.4rem; }

/* line 219, sass/components/_paragraphs.scss */
.paragraphs-item-embedded-block .sf-menu {
  float: none !important; }
  /* line 221, sass/components/_paragraphs.scss */
  .paragraphs-item-embedded-block .sf-menu a {
    padding-left: 0;
    padding-right: 0; }
  /* line 225, sass/components/_paragraphs.scss */
  .paragraphs-item-embedded-block .sf-menu * {
    list-style: disc; }
  /* line 228, sass/components/_paragraphs.scss */
  .paragraphs-item-embedded-block .sf-menu,
  .paragraphs-item-embedded-block .sf-menu ul {
    margin-left: 20px !important;
    margin-left: 2rem !important; }
  /* line 232, sass/components/_paragraphs.scss */
  .paragraphs-item-embedded-block .sf-menu ul {
    display: block !important;
    position: static !important; }
  /* line 236, sass/components/_paragraphs.scss */
  .paragraphs-item-embedded-block .sf-menu li {
    background: transparent;
    float: none !important; }
  /* line 240, sass/components/_paragraphs.scss */
  .paragraphs-item-embedded-block .sf-menu > li {
    float: none !important; }

/* line 277, sass/components/_paragraphs.scss */
.paragraphs-item-text-block.spacing-bottom .content p:last-child,
.paragraphs-item-text-block.spacing-bottom .content ul:last-child,
.paragraphs-item-text-block.spacing .content p:last-child,
.paragraphs-item-text-block.spacing .content ul:last-child,
.paragraphs-item-text-block.spacing-extra-bottom .content p:last-child,
.paragraphs-item-text-block.spacing-extra-bottom .content ul:last-child {
  margin-bottom: 0; }

@media (min-width: 550px) {
  /* line 286, sass/components/_paragraphs.scss */
  .paragraphs-item-slideshow-images figure {
    margin: 24px 10px;
    margin: 2.4rem 1rem; }
  /* line 291, sass/components/_paragraphs.scss */
  .paragraphs-item-image-with-download.grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    grid-gap: 2.4rem; }
    /* line 295, sass/components/_paragraphs.scss */
    .paragraphs-item-image-with-download.grid .filesize {
      font-size: 12px;
      font-size: 1.2rem;
      line-height: 2; }
  /* line 302, sass/components/_paragraphs.scss */
  .paragraphs-item-grid-layout > .content {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 24px;
    grid-gap: 2.4rem; }
    /* line 306, sass/components/_paragraphs.scss */
    .paragraphs-item-grid-layout > .content > .entity {
      display: flex;
      grid-column-end: span 6;
      margin-bottom: 0;
      justify-content: center; } }

@media (min-width: 768px) {
  /* line 331, sass/components/_paragraphs.scss */
  .paragraphs-item-three-col-equal .col {
    margin-bottom: 0; } }

@media (min-width: 980px) {
  /* line 343, sass/components/_paragraphs.scss */
  .paragraphs-item-grid-layout.col-md-4 > .content > .entity, .paragraphs-item-grid-layout.col-wd-2 > .content > .entity {
    grid-column-end: span 4; }
  /* line 351, sass/components/_paragraphs.scss */
  .paragraphs-item-grid-layout.col-md-3 > .content > .entity {
    grid-column-end: span 3; }
  /* line 359, sass/components/_paragraphs.scss */
  .paragraphs-item-image-with-download.grid {
    grid-template-columns: repeat(3, 1fr); } }

@media (min-width: 1280px) {
  /* line 379, sass/components/_paragraphs.scss */
  .paragraphs-item-grid-layout.col-wd-2 > .content > .entity {
    grid-column-end: span 2; }
  /* line 386, sass/components/_paragraphs.scss */
  .paragraphs-item-image-with-download.grid {
    grid-template-columns: repeat(4, 1fr); } }

/* line 6, sass/components/_postscript.scss */
.region-postscript .page-nav {
  font-weight: 700;
  max-width: 175px;
  max-width: 17.5rem;
  position: fixed;
  right: 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  z-index: 1000;
  opacity: 0;
  transition: opacity 400ms, bottom 200ms;
  bottom: -9999px; }
  /* line 19, sass/components/_postscript.scss */
  .region-postscript .page-nav .text {
    color: #000;
    opacity: 0;
    font-size: 12px;
    font-size: 1.2rem; }
    /* line 23, sass/components/_postscript.scss */
    .show-page-nav-alt .region-postscript .page-nav .text {
      color: white; }
  /* line 28, sass/components/_postscript.scss */
  .region-postscript .page-nav .icon {
    background-color: #F0B323;
    border-radius: 50%;
    border: 3px solid #fff;
    box-shadow: -1px 0 2px 0 rgba(0, 0, 0, 0.12), 1px 0 2px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
    display: block;
    height: 36px;
    height: 3.6rem;
    width: 36px;
    width: 3.6rem;
    margin: 0 auto;
    position: relative;
    background-image: url("../images/bg/arrow-white-up.png");
    background-image: url("data:image/svg+xml, %3Csvg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 31 19' fill='rgb%28255%2C255%2C255%29'%3E%3Cstyle%3E.st0%7Bfill:%23fff%7D%3C/style%3E%3Cpath class='st0' d='M14.8.7L.3 15.2c-.2.2-.3.4-.3.7 0 .3.1.5.3.7l1.6 1.6c.2.2.4.3.7.3s.5-.1.7-.3L15.5 6.1l12.2 12.2c.2.2.4.3.7.3.3 0 .5-.1.7-.3l1.6-1.6c.2-.2.3-.4.3-.7 0-.3-.1-.5-.3-.7L16.2.7c-.2-.2-.4-.3-.7-.3-.3 0-.5.2-.7.3z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50% 45%;
    background-size: 60%; }
  /* line 46, sass/components/_postscript.scss */
  .region-postscript .page-nav:hover .text, .region-postscript .page-nav:active .text {
    opacity: 1; }
  /* line 51, sass/components/_postscript.scss */
  .show-page-nav .region-postscript .page-nav {
    display: block;
    opacity: 1;
    bottom: 0; }

@media (min-width: 768px) {
  /* line 61, sass/components/_postscript.scss */
  .region-postscript .page-nav .icon {
    height: 54px;
    height: 5.4rem;
    width: 54px;
    width: 5.4rem; } }

@media (min-width: 1280px) {
  /* line 68, sass/components/_postscript.scss */
  .region-postscript .page-nav {
    right: 2%; } }

/* line 4, sass/components/_search-form.scss */
.block-google-appliance .container-inline,
.search-form .container-inline, #search-block-form .container-inline,
.edan-search-form .container-inline {
  display: flex;
  align-items: center; }

/* line 9, sass/components/_search-form.scss */
.block-google-appliance .form-item,
.search-form .form-item, #search-block-form .form-item,
.edan-search-form .form-item {
  margin-top: 0;
  margin-bottom: 0; }

/* line 13, sass/components/_search-form.scss */
.block-google-appliance .fill,
.search-form .fill, #search-block-form .fill,
.edan-search-form .fill {
  fill: #FFF; }

/* line 19, sass/components/_search-form.scss */
.block-google-appliance .sb-search-open svg .fill,
.search-form .sb-search-open svg .fill, #search-block-form .sb-search-open svg .fill,
.edan-search-form .sb-search-open svg .fill {
  fill: #165C7D; }

/* line 28, sass/components/_search-form.scss */
.main .search-form,
.main .edan-search-form,
.main .search-form-wrapper {
  position: relative;
  margin-bottom: 36px;
  margin-bottom: 3.6rem;
  max-width: 980px; }

/* line 38, sass/components/_search-form.scss */
.main .search-form input[type="text"],
.main .edan-search-form input[type="text"] {
  display: inline-block;
  width: 100%;
  height: 48px;
  padding-right: 58px;
  border-radius: 0;
  -webkit-appearance: none;
  border: 1px solid #c1cad3;
  padding-left: 10px;
  padding-left: 1rem;
  color: #333F48;
  line-height: 1; }

/* line 50, sass/components/_search-form.scss */
.main .search-form .form-actions,
.main .edan-search-form .form-actions {
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
  display: flex; }

/* line 58, sass/components/_search-form.scss */
.main .search-form.show-label .form-actions,
.main .edan-search-form.show-label .form-actions {
  top: 58px;
  top: 5.8rem; }

/* line 64, sass/components/_search-form.scss */
.main .search-form .form-text input:placeholder-shown,
.main .edan-search-form .form-text input:placeholder-shown {
  color: #41505c;
  text-transform: uppercase; }

/* line 69, sass/components/_search-form.scss */
.js .main .search-form button.btn-svg.reset, .js
.main .edan-search-form button.btn-svg.reset {
  display: none; }

/* line 76, sass/components/_search-form.scss */
.main .search-form.search-executed button.btn-svg.reset,
.main .edan-search-form.search-executed button.btn-svg.reset {
  display: block;
  position: relative;
  top: 2px; }

/* line 83, sass/components/_search-form.scss */
.entity-paragraphs-item .main .search-form button, .entity-paragraphs-item
.main .edan-search-form button {
  background: #F0B323; }
  /* line 85, sass/components/_search-form.scss */
  .entity-paragraphs-item .main .search-form button .fill, .entity-paragraphs-item
  .main .edan-search-form button .fill {
    fill: #FFF; }
  /* line 88, sass/components/_search-form.scss */
  .entity-paragraphs-item .main .search-form button:hover, .entity-paragraphs-item
  .main .edan-search-form button:hover {
    background: #165C7D; }
    /* line 90, sass/components/_search-form.scss */
    .entity-paragraphs-item .main .search-form button:hover .fill, .entity-paragraphs-item
    .main .edan-search-form button:hover .fill {
      fill: #FFF; }

/* line 99, sass/components/_search-form.scss */
.main .edan-search-form .form-type-textfield label {
  margin-bottom: 12px;
  margin-bottom: 1.2rem;
  display: block; }

/* line 105, sass/components/_search-form.scss */
.main .edan-search-form input[type="submit"] {
  top: 59px;
  top: 5.9rem; }

/* line 113, sass/components/_search-form.scss */
.main .bg-burgundy .edan-search-form label,
.main .bg-gray-dark .edan-search-form label,
.main .bg-blue .edan-search-form label {
  color: #fff; }

/* line 120, sass/components/_search-form.scss */
header .block-google-appliance,
header .block-edan-searchtab {
  -webkit-backface-visibility: hidden; }
  /* line 122, sass/components/_search-form.scss */
  header .block-google-appliance form,
  header .block-edan-searchtab form {
    position: relative;
    width: 35px;
    width: 3.5rem;
    min-width: 35px;
    min-width: 3.5rem;
    height: 36px;
    height: 3.6rem;
    overflow: hidden; }
  /* line 132, sass/components/_search-form.scss */
  header .block-google-appliance .form-type-textfield,
  header .block-edan-searchtab .form-type-textfield {
    min-width: 0;
    overflow: hidden;
    margin: 0;
    opacity: 0;
    transition: all .4s ease; }
  /* line 139, sass/components/_search-form.scss */
  header .block-google-appliance input[type="text"],
  header .block-edan-searchtab input[type="text"] {
    padding-right: 50px;
    font-size: 14px;
    font-size: 1.4rem;
    color: #333F48;
    background: transparent;
    border-color: transparent;
    width: 100%; }
  /* line 152, sass/components/_search-form.scss */
  header .block-google-appliance button.search,
  header .block-edan-searchtab button.search {
    height: 36px;
    height: 3.6rem;
    width: 44px; }
    /* line 155, sass/components/_search-form.scss */
    header .block-google-appliance button.search svg,
    header .block-edan-searchtab button.search svg {
      width: 32px;
      width: 3.2rem; }
    /* line 158, sass/components/_search-form.scss */
    header .block-google-appliance button.search .fill,
    header .block-edan-searchtab button.search .fill {
      fill: #fff; }
  /* line 162, sass/components/_search-form.scss */
  header .block-google-appliance .form-actions,
  header .block-edan-searchtab .form-actions {
    height: 36px;
    height: 3.6rem;
    padding: 0;
    margin: 0;
    cursor: pointer;
    z-index: 100;
    position: absolute;
    right: 0;
    top: 3px; }
  /* line 176, sass/components/_search-form.scss */
  header .block-google-appliance.sb-search-open form,
  .no-js header .block-google-appliance form,
  header .block-edan-searchtab.sb-search-open form,
  .no-js
  header .block-edan-searchtab form {
    width: 100%;
    height: auto;
    overflow: visible;
    min-width: 100%; }
  /* line 188, sass/components/_search-form.scss */
  header .block-google-appliance.sb-search-open .form-type-textfield,
  .no-js header .block-google-appliance .form-type-textfield,
  header .block-edan-searchtab.sb-search-open .form-type-textfield,
  .no-js
  header .block-edan-searchtab .form-type-textfield {
    opacity: 1;
    min-width: 175px;
    position: absolute;
    top: 36px;
    top: 3.6rem;
    right: 0;
    z-index: 100; }
    @media (min-width: 768px) {
      /* line 188, sass/components/_search-form.scss */
      header .block-google-appliance.sb-search-open .form-type-textfield,
      .no-js header .block-google-appliance .form-type-textfield,
      header .block-edan-searchtab.sb-search-open .form-type-textfield,
      .no-js
      header .block-edan-searchtab .form-type-textfield {
        max-width: 250px;
        top: auto;
        position: static; } }
  /* line 201, sass/components/_search-form.scss */
  header .block-google-appliance.sb-search-open input[type="text"],
  .no-js header .block-google-appliance input[type="text"],
  header .block-edan-searchtab.sb-search-open input[type="text"],
  .no-js
  header .block-edan-searchtab input[type="text"] {
    height: 36px;
    height: 3.6rem;
    background: white; }
  /* line 206, sass/components/_search-form.scss */
  header .block-google-appliance.sb-search-open input[type="submit"],
  .no-js header .block-google-appliance input[type="submit"],
  header .block-edan-searchtab.sb-search-open input[type="submit"],
  .no-js
  header .block-edan-searchtab input[type="submit"] {
    border-radius: 0; }
  /* line 210, sass/components/_search-form.scss */
  header .block-google-appliance.sb-search-open button.search .fill,
  .no-js header .block-google-appliance button.search .fill,
  header .block-edan-searchtab.sb-search-open button.search .fill,
  .no-js
  header .block-edan-searchtab button.search .fill {
    fill: #165C7D; }

/* line 1, sass/components/_search-result.scss */
.search-result {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #41505c;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  flex-direction: column-reverse; }
  @media (min-width: 768px) {
    /* line 1, sass/components/_search-result.scss */
    .search-result {
      flex-direction: row; } }

/* line 12, sass/components/_search-result.scss */
.search-result__content {
  margin-right: 2rem; }

/* line 15, sass/components/_search-result.scss */
.search-result__media {
  width: 200px;
  height: 150px;
  max-height: 150px;
  flex: 0 0 200px;
  overflow: hidden;
  margin-bottom: 1.5rem; }
  @media (min-width: 768px) {
    /* line 15, sass/components/_search-result.scss */
    .search-result__media {
      margin-bottom: 0; } }

/* line 27, sass/components/_search-result.scss */
.search-result__image {
  object-fit: cover;
  height: 100%;
  width: 100%; }

/* line 2, sass/components/_share.scss */
.addtoany_list {
  display: block;
  text-align: center;
  position: relative;
  width: 175px;
  margin: auto; }
  /* line 9, sass/components/_share.scss */
  .addtoany_list a:hover svg path {
    fill: #9f3050 !important;
    stroke: #eef0f3 !important; }
  /* line 13, sass/components/_share.scss */
  .addtoany_list a:hover span {
    opacity: 1; }

/* line 19, sass/components/_share.scss */
.share-btn {
  width: 32px;
  height: 32px;
  display: block;
  border: 1px solid transparent;
  position: relative;
  z-index: 20;
  fill: #165C7D;
  stroke: #165C7D; }
  /* line 28, sass/components/_share.scss */
  .share-btn:hover, .share-btn.active {
    fill: #9f3050;
    stroke: #9f3050 !important; }

/* line 35, sass/components/_share.scss */
.social-media {
  position: absolute;
  top: -999px;
  right: 0;
  min-width: 217px;
  z-index: 10;
  opacity: 0;
  background: #eef0f3;
  text-align: center;
  transition: opacity 400ms ease-in-out; }
  /* line 44, sass/components/_share.scss */
  .social-media a {
    padding: 2px;
    line-height: 1; }
    /* line 47, sass/components/_share.scss */
    .social-media a:hover {
      border: none; }
  /* line 52, sass/components/_share.scss */
  .social-media.active {
    top: -36px;
    opacity: 1; }
    /* line 55, sass/components/_share.scss */
    .social-media.active:after {
      content: '';
      display: table;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #eef0f3;
      margin-bottom: -10px;
      margin-left: 60px; }

@media (min-width: 768px) {
  /* line 68, sass/components/_share.scss */
  .addtoany_list {
    text-align: right;
    margin: 0; } }

/* line 1, sass/components/_sidebar.scss */
.panel-region-left-aside,
.panel-region-right-aside {
  margin-top: 36px;
  margin-top: 3.6rem;
  margin-bottom: 36px;
  margin-bottom: 3.6rem; }
  /* line 6, sass/components/_sidebar.scss */
  .panel-region-left-aside > div,
  .panel-region-left-aside .pane-node-field-sidebar > div,
  .panel-region-right-aside > div,
  .panel-region-right-aside .pane-node-field-sidebar > div {
    margin-bottom: 36px;
    margin-bottom: 3.6rem; }
    /* line 9, sass/components/_sidebar.scss */
    .panel-region-left-aside > div:first-child,
    .panel-region-left-aside .pane-node-field-sidebar > div:first-child,
    .panel-region-right-aside > div:first-child,
    .panel-region-right-aside .pane-node-field-sidebar > div:first-child {
      margin-top: 0; }
    /* line 12, sass/components/_sidebar.scss */
    .panel-region-left-aside > div:last-child,
    .panel-region-left-aside .pane-node-field-sidebar > div:last-child,
    .panel-region-right-aside > div:last-child,
    .panel-region-right-aside .pane-node-field-sidebar > div:last-child {
      margin-bottom: 0; }
  /* line 16, sass/components/_sidebar.scss */
  .panel-region-left-aside ul,
  .panel-region-right-aside ul {
    margin: 0 0 24px 30px;
    margin: 0 0 2.4rem 3rem; }
    /* line 18, sass/components/_sidebar.scss */
    .panel-region-left-aside ul.links,
    .panel-region-right-aside ul.links {
      margin-left: 0; }
  /* line 22, sass/components/_sidebar.scss */
  .panel-region-left-aside h2, .panel-region-left-aside .main .edan-search-form .form-type-textfield label, .main .edan-search-form .form-type-textfield .panel-region-left-aside label, .panel-region-right-aside h2, .panel-region-right-aside .main .edan-search-form .form-type-textfield label, .main .edan-search-form .form-type-textfield .panel-region-right-aside label {
    margin: 0 auto 12px;
    margin: 0 auto 1.2rem; }
  /* line 25, sass/components/_sidebar.scss */
  .panel-region-left-aside .section-title,
  .panel-region-left-aside .title,
  .panel-region-right-aside .section-title,
  .panel-region-right-aside .title {
    background: #101820;
    color: #fff;
    padding: 12px 15px;
    padding: 1.2rem 1.5rem;
    margin: 0; }
  /* line 33, sass/components/_sidebar.scss */
  .panel-region-left-aside .entity-paragraphs-item,
  .panel-region-right-aside .entity-paragraphs-item {
    border: 1px solid #D0D7DE;
    background: #eef0f3;
    padding: 12px 15px;
    padding: 1.2rem 1.5rem; }
    /* line 38, sass/components/_sidebar.scss */
    .panel-region-left-aside .entity-paragraphs-item img,
    .panel-region-right-aside .entity-paragraphs-item img {
      border: none;
      padding: 0;
      margin-bottom: 0;
      width: 100%; }
    /* line 47, sass/components/_sidebar.scss */
    .panel-region-left-aside .entity-paragraphs-item .reset-list li:last-child,
    .panel-region-left-aside .entity-paragraphs-item .item-list .reset-list li:last-child,
    .panel-region-right-aside .entity-paragraphs-item .reset-list li:last-child,
    .panel-region-right-aside .entity-paragraphs-item .item-list .reset-list li:last-child {
      margin-bottom: 0; }
    /* line 51, sass/components/_sidebar.scss */
    .panel-region-left-aside .entity-paragraphs-item.paragraphs-item-embedded-view, .panel-region-left-aside .entity-paragraphs-item.paragraphs-item-captioned-image,
    .panel-region-right-aside .entity-paragraphs-item.paragraphs-item-embedded-view,
    .panel-region-right-aside .entity-paragraphs-item.paragraphs-item-captioned-image {
      padding: 0; }
  /* line 56, sass/components/_sidebar.scss */
  .panel-region-left-aside figure.entity-paragraphs-item,
  .panel-region-right-aside figure.entity-paragraphs-item {
    padding: 0; }
    /* line 58, sass/components/_sidebar.scss */
    .panel-region-left-aside figure.entity-paragraphs-item figcaption,
    .panel-region-right-aside figure.entity-paragraphs-item figcaption {
      max-width: 100%;
      padding: 12px 10px;
      padding: 1.2rem 1rem; }
  /* line 63, sass/components/_sidebar.scss */
  .panel-region-left-aside h3, .panel-region-left-aside .node-type-recipient .field-name-field-title-long, .node-type-recipient .panel-region-left-aside .field-name-field-title-long,
  .panel-region-left-aside .node-type-staff .field-name-field-title-long, .node-type-staff .panel-region-left-aside .field-name-field-title-long, .panel-region-left-aside .front #paragraphs-item-5555 .group-right .section-title, .front #paragraphs-item-5555 .group-right .panel-region-left-aside .section-title, .panel-region-left-aside h4, .panel-region-left-aside .node-tdiph .field-type-datetime, .node-tdiph .panel-region-left-aside .field-type-datetime, .panel-region-left-aside .si-tabs.r-tabs .panel-heading, .si-tabs.r-tabs .panel-region-left-aside .panel-heading,
  .panel-region-left-aside h5, .panel-region-right-aside h3, .panel-region-right-aside .node-type-recipient .field-name-field-title-long, .node-type-recipient .panel-region-right-aside .field-name-field-title-long,
  .panel-region-right-aside .node-type-staff .field-name-field-title-long, .node-type-staff .panel-region-right-aside .field-name-field-title-long, .panel-region-right-aside .front #paragraphs-item-5555 .group-right .section-title, .front #paragraphs-item-5555 .group-right .panel-region-right-aside .section-title, .panel-region-right-aside h4, .panel-region-right-aside .node-tdiph .field-type-datetime, .node-tdiph .panel-region-right-aside .field-type-datetime, .panel-region-right-aside .si-tabs.r-tabs .panel-heading, .si-tabs.r-tabs .panel-region-right-aside .panel-heading,
  .panel-region-right-aside h5 {
    margin-bottom: 0; }
  @media (min-width: 768px) {
    /* line 1, sass/components/_sidebar.scss */
    .panel-region-left-aside,
    .panel-region-right-aside {
      margin-top: 0; } }

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    transform: rotate(359deg); } }

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -moz-transform: rotate(359deg);
    transform: rotate(359deg); } }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@-ms-keyframes spin {
  /* line 44, sass/components/_spiner.scss */
  from {
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  /* line 48, sass/components/_spiner.scss */
  to {
    -ms-transform: rotate(359deg);
    transform: rotate(359deg); } }

@-o-keyframes spin {
  from {
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -o-transform: rotate(359deg);
    transform: rotate(359deg); } }

/* line 66, sass/components/_spiner.scss */
.progress {
  line-height: 0; }

/* line 73, sass/components/_spiner.scss */
.loading {
  margin: 5% auto;
  border-bottom: 6px solid #fff;
  border-left: 6px solid #fff;
  border-right: 6px solid #165C7D;
  border-top: 6px solid #165C7D;
  border-radius: 100%;
  height: 125px;
  width: 125px;
  -webkit-animation: spin .8s infinite linear;
  -moz-animation: spin .8s infinite linear;
  -ms-animation: spin .8s infinite linear;
  -o-animation: spin .8s infinite linear;
  animation: spin .8s infinite linear; }

/* line 99, sass/components/_spiner.scss */
.loader {
  margin: 100px auto;
  font-size: 25px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0); }

@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7); }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5); }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff; } }

@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7); }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5); }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff; } }

/* line 169, sass/components/_spiner.scss */
.loader {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #ffffff;
  background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0); }

/* line 189, sass/components/_spiner.scss */
.loader:before {
  width: 50%;
  height: 50%;
  background: #ffffff;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: ''; }

/* line 199, sass/components/_spiner.scss */
.loader:after {
  background: #0dc5c1;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

/* line 1, sass/components/_tabs-responsive.scss */
.si-tabs {
  margin-bottom: 24px;
  margin-bottom: 2.4rem; }
  /* line 3, sass/components/_tabs-responsive.scss */
  .si-tabs .fade {
    transition: opacity 0.15s linear; }
  /* line 5, sass/components/_tabs-responsive.scss */
  .si-tabs .fade:not(.show) {
    opacity: 0; }
  /* line 8, sass/components/_tabs-responsive.scss */
  .si-tabs .collapse:not(.show) {
    display: none; }
  /* line 11, sass/components/_tabs-responsive.scss */
  .si-tabs .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease; }
  /* line 18, sass/components/_tabs-responsive.scss */
  .si-tabs .card-body {
    flex: 1 1 auto;
    padding: 24px 24px;
    padding: 2.4rem 2.4rem; }
  /* line 23, sass/components/_tabs-responsive.scss */
  .si-tabs .card-header .btn {
    margin: 0;
    display: block;
    border: none;
    width: 100%;
    text-align: left; }
  /* line 32, sass/components/_tabs-responsive.scss */
  .si-tabs.accordion {
    clear: both; }
    /* line 34, sass/components/_tabs-responsive.scss */
    .si-tabs.accordion > .card {
      overflow: hidden;
      margin-bottom: -1px; }
  /* line 44, sass/components/_tabs-responsive.scss */
  .si-tabs.r-tabs {
    /* Disabled tab */ }
    /* line 45, sass/components/_tabs-responsive.scss */
    .si-tabs.r-tabs .r-tabs-nav {
      display: none; }
    /* line 48, sass/components/_tabs-responsive.scss */
    .si-tabs.r-tabs .r-tabs-accordion-title {
      display: block; }
      /* line 50, sass/components/_tabs-responsive.scss */
      .no-js .si-tabs.r-tabs .r-tabs-accordion-title {
        display: none; }
    /* line 60, sass/components/_tabs-responsive.scss */
    .si-tabs.r-tabs .panel-heading {
      margin-bottom: 0; }
    /* line 64, sass/components/_tabs-responsive.scss */
    .si-tabs.r-tabs .r-tabs-panel,
    .si-tabs.r-tabs .tab-content-title {
      transition: opacity .5s, height .8s;
      opacity: 0;
      height: 0;
      overflow: hidden; }
      /* line 71, sass/components/_tabs-responsive.scss */
      .no-js .si-tabs.r-tabs .r-tabs-panel, .no-js
      .si-tabs.r-tabs .tab-content-title {
        height: 100%; }
    /* line 76, sass/components/_tabs-responsive.scss */
    .si-tabs.r-tabs .r-tabs-panel.r-tabs-state-active {
      display: block;
      opacity: 1;
      height: 100%; }
    /* line 82, sass/components/_tabs-responsive.scss */
    .si-tabs.r-tabs .r-tabs-nav .r-tabs-state-disabled {
      opacity: 0.5; }
  /* line 88, sass/components/_tabs-responsive.scss */
  .si-tabs .card-header,
  .si-tabs .r-tabs-accordion-title {
    font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin: 0;
    margin-bottom: -1px; }
    /* line 93, sass/components/_tabs-responsive.scss */
    .si-tabs .card-header a,
    .si-tabs .card-header .btn,
    .si-tabs .r-tabs-accordion-title a,
    .si-tabs .r-tabs-accordion-title .btn {
      font-size: 24px;
      font-size: 2.4rem;
      padding: 12px 15px;
      padding: 1.2rem 1.5rem;
      padding-left: 30px;
      padding-left: 3rem; }
      /* line 98, sass/components/_tabs-responsive.scss */
      .si-tabs .card-header a:before,
      .si-tabs .card-header .btn:before,
      .si-tabs .r-tabs-accordion-title a:before,
      .si-tabs .r-tabs-accordion-title .btn:before {
        font-family: FontAwesome;
            font-style: normal;
            font-weight: 400;
            text-decoration: inherit;
        font-size: 17px;
        font-size: 1.7rem;
        position: absolute;
        display: inline-block;
        color: inherit; }
      /* line 108, sass/components/_tabs-responsive.scss */
      .si-tabs .card-header a:hover, .si-tabs .card-header a:focus, .si-tabs .card-header a:active,
      .si-tabs .card-header .btn:hover,
      .si-tabs .card-header .btn:focus,
      .si-tabs .card-header .btn:active,
      .si-tabs .r-tabs-accordion-title a:hover,
      .si-tabs .r-tabs-accordion-title a:focus,
      .si-tabs .r-tabs-accordion-title a:active,
      .si-tabs .r-tabs-accordion-title .btn:hover,
      .si-tabs .r-tabs-accordion-title .btn:focus,
      .si-tabs .r-tabs-accordion-title .btn:active {
        box-shadow: none; }
  /* line 115, sass/components/_tabs-responsive.scss */
  .si-tabs .card-header .btn.collapsed,
  .si-tabs .r-tabs-accordion-title a {
    display: block;
    position: relative;
    font-weight: bold;
    background-color: transparent;
    border: none;
    transition: all ease .35s;
    color: #333F48; }
    /* line 124, sass/components/_tabs-responsive.scss */
    .si-tabs .card-header .btn.collapsed:before,
    .si-tabs .r-tabs-accordion-title a:before {
      top: 20px;
      top: 2rem;
      left: 0;
      content: "\f054"; }
    /* line 132, sass/components/_tabs-responsive.scss */
    .si-tabs .card-header .btn.collapsed:hover, .si-tabs .card-header .btn.collapsed:focus, .si-tabs .card-header .btn.collapsed:active,
    .si-tabs .r-tabs-accordion-title a:hover,
    .si-tabs .r-tabs-accordion-title a:focus,
    .si-tabs .r-tabs-accordion-title a:active {
      background: transparent;
      color: #9f3050;
      box-shadow: none; }
  /* line 154, sass/components/_tabs-responsive.scss */
  .si-tabs .r-tabs-accordion-title.r-tabs-state-active a,
  .si-tabs .card-header .btn {
    background: transparent;
    color: #165C7D; }
    /* line 159, sass/components/_tabs-responsive.scss */
    .si-tabs .r-tabs-accordion-title.r-tabs-state-active a:before,
    .si-tabs .card-header .btn:before {
      top: 16px;
      top: 1.6rem;
      left: 0;
      content: "\f078"; }
    /* line 164, sass/components/_tabs-responsive.scss */
    .si-tabs .r-tabs-accordion-title.r-tabs-state-active a:focus,
    .si-tabs .card-header .btn:focus {
      box-shadow: none; }
  /* line 169, sass/components/_tabs-responsive.scss */
  .si-tabs.accordion .card {
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box; }
    /* line 170, sass/components/_tabs-responsive.scss */
    .si-tabs.accordion .card .tab-pane {
      padding: 0 20px 24px;
      padding: 0 2rem 2.4rem;
      padding-left: 30px;
      padding-left: 3rem; }
    /* line 182, sass/components/_tabs-responsive.scss */
    .si-tabs.accordion .card > hr {
      margin-right: 0;
      margin-left: 0; }
    /* line 186, sass/components/_tabs-responsive.scss */
    .si-tabs.accordion .card:last-child .card-header {
      margin-bottom: 0; }
  /* line 196, sass/components/_tabs-responsive.scss */
  .si-tabs.responsive-tab .r-tabs-nav .r-tabs-anchor {
    padding: 11px 20px 12px;
    padding: 1.1rem 2rem 1.2rem;
    color: #41505c;
    border: 1px solid #D0D7DE;
    border-width: 1px 0 0 1px;
    position: relative;
    background: #eef0f3;
    top: 2px;
    display: block;
    margin: 0;
    margin-bottom: 1px; }
    /* line 207, sass/components/_tabs-responsive.scss */
    .si-tabs.responsive-tab .r-tabs-nav .r-tabs-anchor:hover {
      box-shadow: none;
      color: #9f3050; }
    /* line 211, sass/components/_tabs-responsive.scss */
    .si-tabs.responsive-tab .r-tabs-nav .r-tabs-anchor:active, .si-tabs.responsive-tab .r-tabs-nav .r-tabs-anchor:focus {
      box-shadow: none; }
  /* line 216, sass/components/_tabs-responsive.scss */
  .si-tabs.responsive-tab .r-tabs-nav .last .r-tabs-anchor {
    border-right-width: 1px; }
  /* line 220, sass/components/_tabs-responsive.scss */
  .si-tabs.responsive-tab .r-tabs-nav .r-tabs-state-active .r-tabs-anchor {
    background: white;
    padding: 12px 20px;
    padding: 1.2rem 2rem;
    border-bottom: 1px transparent;
    color: #165C7D;
    font-weight: bold; }
  /* line 231, sass/components/_tabs-responsive.scss */
  .si-tabs.responsive-tab .r-tabs-panel.r-tabs-state-active {
    border: 1px solid #D0D7DE;
    padding: 24px 20px;
    padding: 2.4rem 2rem; }
  @media (min-width: 980px) {
    /* line 240, sass/components/_tabs-responsive.scss */
    .si-tabs .tab-content-title {
      display: block; }
    /* line 244, sass/components/_tabs-responsive.scss */
    .si-tabs.r-tabs .r-tabs-nav {
      display: flex;
      flex-wrap: nowrap; }
    /* line 248, sass/components/_tabs-responsive.scss */
    .si-tabs.r-tabs .r-tabs-accordion-title {
      display: none; }
    /* line 251, sass/components/_tabs-responsive.scss */
    .si-tabs.r-tabs .r-tabs-tab {
      display: flex;
      flex: 0 1 auto; }
    /* line 256, sass/components/_tabs-responsive.scss */
    .si-tabs.r-tabs .panel-heading {
      display: flex; } }

/* line 1, sass/components/_tabs.scss */
.wrapper--tabs {
  background: #D0D7DE; }

/* line 5, sass/components/_tabs.scss */
ul.tabs.primary {
  border: none; }
  /* line 9, sass/components/_tabs.scss */
  ul.tabs.primary li {
    text-align: center;
    border: none;
    background: none;
    display: block;
    float: left;
    padding: 0; }
  /* line 17, sass/components/_tabs.scss */
  ul.tabs.primary a {
    display: block;
    padding: 10px;
    color: #75787B;
    border: none; }
    /* line 147, sass/helpers/_text.scss */
    ul.tabs.primary a:visited {
      color: #75787B; }
    /* line 152, sass/helpers/_text.scss */
    ul.tabs.primary a:focus {
      color: #75787B; }
    /* line 157, sass/helpers/_text.scss */
    ul.tabs.primary a:hover {
      color: #9f3050; }
    /* line 162, sass/helpers/_text.scss */
    ul.tabs.primary a:active {
      color: #9f3050; }
    /* line 23, sass/components/_tabs.scss */
    ul.tabs.primary a:hover, ul.tabs.primary a.active {
      background: white;
      color: #333F48;
      border: none;
      border-top: 3px solid #165C7D;
      margin-top: -3px; }

/* line 35, sass/components/_tabs.scss */
.collapse:not(.show) {
  display: none; }

/* line 38, sass/components/_tabs.scss */
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

/* line 45, sass/components/_tabs.scss */
.card-body {
  flex: 1 1 auto;
  padding: 24px 24px;
  padding: 2.4rem 2.4rem; }

/* line 50, sass/components/_tabs.scss */
.card-header .btn {
  margin: 0;
  display: block;
  border: none;
  width: 100%;
  text-align: left; }

/* line 59, sass/components/_tabs.scss */
.accordion {
  clear: both; }
  /* line 61, sass/components/_tabs.scss */
  .accordion > .card {
    overflow: hidden;
    margin-bottom: -1px; }

/* line 70, sass/components/_tabs.scss */
.tabWrapper .tabHeading {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(170px, 1fr)); }
  /* line 73, sass/components/_tabs.scss */
  .tabWrapper .tabHeading button {
    margin: 0; }
  /* line 76, sass/components/_tabs.scss */
  .tabWrapper .tabHeading .btn + .btn {
    margin: 0; }
  /* line 79, sass/components/_tabs.scss */
  .tabWrapper .tabHeading button.btn {
    border: 1px solid #c4cad1;
    border-bottom-color: #fff;
    background: #fff; }
    /* line 84, sass/components/_tabs.scss */
    .tabWrapper .tabHeading button.btn.collapsed {
      font-size: 17px;
      font-size: 1.7rem;
      transition: background-color 300ms ease-out, border-color 320ms ease-out;
      background-color: #D0D7DE;
      border: 1px solid #fdfdfd;
      color: #41505c;
      position: relative;
      z-index: 1; }
      /* line 147, sass/helpers/_text.scss */
      .tabWrapper .tabHeading button.btn.collapsed:visited {
        color: #41505c; }
      /* line 152, sass/helpers/_text.scss */
      .tabWrapper .tabHeading button.btn.collapsed:focus {
        color: #41505c; }
      /* line 157, sass/helpers/_text.scss */
      .tabWrapper .tabHeading button.btn.collapsed:hover {
        color: #41505c; }
      /* line 162, sass/helpers/_text.scss */
      .tabWrapper .tabHeading button.btn.collapsed:active {
        color: #41505c; }
      /* line 78, sass/helpers/_buttons.scss */
      .tabWrapper .tabHeading button.btn.collapsed:focus, .tabWrapper .tabHeading button.btn.collapsed:hover, .tabWrapper .tabHeading button.btn.collapsed:active {
        background-color: #b2bec9;
        border-color: #fdfdfd;
        text-decoration: none;
        box-shadow: rgba(0, 0, 0, 0.16) -2px 0 2px 0, rgba(0, 0, 0, 0.16) 2px 0 2px 0, rgba(0, 0, 0, 0.32) 0 2px 2px 0; }

/* line 89, sass/components/_tabs.scss */
.tabWrapper .tab-pane {
  border: 1px solid #c4cad1;
  border-top-color: #fff;
  padding: 24px 20px;
  padding: 2.4rem 2rem; }

/* line 1, sass/components/_teasers.scss */
.b-media-wrapper {
  line-height: 1; }

/* line 5, sass/components/_teasers.scss */
.teaser {
  position: relative; }
  /* line 7, sass/components/_teasers.scss */
  .teaser headings {
    font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif; }
    /* line 9, sass/components/_teasers.scss */
    .teaser headings.secondary {
      font-family: "MinionPro", Georgia, Times, "Times New Roman", serif; }
  /* line 13, sass/components/_teasers.scss */
  .teaser p {
    margin: 0 0 12px;
    margin: 0 0 1.2rem; }
    /* line 15, sass/components/_teasers.scss */
    .teaser p:last-child {
      margin: 0; }
  /* line 19, sass/components/_teasers.scss */
  .teaser img {
    display: block;
    width: 100%; }
  /* line 24, sass/components/_teasers.scss */
  .teaser h3, .teaser .node-type-recipient .field-name-field-title-long, .node-type-recipient .teaser .field-name-field-title-long,
  .teaser .node-type-staff .field-name-field-title-long, .node-type-staff .teaser .field-name-field-title-long, .teaser .front #paragraphs-item-5555 .group-right .section-title, .front #paragraphs-item-5555 .group-right .teaser .section-title,
  .teaser h2,
  .teaser .main .edan-search-form .form-type-textfield label,
  .main .edan-search-form .form-type-textfield .teaser label {
    margin-bottom: 0; }
  /* line 28, sass/components/_teasers.scss */
  .teaser a:hover {
    text-decoration: none; }
  /* line 31, sass/components/_teasers.scss */
  .teaser .preface {
    font-family: "MinionPro", Georgia, Times, "Times New Roman", serif; }
  /* line 34, sass/components/_teasers.scss */
  .teaser a {
    color: #333F48; }
    /* line 147, sass/helpers/_text.scss */
    .teaser a:visited {
      color: #333F48; }
    /* line 152, sass/helpers/_text.scss */
    .teaser a:focus {
      color: #333F48; }
    /* line 157, sass/helpers/_text.scss */
    .teaser a:hover {
      color: #333F48; }
    /* line 162, sass/helpers/_text.scss */
    .teaser a:active {
      color: #333F48; }
  /* line 37, sass/components/_teasers.scss */
  .teaser img {
    width: 100% !important; }
  /* line 40, sass/components/_teasers.scss */
  .teaser .b-media-wrapper, .teaser .b-text-wrapper {
    display: block; }
  /* line 41, sass/components/_teasers.scss */
  .teaser .b-text-wrapper {
    padding: 12px 10px 24px;
    padding: 1.2rem 1rem 2.4rem; }
    /* line 43, sass/components/_teasers.scss */
    .teaser .b-text-wrapper span {
      display: block; }
      /* line 45, sass/components/_teasers.scss */
      .teaser .b-text-wrapper span.btn-text {
        display: inline-block; }
      /* line 49, sass/components/_teasers.scss */
      .teaser .b-text-wrapper span.date-display-single,
      .teaser .b-text-wrapper span.date-display-single span {
        display: inline-block; }
      /* line 55, sass/components/_teasers.scss */
      .teaser .b-text-wrapper span.date-display-wrapper span {
        display: inline-block; }
  /* line 61, sass/components/_teasers.scss */
  .teaser .h2, .teaser .h3, .teaser .h4,
  .teaser h2,
  .teaser .main .edan-search-form .form-type-textfield label,
  .main .edan-search-form .form-type-textfield .teaser label, .teaser h3, .teaser .node-type-recipient .field-name-field-title-long, .node-type-recipient .teaser .field-name-field-title-long,
  .teaser .node-type-staff .field-name-field-title-long, .node-type-staff .teaser .field-name-field-title-long, .teaser .front #paragraphs-item-5555 .group-right .section-title, .front #paragraphs-item-5555 .group-right .teaser .section-title, .teaser h4, .teaser .node-tdiph .field-type-datetime, .node-tdiph .teaser .field-type-datetime, .teaser .si-tabs.r-tabs .panel-heading, .si-tabs.r-tabs .teaser .panel-heading {
    margin-bottom: 12px;
    margin-bottom: 1.2rem; }
  /* line 64, sass/components/_teasers.scss */
  .teaser .bg-image {
    height: 66.66vw;
    max-height: 300px;
    display: block;
    background-repeat: no-repeat;
    background-size: cover; }
  /* line 71, sass/components/_teasers.scss */
  .teaser .btn-text {
    margin-top: 12px;
    margin-top: 1.2rem; }
  /* line 74, sass/components/_teasers.scss */
  .teaser p + p .btn-text {
    margin-top: 0; }
  /* line 77, sass/components/_teasers.scss */
  .teaser ul {
    margin-top: 12px;
    margin-top: 1.2rem;
    margin-bottom: 12px;
    margin-bottom: 1.2rem; }

/* line 84, sass/components/_teasers.scss */
.teaser-long {
  display: flex;
  flex-flow: column;
  max-width: 100%;
  width: 100%;
  overflow: hidden; }
  /* line 90, sass/components/_teasers.scss */
  .teaser-long .preface {
    font-size: 14px;
    font-size: 1.4rem; }
  /* line 93, sass/components/_teasers.scss */
  .teaser-long .inner {
    background-color: #eef0f3;
    border: 1px solid #e8ebef;
    display: block;
    position: relative;
    z-index: 1;
    border-radius: inherit;
    flex-grow: 1;
    color: #333F48; }
    /* line 147, sass/helpers/_text.scss */
    .teaser-long .inner:visited {
      color: #333F48; }
    /* line 152, sass/helpers/_text.scss */
    .teaser-long .inner:focus {
      color: #333F48; }
    /* line 157, sass/helpers/_text.scss */
    .teaser-long .inner:hover {
      color: #333F48; }
    /* line 162, sass/helpers/_text.scss */
    .teaser-long .inner:active {
      color: #333F48; }
    /* line 103, sass/components/_teasers.scss */
    .teaser-long .inner:after, .teaser-long .inner:before {
      border-radius: inherit;
      z-index: -1;
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0;
      padding: 0; }
    /* line 115, sass/components/_teasers.scss */
    .teaser-long .inner:after {
      opacity: .0625;
      box-shadow: 0 2px 0 0 #000; }
    /* line 119, sass/components/_teasers.scss */
    .panel-region-left-aside .teaser-long .inner,
    .panel-region-right-aside .teaser-long .inner,
    #paragraphs-item-5555 .teaser-long .inner {
      background: transparent;
      border: none; }
      /* line 127, sass/components/_teasers.scss */
      .panel-region-left-aside .teaser-long .inner:after,
      .panel-region-right-aside .teaser-long .inner:after,
      #paragraphs-item-5555 .teaser-long .inner:after {
        box-shadow: none;
        opacity: 0; }
  /* line 133, sass/components/_teasers.scss */
  .teaser-long.hover .inner,
  .teaser-long a.inner:hover,
  .teaser-long .inner.hover {
    background-color: #D0D7DE; }
    /* line 137, sass/components/_teasers.scss */
    .bg-gray-light .teaser-long.hover .inner, .bg-gray-light
    .teaser-long a.inner:hover, .bg-gray-light
    .teaser-long .inner.hover {
      background: none; }
  /* line 142, sass/components/_teasers.scss */
  .teaser-long:focus .inner {
    background-color: #D0D7DE; }
    /* line 144, sass/components/_teasers.scss */
    .bg-gray-light .teaser-long:focus .inner {
      background: none; }
  /* line 149, sass/components/_teasers.scss */
  .teaser-long.file {
    border: none; }
  /* line 156, sass/components/_teasers.scss */
  .teaser-long .file .file-size {
    display: none; }
  /* line 160, sass/components/_teasers.scss */
  .teaser-long.animate * {
    color: #fff; }
  /* line 163, sass/components/_teasers.scss */
  .teaser-long.animate img {
    display: block;
    transition: transform .5s ease-out; }
  /* line 167, sass/components/_teasers.scss */
  .teaser-long.animate .b-text-wrapper {
    transition: all 300ms ease-out;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 16%;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.45); }
  /* line 175, sass/components/_teasers.scss */
  .teaser-long.animate .b-media-wrapper {
    overflow: hidden; }
  /* line 178, sass/components/_teasers.scss */
  .teaser-long.animate .hovertext {
    transition: all 350ms ease-in;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 25%;
    font-weight: 300;
    padding: 12px 10px 24px;
    padding: 1.2rem 1rem 2.4rem;
    opacity: 0; }
  /* line 188, sass/components/_teasers.scss */
  .teaser-long.animate .b-text-wrapper,
  .teaser-long.animate .hovertext {
    text-align: center;
    z-index: 10; }
  /* line 193, sass/components/_teasers.scss */
  .teaser-long.animate .hovertext.caption {
    text-align: left; }
  /* line 196, sass/components/_teasers.scss */
  .teaser-long.animate .h3, .teaser-long.animate .large.hovertext {
    font-size: 34px;
    font-size: 3.4rem;
    line-height: 1.41176; }
  /* line 200, sass/components/_teasers.scss */
  .teaser-long.animate .inner {
    background: #000;
    border: none; }
    /* line 203, sass/components/_teasers.scss */
    .teaser-long.animate .inner:after {
      content: '';
      display: inline-block;
      transition: opacity 300ms ease-in;
      background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8) 40%, rgba(0, 0, 0, 0.95) 65%);
      opacity: .35;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      z-index: 1; }
    /* line 217, sass/components/_teasers.scss */
    .teaser-long.animate .inner a {
      color: white; }
      /* line 147, sass/helpers/_text.scss */
      .teaser-long.animate .inner a:visited {
        color: white; }
      /* line 152, sass/helpers/_text.scss */
      .teaser-long.animate .inner a:focus {
        color: #F0B323; }
      /* line 157, sass/helpers/_text.scss */
      .teaser-long.animate .inner a:hover {
        color: #F0B323; }
      /* line 162, sass/helpers/_text.scss */
      .teaser-long.animate .inner a:active {
        color: #F0B323; }
  /* line 219, sass/components/_teasers.scss */
  .teaser-long.animate .expand-icon {
    background-image: url("../images/bg/expand-white.png");
    background-image: url("data:image/svg+xml, %3Csvg data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'%3E%3Ctitle%3EArtboard 1%3C/title%3E%3Cpath d='M20.41 24.1L7.61 11.31 0 18.83V0h19l-7.58 7.5 16.51 16.6zm15.65 0L52.57 7.55 45 0h19v18.88l-7.62-7.52-12.8 12.79zm7.52 15.72l12.8 12.79L64 45.14V64H45l7.53-7.52-16.47-16.61zm-15.65 0L11.42 56.43 18.94 64H0V45.1l7.62 7.52 12.8-12.8z' fill='%23fff'/%3E%3C/svg%3E"); }
  /* line 224, sass/components/_teasers.scss */
  .teaser-long.animate a:hover .expand-icon,
  .teaser-long.animate a:focus .expand-icon {
    background-image: url("../images/bg/expand-yellow.png");
    background-image: url("data:image/svg+xml, %3Csvg data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'%3E%3Ctitle%3EArtboard 1%3C/title%3E%3Cpath d='M20.41 24.1L7.61 11.31 0 18.83V0h19l-7.58 7.5 16.51 16.6zm15.65 0L52.57 7.55 45 0h19v18.88l-7.62-7.52-12.8 12.79zm7.52 15.72l12.8 12.79L64 45.14V64H45l7.53-7.52-16.47-16.61zm-15.65 0L11.42 56.43 18.94 64H0V45.1l7.62 7.52 12.8-12.8z' fill='%23f0b323'/%3E%3C/svg%3E"); }
  /* line 228, sass/components/_teasers.scss */
  .teaser-long.animate .display-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    font-size: 14px;
    font-size: 1.4rem; }
  /* line 245, sass/components/_teasers.scss */
  .teaser-long.animate:focus .inner img,
  .teaser-long.animate .inner:hover img {
    transform: perspective(1px) scale(1.03);
    transition-duration: 2s; }
  /* line 249, sass/components/_teasers.scss */
  .teaser-long.animate:focus .inner .b-text-wrapper,
  .teaser-long.animate .inner:hover .b-text-wrapper {
    transform: perspective(1px) translateY(-10px);
    transition: all 100ms ease-out;
    opacity: 0; }
  /* line 254, sass/components/_teasers.scss */
  .teaser-long.animate:focus .inner .hovertext,
  .teaser-long.animate .inner:hover .hovertext {
    transform: perspective(1px) translateY(0);
    opacity: 1; }
  /* line 260, sass/components/_teasers.scss */
  .teaser-long.animate.has-text .b-media-wrapper {
    position: relative; }
  /* line 263, sass/components/_teasers.scss */
  .teaser-long.animate.has-text .title {
    padding: 12px 10px;
    padding: 1.2rem 1rem;
    transition: all 300ms ease-out;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 16%;
    z-index: 10;
    text-align: center;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.45); }
  /* line 274, sass/components/_teasers.scss */
  .teaser-long.animate.has-text .caption,
  .teaser-long.animate.has-text .b-text-wrapper {
    position: static;
    bottom: 0;
    opacity: 1; }
  /* line 281, sass/components/_teasers.scss */
  .teaser-long.animate.has-text .inner:hover .b-text-wrapper {
    opacity: 1;
    transform: none; }

/* line 290, sass/components/_teasers.scss */
.teaser-long.border {
  background: none; }

@media (min-width: 768px) {
  /* line 297, sass/components/_teasers.scss */
  .teaser .b-text-wrapper {
    padding-bottom: 12px;
    padding-bottom: 1.2rem; }
  /* line 304, sass/components/_teasers.scss */
  .teaser-list.has-media .inner,
  .paragraphs-item-grid-layout.col-sm-2 .teaser-list.has-media .inner,
  .paragraphs-item-grid-layout.col-sm-2 .teaser-list.has-media .inner {
    display: grid;
    grid-template-columns: 3fr 7fr;
    grid-column-gap: 20px;
    grid-column-gap: 2rem; }
  /* line 311, sass/components/_teasers.scss */
  .paragraphs-item-grid-layout .teaser-list.has-media .inner {
    display: block; }
  /* line 315, sass/components/_teasers.scss */
  .teaser-list.has-media .b-text-wrapper {
    padding: 0; }
  /* line 331, sass/components/_teasers.scss */
  .no-cssgrid .teaser-list.has-media .inner {
    *zoom: 1; }
    /* line 15, sass/helpers/_clearfix.scss */
    .no-cssgrid .teaser-list.has-media .inner:before, .no-cssgrid .teaser-list.has-media .inner:after {
      content: " ";
      display: table;
      line-height: 0; }
    /* line 21, sass/helpers/_clearfix.scss */
    .no-cssgrid .teaser-list.has-media .inner:after {
      clear: both; }
  /* line 334, sass/components/_teasers.scss */
  .no-cssgrid .teaser-list.has-media .b-media-wrapper {
    width: 40%;
    float: left; }
  /* line 338, sass/components/_teasers.scss */
  .no-cssgrid .teaser-list.has-media .b-text-wrapper {
    width: 69%;
    float: right; }
  /* line 345, sass/components/_teasers.scss */
  .panel-region-left-aside .teaser-list.has-media .inner,
  .panel-region-right-aside .teaser-list.has-media .inner {
    display: flex;
    flex-flow: column;
    max-width: 100%;
    width: 100%; }
  /* line 351, sass/components/_teasers.scss */
  .panel-region-left-aside .teaser-list.has-media .b-media-wrapper,
  .panel-region-right-aside .teaser-list.has-media .b-media-wrapper {
    padding: 0; }
  /* line 354, sass/components/_teasers.scss */
  .panel-region-left-aside .teaser-list.has-media .b-text-wrapper,
  .panel-region-right-aside .teaser-list.has-media .b-text-wrapper {
    padding: 24px 20px;
    padding: 2.4rem 2rem; }
  /* line 360, sass/components/_teasers.scss */
  .teaser-list.border .b-text-wrapper {
    padding: 12px 10px;
    padding: 1.2rem 1rem;
    padding-left: 0; } }

@media (min-width: 980px) {
  /* line 369, sass/components/_teasers.scss */
  .teaser .bg-image {
    height: 12vw; }
  /* line 375, sass/components/_teasers.scss */
  .teaser-long.animate.file .inner {
    padding-bottom: 36px;
    padding-bottom: 3.6rem; }
  /* line 378, sass/components/_teasers.scss */
  .teaser-long.animate.file .display-text {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 12px 10px 6px;
    padding: 1.2rem 1rem 0.6rem; }
  /* line 387, sass/components/_teasers.scss */
  .teaser-long.animate.has-text .b-media-wrapper {
    position: static; }
  /* line 390, sass/components/_teasers.scss */
  .teaser-long.animate.has-text .title {
    transition: all 300ms ease-out;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 25%;
    z-index: 10; }
  /* line 398, sass/components/_teasers.scss */
  .teaser-long.animate.has-text .hovertext.caption {
    transition: all 350ms ease-in;
    position: absolute;
    left: 0;
    right: 0;
    font-weight: 300;
    bottom: 36px;
    bottom: 3.6rem;
    padding: 12px 10px;
    padding: 1.2rem 1rem;
    opacity: 0;
    font-size: 14px;
    font-size: 1.4rem;
    background: #000; }
  /* line 412, sass/components/_teasers.scss */
  .teaser-long.animate.has-text:focus .inner .title,
  .teaser-long.animate.has-text .inner:hover .title {
    transform: perspective(1px) translateY(-20px);
    transition: all 100ms ease-out;
    opacity: 0; }
  /* line 417, sass/components/_teasers.scss */
  .teaser-long.animate.has-text:focus .inner .hovertext.caption,
  .teaser-long.animate.has-text .inner:hover .hovertext.caption {
    transform: perspective(1px) translateY(0);
    opacity: 1; }
  /* line 422, sass/components/_teasers.scss */
  .teaser-long.animate.has-text:focus, .teaser-long.animate.has-text:hover {
    overflow: visible; } }

@media (min-width: 1280px) {
  /* line 433, sass/components/_teasers.scss */
  .teaser-list.has-media .inner,
  .paragraphs-item-grid-layout.col-sm-2 .teaser-list.has-media .inner,
  .paragraphs-item-grid-layout.col-sm-2 .teaser-list.has-media .inner {
    grid-template-columns: 1fr 2fr; } }

/* line 6, sass/components/_title.scss */
.panel-region-main.col .page-title {
  max-width: 100%;
  width: 100%; }

/* line 11, sass/components/_title.scss */
.panel-region-main #mini-panel-page_header {
  margin: 48px auto 36px;
  margin: 4.8rem auto 3.6rem; }

/* line 16, sass/components/_title.scss */
.section-title {
  width: 100%; }

/* line 20, sass/components/_title.scss */
.page-title {
  margin: 24px auto;
  margin: 2.4rem auto; }
  /* line 22, sass/components/_title.scss */
  .page-title-wrapper .page-title {
    margin-top: 0; }

/* line 6, sass/components/_views.scss */
.view .item-list ul li h2, .view .item-list ul li .main .edan-search-form .form-type-textfield label, .main .edan-search-form .form-type-textfield .view .item-list ul li label, .view .item-list ul li h3, .view .item-list ul li .node-type-recipient .field-name-field-title-long, .node-type-recipient .view .item-list ul li .field-name-field-title-long,
.view .item-list ul li .node-type-staff .field-name-field-title-long, .node-type-staff .view .item-list ul li .field-name-field-title-long, .view .item-list ul li .front #paragraphs-item-5555 .group-right .section-title, .front #paragraphs-item-5555 .group-right .view .item-list ul li .section-title, .view .item-list ul li h4, .view .item-list ul li .node-tdiph .field-type-datetime, .node-tdiph .view .item-list ul li .field-type-datetime, .view .item-list ul li .si-tabs.r-tabs .panel-heading, .si-tabs.r-tabs .view .item-list ul li .panel-heading, .view .item-list ul li h5 {
  margin-bottom: 12px;
  margin-bottom: 1.2rem; }

/* line 15, sass/components/_views.scss */
.view-events .slide {
  border: 1px solid #D0D7DE;
  padding: 12px 10px;
  padding: 1.2rem 1rem; }

/* line 28, sass/components/_views.scss */
.views-exposed-form .views-exposed-widgets {
  margin-bottom: 24px;
  margin-bottom: 2.4rem; }

/* line 31, sass/components/_views.scss */
.views-exposed-form .views-exposed-widget {
  float: none; }
  /* line 33, sass/components/_views.scss */
  .views-exposed-form .views-exposed-widget .form-submit {
    margin: 0; }
  /* line 36, sass/components/_views.scss */
  .views-exposed-form .views-exposed-widget input[type=text], .views-exposed-form .views-exposed-widget input[type=search], .views-exposed-form .views-exposed-widget input[type=url], .views-exposed-form .views-exposed-widget input[type=number], .views-exposed-form .views-exposed-widget textarea {
    width: 100%; }
  /* line 39, sass/components/_views.scss */
  .views-exposed-form .views-exposed-widget:last-child {
    padding-right: 0; }

/* line 45, sass/components/_views.scss */
.ctools-auto-submit-processed .views-exposed-widget .views-submit-button {
  height: 0;
  padding: 0; }

@media (min-width: 768px) {
  /* line 52, sass/components/_views.scss */
  .views-exposed-form .form-checkboxes .form-item, .views-exposed-form .form-radios .form-item {
    margin-bottom: 8px;
    margin-bottom: 0.8rem; }
  /* line 55, sass/components/_views.scss */
  .views-exposed-form .views-exposed-widgets {
    display: flex; }
  /* line 59, sass/components/_views.scss */
  .views-exposed-form .views-exposed-widget .form-submit {
    margin-top: 30px;
    margin-top: 3rem; }
  /* line 63, sass/components/_views.scss */
  .views-exposed-form fieldset.collapsible {
    margin-top: 36px;
    margin-top: 3.6rem; }
  /* line 66, sass/components/_views.scss */
  .views-exposed-form .views-widget-filter-field_topics,
  .views-exposed-form .views-widget-filter-search_api_views_fulltext {
    width: 45%; }
  /* line 72, sass/components/_views.scss */
  .view-finding-guide .bef-select-as-links > .form-item {
    display: flex; }
    /* line 74, sass/components/_views.scss */
    .view-finding-guide .bef-select-as-links > .form-item > .form-item {
      margin-right: 5px;
      margin-right: 0.5rem; }
      /* line 76, sass/components/_views.scss */
      .view-finding-guide .bef-select-as-links > .form-item > .form-item:after {
        content: '  • '; }
      /* line 83, sass/components/_views.scss */
      .view-finding-guide .bef-select-as-links > .form-item > .form-item:first-child:after, .view-finding-guide .bef-select-as-links > .form-item > .form-item:last-child:after {
        content: ''; }
      /* line 87, sass/components/_views.scss */
      .view-finding-guide .bef-select-as-links > .form-item > .form-item:first-child {
        margin-right: 10px;
        margin-right: 1rem; } }

/* line 2, sass/components/_webforms.scss */
.webform-component .select-or-other > .form-item > label {
  display: none; }

/* Reset so that these appear the same as the label elements they replace. */
/* line 8, sass/components/_webforms.scss */
fieldset.fieldset-invisible,
fieldset.fieldset-invisible > legend {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0;
  background: inherit;
  position: static;
  color: inherit;
  height: auto;
  width: auto;
  font-family: inherit;
  text-indent: 0;
  line-height: inherit;
  text-shadow: unset;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

/* line 28, sass/components/_webforms.scss */
fieldset.fieldset-invisible > legend {
  font-weight: bold;
  font-size: 0.929em; }

/* This margin causes the fieldset to be too big. */
/* line 33, sass/components/_webforms.scss */
fieldset.fieldset-invisible > div > div.form-item:last-child,
fieldset.fieldset-invisible > table {
  margin-bottom: 0; }

/* line 37, sass/components/_webforms.scss */
.webform-datepicker {
  display: flex;
  align-items: center; }
  /* line 40, sass/components/_webforms.scss */
  .webform-datepicker .form-item {
    margin-right: 10px;
    margin-right: 1rem; }

/* line 2, sass/components/edan/_edan-record.scss */
.modal-footer, .modal-header {
  border: none; }

/* line 8, sass/components/edan/_edan-record.scss */
.related-object-groups .edan-search-result a {
  display: grid;
  grid-column-gap: 20px;
  grid-column-gap: 2rem;
  grid-template-columns: 150px 1fr; }

/* line 15, sass/components/edan/_edan-record.scss */
.edan-viewer-container .card-header .btn,
.edan-viewer-container .btn {
  margin-bottom: 12px;
  margin-bottom: 1.2rem;
  text-align: center; }

/* line 20, sass/components/edan/_edan-record.scss */
.edan-viewer-container .btn + .btn {
  margin-left: 0; }

/* line 26, sass/components/edan/_edan-record.scss */
.edan-record .page-title {
  text-align: center; }

/* line 29, sass/components/edan/_edan-record.scss */
.edan-record .btn {
  color: #333F48; }
  /* line 31, sass/components/edan/_edan-record.scss */
  .edan-record .btn:hover {
    box-shadow: none; }

/* line 35, sass/components/edan/_edan-record.scss */
.edan-record .caption {
  font-style: italic;
  max-width: 98%;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.71429; }

/* line 41, sass/components/edan/_edan-record.scss */
.edan-record .no-media {
  width: 96%;
  max-width: 1366px;
  margin-left: auto;
  margin-right: auto; }
  /* line 43, sass/components/edan/_edan-record.scss */
  .edan-record .no-media .media-metadata {
    display: none; }

/* line 47, sass/components/edan/_edan-record.scss */
.edan-record figure {
  display: block !important; }

/* line 50, sass/components/edan/_edan-record.scss */
.edan-record .record-section {
  margin-bottom: 36px;
  margin-bottom: 3.6rem; }

/* line 54, sass/components/edan/_edan-record.scss */
.edan-record .has-media .record-section {
  flex-direction: column; }

/* line 57, sass/components/edan/_edan-record.scss */
.edan-record .has-media .pageHeader,
.edan-record .has-media .media-wrapper {
  order: 1; }

/* line 61, sass/components/edan/_edan-record.scss */
.edan-record .has-media .recordDetails {
  order: 2; }

/* line 65, sass/components/edan/_edan-record.scss */
.edan-record .accordion .inner {
  width: 100%; }

/* line 69, sass/components/edan/_edan-record.scss */
.edan-record .media-slider {
  margin-top: 0;
  border-top: 1px solid #D0D7DE;
  padding-top: 12px;
  padding-top: 1.2rem; }
  /* line 73, sass/components/edan/_edan-record.scss */
  .edan-record .media-slider .slide {
    display: flex; }

/* line 77, sass/components/edan/_edan-record.scss */
.edan-record .media-metadata {
  border: none;
  padding-bottom: 12px;
  padding-bottom: 1.2rem;
  margin: 12px auto 0;
  margin: 1.2rem auto 0; }

/* line 83, sass/components/edan/_edan-record.scss */
.edan-record .btn-tab {
  padding: 12px 10px;
  padding: 1.2rem 1rem;
  text-align: center; }

/* line 90, sass/components/edan/_edan-record.scss */
.popover {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.71429; }

@media (min-width: 768px) {
  /* line 96, sass/components/edan/_edan-record.scss */
  .edan-record .has-media .record-section {
    grid-template-columns: 60% 1fr; }
  /* line 99, sass/components/edan/_edan-record.scss */
  .edan-record .has-media .recordDetails {
    overflow-y: initial;
    background: transparent;
    padding: 0;
    height: auto; } }

/* line 8, sass/components/edan/_facets.scss */
.active-facet-list.reset-list {
  margin-left: 10px;
  margin-left: 1rem; }

/* line 12, sass/components/edan/_facets.scss */
.edan-search-facets {
  margin-bottom: 24px;
  margin-bottom: 2.4rem;
  margin-left: 0;
  background-color: #fff;
  border: 1px solid #D0D7DE;
  padding-bottom: 12px;
  padding-bottom: 1.2rem; }
  /* line 23, sass/components/edan/_facets.scss */
  .edan-search-facets h3, .edan-search-facets .node-type-recipient .field-name-field-title-long, .node-type-recipient .edan-search-facets .field-name-field-title-long,
  .edan-search-facets .node-type-staff .field-name-field-title-long, .node-type-staff .edan-search-facets .field-name-field-title-long, .edan-search-facets .front #paragraphs-item-5555 .group-right .section-title, .front #paragraphs-item-5555 .group-right .edan-search-facets .section-title {
    padding: 0 10px;
    padding: 0 1rem;
    margin-bottom: 0; }
    /* line 26, sass/components/edan/_facets.scss */
    .edan-search-facets h3 a:after, .edan-search-facets .node-type-recipient .field-name-field-title-long a:after, .node-type-recipient .edan-search-facets .field-name-field-title-long a:after, .edan-search-facets .node-type-staff .field-name-field-title-long a:after, .node-type-staff .edan-search-facets .field-name-field-title-long a:after, .edan-search-facets .front #paragraphs-item-5555 .group-right .section-title a:after, .front #paragraphs-item-5555 .group-right .edan-search-facets .section-title a:after {
      content: ''; }
  /* line 31, sass/components/edan/_facets.scss */
  .edan-search-facets a {
    color: #165C7D; }
    /* line 147, sass/helpers/_text.scss */
    .edan-search-facets a:visited {
      color: #165C7D; }
    /* line 152, sass/helpers/_text.scss */
    .edan-search-facets a:focus {
      color: #165C7D; }
    /* line 157, sass/helpers/_text.scss */
    .edan-search-facets a:hover {
      color: #9f3050; }
    /* line 162, sass/helpers/_text.scss */
    .edan-search-facets a:active {
      color: #9f3050; }
  /* line 34, sass/components/edan/_facets.scss */
  .edan-search-facets .icon {
    background: none;
    position: relative;
    display: inline-block; }
    /* line 38, sass/components/edan/_facets.scss */
    .edan-search-facets .icon:before, .edan-search-facets .icon:after {
      display: block;
      height: 3px;
      width: 14px;
      width: 1.4rem;
      position: absolute;
      left: 25%;
      content: ''; }
  /* line 49, sass/components/edan/_facets.scss */
  .edan-search-facets p {
    margin: 12px 10px 0;
    margin: 1.2rem 1rem 0; }
  /* line 53, sass/components/edan/_facets.scss */
  .edan-search-facets .edan-search-facet-list {
    padding: 12px 0;
    padding: 1.2rem 0; }
    /* line 55, sass/components/edan/_facets.scss */
    .edan-search-facets .edan-search-facet-list.filter {
      padding-bottom: 0; }
  /* line 68, sass/components/edan/_facets.scss */
  .edan-search-facets .active-facet-list > li a {
    display: block;
    padding-left: 19px;
    padding-left: 1.9rem; }
  /* line 72, sass/components/edan/_facets.scss */
  .edan-search-facets .active-facet-list > li .icon {
    width: 12px;
    width: 1.2rem;
    height: 12px;
    height: 1.2rem;
    margin-right: 10px;
    margin-right: 1rem;
    margin-left: -24px;
    margin-left: -2.4rem; }
    /* line 77, sass/components/edan/_facets.scss */
    .edan-search-facets .active-facet-list > li .icon:before, .edan-search-facets .active-facet-list > li .icon:after {
      background: #9f3050;
      top: 4px;
      top: 0.4rem; }
    /* line 82, sass/components/edan/_facets.scss */
    .edan-search-facets .active-facet-list > li .icon:before {
      transform: rotate(45deg); }
    /* line 85, sass/components/edan/_facets.scss */
    .edan-search-facets .active-facet-list > li .icon:after {
      transform: rotate(-45deg); }
  /* line 91, sass/components/edan/_facets.scss */
  .edan-search-facets .active-facet-list.clear-all .icon {
    width: 0;
    height: 0;
    margin: 0; }
  /* line 101, sass/components/edan/_facets.scss */
  .edan-search-facets ul li {
    padding: 6px 10px;
    padding: 0.6rem 1rem; }
  /* line 105, sass/components/edan/_facets.scss */
  .edan-search-facets ul.facets li {
    padding: 0; }
  /* line 116, sass/components/edan/_facets.scss */
  .edan-search-facets .root-facet-list > li:first-child {
    border-top-width: 1px; }
  /* line 119, sass/components/edan/_facets.scss */
  .edan-search-facets .root-facet-list > li:last-child {
    border-bottom: 0; }
  /* line 124, sass/components/edan/_facets.scss */
  .edan-search-facets .root-facet-list > li > a .icon,
  .edan-search-facets .root-facet-list > li > a.expand .icon {
    width: 32px;
    width: 3.2rem;
    height: 32px;
    height: 3.2rem;
    min-width: 32px;
    min-width: 3.2rem;
    margin-right: 5px;
    margin-right: 0.5rem;
    position: relative;
    top: 10px;
    top: 1rem; }
    /* line 133, sass/components/edan/_facets.scss */
    .edan-search-facets .root-facet-list > li > a .icon:before, .edan-search-facets .root-facet-list > li > a .icon:after,
    .edan-search-facets .root-facet-list > li > a.expand .icon:before,
    .edan-search-facets .root-facet-list > li > a.expand .icon:after {
      background: #165C7D;
      display: block;
      transition: transform .3s ease-out, transform .3s ease-out, top .3s ease-out;
      content: '';
      top: 45%; }
    /* line 141, sass/components/edan/_facets.scss */
    .edan-search-facets .root-facet-list > li > a .icon:after,
    .edan-search-facets .root-facet-list > li > a.expand .icon:after {
      transform: rotate3d(0, 0, 1, -90deg); }
  /* line 147, sass/components/edan/_facets.scss */
  .edan-search-facets .root-facet-list > li > a.expand .icon:after {
    transform: rotate3d(0, 0, 1, 0deg); }
  /* line 171, sass/components/edan/_facets.scss */
  .edan-search-facets .root-facet-list > li ul {
    display: none;
    height: 0;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.4s cubic-bezier(0.55, 0.085, 0, 0.99);
    -o-transition: all 0.4s cubic-bezier(0.55, 0.085, 0, 0.99);
    transition: all 0.4s cubic-bezier(0.55, 0.085, 0, 0.99); }
  /* line 179, sass/components/edan/_facets.scss */
  .edan-search-facets .root-facet-list > li.expand ul {
    display: block !important;
    height: auto;
    visibility: visible;
    opacity: 1; }
  /* line 196, sass/components/edan/_facets.scss */
  .edan-search-facets .root-facet-list .facets {
    padding-left: 38px;
    padding-left: 3.8rem;
    margin-bottom: 0; }
    /* line 199, sass/components/edan/_facets.scss */
    .edan-search-facets .root-facet-list .facets .facet {
      padding-top: 6px;
      padding-top: 0.6rem; }
      /* line 201, sass/components/edan/_facets.scss */
      .edan-search-facets .root-facet-list .facets .facet span {
        font-size: 1.4rem; }
        /* line 203, sass/components/edan/_facets.scss */
        .edan-search-facets .root-facet-list .facets .facet span:before {
          content: "("; }
        /* line 206, sass/components/edan/_facets.scss */
        .edan-search-facets .root-facet-list .facets .facet span:after {
          content: ")"; }

/* line 222, sass/components/edan/_facets.scss */
.search-results__container.with-facets {
  display: flex;
  flex-direction: column; }
  @media (min-width: 980px) {
    /* line 222, sass/components/edan/_facets.scss */
    .search-results__container.with-facets {
      display: grid;
      grid-template-columns: 3fr 1fr;
      grid-gap: 4rem; } }

/* line 231, sass/components/edan/_facets.scss */
.search-results__container .google-appliance-results-control-bar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

/* line 236, sass/components/edan/_facets.scss */
.search-results__container .google-appliance-search-stats {
  flex-grow: 1; }

@media (min-width: 980px) {
  /* line 239, sass/components/edan/_facets.scss */
  .search-results__container .search-results__skip-to-facets {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    padding: 0;
    border: 0;
    clip: rect(1px, 1px, 1px, 1px); }
    /* line 243, sass/components/edan/_facets.scss */
    .search-results__container .search-results__skip-to-facets:active, .search-results__container .search-results__skip-to-facets:focus {
      position: static;
      left: auto;
      width: auto;
      height: auto;
      clip: auto; } }

/* line 250, sass/components/edan/_facets.scss */
.search-results__container .search-results__facets {
  margin-bottom: 24px;
  margin-bottom: 2.4rem;
  margin-left: 0;
  background-color: #fff;
  border: 1px solid #D0D7DE;
  padding-bottom: 12px;
  padding-bottom: 1.2rem;
  padding: 24px;
  padding: 2.4rem; }

/* line 256, sass/components/edan/_facets.scss */
.search-results__container summary {
  cursor: pointer;
  display: list-item;
  color: #165C7D; }
  /* line 147, sass/helpers/_text.scss */
  .search-results__container summary:visited {
    color: #165C7D; }
  /* line 152, sass/helpers/_text.scss */
  .search-results__container summary:focus {
    color: #9f3050; }
  /* line 157, sass/helpers/_text.scss */
  .search-results__container summary:hover {
    color: #9f3050; }
  /* line 162, sass/helpers/_text.scss */
  .search-results__container summary:active {
    color: #9f3050; }

/* line 261, sass/components/edan/_facets.scss */
.search-results__container .facets-block__title,
.search-results__container .facets-group__title {
  color: inherit;
  display: inline-block; }

/* line 267, sass/components/edan/_facets.scss */
.search-results__container .dynamic_nav_list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 0 0 2rem; }
  @media (min-width: 980px) {
    /* line 267, sass/components/edan/_facets.scss */
    .search-results__container .dynamic_nav_list {
      display: block; } }

/* line 277, sass/components/edan/_facets.scss */
.search-results__container .facet-item {
  padding-right: 2rem; }
  /* line 281, sass/components/edan/_facets.scss */
  .search-results__container .facet-item.separator {
    flex-basis: 100%;
    height: 0;
    margin: 0; }
  /* line 287, sass/components/edan/_facets.scss */
  .search-results__container .facet-item .icon--clear {
    background: none;
    position: relative;
    display: inline-block;
    width: 12px;
    width: 1.2rem;
    height: 12px;
    height: 1.2rem;
    margin-right: 10px;
    margin-right: 1rem; }
    /* line 295, sass/components/edan/_facets.scss */
    .search-results__container .facet-item .icon--clear:before, .search-results__container .facet-item .icon--clear:after {
      display: block;
      height: 3px;
      width: 14px;
      width: 1.4rem;
      position: absolute;
      left: 25%;
      content: '';
      background: #9f3050;
      top: 4px;
      top: 0.4rem; }
    /* line 306, sass/components/edan/_facets.scss */
    .search-results__container .facet-item .icon--clear:before {
      transform: rotate(45deg); }
    /* line 309, sass/components/edan/_facets.scss */
    .search-results__container .facet-item .icon--clear:after {
      transform: rotate(-45deg); }

/* line 1, sass/components/edan/_ogmt.scss */
.ogmt-og-content {
  width: 96%;
  max-width: 1366px;
  margin-left: auto;
  margin-right: auto; }
  /* line 4, sass/components/edan/_ogmt.scss */
  .layout-default .ogmt-og-content {
    width: 100%; }

/* line 1, sass/components/edan/_search-results.scss */
.edan-search {
  width: 96%;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto; }
  /* line 3, sass/components/edan/_search-results.scss */
  .layout-default .edan-search {
    width: 100%; }

/* line 10, sass/components/edan/_search-results.scss */
.edan-search ul.search-results li {
  margin-bottom: 24px; }

/* line 14, sass/components/edan/_search-results.scss */
.edan-search .edan-search-result {
  background-color: transparent;
  border: 1px solid #eef0f3, 2%;
  padding: 0; }

/* line 19, sass/components/edan/_search-results.scss */
.edan-search img {
  display: block;
  margin-bottom: 12px;
  margin-bottom: 1.2rem; }

/* line 23, sass/components/edan/_search-results.scss */
.edan-search .title {
  display: block; }

/* line 26, sass/components/edan/_search-results.scss */
.edan-search .title, .edan-search .field {
  padding: 0 10px;
  padding: 0 1rem; }

/* line 29, sass/components/edan/_search-results.scss */
.edan-search h3, .edan-search .node-type-recipient .field-name-field-title-long, .node-type-recipient .edan-search .field-name-field-title-long,
.edan-search .node-type-staff .field-name-field-title-long, .node-type-staff .edan-search .field-name-field-title-long, .edan-search .front #paragraphs-item-5555 .group-right .section-title, .front #paragraphs-item-5555 .group-right .edan-search .section-title {
  margin-bottom: 12px;
  margin-bottom: 1.2rem; }

/* line 32, sass/components/edan/_search-results.scss */
.edan-search dl {
  margin-bottom: 12px;
  margin-bottom: 1.2rem; }

/* line 38, sass/components/edan/_search-results.scss */
.wrapper--search-tabs .wrapper--btn .btn.active {
  background: #9f3050;
  color: #fff; }
  /* line 147, sass/helpers/_text.scss */
  .wrapper--search-tabs .wrapper--btn .btn.active:visited {
    color: #fff; }
  /* line 152, sass/helpers/_text.scss */
  .wrapper--search-tabs .wrapper--btn .btn.active:focus {
    color: #fff; }
  /* line 157, sass/helpers/_text.scss */
  .wrapper--search-tabs .wrapper--btn .btn.active:hover {
    color: #fff; }
  /* line 162, sass/helpers/_text.scss */
  .wrapper--search-tabs .wrapper--btn .btn.active:active {
    color: #fff; }

@media (min-width: 600px) {
  /* line 46, sass/components/edan/_search-results.scss */
  .edan-results-wrapper.view--grid ul.search-results li {
    margin-bottom: 0; } }

/* line 5, sass/components/navigation/_00-all.scss */
ul.sf-menu.sf-accordion,
ul.sf-menu.sf-accordion .sf-hidden {
  display: none !important;
  position: absolute; }

/* line 10, sass/components/navigation/_00-all.scss */
ul.sf-menu.sf-accordion {
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.24); }
  /* line 12, sass/components/navigation/_00-all.scss */
  ul.sf-menu.sf-accordion,
  ul.sf-menu.sf-accordion ul,
  ul.sf-menu.sf-accordion li {
    float: left;
    width: 100%; }
  /* line 18, sass/components/navigation/_00-all.scss */
  ul.sf-menu.sf-accordion ul {
    margin: 0;
    padding: 0; }
  /* line 23, sass/components/navigation/_00-all.scss */
  ul.sf-menu.sf-accordion.sf-expanded,
  ul.sf-menu.sf-accordion li.sf-expanded > ul {
    left: auto !important;
    position: relative;
    top: auto !important;
    display: block !important; }
  /* line 32, sass/components/navigation/_00-all.scss */
  ul.sf-menu.sf-accordion li.menuparent > a {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    padding-left: 2rem;
    display: block; }
    /* line 68, sass/helpers/_icon.scss */
    ul.sf-menu.sf-accordion li.menuparent > a:before {
      content: "";
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
      color: white;
      position: absolute;
      font-size: 14px;
      font-size: 1.4rem;
      top: 12px;
      top: 1.2rem;
      left: 5px;
      left: 0.5rem; }
    /* line 80, sass/helpers/_icon.scss */
    ul.sf-menu.sf-accordion li.menuparent > a:hover:before {
      color: white; }
  /* line 36, sass/components/navigation/_00-all.scss */
  ul.sf-menu.sf-accordion li.menuparent.sf-expanded {
    background: #1a2024; }
    /* line 38, sass/components/navigation/_00-all.scss */
    ul.sf-menu.sf-accordion li.menuparent.sf-expanded > a:before {
      content: "\f068"; }

/* line 46, sass/components/navigation/_00-all.scss */
.sf-menu,
.sf-menu * {
  list-style: none;
  margin: 0;
  padding: 0; }

/* line 52, sass/components/navigation/_00-all.scss */
.sf-menu {
  line-height: 1.25;
  z-index: 497; }
  /* line 57, sass/components/navigation/_00-all.scss */
  .sf-menu a,
  .sf-menu span.nolink {
    display: block;
    position: relative; }
  /* line 63, sass/components/navigation/_00-all.scss */
  .sf-menu.sf-horizontal ul {
    left: 0;
    position: absolute;
    top: -99999em;
    width: 12em; }
    /* line 68, sass/components/navigation/_00-all.scss */
    .sf-menu.sf-horizontal ul li {
      width: 100%; }
  /* line 72, sass/components/navigation/_00-all.scss */
  .sf-menu.sf-horizontal li {
    float: left;
    position: relative;
    z-index: 498; }
    /* line 76, sass/components/navigation/_00-all.scss */
    .sf-menu.sf-horizontal li:hover > ul,
    .sf-menu.sf-horizontal li.sfHover > ul {
      left: 0;
      top: 2.5em; }
    /* line 81, sass/components/navigation/_00-all.scss */
    .sf-menu.sf-horizontal li li:hover > ul,
    .sf-menu.sf-horizontal li li.sfHover > ul {
      left: 12em;
      top: 0; }
  /* line 87, sass/components/navigation/_00-all.scss */
  .sf-menu.sf-horizontal .sf-hidden {
    left: -99999em !important;
    top: -99999em !important; }

/* line 95, sass/components/navigation/_00-all.scss */
.sf-menu.menu,
.sf-menu.menu ul,
.sf-menu.menu li {
  margin: 0; }

/* line 101, sass/components/navigation/_00-all.scss */
.sf-menu a.sf-with-ul,
.sf-menu span.nolink.sf-with-ul {
  min-width: 1px; }

/* line 105, sass/components/navigation/_00-all.scss */
.sf-sub-indicator {
  background: url("../images/arrows-ffffff.png") no-repeat -10px -100px;
  display: block;
  height: 10px;
  overflow: hidden;
  position: absolute;
  right: 0.75em;
  text-indent: -999em;
  top: 1.05em;
  width: 10px; }

/* line 116, sass/components/navigation/_00-all.scss */
a > .sf-sub-indicator,
span.nolink > .sf-sub-indicator {
  top: 0.8em;
  background-position: 0 -100px; }

/* line 121, sass/components/navigation/_00-all.scss */
a:focus > .sf-sub-indicator,
a:hover > .sf-sub-indicator,
a:active > .sf-sub-indicator,
li:hover > a > .sf-sub-indicator,
li.sfHover > a > .sf-sub-indicator,
span.nolink:hover > .sf-sub-indicator,
li:hover > span.nolink > .sf-sub-indicator,
li.sfHover > span.nolink > .sf-sub-indicator {
  background-position: -10px -100px; }

/* line 131, sass/components/navigation/_00-all.scss */
.sf-menu ul .sf-sub-indicator {
  background-position: -10px 0; }

/* line 134, sass/components/navigation/_00-all.scss */
.sf-menu ul a > .sf-sub-indicator,
.sf-menu ul span.nolink > .sf-sub-indicator {
  background-position: 0 0; }

/* line 138, sass/components/navigation/_00-all.scss */
.sf-menu ul a:focus > .sf-sub-indicator,
.sf-menu ul a:hover > .sf-sub-indicator,
.sf-menu ul a:active > .sf-sub-indicator,
.sf-menu ul li:hover > a > .sf-sub-indicator,
.sf-menu ul li.sfHover > a > .sf-sub-indicator,
.sf-menu ul span.nolink:hover > .sf-sub-indicator,
.sf-menu ul li:hover > span.nolink > .sf-sub-indicator,
.sf-menu ul li.sfHover > span.nolink > .sf-sub-indicator {
  background-position: -10px 0; }

/* line 148, sass/components/navigation/_00-all.scss */
.sf-menu.sf-horizontal.sf-shadow ul,
.sf-menu.sf-vertical.sf-shadow ul,
.sf-menu.sf-navbar.sf-shadow ul ul {
  background: url("../images/shadow.png") no-repeat right bottom;
  padding: 0 8px 9px 0 !important;
  -webkit-border-top-right-radius: 8px;
  -webkit-border-bottom-left-radius: 8px;
  -moz-border-radius-topright: 8px;
  -moz-border-radius-bottomleft: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px; }

/* line 160, sass/components/navigation/_00-all.scss */
.sf-shadow ul.sf-shadow-off {
  background: transparent; }

/* line 163, sass/components/navigation/_00-all.scss */
.sf-menu.rtl,
.sf-menu.rtl li {
  float: right; }

/* line 167, sass/components/navigation/_00-all.scss */
.sf-menu.rtl li:hover > ul,
.sf-menu.rtl li.sfHover > ul {
  left: auto;
  right: 0; }

/* line 172, sass/components/navigation/_00-all.scss */
.sf-menu.rtl li li:hover > ul,
.sf-menu.rtl li li.sfHover > ul {
  left: auto;
  right: 12em; }

/* line 177, sass/components/navigation/_00-all.scss */
.sf-menu.rtl ul {
  left: auto;
  right: 0; }

/* line 181, sass/components/navigation/_00-all.scss */
.sf-menu.rtl .sf-sub-indicator {
  left: 0.75em;
  right: auto;
  background: url("../images/arrows-ffffff-rtl.png") no-repeat -10px -100px; }

/* line 186, sass/components/navigation/_00-all.scss */
.sf-menu.rtl a > .sf-sub-indicator,
.sf-menu.rtl span.nolink > .sf-sub-indicator {
  top: 0.8em;
  background-position: -10px -100px; }

/* line 191, sass/components/navigation/_00-all.scss */
.sf-menu.rtl a:focus > .sf-sub-indicator,
.sf-menu.rtl a:hover > .sf-sub-indicator,
.sf-menu.rtl a:active > .sf-sub-indicator,
.sf-menu.rtl li:hover > a > .sf-sub-indicator,
.sf-menu.rtl li.sfHover > a > .sf-sub-indicator,
.sf-menu.rtl span.nolink:hover > .sf-sub-indicator,
.sf-menu.rtl li:hover > span.nolink > .sf-sub-indicator,
.sf-menu.rtl li.sfHover > span.nolink > .sf-sub-indicator {
  background-position: 0 -100px; }

/* line 201, sass/components/navigation/_00-all.scss */
.sf-menu.rtl ul .sf-sub-indicator {
  background-position: 0 0; }

/* line 204, sass/components/navigation/_00-all.scss */
.sf-menu.rtl ul a > .sf-sub-indicator,
.sf-menu.rtl ul span.nolink > .sf-sub-indicator {
  background-position: -10px 0; }

/* line 208, sass/components/navigation/_00-all.scss */
.sf-menu.rtl ul a:focus > .sf-sub-indicator,
.sf-menu.rtl ul a:hover > .sf-sub-indicator,
.sf-menu.rtl ul a:active > .sf-sub-indicator,
.sf-menu.rtl ul li:hover > a > .sf-sub-indicator,
.sf-menu.rtl ul li.sfHover > a > .sf-sub-indicator,
.sf-menu.rtl ul span.nolink:hover > .sf-sub-indicator,
.sf-menu.rtl ul li:hover > span.nolink > .sf-sub-indicator,
.sf-menu.rtl ul li.sfHover > span.nolink > .sf-sub-indicator {
  background-position: 0 0; }

/* line 218, sass/components/navigation/_00-all.scss */
.sf-menu.rtl .sf-hidden,
.sf-menu.rtl.sf-hidden {
  left: auto !important;
  right: -99999em !important; }

/* line 223, sass/components/navigation/_00-all.scss */
.sf-menu.rtl.sf-horizontal.sf-shadow ul,
.sf-menu.rtl.sf-vertical.sf-shadow ul,
.sf-menu.rtl.sf-navbar.sf-shadow ul ul {
  background-position: bottom left;
  padding: 0 0 9px 8px !important;
  -webkit-border-radius: 8px;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius: 8px;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomleft: 0;
  border-radius: 8px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0; }

/* line 238, sass/components/navigation/_00-all.scss */
.sf-vertical.rtl li:hover > ul,
.sf-vertical.rtl li.sfHover > ul {
  left: auto;
  right: 12em; }

/* line 243, sass/components/navigation/_00-all.scss */
.sf-vertical.rtl .sf-sub-indicator {
  background-position: -10px 0; }

/* line 246, sass/components/navigation/_00-all.scss */
.sf-vertical.rtl a > .sf-sub-indicator,
.sf-vertical.rtl span.nolink > .sf-sub-indicator {
  background-position: 0 0; }

/* line 250, sass/components/navigation/_00-all.scss */
.sf-vertical.rtl a:focus > .sf-sub-indicator,
.sf-vertical.rtl a:hover > .sf-sub-indicator,
.sf-vertical.rtl a:active > .sf-sub-indicator,
.sf-vertical.rtl li:hover > a > .sf-sub-indicator,
.sf-vertical.rtl li.sfHover > a > .sf-sub-indicator,
.sf-vertical.rtl span.nolink:hover > .sf-sub-indicator,
.sf-vertical.rtl li:hover > span.nolink > .sf-sub-indicator,
.sf-vertical.rtl li.sfHover > span.nolink > .sf-sub-indicator {
  background-position: -10px 0; }

/* line 260, sass/components/navigation/_00-all.scss */
.sf-navbar.rtl li li {
  float: right; }

/* line 263, sass/components/navigation/_00-all.scss */
.sf-navbar.rtl ul .sf-sub-indicator {
  background-position: 0 -100px; }

/* line 266, sass/components/navigation/_00-all.scss */
.sf-navbar.rtl ul a > .sf-sub-indicator {
  background-position: -10px -100px; }

/* line 269, sass/components/navigation/_00-all.scss */
.sf-navbar.rtl ul a:focus > .sf-sub-indicator,
.sf-navbar.rtl ul a:hover > .sf-sub-indicator,
.sf-navbar.rtl ul a:active > .sf-sub-indicator,
.sf-navbar.rtl ul li:hover > a > .sf-sub-indicator,
.sf-navbar.rtl ul li.sfHover > a > .sf-sub-indicator,
.sf-navbar.rtl ul span.nolink:hover > .sf-sub-indicator,
.sf-navbar.rtl ul li:hover > span.nolink > .sf-sub-indicator,
.sf-navbar.rtl ul li.sfHover > span.nolink > .sf-sub-indicator {
  background-position: 0 -100px; }

/* line 279, sass/components/navigation/_00-all.scss */
.sf-navbar.rtl ul ul .sf-sub-indicator {
  background-position: 0 0; }

/* line 282, sass/components/navigation/_00-all.scss */
.sf-navbar.rtl ul ul a > .sf-sub-indicator,
.sf-navbar.rtl ul ul span.nolink > .sf-sub-indicator {
  background-position: -10px 0; }

/* line 286, sass/components/navigation/_00-all.scss */
.sf-navbar.rtl ul ul a:focus > .sf-sub-indicator,
.sf-navbar.rtl ul ul a:hover > .sf-sub-indicator,
.sf-navbar.rtl ul ul a:active > .sf-sub-indicator,
.sf-navbar.rtl ul ul li:hover > a > .sf-sub-indicator,
.sf-navbar.rtl ul ul li.sfHover > a > .sf-sub-indicator,
.sf-navbar.rtl ul ul span.nolink:hover > .sf-sub-indicator,
.sf-navbar.rtl ul ul li:hover > span.nolink > .sf-sub-indicator,
.sf-navbar.rtl ul ul li.sfHover > span.nolink > .sf-sub-indicator {
  background-position: 0 0; }

/* line 296, sass/components/navigation/_00-all.scss */
.sf-navbar.rtl li li:hover > ul,
.sf-navbar.rtl li li.sfHover > ul {
  left: auto;
  right: 0; }

/* line 301, sass/components/navigation/_00-all.scss */
.sf-navbar.rtl li li li:hover > ul,
.sf-navbar.rtl li li li.sfHover > ul {
  left: auto;
  right: 12em; }

/* line 306, sass/components/navigation/_00-all.scss */
.sf-navbar.rtl > li > ul {
  background: transparent;
  padding: 0;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-topleft: 0;
  -webkit-border-top-left-radius: 0;
  -webkit-border-bottom-right-radius: 0; }

/* line 15, sass/components/navigation/_main-nav.scss */
.lines-button {
  background: #165C7D;
  border: 0;
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 18px 10px;
  padding: 1.8rem 1rem;
  user-select: none;
  border-radius: 0;
  margin: 9px 0 0;
  margin: 0.9rem 0 0; }
  /* line 27, sass/components/navigation/_main-nav.scss */
  .lines-button:hover {
    opacity: 1; }
  /* line 31, sass/components/navigation/_main-nav.scss */
  .lines-button:active {
    background: rgba(0, 0, 0, 0.1);
    transition: none; }

/* line 49, sass/components/navigation/_main-nav.scss */
.lines {
  background: white;
  border-radius: 2px;
  display: block;
  height: 3px;
  transition: transform .3s ease-out, transform .3s ease-out, top .3s ease-out;
  width: 24px;
  position: relative;
  top: -.13em;
  /*create the upper and lower lines as pseudo-elements of the middle line*/ }
  /* line 56, sass/components/navigation/_main-nav.scss */
  .lines:before, .lines:after {
    background: white;
    border-radius: 2px;
    display: block;
    height: 3px;
    transition: transform .3s ease-out, transform .3s ease-out, top .3s ease-out;
    width: 24px;
    position: absolute;
    left: 0;
    content: '';
    transform-origin: 1.71429px center; }
  /* line 64, sass/components/navigation/_main-nav.scss */
  .lines:before {
    top: 8px; }
  /* line 65, sass/components/navigation/_main-nav.scss */
  .lines:after {
    top: -8px; }

/* line 72, sass/components/navigation/_main-nav.scss */
.sf-expanded.lines-button .lines {
  background: transparent;
  top: .4em; }
  /* line 75, sass/components/navigation/_main-nav.scss */
  .sf-expanded.lines-button .lines:before, .sf-expanded.lines-button .lines:after {
    transform-origin: 50% 50%;
    top: -4px; }
  /* line 79, sass/components/navigation/_main-nav.scss */
  .sf-expanded.lines-button .lines:before {
    transform: rotate3d(0, 0, 1, 45deg); }
  /* line 82, sass/components/navigation/_main-nav.scss */
  .sf-expanded.lines-button .lines:after {
    transform: rotate3d(0, 0, 1, -45deg); }

/* line 90, sass/components/navigation/_main-nav.scss */
.region-navigation .lines-button,
.region-header .lines-button {
  position: absolute;
  right: 0; }

/* line 94, sass/components/navigation/_main-nav.scss */
.region-navigation ul.sf-menu.sf-accordion, .region-navigation ul.sf-menu.sf-accordion ul, .region-navigation ul.sf-menu.sf-accordion li,
.region-header ul.sf-menu.sf-accordion,
.region-header ul.sf-menu.sf-accordion ul,
.region-header ul.sf-menu.sf-accordion li {
  float: none !important; }

/* line 97, sass/components/navigation/_main-nav.scss */
.region-navigation .sf-menu,
.region-navigation .menu,
.region-header .sf-menu,
.region-header .menu {
  line-height: 1.5;
  margin: 0;
  padding: 0;
  background: #165C7D;
  width: 100%;
  max-width: 30em; }
  /* line 105, sass/components/navigation/_main-nav.scss */
  .region-navigation .sf-menu.sf-accordion.sf-expanded,
  .region-navigation .menu.sf-accordion.sf-expanded,
  .region-header .sf-menu.sf-accordion.sf-expanded,
  .region-header .menu.sf-accordion.sf-expanded {
    top: 48px !important; }
  /* line 109, sass/components/navigation/_main-nav.scss */
  .region-navigation .sf-menu.sf-navbar,
  .region-navigation .menu.sf-navbar,
  .region-header .sf-menu.sf-navbar,
  .region-header .menu.sf-navbar {
    width: 100%; }
  /* line 112, sass/components/navigation/_main-nav.scss */
  .region-navigation .sf-menu ul,
  .region-navigation .menu ul,
  .region-header .sf-menu ul,
  .region-header .menu ul {
    padding-left: 0; }
  /* line 116, sass/components/navigation/_main-nav.scss */
  .region-navigation .sf-menu li a,
  .region-navigation .menu li a,
  .region-header .sf-menu li a,
  .region-header .menu li a {
    font-size: 1em;
    font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: 1px;
    font-weight: 500;
    color: white;
    padding: 8px 12px;
    padding: 0.8rem 1.2rem; }
    /* line 147, sass/helpers/_text.scss */
    .region-navigation .sf-menu li a:visited,
    .region-navigation .menu li a:visited,
    .region-header .sf-menu li a:visited,
    .region-header .menu li a:visited {
      color: white; }
    /* line 152, sass/helpers/_text.scss */
    .region-navigation .sf-menu li a:focus,
    .region-navigation .menu li a:focus,
    .region-header .sf-menu li a:focus,
    .region-header .menu li a:focus {
      color: white; }
    /* line 157, sass/helpers/_text.scss */
    .region-navigation .sf-menu li a:hover,
    .region-navigation .menu li a:hover,
    .region-header .sf-menu li a:hover,
    .region-header .menu li a:hover {
      color: white; }
    /* line 162, sass/helpers/_text.scss */
    .region-navigation .sf-menu li a:active,
    .region-navigation .menu li a:active,
    .region-header .sf-menu li a:active,
    .region-header .menu li a:active {
      color: white; }
  /* line 125, sass/components/navigation/_main-nav.scss */
  .region-navigation .sf-menu li ul,
  .region-navigation .menu li ul,
  .region-header .sf-menu li ul,
  .region-header .menu li ul {
    background: #1a2024; }
  /* line 133, sass/components/navigation/_main-nav.scss */
  .region-navigation .sf-menu > li > a,
  .region-navigation .menu > li > a,
  .region-header .sf-menu > li > a,
  .region-header .menu > li > a {
    padding-left: 20px;
    padding-left: 2rem;
    margin-left: 10px;
    margin-left: 1rem; }
    /* line 136, sass/components/navigation/_main-nav.scss */
    .region-navigation .sf-menu > li > a.btn,
    .region-navigation .menu > li > a.btn,
    .region-header .sf-menu > li > a.btn,
    .region-header .menu > li > a.btn {
      display: inline-block; }
  /* line 141, sass/components/navigation/_main-nav.scss */
  .region-navigation .sf-menu .sf-mega-section,
  .region-navigation .menu .sf-mega-section,
  .region-header .sf-menu .sf-mega-section,
  .region-header .menu .sf-mega-section {
    display: none; }
    /* line 143, sass/components/navigation/_main-nav.scss */
    .region-navigation .sf-menu .sf-mega-section.wrapper-sub-menu,
    .region-navigation .menu .sf-mega-section.wrapper-sub-menu,
    .region-header .sf-menu .sf-mega-section.wrapper-sub-menu,
    .region-header .menu .sf-mega-section.wrapper-sub-menu {
      display: block; }
  /* line 148, sass/components/navigation/_main-nav.scss */
  .region-navigation .sf-menu .sf-depth-1.sf-expanded .sf-mega .sub-menu > ul,
  .region-navigation .sf-menu .sf-depth-1.sf-expanded .sf-expanded > ul,
  .region-navigation .menu .sf-depth-1.sf-expanded .sf-mega .sub-menu > ul,
  .region-navigation .menu .sf-depth-1.sf-expanded .sf-expanded > ul,
  .region-header .sf-menu .sf-depth-1.sf-expanded .sf-mega .sub-menu > ul,
  .region-header .sf-menu .sf-depth-1.sf-expanded .sf-expanded > ul,
  .region-header .menu .sf-depth-1.sf-expanded .sf-mega .sub-menu > ul,
  .region-header .menu .sf-depth-1.sf-expanded .sf-expanded > ul {
    display: block !important;
    position: relative;
    top: 0 !important;
    left: 0 !important; }
  /* line 156, sass/components/navigation/_main-nav.scss */
  .region-navigation .sf-menu ul li,
  .region-navigation .sf-menu ul ul li,
  .region-navigation .menu ul li,
  .region-navigation .menu ul ul li,
  .region-header .sf-menu ul li,
  .region-header .sf-menu ul ul li,
  .region-header .menu ul li,
  .region-header .menu ul ul li {
    background: transparent; }

@media (min-width: 980px) {
  /* line 88, sass/components/navigation/_main-nav.scss */
  .region-navigation,
  .region-header {
    background: #333F48; }
    /* line 164, sass/components/navigation/_main-nav.scss */
    .region-navigation .sf-accordion-toggle,
    .region-header .sf-accordion-toggle {
      display: none; }
    /* line 169, sass/components/navigation/_main-nav.scss */
    .region-navigation .sf-menu.sf-style-none,
    .region-header .sf-menu.sf-style-none {
      background: #333F48;
      box-shadow: none;
      max-width: 100%;
      display: flex !important;
      align-items: center;
      justify-content: flex-end; }
      /* line 177, sass/components/navigation/_main-nav.scss */
      .no-flexbox .region-navigation .sf-menu.sf-style-none, .no-flexbox
      .region-header .sf-menu.sf-style-none {
        *zoom: 1;
        float: right; }
        /* line 15, sass/helpers/_clearfix.scss */
        .no-flexbox .region-navigation .sf-menu.sf-style-none:before, .no-flexbox .region-navigation .sf-menu.sf-style-none:after, .no-flexbox
        .region-header .sf-menu.sf-style-none:before, .no-flexbox
        .region-header .sf-menu.sf-style-none:after {
          content: " ";
          display: table;
          line-height: 0; }
        /* line 21, sass/helpers/_clearfix.scss */
        .no-flexbox .region-navigation .sf-menu.sf-style-none:after, .no-flexbox
        .region-header .sf-menu.sf-style-none:after {
          clear: both; }
      /* line 181, sass/components/navigation/_main-nav.scss */
      .region-navigation .sf-menu.sf-style-none li,
      .region-header .sf-menu.sf-style-none li {
        position: static; }
        /* line 183, sass/components/navigation/_main-nav.scss */
        .region-navigation .sf-menu.sf-style-none li a,
        .region-header .sf-menu.sf-style-none li a {
          color: white; }
          /* line 147, sass/helpers/_text.scss */
          .region-navigation .sf-menu.sf-style-none li a:visited,
          .region-header .sf-menu.sf-style-none li a:visited {
            color: white; }
          /* line 152, sass/helpers/_text.scss */
          .region-navigation .sf-menu.sf-style-none li a:focus,
          .region-header .sf-menu.sf-style-none li a:focus {
            color: #ffd400; }
          /* line 157, sass/helpers/_text.scss */
          .region-navigation .sf-menu.sf-style-none li a:hover,
          .region-header .sf-menu.sf-style-none li a:hover {
            color: #ffd400; }
          /* line 162, sass/helpers/_text.scss */
          .region-navigation .sf-menu.sf-style-none li a:active,
          .region-header .sf-menu.sf-style-none li a:active {
            color: #ffd400; }
          /* line 186, sass/components/navigation/_main-nav.scss */
          .region-navigation .sf-menu.sf-style-none li a:focus, .region-navigation .sf-menu.sf-style-none li a:hover, .region-navigation .sf-menu.sf-style-none li a:active,
          .region-header .sf-menu.sf-style-none li a:focus,
          .region-header .sf-menu.sf-style-none li a:hover,
          .region-header .sf-menu.sf-style-none li a:active {
            border-bottom: none; }
        /* line 193, sass/components/navigation/_main-nav.scss */
        .region-navigation .sf-menu.sf-style-none li ul,
        .region-header .sf-menu.sf-style-none li ul {
          background: #165C7D; }
        /* line 197, sass/components/navigation/_main-nav.scss */
        .region-navigation .sf-menu.sf-style-none li.sf-depth-2 ul,
        .region-header .sf-menu.sf-style-none li.sf-depth-2 ul {
          display: none !important; }
        /* line 201, sass/components/navigation/_main-nav.scss */
        .region-navigation .sf-menu.sf-style-none li.active-trail > a,
        .region-header .sf-menu.sf-style-none li.active-trail > a {
          color: #ffd400; }
      /* line 207, sass/components/navigation/_main-nav.scss */
      .region-navigation .sf-menu.sf-style-none a > .sf-sub-indicator, .region-navigation .sf-menu.sf-style-none span.nolink > .sf-sub-indicator,
      .region-header .sf-menu.sf-style-none a > .sf-sub-indicator,
      .region-header .sf-menu.sf-style-none span.nolink > .sf-sub-indicator {
        top: .8em;
        right: .5em; }
      /* line 211, sass/components/navigation/_main-nav.scss */
      .region-navigation .sf-menu.sf-style-none > li,
      .region-header .sf-menu.sf-style-none > li {
        flex: 0 0 auto; }
        /* line 216, sass/components/navigation/_main-nav.scss */
        .region-navigation .sf-menu.sf-style-none > li > a,
        .region-header .sf-menu.sf-style-none > li > a {
          border: none;
          background: transparent;
          display: block;
          margin-left: 0;
          transition: all ease-in .4ms;
          font-size: 19px;
          font-size: 1.9rem;
          line-height: 1.26316;
          -webkit-transition: all 0.4s cubic-bezier(0.55, 0.085, 0, 0.99);
          -o-transition: all 0.4s cubic-bezier(0.55, 0.085, 0, 0.99);
          transition: all 0.4s cubic-bezier(0.55, 0.085, 0, 0.99);
          text-align: center;
          padding: 8px 12px 12px;
          padding: 0.8rem 1.2rem 1.2rem; }
        /* line 231, sass/components/navigation/_main-nav.scss */
        .region-navigation .sf-menu.sf-style-none > li > a.active,
        .region-navigation .sf-menu.sf-style-none > li > a:hover,
        .region-navigation .sf-menu.sf-style-none > li.active-trail > a,
        .region-navigation .sf-menu.sf-style-none > li.sfHover > a,
        .region-header .sf-menu.sf-style-none > li > a.active,
        .region-header .sf-menu.sf-style-none > li > a:hover,
        .region-header .sf-menu.sf-style-none > li.active-trail > a,
        .region-header .sf-menu.sf-style-none > li.sfHover > a {
          color: #2fa4da; }
        /* line 239, sass/components/navigation/_main-nav.scss */
        .region-navigation .sf-menu.sf-style-none > li > a:hover:after,
        .region-navigation .sf-menu.sf-style-none > li.sfHover > a:after,
        .region-header .sf-menu.sf-style-none > li > a:hover:after,
        .region-header .sf-menu.sf-style-none > li.sfHover > a:after {
          bottom: -2px;
          left: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-bottom-color: #165C7D;
          border-width: 10px;
          margin-left: -10px;
          opacity: 1;
          animation: menuArrow 0.7s cubic-bezier(0.55, 0.085, 0, 0.99) forwards; }
        /* line 256, sass/components/navigation/_main-nav.scss */
        .region-navigation .sf-menu.sf-style-none > li > ul,
        .region-header .sf-menu.sf-style-none > li > ul {
          padding-left: 0;
          -webkit-box-shadow: rgba(0, 0, 0, 0.3) 1px 1px 2px;
          box-shadow: rgba(0, 0, 0, 0.3) 1px 1px 2px;
          border-bottom: none;
          min-width: 3em; }
        /* line 265, sass/components/navigation/_main-nav.scss */
        .region-navigation .sf-menu.sf-style-none > li.sfHover ul,
        .region-header .sf-menu.sf-style-none > li.sfHover ul {
          float: none !important; }
        /* line 268, sass/components/navigation/_main-nav.scss */
        .region-navigation .sf-menu.sf-style-none > li.sfHover .sub-list,
        .region-header .sf-menu.sf-style-none > li.sfHover .sub-list {
          display: block !important; }
        /* line 272, sass/components/navigation/_main-nav.scss */
        .no-js .region-navigation .sf-menu.sf-style-none > li.sfHover ul, .no-js
        .region-header .sf-menu.sf-style-none > li.sfHover ul {
          display: block !important; }
        /* line 277, sass/components/navigation/_main-nav.scss */
        .region-navigation .sf-menu.sf-style-none > li.sfHover .wrapper-sub-menu ul,
        .region-header .sf-menu.sf-style-none > li.sfHover .wrapper-sub-menu ul {
          display: block !important; }
      /* line 283, sass/components/navigation/_main-nav.scss */
      .region-navigation .sf-menu.sf-style-none .sf-mega,
      .region-header .sf-menu.sf-style-none .sf-mega {
        background-color: #165C7D;
        padding: 36px 0 24px;
        padding: 3.6rem 0 2.4rem;
        background: #165C7D;
        left: 0;
        right: 0; }
        /* line 285, sass/components/navigation/_main-nav.scss */
        .region-navigation .sf-menu.sf-style-none .sf-mega *,
        .region-header .sf-menu.sf-style-none .sf-mega * {
          color: white; }
        /* line 288, sass/components/navigation/_main-nav.scss */
        .region-navigation .sf-menu.sf-style-none .sf-mega .form-text,
        .region-header .sf-menu.sf-style-none .sf-mega .form-text {
          color: #41505c; }
        /* line 293, sass/components/navigation/_main-nav.scss */
        .region-navigation .sf-menu.sf-style-none .sf-mega p,
        .region-header .sf-menu.sf-style-none .sf-mega p {
          line-height: 1.5;
          margin: 0 0 12px;
          margin: 0 0 1.2rem; }
          /* line 296, sass/components/navigation/_main-nav.scss */
          .region-navigation .sf-menu.sf-style-none .sf-mega p:last-child,
          .region-header .sf-menu.sf-style-none .sf-mega p:last-child {
            margin: 0; }
        /* line 301, sass/components/navigation/_main-nav.scss */
        .region-navigation .sf-menu.sf-style-none .sf-mega .gamma a,
        .region-navigation .sf-menu.sf-style-none .sf-mega h2 a,
        .region-navigation .sf-menu.sf-style-none .sf-mega .main .edan-search-form .form-type-textfield label a,
        .main .edan-search-form .form-type-textfield .region-navigation .sf-menu.sf-style-none .sf-mega label a,
        .region-header .sf-menu.sf-style-none .sf-mega .gamma a,
        .region-header .sf-menu.sf-style-none .sf-mega h2 a,
        .region-header .sf-menu.sf-style-none .sf-mega .main .edan-search-form .form-type-textfield label a,
        .main .edan-search-form .form-type-textfield .region-header .sf-menu.sf-style-none .sf-mega label a {
          font-weight: 600; }
        /* line 305, sass/components/navigation/_main-nav.scss */
        .region-navigation .sf-menu.sf-style-none .sf-mega .gamma,
        .region-header .sf-menu.sf-style-none .sf-mega .gamma {
          margin: 0 0 12px;
          margin: 0 0 1.2rem;
          line-height: normal; }
        /* line 311, sass/components/navigation/_main-nav.scss */
        .region-navigation .sf-menu.sf-style-none .sf-mega .inner,
        .region-header .sf-menu.sf-style-none .sf-mega .inner {
          display: block;
          margin-left: 0; }
        /* line 315, sass/components/navigation/_main-nav.scss */
        .region-navigation .sf-menu.sf-style-none .sf-mega .b-text-wrapper,
        .region-header .sf-menu.sf-style-none .sf-mega .b-text-wrapper {
          padding: 12px 0 0;
          padding: 1.2rem 0 0; }
        /* line 318, sass/components/navigation/_main-nav.scss */
        .region-navigation .sf-menu.sf-style-none .sf-mega ul,
        .region-header .sf-menu.sf-style-none .sf-mega ul {
          position: static; }
          /* line 320, sass/components/navigation/_main-nav.scss */
          .region-navigation .sf-menu.sf-style-none .sf-mega ul ul li,
          .region-header .sf-menu.sf-style-none .sf-mega ul ul li {
            background: #165C7D; }
        /* line 324, sass/components/navigation/_main-nav.scss */
        .region-navigation .sf-menu.sf-style-none .sf-mega > .inner,
        .region-header .sf-menu.sf-style-none .sf-mega > .inner {
          width: 96%;
          max-width: 1366px;
          margin-left: auto;
          margin-right: auto;
          display: grid;
          grid-template-columns: repeat(12, 1fr);
          grid-gap: 40px;
          grid-gap: 4rem; }
          /* line 329, sass/components/navigation/_main-nav.scss */
          .no-cssgrid .region-navigation .sf-menu.sf-style-none .sf-mega > .inner, .no-cssgrid
          .region-header .sf-menu.sf-style-none .sf-mega > .inner {
            *zoom: 1; }
            /* line 15, sass/helpers/_clearfix.scss */
            .no-cssgrid .region-navigation .sf-menu.sf-style-none .sf-mega > .inner:before, .no-cssgrid .region-navigation .sf-menu.sf-style-none .sf-mega > .inner:after, .no-cssgrid
            .region-header .sf-menu.sf-style-none .sf-mega > .inner:before, .no-cssgrid
            .region-header .sf-menu.sf-style-none .sf-mega > .inner:after {
              content: " ";
              display: table;
              line-height: 0; }
            /* line 21, sass/helpers/_clearfix.scss */
            .no-cssgrid .region-navigation .sf-menu.sf-style-none .sf-mega > .inner:after, .no-cssgrid
            .region-header .sf-menu.sf-style-none .sf-mega > .inner:after {
              clear: both; }
            /* line 331, sass/components/navigation/_main-nav.scss */
            .no-cssgrid .region-navigation .sf-menu.sf-style-none .sf-mega > .inner .sf-mega-section, .no-cssgrid
            .region-header .sf-menu.sf-style-none .sf-mega > .inner .sf-mega-section {
              float: left; }
          /* line 336, sass/components/navigation/_main-nav.scss */
          .region-navigation .sf-menu.sf-style-none .sf-mega > .inner > .sf-mega-section,
          .region-header .sf-menu.sf-style-none .sf-mega > .inner > .sf-mega-section {
            display: block;
            grid-column-end: span 2; }
            /* line 339, sass/components/navigation/_main-nav.scss */
            .region-navigation .sf-menu.sf-style-none .sf-mega > .inner > .sf-mega-section .colorbox-link-close,
            .region-header .sf-menu.sf-style-none .sf-mega > .inner > .sf-mega-section .colorbox-link-close {
              padding-right: 24px;
              padding-right: 2.4rem; }
            /* line 342, sass/components/navigation/_main-nav.scss */
            .region-navigation .sf-menu.sf-style-none .sf-mega > .inner > .sf-mega-section.last,
            .region-header .sf-menu.sf-style-none .sf-mega > .inner > .sf-mega-section.last {
              grid-column-end: span 3; }
              /* line 344, sass/components/navigation/_main-nav.scss */
              .region-navigation .sf-menu.sf-style-none .sf-mega > .inner > .sf-mega-section.last .teaser img,
              .region-header .sf-menu.sf-style-none .sf-mega > .inner > .sf-mega-section.last .teaser img {
                width: 215px !important; }
            /* line 348, sass/components/navigation/_main-nav.scss */
            .region-navigation .sf-menu.sf-style-none .sf-mega > .inner > .sf-mega-section.wrapper-sub-menu,
            .region-header .sf-menu.sf-style-none .sf-mega > .inner > .sf-mega-section.wrapper-sub-menu {
              grid-column-end: span 7;
              border-right: #1a6c93 1px solid;
              border-left: #1a6c93 1px solid;
              padding: 0 40px;
              padding: 0 4rem; }
              /* line 363, sass/components/navigation/_main-nav.scss */
              .region-navigation .sf-menu.sf-style-none .sf-mega > .inner > .sf-mega-section.wrapper-sub-menu ul a,
              .region-header .sf-menu.sf-style-none .sf-mega > .inner > .sf-mega-section.wrapper-sub-menu ul a {
                padding: 12px 20px 0;
                padding: 1.2rem 2rem 0; }
              /* line 366, sass/components/navigation/_main-nav.scss */
              .region-navigation .sf-menu.sf-style-none .sf-mega > .inner > .sf-mega-section.wrapper-sub-menu .sf-depth-2.first > a,
              .region-header .sf-menu.sf-style-none .sf-mega > .inner > .sf-mega-section.wrapper-sub-menu .sf-depth-2.first > a {
                padding-top: 0; }
              /* line 373, sass/components/navigation/_main-nav.scss */
              .region-navigation .sf-menu.sf-style-none .sf-mega > .inner > .sf-mega-section.wrapper-sub-menu .sub-menu li,
              .region-header .sf-menu.sf-style-none .sf-mega > .inner > .sf-mega-section.wrapper-sub-menu .sub-menu li {
                font-size: 19px;
                font-size: 1.9rem; }
              /* line 376, sass/components/navigation/_main-nav.scss */
              .region-navigation .sf-menu.sf-style-none .sf-mega > .inner > .sf-mega-section.wrapper-sub-menu .sub-menu > ul,
              .region-header .sf-menu.sf-style-none .sf-mega > .inner > .sf-mega-section.wrapper-sub-menu .sub-menu > ul {
                display: grid !important;
                grid-template-columns: repeat(12, 1fr);
                justify-items: center;
                grid-gap: 20px;
                grid-gap: 2rem;
                width: 100%; }
                /* line 383, sass/components/navigation/_main-nav.scss */
                .region-navigation .sf-menu.sf-style-none .sf-mega > .inner > .sf-mega-section.wrapper-sub-menu .sub-menu > ul > li,
                .region-header .sf-menu.sf-style-none .sf-mega > .inner > .sf-mega-section.wrapper-sub-menu .sub-menu > ul > li {
                  grid-column-end: span 6; }
                  /* line 385, sass/components/navigation/_main-nav.scss */
                  .region-navigation .sf-menu.sf-style-none .sf-mega > .inner > .sf-mega-section.wrapper-sub-menu .sub-menu > ul > li > a,
                  .region-header .sf-menu.sf-style-none .sf-mega > .inner > .sf-mega-section.wrapper-sub-menu .sub-menu > ul > li > a {
                    padding: 0; }
              /* line 391, sass/components/navigation/_main-nav.scss */
              .region-navigation .sf-menu.sf-style-none .sf-mega > .inner > .sf-mega-section.wrapper-sub-menu .section-image,
              .region-header .sf-menu.sf-style-none .sf-mega > .inner > .sf-mega-section.wrapper-sub-menu .section-image {
                display: block; }
            /* line 397, sass/components/navigation/_main-nav.scss */
            .region-navigation .sf-menu.sf-style-none .sf-mega > .inner > .sf-mega-section .section-title,
            .region-header .sf-menu.sf-style-none .sf-mega > .inner > .sf-mega-section .section-title {
              border-bottom: 2px #E87722 solid;
              text-align: center;
              font-size: 19px;
              font-size: 1.9rem; }
              /* line 401, sass/components/navigation/_main-nav.scss */
              .region-navigation .sf-menu.sf-style-none .sf-mega > .inner > .sf-mega-section .section-title a,
              .region-header .sf-menu.sf-style-none .sf-mega > .inner > .sf-mega-section .section-title a {
                padding: 0;
                text-align: center; }
              /* line 405, sass/components/navigation/_main-nav.scss */
              .region-navigation .sf-menu.sf-style-none .sf-mega > .inner > .sf-mega-section .section-title .btn-text,
              .region-header .sf-menu.sf-style-none .sf-mega > .inner > .sf-mega-section .section-title .btn-text {
                display: inline-block;
                padding: 0;
                padding: 0 30px 12px 0;
                padding: 0 3rem 1.2rem 0;
                line-height: normal; }
                /* line 411, sass/components/navigation/_main-nav.scss */
                .region-navigation .sf-menu.sf-style-none .sf-mega > .inner > .sf-mega-section .section-title .btn-text:after,
                .region-header .sf-menu.sf-style-none .sf-mega > .inner > .sf-mega-section .section-title .btn-text:after {
                  font-size: 27px;
                  font-size: 2.7rem;
                  right: 0; }
            /* line 418, sass/components/navigation/_main-nav.scss */
            .region-navigation .sf-menu.sf-style-none .sf-mega > .inner > .sf-mega-section.last,
            .region-header .sf-menu.sf-style-none .sf-mega > .inner > .sf-mega-section.last {
              margin-right: 0;
              border-right: none; }
      /* line 427, sass/components/navigation/_main-nav.scss */
      .region-navigation .sf-menu.sf-style-none .view a,
      .region-header .sf-menu.sf-style-none .view a {
        padding: 0; }
      /* line 431, sass/components/navigation/_main-nav.scss */
      .region-navigation .sf-menu.sf-style-none ul ul,
      .region-header .sf-menu.sf-style-none ul ul {
        margin-left: 20px;
        margin-left: 2rem; }
      /* line 435, sass/components/navigation/_main-nav.scss */
      .region-navigation .sf-menu.sf-style-none .menu-mlid-928 ul ul,
      .region-header .sf-menu.sf-style-none .menu-mlid-928 ul ul {
        display: none !important; } }

@media (min-width: 1680px) {
  /* line 443, sass/components/navigation/_main-nav.scss */
  .region-navigation .sf-menu.sf-style-none > li.sf-depth-1.last.sfHover > ul,
  .region-header .sf-menu.sf-style-none > li.sf-depth-1.last.sfHover > ul {
    left: 0 !important;
    right: auto !important; } }

/* line 2, sass/components/navigation/_pagination.scss */
.pager {
  display: flex;
  overflow: hidden;
  margin: 24px 0 !important;
  margin: 2.4rem 0 !important; }
  /* line 10, sass/components/navigation/_pagination.scss */
  .pager li.pager-item,
  .pager li {
    border: 1px solid #8d9aa5;
    border-width: 1px 1px 1px 0;
    background: #A1ACB5;
    padding: 0 !important;
    margin: 0 !important;
    text-align: center;
    position: relative; }
    /* line 17, sass/components/navigation/_pagination.scss */
    .pager li.pager-item:first-child,
    .pager li:first-child {
      border-width: 1px 1px 1px 1px; }
    /* line 20, sass/components/navigation/_pagination.scss */
    .no-flexbox .pager li.pager-item, .no-flexbox
    .pager li {
      margin: 0 2.5px;
      margin: 0 0.25rem; }
    /* line 25, sass/components/navigation/_pagination.scss */
    .pager li.pager-item.pager-current, .pager li.pager-item.active,
    .pager li.pager-current,
    .pager li.active {
      padding: 12px 10px;
      padding: 1.2rem 1rem;
      min-width: 50px;
      min-width: 5rem;
      background: #D0D7DE;
      display: flex;
      align-items: center;
      justify-content: center; }
      /* line 33, sass/components/navigation/_pagination.scss */
      .pager li.pager-item.pager-current:after, .pager li.pager-item.active:after,
      .pager li.pager-current:after,
      .pager li.active:after {
        content: '';
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #8d9aa5;
        position: absolute;
        left: 30%;
        bottom: -1px; }
    /* line 43, sass/components/navigation/_pagination.scss */
    .pager li.pager-item.pager-previous, .pager li.pager-item.pager-next, .pager li.pager-item.pager-ellipsis,
    .pager li.pager-previous,
    .pager li.pager-next,
    .pager li.pager-ellipsis {
      display: none; }
    /* line 48, sass/components/navigation/_pagination.scss */
    .pager li.pager-item a,
    .pager li a {
      display: block;
      padding: 12px 10px;
      padding: 1.2rem 1rem;
      min-width: 50px;
      min-width: 5rem;
      color: #333F48; }
      /* line 147, sass/helpers/_text.scss */
      .pager li.pager-item a:visited,
      .pager li a:visited {
        color: #333F48; }
      /* line 152, sass/helpers/_text.scss */
      .pager li.pager-item a:focus,
      .pager li a:focus {
        color: #333F48; }
      /* line 157, sass/helpers/_text.scss */
      .pager li.pager-item a:hover,
      .pager li a:hover {
        color: #9f3050; }
      /* line 162, sass/helpers/_text.scss */
      .pager li.pager-item a:active,
      .pager li a:active {
        color: #9f3050; }
      /* line 53, sass/components/navigation/_pagination.scss */
      .pager li.pager-item a:hover,
      .pager li a:hover {
        background-color: #D0D7DE; }

/* line 62, sass/components/navigation/_pagination.scss */
.js .search-results-container .pager {
  margin: 36px 0 auto;
  margin: 3.6rem 0 auto; }
  /* line 64, sass/components/navigation/_pagination.scss */
  .js .search-results-container .pager li.pager-item {
    float: none;
    display: none; }
  /* line 68, sass/components/navigation/_pagination.scss */
  .js .search-results-container .pager li.pager-item-next {
    display: inline-block !important; }
    /* line 70, sass/components/navigation/_pagination.scss */
    .js .search-results-container .pager li.pager-item-next a {
      font-size: 17px;
      font-size: 1.7rem;
      transition: background-color 300ms ease-out, border-color 320ms ease-out;
      background-color: #D0D7DE;
      border: 1px solid #fdfdfd;
      color: #41505c;
      position: relative;
      z-index: 1;
      padding: 24px 20px;
      padding: 2.4rem 2rem;
      text-transform: capitalize; }
      /* line 147, sass/helpers/_text.scss */
      .js .search-results-container .pager li.pager-item-next a:visited {
        color: #41505c; }
      /* line 152, sass/helpers/_text.scss */
      .js .search-results-container .pager li.pager-item-next a:focus {
        color: #41505c; }
      /* line 157, sass/helpers/_text.scss */
      .js .search-results-container .pager li.pager-item-next a:hover {
        color: #41505c; }
      /* line 162, sass/helpers/_text.scss */
      .js .search-results-container .pager li.pager-item-next a:active {
        color: #41505c; }
      /* line 78, sass/helpers/_buttons.scss */
      .js .search-results-container .pager li.pager-item-next a:focus, .js .search-results-container .pager li.pager-item-next a:hover, .js .search-results-container .pager li.pager-item-next a:active {
        background-color: #b2bec9;
        border-color: #fdfdfd;
        text-decoration: none;
        box-shadow: rgba(0, 0, 0, 0.16) -2px 0 2px 0, rgba(0, 0, 0, 0.16) 2px 0 2px 0, rgba(0, 0, 0, 0.32) 0 2px 2px 0; }

/* line 79, sass/components/navigation/_pagination.scss */
.pager-wrapper > .item-list {
  margin-bottom: 24px;
  margin-bottom: 2.4rem;
  display: flex;
  justify-content: center; }

/* line 1, sass/components/navigation/_side-nav.scss */
.side-nav {
  position: absolute;
  top: 0;
  display: block; }
  /* line 2, sass/components/navigation/_side-nav.scss */
  .side-nav,
  .side-nav select {
    width: 100%; }
  /* line 6, sass/components/navigation/_side-nav.scss */
  .side-nav p {
    margin: 0; }
  /* line 9, sass/components/navigation/_side-nav.scss */
  .side-nav h2, .side-nav .main .edan-search-form .form-type-textfield label, .main .edan-search-form .form-type-textfield .side-nav label {
    margin-bottom: 12px;
    font-family: "MinionPro", Georgia, Times, "Times New Roman", serif;
    font-size: 21px;
    font-size: 2.1rem;
    font-weight: 600; }
    /* line 14, sass/components/navigation/_side-nav.scss */
    .side-nav h2 a, .side-nav .main .edan-search-form .form-type-textfield label a, .main .edan-search-form .form-type-textfield .side-nav label a {
      position: relative;
      display: inline-block;
      padding-right: 24px;
      padding-right: 2.4rem; }
      /* line 15, sass/components/navigation/_side-nav.scss */
      .side-nav h2 a:hover, .side-nav .main .edan-search-form .form-type-textfield label a:hover, .main .edan-search-form .form-type-textfield .side-nav label a:hover {
        color: #9f3050; }
      /* line 50, sass/helpers/_icon.scss */
      .side-nav h2 a:after, .side-nav .main .edan-search-form .form-type-textfield label a:after, .main .edan-search-form .form-type-textfield .side-nav label a:after {
        content: "";
        font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
        text-decoration: inherit;
        color: #101820;
        position: absolute;
        font-size: 17px;
        font-size: 1.7rem;
        top: 3px;
        top: 0.3rem;
        right: 4px;
        right: 0.4rem; }
      /* line 62, sass/helpers/_icon.scss */
      .side-nav h2 a:hover:after, .side-nav .main .edan-search-form .form-type-textfield label a:hover:after, .main .edan-search-form .form-type-textfield .side-nav label a:hover:after {
        color: #9f3050; }
      /* line 17, sass/components/navigation/_side-nav.scss */
      .side-nav h2 a:after, .side-nav .main .edan-search-form .form-type-textfield label a:after, .main .edan-search-form .form-type-textfield .side-nav label a:after {
        color: inherit; }
        /* line 19, sass/components/navigation/_side-nav.scss */
        .side-nav h2 a:after:hover, .side-nav .main .edan-search-form .form-type-textfield label a:after:hover, .main .edan-search-form .form-type-textfield .side-nav label a:after:hover {
          color: inherit; }
  /* line 26, sass/components/navigation/_side-nav.scss */
  .side-nav .sf-accordion-toggle a {
    width: 100%;
    font-size: 17px;
    font-size: 1.7rem;
    transition: background-color 300ms ease-out, border-color 320ms ease-out;
    background-color: #41505c;
    border: 1px solid #677683;
    color: #FFF;
    position: relative;
    z-index: 1;
    text-transform: uppercase;
    letter-spacing: .1px;
    margin: 0; }
    /* line 147, sass/helpers/_text.scss */
    .side-nav .sf-accordion-toggle a:visited {
      color: #FFF; }
    /* line 152, sass/helpers/_text.scss */
    .side-nav .sf-accordion-toggle a:focus {
      color: #FFF; }
    /* line 157, sass/helpers/_text.scss */
    .side-nav .sf-accordion-toggle a:hover {
      color: #FFF; }
    /* line 162, sass/helpers/_text.scss */
    .side-nav .sf-accordion-toggle a:active {
      color: #FFF; }
    /* line 78, sass/helpers/_buttons.scss */
    .side-nav .sf-accordion-toggle a:focus, .side-nav .sf-accordion-toggle a:hover, .side-nav .sf-accordion-toggle a:active {
      background-color: #165C7D;
      border-color: #3da5d6;
      text-decoration: none;
      box-shadow: rgba(0, 0, 0, 0.16) -2px 0 2px 0, rgba(0, 0, 0, 0.16) 2px 0 2px 0, rgba(0, 0, 0, 0.32) 0 2px 2px 0; }
    /* line 32, sass/components/navigation/_side-nav.scss */
    .side-nav .sf-accordion-toggle a.sf-expanded {
      background: #165C7D;
      border-bottom: none; }
  /* line 37, sass/components/navigation/_side-nav.scss */
  .side-nav .sf-arrows .sf-with-ul:after {
    border: none; }
  /* line 40, sass/components/navigation/_side-nav.scss */
  .side-nav .menu {
    width: 100%;
    background-color: #eef0f3;
    z-index: 0; }
    /* line 45, sass/components/navigation/_side-nav.scss */
    .side-nav .menu ul {
      transition: opacity .2s ease-in, max-height .4s ease-in;
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      display: block !important;
      position: static;
      width: 100%; }
    /* line 55, sass/components/navigation/_side-nav.scss */
    .side-nav .menu .menu-mlid-928 ul ul {
      display: none !important; }
    /* line 58, sass/components/navigation/_side-nav.scss */
    .side-nav .menu li {
      transition: all .5s ease-in;
      width: 100%;
      float: none;
      z-index: 0; }
      /* line 64, sass/components/navigation/_side-nav.scss */
      .side-nav .menu li.active-trail a {
        background-color: #dfe3e8; }
      /* line 73, sass/components/navigation/_side-nav.scss */
      .side-nav .menu li li a {
        padding-left: 30px;
        padding-left: 3rem; }
      /* line 77, sass/components/navigation/_side-nav.scss */
      .side-nav .menu li li li a {
        padding-left: 45px;
        padding-left: 4.5rem; }
      /* line 80, sass/components/navigation/_side-nav.scss */
      .side-nav .menu li li li li a {
        padding-left: 60px;
        padding-left: 6rem; }
      /* line 83, sass/components/navigation/_side-nav.scss */
      .side-nav .menu li li li li li a {
        padding-left: 75px;
        padding-left: 7.5rem; }
      /* line 88, sass/components/navigation/_side-nav.scss */
      .side-nav .menu li.active a {
        background-color: #dfe3e8; }
      /* line 91, sass/components/navigation/_side-nav.scss */
      .side-nav .menu li.active > a {
        color: #9f3050;
        font-weight: 400; }
      /* line 98, sass/components/navigation/_side-nav.scss */
      .side-nav .menu li.expanded > a, .side-nav .menu li.menuparent > a {
        position: relative;
        display: block;
        padding-right: 24px;
        padding-right: 2.4rem; }
        /* line 50, sass/helpers/_icon.scss */
        .side-nav .menu li.expanded > a:after, .side-nav .menu li.menuparent > a:after {
          content: "";
          font-family: FontAwesome;
          font-style: normal;
          font-weight: normal;
          text-decoration: inherit;
          color: #333F48;
          position: absolute;
          font-size: 14px;
          font-size: 1.4rem;
          top: 8px;
          top: 0.8rem;
          right: 6px;
          right: 0.6rem; }
        /* line 62, sass/helpers/_icon.scss */
        .side-nav .menu li.expanded > a:hover:after, .side-nav .menu li.menuparent > a:hover:after {
          color: #9f3050; }
        /* line 100, sass/components/navigation/_side-nav.scss */
        .side-nav .menu li.expanded > a:after, .side-nav .menu li.menuparent > a:after {
          transition: transform .3s ease-out; }
        /* line 105, sass/components/navigation/_side-nav.scss */
        .side-nav .menu li.expanded > a.active:after, .side-nav .menu li.expanded > a.active-trail:after, .side-nav .menu li.menuparent > a.active:after, .side-nav .menu li.menuparent > a.active-trail:after {
          content: ''; }
      /* line 111, sass/components/navigation/_side-nav.scss */
      .side-nav .menu li.expanded.sf-depth-1 > a:after, .side-nav .menu li.menuparent.sf-depth-1 > a:after {
        content: ''; }
      /* line 119, sass/components/navigation/_side-nav.scss */
      .side-nav .menu li.sfHover > ul, .side-nav .menu li.active-trail > ul, .side-nav .menu li.sfHover.active-trail > ul {
        max-height: 9999px;
        opacity: 1; }
      /* line 125, sass/components/navigation/_side-nav.scss */
      .side-nav .menu li.active-trail > a:after {
        content: ''; }
      /* line 130, sass/components/navigation/_side-nav.scss */
      .side-nav .menu li.sfHover a {
        background-color: #dfe3e8; }
      /* line 133, sass/components/navigation/_side-nav.scss */
      .side-nav .menu li.sfHover > a:after {
        transform: rotateX(180deg); }
    /* line 138, sass/components/navigation/_side-nav.scss */
    .side-nav .menu > li {
      border-top: 1px solid #D0D7DE; }
      /* line 140, sass/components/navigation/_side-nav.scss */
      .side-nav .menu > li > a.active:after {
        content: ''; }
  @media (min-width: 980px) {
    /* line 1, sass/components/navigation/_side-nav.scss */
    .side-nav {
      display: block;
      *zoom: 1;
      margin-bottom: 36px;
      margin-bottom: 3.6rem;
      border: 1px solid #D0D7DE;
      border-width: 0 1px 1px;
      position: static; }
      /* line 15, sass/helpers/_clearfix.scss */
      .side-nav:before, .side-nav:after {
        content: " ";
        display: table;
        line-height: 0; }
      /* line 21, sass/helpers/_clearfix.scss */
      .side-nav:after {
        clear: both; }
      /* line 169, sass/components/navigation/_side-nav.scss */
      .side-nav .sf-hidden {
        position: static;
        left: auto !important;
        top: auto !important; }
      /* line 175, sass/components/navigation/_side-nav.scss */
      .side-nav h2 a, .side-nav .main .edan-search-form .form-type-textfield label a, .main .edan-search-form .form-type-textfield .side-nav label a,
      .side-nav .menu a {
        background-color: #eef0f3;
        display: block;
        padding: 12px 15px;
        padding: 1.2rem 1.5rem;
        transition: background-color .5s;
        color: #333F48; }
        /* line 147, sass/helpers/_text.scss */
        .side-nav h2 a:visited, .side-nav .main .edan-search-form .form-type-textfield label a:visited, .main .edan-search-form .form-type-textfield .side-nav label a:visited,
        .side-nav .menu a:visited {
          color: #333F48; }
        /* line 152, sass/helpers/_text.scss */
        .side-nav h2 a:focus, .side-nav .main .edan-search-form .form-type-textfield label a:focus, .main .edan-search-form .form-type-textfield .side-nav label a:focus,
        .side-nav .menu a:focus {
          color: #333F48; }
        /* line 157, sass/helpers/_text.scss */
        .side-nav h2 a:hover, .side-nav .main .edan-search-form .form-type-textfield label a:hover, .main .edan-search-form .form-type-textfield .side-nav label a:hover,
        .side-nav .menu a:hover {
          color: #9f3050; }
        /* line 162, sass/helpers/_text.scss */
        .side-nav h2 a:active, .side-nav .main .edan-search-form .form-type-textfield label a:active, .main .edan-search-form .form-type-textfield .side-nav label a:active,
        .side-nav .menu a:active {
          color: #9f3050; }
        /* line 183, sass/components/navigation/_side-nav.scss */
        .side-nav h2 a.active, .side-nav .main .edan-search-form .form-type-textfield label a.active, .main .edan-search-form .form-type-textfield .side-nav label a.active,
        .side-nav .menu a.active {
          background-color: #dfe3e8;
          color: #9f3050;
          font-weight: 600; }
        /* line 188, sass/components/navigation/_side-nav.scss */
        .side-nav h2 a:hover, .side-nav .main .edan-search-form .form-type-textfield label a:hover, .main .edan-search-form .form-type-textfield .side-nav label a:hover,
        .side-nav .menu a:hover {
          background-color: #dfe3e8;
          text-decoration: none;
          font-weight: 400; }
      /* line 194, sass/components/navigation/_side-nav.scss */
      .side-nav h2, .side-nav .main .edan-search-form .form-type-textfield label, .main .edan-search-form .form-type-textfield .side-nav label {
        border-top: 1px solid #D0D7DE;
        font-size: 17px;
        font-size: 1.7rem;
        line-height: 1.5;
        font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
        margin: 0;
        font-weight: 400; }
        /* line 201, sass/components/navigation/_side-nav.scss */
        .side-nav h2 a:after, .side-nav .main .edan-search-form .form-type-textfield label a:after, .main .edan-search-form .form-type-textfield .side-nav label a:after {
          content: ''; } }

/* line 4, sass/components/navigation/_social-media.scss */
.block-menu-menu-social-media .menu li {
  display: inline-block; }

/* Slider */
/* line 33, sass/components/slick/_slick.scss */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

/* line 47, sass/components/slick/_slick.scss */
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  /* line 54, sass/components/slick/_slick.scss */
  .slick-list:focus {
    outline: none; }
  /* line 58, sass/components/slick/_slick.scss */
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

/* line 63, sass/components/slick/_slick.scss */
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/* line 71, sass/components/slick/_slick.scss */
.slick-track, .slick-list {
  display: flex; }

/* line 74, sass/components/slick/_slick.scss */
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  margin-left: auto;
  margin-right: auto; }
  /* line 92, sass/components/slick/_slick.scss */
  .slick-loading .slick-track {
    visibility: hidden; }

/* line 96, sass/components/slick/_slick.scss */
.slick-slide {
  height: 100%;
  min-height: 1px;
  display: none; }
  /* line 103, sass/components/slick/_slick.scss */
  .slick-slide img {
    display: block; }
  /* line 106, sass/components/slick/_slick.scss */
  .slick-slide.slick-loading img {
    display: none; }
  /* line 112, sass/components/slick/_slick.scss */
  .slick-slide.dragging img {
    pointer-events: none; }
  /* line 116, sass/components/slick/_slick.scss */
  .slick-initialized .slick-slide {
    display: block; }
  /* line 120, sass/components/slick/_slick.scss */
  .slick-loading .slick-slide {
    visibility: hidden; }
  /* line 124, sass/components/slick/_slick.scss */
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

/* line 130, sass/components/slick/_slick.scss */
.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
/* line 139, sass/components/slick/_slick.scss */
.slick-loading .slick-list {
  background: #fff url("../images/ajax-loader.gif") center center no-repeat; }

/* Icons */
/* line 155, sass/components/slick/_slick.scss */
.slick, .slick-slider {
  position: relative; }
  /* line 157, sass/components/slick/_slick.scss */
  .slick button, .slick-slider button {
    box-shadow: none;
    margin: 0; }
    /* line 161, sass/components/slick/_slick.scss */
    .slick button:focus, .slick button:active, .slick button:hover, .slick-slider button:focus, .slick-slider button:active, .slick-slider button:hover {
      opacity: 1;
      background-color: transparent;
      box-shadow: none;
      border: none; }

/* Arrows */
/* line 175, sass/components/slick/_slick.scss */
.slick-prev,
.slick-next {
  text-decoration: none;
  display: block;
  position: absolute;
  z-index: 10;
  margin: 0;
  top: 40%;
  width: 30px;
  height: 30px;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
  opacity: 0.5;
  overflow: hidden;
  background-image: url("../images/bg/prev.png");
  background-image: url("data:image/svg+xml, %3Csvg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50' fill='rgb%28255%2C255%2C255%29'%3E%3Cstyle%3E.st0%7Bopacity:.5%3Bfill:%231b1b1c%7D.st1%7Bfill:%23fff%7D.st2%2C.st3%7Bfill:none%3Bstroke:%23fff%3Bstroke-width:1.547%7D.st3%7Bdisplay:none%7D%3C/style%3E%3Ccircle class='st0' cx='25' cy='25' r='24.5'/%3E%3Cpath class='st1' d='M25 50C11.2 50 0 38.8 0 25S11.2 0 25 0s25 11.2 25 25-11.2 25-25 25zm0-49C11.8 1 1 11.8 1 25s10.8 24 24 24 24-10.8 24-24S38.2 1 25 1z'/%3E%3Cpath class='st2' d='M30.5 14l-11 11 11 11'/%3E%3Cpath class='st3' d='M19.5 14l11 11-11 11'/%3E%3C/svg%3E");
  background-color: transparent;
  border: none;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  /* line 192, sass/components/slick/_slick.scss */
  .slick-prev:hover,
  .slick-next:hover {
    opacity: .8; }
  /* line 195, sass/components/slick/_slick.scss */
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }

/* line 210, sass/components/slick/_slick.scss */
.slick-prev {
  left: 1%; }
  /* line 212, sass/components/slick/_slick.scss */
  [dir="rtl"] .slick-prev {
    left: auto;
    right: 1%; }

/* line 226, sass/components/slick/_slick.scss */
.slick-next {
  right: 1%;
  background-image: url("../images/bg/next.png");
  background-image: url("data:image/svg+xml, %3Csvg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50' fill='rgb%28255%2C255%2C255%29'%3E%3Cstyle%3E.st0%7Bopacity:.5%3Bfill:%231b1b1c%7D.st1%7Bfill:%23fff%7D.st2%7Bdisplay:none%7D.st2%2C.st3%7Bfill:none%3Bstroke:%23fff%3Bstroke-width:1.547%7D%3C/style%3E%3Ccircle class='st0' cx='25' cy='25' r='24.5'/%3E%3Cpath class='st1' d='M25 50C11.2 50 0 38.8 0 25S11.2 0 25 0s25 11.2 25 25-11.2 25-25 25zm0-49C11.8 1 1 11.8 1 25s10.8 24 24 24 24-10.8 24-24S38.2 1 25 1z'/%3E%3Cpath class='st2' d='M30.5 14l-11 11 11 11'/%3E%3Cpath class='st3' d='M19.5 14l11 11-11 11'/%3E%3C/svg%3E"); }
  /* line 228, sass/components/slick/_slick.scss */
  [dir="rtl"] .slick-next {
    left: 1%;
    right: auto; }

/* line 240, sass/components/slick/_slick.scss */
.multiple-slides {
  padding: 0 35px; }
  /* line 242, sass/components/slick/_slick.scss */
  .multiple-slides .slick-prev {
    left: 0; }
  /* line 245, sass/components/slick/_slick.scss */
  .multiple-slides .slick-next {
    right: 0; }

/* Dots */
/* line 252, sass/components/slick/_slick.scss */
.slick-dotted.slick-slider {
  margin-bottom: 48px;
  margin-bottom: 4.8rem; }

/* line 256, sass/components/slick/_slick.scss */
.slick-dots {
  position: absolute;
  bottom: -24px;
  bottom: -2.4rem;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  z-index: 100;
  line-height: 0; }
  /* line 267, sass/components/slick/_slick.scss */
  .slick-dots li {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    /* line 275, sass/components/slick/_slick.scss */
    .slick-dots li button {
      border: 1px solid #000;
      background: transparent;
      display: block;
      height: 6px;
      width: 6px;
      outline: none;
      line-height: 1;
      color: transparent;
      padding: 4px;
      cursor: pointer;
      border-radius: 6px;
      transition: background .4ms ease-in;
      box-shadow: none; }
      /* line 289, sass/components/slick/_slick.scss */
      .dark-bg .slick-dots li button {
        border-color: #eef0f3; }
      /* line 292, sass/components/slick/_slick.scss */
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none;
        background: #555;
        border: 1px solid #555; }
    /* line 298, sass/components/slick/_slick.scss */
    .slick-dots li.slick-active button {
      background: #000; }
      /* line 300, sass/components/slick/_slick.scss */
      .dark-bg .slick-dots li.slick-active button {
        background: #eef0f3; }

/* line 307, sass/components/slick/_slick.scss */
.slider-control {
  position: absolute;
  top: 24px;
  line-height: 1;
  right: 1%;
  display: block;
  width: 26px;
  height: 24px;
  opacity: .4;
  background: transparent;
  border: none;
  z-index: 10;
  overflow: hidden;
  cursor: pointer;
  color: #fff;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.4); }
  /* line 323, sass/components/slick/_slick.scss */
  .node-type-exhibition .slider-control {
    color: #000 !important; }
  /* line 326, sass/components/slick/_slick.scss */
  .slider-control:before {
    font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
        text-decoration: inherit;
    font-size: 20px;
    color: inherit;
    top: 0;
    left: 0;
    display: inline-block;
    content: "\f04c"; }
  /* line 339, sass/components/slick/_slick.scss */
  .slider-control:focus, .slider-control:active, .slider-control:hover {
    opacity: 1;
    background-color: transparent;
    box-shadow: none;
    border: none; }
  /* line 349, sass/components/slick/_slick.scss */
  .slider-control.play:before {
    content: "\f04b"; }

@media (min-width: 768px) {
  /* line 356, sass/components/slick/_slick.scss */
  .slick-prev,
  .slick-next {
    width: 40px;
    height: 40px; }
  /* line 361, sass/components/slick/_slick.scss */
  .multiple-slides {
    padding: 0 45px; } }

@media (min-width: 1280px) {
  /* line 367, sass/components/slick/_slick.scss */
  .slick-prev,
  .slick-next {
    width: 60px;
    height: 60px; }
  /* line 372, sass/components/slick/_slick.scss */
  .multiple-slides {
    padding: 0 65px; } }

/* line 2, sass/components/slick/_slideshow.scss */
.panel-region-hero .pane-node-field-slideshow {
  background: none; }
  /* line 11, sass/components/slick/_slideshow.scss */
  .panel-region-hero .pane-node-field-slideshow p {
    margin-bottom: 0;
    display: none; }
  /* line 15, sass/components/slick/_slideshow.scss */
  .panel-region-hero .pane-node-field-slideshow figcaption p {
    display: block; }

/* line 20, sass/components/slick/_slideshow.scss */
.panel-region-hero .b-text-wrapper {
  border: none;
  padding: 0;
  text-shadow: rgba(0, 0, 0, 0.35) 1px 1px 2px; }
  /* line 23, sass/components/slick/_slideshow.scss */
  .panel-region-hero .b-text-wrapper * {
    color: white; }

/* line 34, sass/components/slick/_slideshow.scss */
.paragraphs-item-flickr-slideshow img,
.paragraphs-item-slideshow-images img {
  width: auto !important;
  max-width: 100%;
  max-height: 360px;
  margin: auto; }

/* line 41, sass/components/slick/_slideshow.scss */
.paragraphs-item-flickr-slideshow .title,
.paragraphs-item-slideshow-images .title {
  margin: 0;
  font-size: 21px;
  font-size: 2.1rem;
  line-height: 1.71429; }

/* line 46, sass/components/slick/_slideshow.scss */
.paragraphs-item-flickr-slideshow .caption,
.paragraphs-item-slideshow-images .caption {
  display: none; }

/* line 49, sass/components/slick/_slideshow.scss */
.paragraphs-item-flickr-slideshow .wrapper--text,
.paragraphs-item-slideshow-images .wrapper--text {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 12%; }

/* line 57, sass/components/slick/_slideshow.scss */
.paragraphs-item-flickr-slideshow .b-text-wrapper,
.paragraphs-item-slideshow-images .b-text-wrapper {
  display: block;
  background: rgba(0, 0, 0, 0.5); }

@media (min-width: 768px) {
  /* line 67, sass/components/slick/_slideshow.scss */
  .paragraph--type--slideshow .caption {
    display: block; }
  /* line 70, sass/components/slick/_slideshow.scss */
  .paragraph--type--slideshow .b-text-wrapper {
    width: 80%; }
  /* line 73, sass/components/slick/_slideshow.scss */
  .paragraph--type--slideshow .title {
    font-size: 24px;
    font-size: 2.4rem;
    line-height: 1.5; }
  /* line 81, sass/components/slick/_slideshow.scss */
  .panel-region-hero .pane-node-field-slideshow p {
    display: block; } }

@media (min-width: 980px) {
  /* line 90, sass/components/slick/_slideshow.scss */
  .slick--optionset--multiple-2 .slide {
    margin-right: 20px;
    margin-right: 2rem; } }

/* line 2, sass/pages/_file.scss */
.page-file .region-content {
  width: 96%;
  max-width: 1366px;
  margin-left: auto;
  margin-right: auto; }

/* line 4, sass/pages/_front.scss */
.front #paragraphs-item-5555 .group-right .entity-paragraphs-item {
  border: 1px solid #D0D7DE;
  background: #eef0f3; }

/* line 9, sass/pages/_front.scss */
.front #paragraphs-item-5555 .group-right .section-title {
  background: #101820;
  color: #fff;
  padding: 12px 15px;
  padding: 1.2rem 1.5rem;
  margin: 0;
  width: 100%; }

@media (min-width: 768px) {
  /* line 26, sass/pages/_front.scss */
  .front #paragraphs-item-5555 .col {
    display: flex; }
  /* line 29, sass/pages/_front.scss */
  .front #paragraphs-item-5555 .gs4 {
    grid-column-end: span 6; }
    /* line 31, sass/pages/_front.scss */
    .front #paragraphs-item-5555 .gs4.group-left {
      grid-column-end: span 12; } }

@media (min-width: 980px) {
  /* line 39, sass/pages/_front.scss */
  .front #paragraphs-item-5555 .gs4 {
    grid-column-end: span 3; }
    /* line 41, sass/pages/_front.scss */
    .front #paragraphs-item-5555 .gs4.group-left {
      grid-column-end: span 6; } }

/* line 2, sass/pages/_search.scss */
.page-search .content-wrapper {
  margin: 36px auto;
  margin: 3.6rem auto; }

/* line 1, sass/pages/_user.scss */
.page-user .content-wrapper {
  padding: 2.82353em 1.17647em; }
