// set layout for site.

@mixin set_layout($container-width) {
  //@include container;
    width: 96%;
    max-width: $container-width;
    margin-left:auto;
    margin-right:auto;
    //@include breakpoint ('all' $container-width){
  //  max-width: 100%;
  //}
  //  @include breakpoint('all' 1010px) {
  //
  //  }
}

%set_layout {
    @include set_layout($max-width)
}
