/* Links */
a {
	//link-colors($normal, $hover, $active, $visited, $focus)
	@include link-colors($link-color, $link-hover, $link-hover, $link-color, $link-hover);
	text-decoration: none;
	outline: 0;
	border-bottom: 1px solid transparent;
	&.active {
		color: $link-hover;
	}
	&:focus,
	&:hover,
	&:active {
		text-decoration: none;
		cursor: hand;
		cursor: pointer;
		border-bottom: 1px dotted;
	}
	&.external-link {
		@include font-icon("\f08e", 20px, 3px, 0, 14px, inherit, inherit, true);
	}
}
