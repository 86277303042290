/**
 * Colorbox Core Style:
 * The following CSS is consistent between example themes and should not be altered.
 */
#colorbox, #cboxOverlay, #cboxWrapper {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9999;
	overflow: hidden;
}

#colorbox {
	border-radius: 0;
}
#cboxOverlay {
	position: fixed;
	width: 100%;
	height: 100%;
}
#cboxMiddleLeft, #cboxBottomLeft {
	clear: left;
}
#cboxContent {
	position: relative;
}
#cboxLoadedContent {
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}

#cboxLoadingOverlay, #cboxLoadingGraphic {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
/**
 * These elements are buttons, and may need to have additional
 * styles reset to avoid unwanted base styles.
 */
#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow {
	border: 0;
	padding: 0;
	margin: 0;
	overflow: visible;
	width: auto;
	background: none;
	cursor: pointer;
}
/**
 * Avoid outlines on :active (mouseclick),
 * but preserve outlines on :focus (tabbed navigating)
 */
#cboxPrevious:active, #cboxNext:active, #cboxClose:active, #cboxSlideshow:active {
	outline: 0;
}
.cboxPhoto {
	float: left;
	margin: auto;
	border: 0;
	display: block;
	max-width: none;
}
.cboxIframe {
	width: 100%;
	height: 100%;
	display: block;
	border: 0;
}
/* Reset box sizing to content-box if theme is using border-box. */
#colorbox, #cboxContent, #cboxLoadedContent {
	-moz-box-sizing: content-box;
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
}

/**
 * Colorbox module Stockholm syndrome style:
 * The styles are ordered & tabbed in a way that represents
 * the nesting of the generated HTML.
 */
#cboxOverlay {
	background: #000;
}
#cboxWrapper {
  border-radius: 0;
 }

#colorbox {
	background: #fff url(../images/bg_tab.png) center bottom repeat-x;
	-moz-box-shadow: 3px 3px 16px #333;
	-webkit-box-shadow: 3px 3px 16px #333;
	box-shadow: 3px 3px 16px #333;
	-moz-border-radius-bottomleft: 9px;
	-moz-border-radius-bottomright: 9px;
	-webkit-border-bottom-left-radius: 9px;
	-webkit-border-bottom-right-radius: 9px;
	border-bottom-left-radius: 9px;
	border-bottom-right-radius: 9px;
	outline: 0;
}
#colorbox, #colorbox div {
	overflow: visible; /* Required by the close button. */
}
#cboxWrapper {
	-moz-border-radius-bottomleft: 9px;
	-moz-border-radius-bottomright: 9px;
	-webkit-border-bottom-left-radius: 9px;
	-webkit-border-bottom-right-radius: 9px;
	border-bottom-left-radius: 9px;
	border-bottom-right-radius: 9px;
}
#cboxTopLeft {
	width: 0;
	height: 0;
}
#cboxTopCenter {
	height: 0;
}
#cboxTopRight {
	width: 0;
	height: 0;
}
#cboxBottomLeft {
	width: 15px;
	height: 10px;
}
#cboxBottomCenter {
	height: 10px;
}
#cboxBottomRight {
	width: 15px;
	height: 10px;
}
#cboxMiddleLeft {
	width: 0;
}
#cboxMiddleRight {
	width: 0;
}
#cboxContent {
	background: #fff;
	overflow: hidden;
	margin-bottom: $space;
}
#cboxError {
	padding: 50px;
	border: 1px solid #ccc;
}
#cboxLoadedContent {
  margin-bottom: 0;
}
#cboxTitle {
	bottom: 0;
//	left: 0;
//	height: 38px;
//	color: #313131;
//	padding: 0 140px 0 15px;
//	display: table-cell !important;
//	vertical-align: middle;
//	float: none !important;
}
#cboxCurrent {
	position: absolute;
	@include rem(bottom, -$space);
	color: $text-color;
	@include rem(font-size, $font-size-small);
	//border-left: 1px solid #313131;
	//padding: 0 0 0 15px;
}
/* Slideshow not implemented. */
.cboxSlideshow_on #cboxSlideshow {
	display: none;
}
.cboxSlideshow_on #cboxSlideshow:hover {
}
.cboxSlideshow_off #cboxSlideshow {
	display: none;
}
.cboxSlideshow_off #cboxSlideshow:hover {
}
#cboxPrevious {
	position: absolute;
	@include rem(bottom, -20px);
	background: url(../images/controls.png) no-repeat 0 -48px;
	width: 21px;
	height: 15px;
	text-indent: -9999px;
}
#cboxPrevious:hover {
	background-position: 0 -111px;
}
#cboxNext {
	position: absolute;
	@include rem(bottom, -20px);
	background: url(../images/controls.png) no-repeat 0 -29px;
	width: 21px;
	height: 15px;
	text-indent: -9999px;
}
#cboxNext:hover {
	background-position: 0 -92px;
}
#cboxLoadingOverlay {
	background: #e6e6e6;
}
#cboxLoadingGraphic {
	background: url(../images/preloader_160x160.gif) no-repeat center center;
}
#cboxClose {
	position: absolute;
	top: -10px;
	right: -10px;
	background: url(../images/controls.png) no-repeat 0px 0px;
	width: 25px;
	height: 25px;
	text-indent: -9999px;
}
#cboxClose:hover {
	background-position: 0 -63px;
}
