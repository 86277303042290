//.views-view-grid {
//  >.entity {
//    @include rem(margin-bottom, $space);
//  }
//}
//@include breakpoint($tab) {
//
//
//  .grid-wrapper > .view-content,
//  .grid-wrapper > .content {
//      .no-flexbox & {
//        @include clearfix;
//      }
//
//      &:before,
//      &:after {
//        flex-basis: 0;
//        order: 1;
//      }
//      display: flex;
//      flex-wrap: wrap;
//      .col {
//        display: flex;
//      }
//      .col-sm-6 {
//        @include span(6);
//        max-width: span(6);
//        &:nth-child(2n) {
//          @include last;
//        }
//        &:nth-child(2n+1) {
//          clear: both;
//        }
//      }
//      .last-sm {
//        margin-right: 0;
//      }
//
//      .col-sm-8 {
//        @include span(8);
//        max-width: span(8);
//      }
//      .col-sm-4 {
//        @include span(4);
//        max-width: span(4);
//      }
//      .col-sm-9 {
//        @include span(9);
//        max-width: span(9);
//      }
//      .col-sm-9 {
//        @include span(9);
//        max-width: span(9);
//      }
//      .col-sm-6.no-margin {
//        float: left;
//        width: 50%;
//        max-width: 50%;
//        margin: 0;
//      }
//      .col-sm-4.no-margin {
//        float: left;
//        width: 25%;
//        max-width: 50%;
//        margin: 0;
//      }
//      > .col {
//        flex: 1 1 auto;
//        // @include rem(margin-bottom, $space);
//        &.last {
//          @include last;
//          order: 2;
//        }
//
//    }
//    &.center {
//      > .view-content,
//      > .content {
//        justify-content: center;
//      }
//    }
//
//    &.equal-col {
//      .col {
//        display: flex;
//      }
//    }
//  }
//  .flex-column {
//    display: flex;
//    flex-direction: column;
//  }
//}
//
//@include breakpoint($desk) {
//
//
//  .grid-wrapper > .view-content,
//  .grid-wrapper > .content {
//      .col-md-3,
//      .col-md-4 {
//        &:nth-child(2n+1) {
//          clear: none;
//        }
//      }
//      .col-md-3 {
//        &,
//        &:nth-child(2n) {
//          @include span(3);
//          max-width: span(3);
//        }
//        &:nth-child(4n) {
//          @include last;
//        }
//        &:nth-child(4n+1) {
//          clear: both;
//        }
//
//      }
//      .col-md-4 {
//        &,
//        &:nth-child(2n) {
//          @include span(4);
//          max-width: span(4);
//        }
//        &:nth-child(3n) {
//          @include last;
//        }
//        &:nth-child(3n+1) {
//          clear: both;
//        }
//      }
//      .col-md-6 {
//        @include span(6);
//        &:nth-child(2n),
//        &.last {
//          @include last;
//        }
//        &:nth-child(2n+1) {
//          clear: both;
//        }
//      }
//      .last-md {
//        margin-right: 0;
//        float: right;
//        order: 2;
//      }
//
//      .col-md-6.no-margin {
//        float: left;
//        width: 50%;
//        margin: 0;
//      }
//      .col-md-4.no-margin {
//        float: left;
//        width: 33.3%;
//        margin: 0;
//      }
//      .col-md-3.no-margin {
//        float: left;
//        width: 25%;
//        margin: 0;
//      }
//      .col-md-6.no-margin {
//        float: left;
//        width: 16.6667%;
//        margin: 0;
//      }
//    } // end content
//
//    &.no-wrap {
//      > .view-content,
//      > .content {
//        display: flex;
//        flex-wrap: nowrap;
//        align-items: center;
//      }
//    }
//    &.border {
//      .col {
//        &,
//        &.last-sm {
//          border-right: 1px solid $gray-light;
//        }
//        &.last-md {
//          border-right: none;
//        }
//      }
//  }
//}
//
//@include breakpoint($bp-wide) {
//
//
//  .grid-wrapper > .view-content,
//  .grid-wrapper > .content {
//      .col-wd-3 {
//        &,
//        &:nth-child(2n),
//        &:nth-child(3n) {
//          @include span(3);
//          max-width: span(3);
//        }
//        &:nth-child(3n+1) {
//          clear: none;
//        }
//        &:nth-child(4n) {
//          @include last;
//        }
//        &:nth-child(4n+1) {
//          clear: both;
//        }
//        &.no-margin {
//          &,
//          &:nth-child(2n),
//          &:nth-child(3n) {
//            @include span(3);
//            max-width: 25%;
//            margin-right: 0;
//          }
//        }
//      }
//      .col-wd-2 {
//        &,
//        &:nth-child(2n),
//        &:nth-child(3n) {
//          @include span(2);
//          max-width: span(2);
//        }
//        &:nth-child(3n+1) {
//          clear: none;
//        }
//        &:nth-child(6n) {
//          @include last;
//        }
//        &:nth-child(6n+1) {
//          clear: both;
//        }
//        &.no-margin {
//          &,
//          &:nth-child(2n),
//          &:nth-child(3n) {
//            float: left;
//            width: 16.667%;
//            max-width: 16.667%;
//            margin-right: 0;
//          }
//        }
//      }
//  }
//}
@each $infix, $breakpoint in $grid-breakpoints {
	@include breakpoint('all' $breakpoint) {
		.flex-#{$infix} {display: flex}
		.flex-#{$infix}-row            { flex-direction: row !important; }
		.flex-#{$infix}-column         { flex-direction: column !important; }
		.flex-#{$infix}-row-reverse    { flex-direction: row-reverse !important; }
		.flex-#{$infix}-column-reverse { flex-direction: column-reverse !important; }

		.flex-#{$infix}-wrap         { flex-wrap: wrap !important; }
		.flex-#{$infix}-nowrap       { flex-wrap: nowrap !important; }
		.flex-#{$infix}-wrap-reverse { flex-wrap: wrap-reverse !important; }
		.flex-#{$infix}-fill         { flex: 1 1 auto !important; }
		.flex-#{$infix}-grow-0       { flex-grow: 0 !important; }
		.flex-#{$infix}-grow-1       { flex-grow: 1 !important; }
		.flex-#{$infix}-shrink-0     { flex-shrink: 0 !important; }
		.flex-#{$infix}-shrink-1     { flex-shrink: 1 !important; }

		.justify-content-#{$infix}-start   { justify-content: flex-start !important; }
		.justify-content-#{$infix}-end     { justify-content: flex-end !important; }
		.justify-content-#{$infix}-center  { justify-content: center !important; }
		.justify-content-#{$infix}-between { justify-content: space-between !important; }
		.justify-content-#{$infix}-around  { justify-content: space-around !important; }

		.align-items-#{$infix}-start    { align-items: flex-start !important; }
		.align-items-#{$infix}-end      { align-items: flex-end !important; }
		.align-items-#{$infix}-center   { align-items: center !important; }
		.align-items-#{$infix}-baseline { align-items: baseline !important; }
		.align-items-#{$infix}-stretch  { align-items: stretch !important; }

		.align-content-#{$infix}-start   { align-content: flex-start !important; }
		.align-content-#{$infix}-end     { align-content: flex-end !important; }
		.align-content-#{$infix}-center  { align-content: center !important; }
		.align-content-#{$infix}-between { align-content: space-between !important; }
		.align-content-#{$infix}-around  { align-content: space-around !important; }
		.align-content-#{$infix}-stretch { align-content: stretch !important; }

		.align-self-#{$infix}-auto     { align-self: auto !important; }
		.align-self-#{$infix}-start    { align-self: flex-start !important; }
		.align-self-#{$infix}-end      { align-self: flex-end !important; }
		.align-self-#{$infix}-center   { align-self: center !important; }
		.align-self-#{$infix}-baseline { align-self: baseline !important; }
		.align-self-#{$infix}-stretch  { align-self: stretch !important; }
	}

}
