footer {
  border-top: 6px solid $blue-dark;
  background:  $footer-bg url(../images/bg/footer-bg-sm.jpg) no-repeat 50% 50%;
  text-shadow: 1px 1px 2px rgba(black,0.65);
  .maintenance-page & {
    background-image: none;
  }
  //.show-page-nav & {
  //  @include rem(padding-bottom, $fab-size*1.5);
  //}
  > .inner {
    @include rem(padding, $space 0 0);
    background: rgba(0, 0, 0, .7);
  }
  .teaser a,
  a {
    @include link-colors(white, $link-color-alt, $link-color-alt, white, white);
  }

  &,.h2, .h3, .h4 {
    color: white;
  }
  p {
    margin: 0;
  }
  .h2,.h3, .h4 {
    @include rem(margin-bottom, $space-half);
  }
  .teaser .b-text-wrapper {padding: 0}
  .menu {
    @extend %reset-list;
    display: grid;
    @include rem(grid-gap, $space-half);
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    .no-flexbox & {
      @extend .cf;
    }
    li {
      .no-flexbox & {
        float: left;
      }
      flex: 1 1 auto;
    }
    a {
      display: block;
    }
  }
  .fa {
    @include rem(font-size, $font-size-h4);
    line-height: $line-height-h4;
    @include link-colors($link-color-alt, white, white, $link-color-alt, $link-color-alt);

  }
  .block, .view {
    @include rem(margin-bottom, $space);
    &:last-child {
      margin-bottom: 0;
    }
  }
  .owney {
    text-align: center;
    .disclaimer {
      display: none;
    }
  }
  // end .menu
  .region-footer-top,
  .region-footer-bottom{
    @extend %set_layout;
  }

  #block-menu-menu-social-media {
    .menu-title {
      @extend %hide-text;
    }
    .menu {
      margin: 0 auto;
      align-items: center;
      text-align: center;
    }
  }
  .region-footer-top {
    border-bottom: 1px solid #666;
    @include rem(padding-bottom, $space);
    @include rem(margin-bottom, $space);
  }

  .site-logo {
    display: block;
    font-family: $font-secondary;
    span {
      @include rem(padding-left, 45px);
      display: block;
    }
    .logo-icon {
      width: 35px;
      height: 35px;
      padding: 0;
    }
  }
  .si-name {
    @include rem(font-size, $font-size-h4);
    line-height: 1;
  }
  .site-name {
    line-height: 1.2;
    font-style: italic;

  }
  .view-promotion {
    .field-name-field-text-block {
      display: flex;
      justify-content: space-between;
    }
    .info {width: 50%}
    .map-info {width: 45%;}
  }
  @include breakpoint($tab) {
    padding-bottom: 0;
    background:  $footer-bg url(../images/bg/footer-bg-lg.jpg) no-repeat 50% 50%;

    .region-footer-top {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      @include rem(grid-column-gap, $space);
      .block {
        grid-column-end: span 4;
      }
      .view-promotion {
        grid-column-end: span 8;
        .info {width: 70%}
        .map-info {width: 27%;}
      }
      .block-menu {
        grid-column-end: span 12;
      }
    }
    .menu {
      justify-items: center;
    }
   .site-logo {
     .logo-icon {
      width: 45px;
     height: 45px;
    }
     span {
       @include rem(padding-left, 55px);
     }
   }

    .si-name {
      @include rem(font-size, 28px);
      @include rem(padding-top, 4px);
      line-height: 1;
    }
    .site-name {
      @include rem(font-size, 19px);
      line-height: 1.2;
      font-style: italic;
    }
    .owney {
      background-image: url(../images/owney.png);
      background-repeat: no-repeat;
      background-position: 10px 100%;
      @include rem(height, 240px);
      @include rem(width, 340px);
      @include rem(margin-top, $space);
      position: relative;
      .disclaimer {
        display: block;
        @include rem(font-size, $font-size-small);
        font-family: $font-secondary;
        position: absolute;
        @include rem(bottom, $space);
        @include rem(right,35px);
        @include rem(width,120px);

      }
      .disclaimer,
      .bubble {
        line-height: 1.25;
      }
      .bubble-wrapper {
        background-image: url(../images/bkg_word_bubble_owney.png);
        background-repeat: no-repeat;
        @include rem(height, 118px);
        @include rem(width, 188px);
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        text-shadow: none;
        @include rem(top, 6px);
        @include rem(right, -4px);
        @include rem(padding-bottom, $space-half);
        //position: relative;
        //width: 200px;
        //@include rem(padding, $space-and-half $pad-and-half);
        //text-align: center;
        //color: #fff;
        //background: #5a8f00;
        //background: -webkit-gradient(linear, 0 0, 0 100%, from(#b8db29), to(#5a8f00));
        //background: -moz-linear-gradient(#b8db29, #5a8f00);
        //background: -o-linear-gradient(#b8db29, #5a8f00);
        //background: linear-gradient(#b8db29, #5a8f00);
        //-webkit-border-top-left-radius: 220px 120px;
        //-webkit-border-top-right-radius: 220px 120px;
        //-webkit-border-bottom-right-radius: 220px 120px;
        //-webkit-border-bottom-left-radius: 220px 120px;
        //-moz-border-radius: 220px / 120px;
        //border-radius: 220px / 120px;
        //&:before {
        //  content: "";
        //  position: absolute;
        //  z-index: -1;
        //  bottom: -25px;
        //  right: 164px;
        //  height: 30px;
        //  border-right: 60px solid #5a8f00;
        //  background: #5a8f00;
        //  -webkit-border-bottom-right-radius: 80px 50px;
        //  -moz-border-radius-bottomright: 80px 50px;
        //  border-bottom-right-radius: 80px 50px;
        //  -webkit-transform: translate(0, -2px);
        //  -moz-transform: translate(0, -2px);
        //  -ms-transform: translate(0, -2px);
        //  -o-transform: translate(0, -2px);
        //  transform: translate(0, -2px);
        //}
        //&:after {
        //  content: "";
        //  position: absolute;
        //  z-index: -1;
        //  bottom: -25px;
        //  right: 164px;
        //  width: 60px;
        //  height: 30px;
        //  background: $footer-bg;
        //  -webkit-border-bottom-right-radius: 40px 50px;
        //  -moz-border-radius-bottomright: 40px 50px;
        //  border-bottom-right-radius: 40px 50px;
        //  -webkit-transform: translate(-30px, -2px);
        //  -moz-transform: translate(-30px, -2px);
        //  -ms-transform: translate(-30px, -2px);
        //  -o-transform: translate(-30px, -2px);
        //  transform: translate(-30px, -2px);
        //}
      }
      .bubble {
        color: $text-color;
        @include rem(padding, 12px 10px 0 18px);
        @include rem(font-size, 14px);
        a {@include link-colors($link-color, $link-hover, $link-hover, $link-color, $link-color)}
      }
    }
  }

  @include breakpoint($desk) {
    background:  $footer-bg url(../images/bg/footer-bg-wd.jpg) no-repeat 50% 50%;

    .region-footer-top {
      @include rem(grid-column-gap, 40px);
      .block {
        grid-column-end: span 3;
      }
      .view-promotion {
        grid-column-end: span 5;
        .field-name-field-text-block {
          display: flex;
          justify-content: space-between;
        }
        .info {width: 70%}
        .map-info {width: 27%}
      }
      .block-menu {
        grid-column-end: span 4;
      }
    }
    .region-footer-bottom {
      display: grid;
      @include rem(grid-column-gap, 40px);
      grid-template-columns: repeat(12, 1fr);
      justify-items: center;
      .block-menu {
        grid-column-end: span 11;
        width: 100%;
        .menu {
          display: flex;
        }
      }
    }
    .fa {
      @include rem(font-size, $font-size-h3);
      line-height: $line-height-h3;
    }
    .owney {
      @include rem(height, 200px);
      .bubble-wrapper {
        @include rem(top, -32px);
      }
      .disclaimer {
        @include rem(right, 60px);
      }
    }
  }
  @include breakpoint($wide) {
    .site-logo {
      .logo-icon {
        width: 55px;
        height: 55px;
      }
      span {
        @include rem(padding-left, 65px);
      }
    }
    .owney .bubble {
      @include rem(padding, 0 40px 0 18px);
    }
  }


}

//end region-footer
