// creates a button style
//@mixin button($color: white, $background: $blue, $border) {
//@mixin button() {
//  display: inline-block;
//  *display: inline;
//  *zoom: 1;
//  margin-bottom: 0; // For input.btn
//  font-weight: $btn-font-weight;
//  text-align: center;
//  vertical-align: middle;
//  touch-action: manipulation;
//  cursor: pointer;
//  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
//  border: 1px solid transparent;
//  white-space: nowrap;
//  // @if $shadow {
//  //   @include box-shadow(rgba(white, 0.1) 0 1px 0 inset, rgba(white, .075) 0 1px 0);
//  //   @include text-shadow(rgba(black, .25) 0 -1px 0);
//  // }
//}

%button {
  background-size: 100% 100%;
  cursor: pointer;
  display: inline-block;
  font-family: $font;
  //margin: 0 0 rhythm(1);
  //padding: .25em 1.25em;
  *display: inline;
  *zoom: 1;
 // margin-bottom: 0; // For input.btn
  font-weight: $btn-font-weight;
  text-align: center;
  touch-action: manipulation;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  text-decoration: none;
  text-transform: capitalize;
  vertical-align: baseline;

  &:focus,
  &:hover,
  &:active {
    //outline: 0;
    text-decoration: none;
  }

}


@mixin button--large {
  // @include adjust-font-size-to($font-size-normal);
  @include rem(font-size, $font-size-h4);
  @include rem(padding, $space-half $pad-half);
  @include rem(margin-bottom, $space);
  &.active {
    // margin: em($space, $font-size-h4) auto;
    @include rem(font-size, $font-size-h4);
    @include rem(padding, $space-half $pad-half);
  }
}


@mixin button($bg-color: $btn-bg-color, $btn-txt-color: $btn-default-color, $hover-bg: $btn-hover, $border: true, $shadow: false, $font-size: $btn-font-size) {
  @extend %button;
  @include rem(font-size, $font-size);
  transition:background-color 300ms ease-out, border-color 320ms ease-out;
  background-color: $bg-color;
  @if $border {
    border: 1px solid desaturate(lighten($bg-color, 15%), 5%);
  }
  @include link-colors($btn-txt-color, $btn-txt-color, $btn-txt-color, $btn-txt-color, $btn-txt-color);
  position: relative;
  z-index: 1;
  @if $shadow {
    //box-shadow: -1px 0 2px 0 rgba(0, 0, 0, 0.12), 1px 0 2px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
   box-shadow: rgba(0, 0, 0, 0.12) -1px 0 2px 0, rgba(0, 0, 0, 0.12) 1px 0 2px 0, rgba(0, 0, 0, 0.24) 0 1px 1px 0;
  }
  &:focus,
  &:hover,
  &:active  {
    background-color: $hover-bg;
    border-color: desaturate(lighten($hover-bg, 25%), 5%);
    //outline: 0;
    text-decoration: none;
    box-shadow: rgba(0, 0, 0, 0.16) -2px 0 2px 0 , rgba(0, 0, 0, 0.16) 2px 0 2px 0, rgba(0, 0, 0, 0.32)0 2px 2px 0 ;
  }
}


// @mixin button-style($color, $background, $border: false, $gradient: false, $hover: false){
//   color: $color;
//   background-color: $background;
//   @if $gradient {
//     @include background-image(linear-gradient(lighten($background, 5), darken($background, 5)));  // top, bottom color
//   }
//   @if $border {
//     border-color: $border;
//     *border: 0;
//   //  border-bottom-color: darken($border, 10%);
//     border-color: rgba(0,0,0,0.1) rgba(0,0,0,0.1) rgba(0,0,0,0.15);
//   }
//   &,
//   &:active,
//   &.active {
//     &:focus,
//     &.focus {
//       @include tab-focus;
//     }
//   }

//   &:active,
//   &.active {
//     outline: 0;
//     @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
//   }
//   &:hover,
//   &:focus,
//   &.focus,
//   &:active,
//   &.active,
//   .open > &.dropdown-toggle {
//     color: $color;
//     text-decoration: none;
//     @if $hover {
//       background-color: $hover;
//     }
//     @else {
//       background-color: darken($background, 20);
//     }

//     @if $border {
//       border-color: darken($border, 25);
//     }
//     @if $gradient {
//       @include background-image(linear-gradient(darken($background, 20), darken($background, 10)));  // top, bottom color
//     }
//   }
//   &:active,
//   &.active,
//   .open > &.dropdown-toggle {
//     background-image: none;
//   }
//   &.disabled,
//   &[disabled],
//   fieldset[disabled] & {
//     &,
//     &:hover,
//     &:focus,
//     &.focus,
//     &:active,
//     &.active {
//       background-color: $background;
//           border-color: $border;
//       pointer-events: none; // Future-proof disabling of clicks
//       @include opacity(.65);
//       @include box-shadow(none);
//     }
//   }

//   .badge {
//     color: $background;
//     background-color: $color;
//   }



// }

// // Button sizes
// @mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius: 0) {
//   @include rem(padding, $padding-vertical) em($padding-horizontal);
//   @include rem(font-size, ($font-size);
//   line-height: $line-height;
//   @if $border-radius > 0 {
//     @include border-radius($border-radius);
//   }
//   @else {
//     border-radius: 0;
//   }
// }

// Button Image
@mixin button-image($width, $height, $icon-url, $bg: false) {
  width: $width;
  height: $height;
  margin: 0;
  padding: 0;
  border: 0;
  background: {
    image: image-url($icon-url);
    repeat: no-repeat;
    position:  50% 50%;
    size: contain;
  }
  @if $bg {
    background-color: $bg;
  }
  @else {
    background-color: transparent;
  }
  text-indent: -1000em;
  cursor: pointer; /* hand-shaped cursor */
  cursor: hand; /* for IE 5.x */
  opacity:.8;
  &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      opacity: 1;
    }
}
