.book-navigation {
	.page-links {
		display: flex;
		width: 100%;
		@include rem(margin, $space-and-half 0 $space);
		border-top-color: $gray-light;
		border-bottom: none;
		justify-content: space-between;

	}

}

.panel-region-bottom {
  .pane-node-book-nav {
	border-top: 1px solid $gray-light;
  }
  .book-navigation {
	@include set-layout($max-width);
  }
  .page-links {
	border-top: none;
  }
}