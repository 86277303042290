/*** MOBILE STYLES ***/

ul.sf-menu.sf-accordion {
  display: none;
  position: absolute;
  &,
  ul,
  li {
    float: left;
    width: 100%;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  &.sf-expanded,
  & li.sf-expanded > ul,
  & li.sf-expanded > .sf-mega{
    left: auto !important;
    position: relative;
    top: auto !important;
  }
  li.menuparent {
    > a {
      @include font-icon('\f107', 24px, 29px, 6px, 6px, true, false);
      &:after {
        transition: all .4s cubic-bezier(.55,.085,0,.99);
      }
    }
    &.sf-expanded {
      > a:after {
        transform: rotate(180deg);
      }
    }
  }
}


/*** ESSENTIAL STYLES ***/
.sf-menu {
  float: left;
  
  &,
  * {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  li {
    position: relative;
    background: $nav-bg;
    //white-space: nowrap; /* no need for Supersubs plugin */
    //*white-space: normal; /* ...unless you support IE7 (let it wrap) */
    transition: background .2s, color .2s;
    
    p{
      white-space: normal;
    }
  }
  .sf-mega,
  ul {
    position: absolute;
    display: none;
    top: 100%;
    left: 0;
    z-index: 200;
    min-width: 12em; /* allow long menu items to determine submenu width */
    *width: 12em; /* no auto sub width for IE7, see white-space comment below */
  }
  .sf-mega ul {
    display: block;
    position: relative;
    top: auto;
    width: 100%;
  }
  > li {
    float: left;
  }
  
  li:hover > ul,
  li.sfHover > ul {
    display: block;
  }
  
  a {
    display: block;
    position: relative;
    @include rem(padding, $space-half 18px);
    text-decoration: none;
    zoom: 1; /* IE7 */
    
  }
  
  ul ul {
    top: 0;
    left: 100%;
  }
  ul li {
    background: $nav-sub-bg;
  }
  ul ul li {
    background: $gray;
  }
  li:hover,
  li.sfHover {
    /* only transition out, not in */
    -webkit-transition: none;
    transition: none;
  }
}


/*** arrows (for all except IE7) **/
.sf-arrows .sf-with-ul {
  padding-right: 2.5em;
  *padding-right: 1em; /* no CSS arrows for IE7 (lack pseudo-elements) */
}
/* styling for both css and generated arrows */
.sf-arrows .sf-with-ul:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 1em;
  margin-top: -3px;
  height: 0;
  width: 0;
  /* order of following 3 rules important for fallbacks to work */
  border: 5px solid transparent;
  border-top-color: #dFeEFF; /* edit this to suit design (no rgba in IE8) */
  border-top-color: rgba(255,255,255,.5);
}
.sf-arrows > li > .sf-with-ul:focus:after,
.sf-arrows > li:hover > .sf-with-ul:after,
.sf-arrows > .sfHover > .sf-with-ul:after {
  border-top-color: white; /* IE8 fallback colour */
}
/* styling for right-facing arrows */
.sf-arrows ul .sf-with-ul:after {
  margin-top: -5px;
  margin-right: -3px;
  border-color: transparent;
  border-left-color: #dFeEFF; /* edit this to suit design (no rgba in IE8) */
  border-left-color: rgba(255,255,255,.5);
}
.sf-arrows ul li > .sf-with-ul:focus:after,
.sf-arrows ul li:hover > .sf-with-ul:after,
.sf-arrows ul .sfHover > .sf-with-ul:after {
  border-left-color: white;
}
