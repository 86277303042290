.search-result {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px $gray-dark;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  flex-direction: column-reverse;
  @include breakpoint($tab) {
    flex-direction: row;
  }
}
.search-result__content {
  margin-right: 2rem;
}
.search-result__media {
  width: 200px;
  height: 150px;
  max-height: 150px;
  flex: 0 0 200px;
  overflow: hidden;
  margin-bottom: 1.5rem;

  @include breakpoint($tab) {
    margin-bottom: 0;
  }
}
.search-result__image {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
