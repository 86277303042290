.panel-region-hero {
  .pane-node-field-slideshow {
	background: none;

	//.b-text-wrapper {
	//  position: absolute;
	//  bottom: 6%;
	//  left: 12%;
	//}

	p {
	  margin-bottom: 0;
	  display: none;
	}
	figcaption p {
	  display: block;
	}
  }

  .b-text-wrapper {
	border: none;
	padding: 0;
	* {
	  color: white;
	}
	text-shadow: rgba(black, 0.35) 1px 1px 2px;
  }
  //end flexslider
}


.paragraphs-item-flickr-slideshow,
.paragraphs-item-slideshow-images{
  img {
	width: auto !important;
	max-width: 100%;
	max-height: 360px;
	margin: auto;
  }

  .title {
	margin: 0;
	@include rem(font-size, $font-size-h4);
	line-height: $line-height-h4;
  }
  .caption {
	display: none;
  }
  .wrapper--text {
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 12%;
  }

  .b-text-wrapper {
	@extend %set_layout;
	display: block;
	background: rgba(0,0,0, .5);
  }
}


@include breakpoint($tab) {
  .paragraph--type--slideshow {
	.caption {
	  display: block;
	}
	.b-text-wrapper {
	  width: 80%;
	}
	.title {
	  @include rem(font-size, $font-size-h3);
	  line-height: $line-height-h3;
	}
  }

	.panel-region-hero {
	  .pane-node-field-slideshow {
		p {
		  display: block
		}
	  }
	}
}

@include breakpoint($desk) {
	.slick--optionset--multiple-2 {
		.slide {
			@include rem(margin-right, $pad);
		}
	}
}
